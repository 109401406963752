export const breakPoints = {
  xl: "1440px",
  lg: "1024px",
  md: "768px",
  sm: "425px",
  xs: "375px",
  xxs: "320px",
};

export const MAXIMUM_SIZE = 100; // 100MB

export const STAN_ROLE = {
  USER: 1,
  TALENT: 2,
  ADMIN_OPERATION_MANAGER: 3,
  SADMIN: 4,
  CHARITY: 5,
  ADVERTISEMENT: 6,
  ADMIN_FINANCE: 7,
  NEWS: 8,
};

export const NFT_SORT_BY = {
  RECENTLY_CREATED: "RECENTLY_CREATED",
  RECENTLY_LISTED: "RECENTLY_LISTED",
  OLDEST: "OLDEST",
  PRICE_LOW_HIGH: "PRICE_LOW_HIGH",
  PRICE_HIGH_LOW: "PRICE_HIGH_LOW",
  NAME_ASC: "NAME_ASC",
  NAME_DESC: "NAME_DESC",
  DATE_TIME_ASC: "DATE_TIME_ASC",
  DATE_TIME_DESC: "DATE_TIME_DESC",
};

export const HOME_SECTIONS = {
  HOT_COLLECTION: "HOT_COLLECTION",
  HOT_OFFER: "HOT_OFFER",
  ARTIST_LATEST_NFT: "ARTIST_LATEST_NFT",
  LATEST_SALE: "LATEST_SALE",
  LIVE_AUCTION: "LIVE_AUCTION",
  FIXED_PRICE: "FIXED_PRICE",
};

// STAN TAB PANE
export const PROFILE_TABPANE = {
  PROFILE_COLLECTIONS: "PROFILE_COLLECTIONS",
  PROFILE_OWNED: "PROFILE_OWNED",
  PROFILE_CREATED: "PROFILE_CREATED",
  PROFILE_ACTIVITY: "PROFILE_ACTIVITY",
  PROFILE_LISTING: "PROFILE_LISTING",
  PROFILE_OFFERS_SENT: "PROFILE_OFFERS_SENT",
  PROFILE_OFFERS_RECEIVED: "PROFILE_OFFERS_RECEIVED",
};

export const EXPLORE_TABPANE = {
  EXPLORE_COLLECTIONS: "EXPLORE_COLLECTIONS",
  EXPLORE_ITEMS: "EXPLORE_ITEMS",
  EXPLORE_ARTISTS: "EXPLORE_ARTISTS",
};

export const NFT_DETAIL_TABPANE = {
  ABOUT: "ABOUT",
  OFFERS: "OFFERS",
  HISTORY: "HISTORY",
};

export const COLLECTION_DETAIL_TABPANE = {
  ITEMS: "ITEMS",
  ACTIVITY: "ACTIVITY",
};
// STAN TAB PANE

export const NFT_ACTIVITY_TYPE = {
  MINT: "MINT",
  CREATE_NFT_BY_STAN: "CREATE_NFT_BY_STAN",
  LIST: "LIST",
  SALE: "SALE",
  TRANSFER: "TRANSFER",
  TRANSFER_NFT_PVP: "TRANSFER_NFT_PVP",
  OFFER: "OFFER",
  CANCEL_LISTING: "CANCEL_LISTING",
  CANCEL_OFFER: "CANCEL_OFFER",
  BUY: "BUY",
  AUCTION: "AUCTION",
};

// MEDIA TYPES
export const MEDIA_TYPE = {
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
  LIVESTREAM: "LIVESTREAM",
  AUDIO: "AUDIO",
};

// PATHS
export const EXPLORE_PATH = {
  EXPLORE: "/explore",
  CATEGORY: "/explore/category/:category",
  HASHTAG: "/explore/hashtag/:hashtag",
  LIST_OF_OWNERS_IN_COLLECTION:
    "/explore/listOfOwnersInCollection/:collectionId",
  LIST_OF_OWNERS: "/explore/listOfOwners/:walletAddress",
  SEARCH: "/search/:keyword",
  HOT_COLLECTIONS: "/hotCollections",
  HOT_OFFERS: "/hotOffers",
  LIVE_AUCTION: "/liveAuction",
  FIXED_PRICE: "/fixedPrice",
  ARTIST_LATEST_NFT: "/artistLatestNFT",
  LATEST_SALE: "/latestSale",
  PROFILE: "/profile/:walletAddress",
  COLLECTION_DETAIL: "/collectionDetail/:collectionId",
  CREATE_COLLECTION: "/createCollection",
  EDIT_COLLECTION: "/editCollection/:collectionId",
  CREATE_NFT: "/createNFT",
  NFT_DETAIL: "/nft/:id",
  QR_AUTHENTICATION: "/qrAuthentication",
  HOME: "/home",
  TOKEN: "/:token",
};

export const COMMON_PATH = {
  NOT_FOUND_404: "/404",
};

// REGEX
export const REGEX_SPECIAL =
  '^[^ <>{}"/|;:.,~!?@#$%^=&*\\]\\\\()\\[¿§«»ω⊙¤°℃℉€¥£¢¡®©_+]*$';

export const REGEX_EMOJI =
  /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}\u{200d}]*/gu;

export const REGEX_DECIMAL = "^(0|[1-9]d*)(.d{0,5})?$";
// REGEX

export const MsgInsufficientBalance =
  "Insufficient balance. Please top-up your StanFund to proceed this transaction";
