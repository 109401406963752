import { Spin } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MaticIcon, WETHIcon } from "../../../common/commonSvg";
import ModalInfoNFT from "../../../component/ModalInfoNFT/ModalInfoNFT";
import StanButton from "../../../component/StanButton/StanButton";
import {
  cancelListingAuction,
  cancelListingFixedPrice,
  getStanFixed,
} from "../../../redux/action/AutionAction";
import { OPEN_DEPOSIT } from "../../../redux/types/ModalType";
import {
  COMPLETE_REQUEST,
  FAILED_REQUEST,
  INSUFFICENT_FUND,
  PENDING_REQUEST,
  PROCESSING,
  REQUESTING,
} from "../../../redux/types/UserType";
import { NETWORK_URL } from "../../../util/settings/config";
import { fromEtherToValue } from "../../../util/settings/utils";
import "./CancelListing.css";
export default function CancelListing(props) {
  const { transactionList } = useSelector((state) => state.NFTReducer);
  const { nftStatus, stanFixed, txtHash } = useSelector((state) => state.AutionReducer);
  const { payload, onClose } = useSelector((state) => state.ModalReducer);
  const { nft } = payload;

  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "nft"]);

  useEffect(() => {
    dispatch(getStanFixed());
  }, []);

  function handleCancelNFT() {
    const transaction = transactionList.find(
      (transaction) =>
        (transaction.type === "LIST" || transaction.type === "AUCTION") &&
        transaction.status === "ACTIVE"
    );
    if (transaction) {
      if (nft.status === "BUY") {
        dispatch(
          cancelListingFixedPrice({
            transactionId: transaction.id,
          })
        );
      } else {
        dispatch(
          cancelListingAuction({
            transactionId: transaction.id,
          })
        );
      }
    } else {
      if (nft.type === "LIST") {
        dispatch(
          cancelListingFixedPrice({
            transactionId: nft.id,
          })
        );
      } else {
        dispatch(
          cancelListingAuction({
            transactionId: nft.id,
          })
        );
      }
    }
  }

  function renderContent() {
    switch (nftStatus) {
      case REQUESTING:
        return (
          <>
            <ModalInfoNFT
              nft={nft}
              currency={nft.status === "BUY" ? "STC" : "STF"}
            />
            <div className="loadingTransferNFT">
              <p>{t("TEXT.CONFIRM_METAMASK", { ns: "nft" })}</p>
            </div>
          </>
        );
      case PROCESSING:
        return (
          <div className="loadingTransferNFT">
            <Spin style={{ margin: "0px 10px" }} />
            <p>{t("TEXT.PROCESSING", { ns: "nft" })}</p>
          </div>
        );
      case COMPLETE_REQUEST:
        return (
          <div className="loadingCancelSuccess">
            {/* <img alt="" src="/img/Confetti.png" width={150} height={150} /> */}
            <ModalInfoNFT nft={nft} />
            <p>{t("TEXT.CANCEL_LISTING_NFT_SUCCESSFULLY", { ns: "nft" })}</p>
            {/* <StanButton
              title={t("BUTTON.DONE")}
              handleOnClick={() => {
                onClose();
              }}
              type="primary"
            /> */}
            <StanButton
              title={t("BUTTON.VIEW_TRANSACTION")}
              type="primary"
              handleOnClick={() => window.open(NETWORK_URL + "tx/" + txtHash)}
            />
          </div>
        );
      case FAILED_REQUEST:
        return onClose();
      case INSUFFICENT_FUND:
        return (
          <div className="completeTransferNFT">
            <img
              alt=""
              src="/img/insufficent-fund.png"
              width={150}
              height={150}
            />
            <p>{t("TEXT.INSUFFICIENT_FUND", { ns: "nft" })}</p>
            <StanButton
              title={t("BUTTON.RECHARGE")}
              handleOnClick={() => {
                dispatch({ type: OPEN_DEPOSIT });
              }}
              type="primary"
            />
            <StanButton
              title={t("BUTTON.CANCEL")}
              handleOnClick={() => {
                props.closeModal();
              }}
              type="secondary"
            />
          </div>
        );
      default:
        return (
          <div>
            <div className="d-flex flex-column mb-3">
              <span>
                {t("TEXT.CANCELLING_LISTINGS_DESCRIPTION", { ns: "nft" })}
              </span>
              {nft.status === "ON_BID" ? (
                <div className="d-flex justify-content-between mt-3">
                  <span>
                    {t("TEXT.SERVICE_FEE", { ns: "nft" }).toUpperCase()}
                  </span>
                  <span>
                    <MaticIcon />{" "}
                    {fromEtherToValue(stanFixed?.feeCancelListingAuction)}
                  </span>
                </div>
              ) : null}
            </div>
            <ModalInfoNFT nft={nft} />
            <div className="BuyNftBody mt-3">
              <StanButton
                handleOnClick={() => handleCancelNFT()}
                title={t("BUTTON.CANCEL_NOW")}
                disable={nftStatus !== PENDING_REQUEST}
                type="primary"
              />
            </div>
          </div>
        );
    }
  }

  return renderContent();
}
