import { useState } from "react";
import { openNotificationWithIcon } from "../../App";
import { REGEX_EMOJI, REGEX_SPECIAL } from "../../common/Constant";
import { preventSpecialCharacters } from "../../util/settings/utils";
import "./StanHashtags.css";

export default function StanHashtags(props) {
  const [tags, setTags] = useState(props.hashtags || []);
  const regex = new RegExp("^[a-zA-Z0-9]+$");
  const regexSpecial = new RegExp(REGEX_SPECIAL);
  const regexEmoji = new RegExp(REGEX_EMOJI);

  const addTags = (event) => {
    // CHECK DUPLICATE HASHTAG
    // if (tags.find((tag) => tag === event.target.value)) {
    //   openNotificationWithIcon("error", "Error", "Duplicate hashtag");
    //   return;
    // }
    if (
      event.target.value.trim() !== "" &&
      regexSpecial.test(event.target.value.trim()) &&
      regexEmoji.test(event.target.value.trim())
    ) {
      setTags([...tags, event.target.value]);
      props.setHashtags([...tags, event.target.value]);
      event.target.value = "";
    } else {
      openNotificationWithIcon("error", "Error", "Invalid hashtag!");
    }
  };

  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
    props.setHashtags([...tags.filter((_, index) => index !== indexToRemove)]);
  };

  return (
    <div className="hashTags">
      <ul id="tags">
        {tags.map((tag, index) => (
          <li key={index} className="tag fade-in">
            <span className="tag-title">{tag}</span>
            <span className="tag-close-icon" onClick={() => removeTags(index)}>
              x
            </span>
          </li>
        ))}
      </ul>
      <input
        type="text"
        maxLength={50}
        disabled={tags.length >= 10}
        placeholder="Press space/enter to add tag"
        // onKeyPress={preventSpecialCharacters}
        onKeyUp={(event) =>
          event.key === "Enter" || event.code === "Space"
            ? addTags(event)
            : null
        }
      />
    </div>
  );
}
