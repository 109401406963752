import { Image, Spin } from "antd";
import Cookies from "js-cookie";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MaticIcon } from "../../../common/commonSvg";
import StanButton from "../../../component/StanButton/StanButton";
import StanTypo from "../../../component/StanTypo/StanTypo";
import {
  acceptOffer,
  acceptOfferAuction,
  getBalanceOfBuyer,
  getStanRoyalty,
  getStanServiceFee,
} from "../../../redux/action/AutionAction";
import {
  COMPLETE_REQUEST,
  FAILED_REQUEST,
  PROCESSING,
  REQUESTING,
} from "../../../redux/types/UserType";
import { NETWORK_URL } from "../../../util/settings/config";
import {
  formatStanCryptoDetail,
  formatStanUsd,
} from "../../../util/settings/utils";
import "./AcceptOffer.css";
import { openNotificationWithIcon } from "../../../App";
import { MsgInsufficientBalance } from "../../../common/Constant";

export default function AcceptOffer(props) {
  //____________ INITIAL STATE ____________//
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "nft"]);
  const { payload, onClose } = useSelector((state) => state.ModalReducer);
  const { nftStatus, txtHash, stanServiceFee, stanRoyalty, balanceOfBuyer } =
    useSelector((state) => state.AutionReducer);
  const { nft, selectedOffer } = payload;
  const refVal = useRef();
  //______________________________________//

  useEffect(() => {
    refVal.current = balanceOfBuyer;
  }, [balanceOfBuyer]);

  useEffect(() => {
    dispatch(getStanRoyalty());
    dispatch(getStanServiceFee());
    dispatch(getBalanceOfBuyer(selectedOffer.buyerWalletAddress));
  }, [nft]);

  //____________ HANDLE FUNCs ____________//
  function handleAccept() {
    if (
      refVal.current &&
      Number(refVal.current) < Number(selectedOffer?.priceCrypto) &&
      selectedOffer.currency === "POLYGON" && //make offer on web
      !selectedOffer.isAuction //isAuction=true  - user paied
    ) {
      openNotificationWithIcon(
        "error",
        "Error",
        t("MSG.MAKE_OFFER_FAILD", { ns: "nft" })
      );
      return;
    }
    if (selectedOffer?.isAuction) {
      dispatch(acceptOfferAuction(nft, selectedOffer));
    } else {
      dispatch(acceptOffer(nft, selectedOffer));
    }
  }

  function calculateTotal(nftPrice) {
    if (selectedOffer?.isAuction) {
      if (nft.creatorAddress !== Cookies.get("UserAddress")) {
        return (
          nftPrice -
          (nftPrice *
            (+stanServiceFee?.ratioAcceptOfferAuctionVal +
              +stanRoyalty?.ratioCreatorVal +
              +stanRoyalty?.ratioStanVal)) /
            100
        );
      } else if (nft.creatorAddress === Cookies.get("UserAddress")) {
        return (
          nftPrice -
          (nftPrice *
            (+stanServiceFee?.ratioAcceptOfferAuctionVal +
              +stanRoyalty?.ratioStanVal)) /
            100
        );
      }
    } else {
      if (nft.creatorAddress !== Cookies.get("UserAddress")) {
        return (
          nftPrice -
          (nftPrice *
            (+stanServiceFee?.ratioAcceptOfferPvPVal +
              +stanRoyalty?.ratioCreatorVal +
              +stanRoyalty?.ratioStanVal)) /
            100
        );
      } else if (nft.creatorAddress === Cookies.get("UserAddress")) {
        return (
          nftPrice -
          (nftPrice *
            (+stanServiceFee?.ratioAcceptOfferPvPVal +
              +stanRoyalty?.ratioStanVal)) /
            100
        );
      }
    }
  }
  //______________________________________//

  //____________ RENDER ____________//

  const FeeSummary = () => {
    return (
      <>
        <div className="d-flex flex-column mt-3">
          <span>{t("TITLE.FEE_SUMMARY", { ns: "nft" })}</span>
          <div className="d-flex justify-content-between mt-3">
            {selectedOffer?.isAuction ? (
              <>
                <span>{t("TITLE.AUCTION_FINISH", { ns: "nft" })}</span>
                <span>
                  {+stanServiceFee?.ratioAcceptOfferAuctionVal +
                    +nft?.creatorRoyalty +
                    +stanRoyalty?.ratioStanVal}
                  %
                </span>
              </>
            ) : (
              <>
                <span>{t("TITLE.FIXED_PRICE_FINISH", { ns: "nft" })}</span>
                <span>
                  {+stanServiceFee?.ratioAcceptOfferPvPVal +
                    +nft?.creatorRoyalty +
                    +stanRoyalty?.ratioStanVal}
                  %
                </span>
              </>
            )}
          </div>
          <div>
            <StanTypo
              fontSize={14}
              fontWeight={400}
              color="#939393"
              content={t("TEXT.TOTAL_ROYALTY_AND_SERVICE_FEE", { ns: "nft" })}
            />
          </div>
        </div>
        {nft.creatorAddress === Cookies.get("UserAddress") ? (
          <div className="d-flex flex-column mt-3">
            <div className="d-flex justify-content-between">
              <span>{t("TITLE.CREATOR_ROYALTY", { ns: "nft" })}</span>
              <span>{+nft?.creatorRoyalty}%</span>
            </div>
            <div>
              <StanTypo
                fontSize={14}
                fontWeight={400}
                color="#939393"
                content={t("TEXT.CREATOR_EARNING_BONUS", { ns: "nft" })}
              />
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const renderContent = useMemo(() => {
    switch (nftStatus) {
      case REQUESTING:
        return (
          <>
            <div className="feeSummary">
              <span>{t("TEXT.WAITING_MESSAGE", { ns: "nft" })}</span>
            </div>
            <div style={{ marginTop: "20px" }}>
              <StanButton
                type="primary"
                title={t("BUTTON.VIEW_TRANSACTION")}
                disabled
              />
            </div>
          </>
        );
      case PROCESSING:
        return (
          <>
            <div className="processing mt-2 mb-2">
              <Spin style={{ marginRight: "10px" }} />
              <span>{t("TEXT.PROCESSING", { ns: "nft" })}</span>
            </div>
            <div>
              <StanButton
                disabled={!txtHash}
                title={t("BUTTON.VIEW_TRANSACTION")}
                type="primary"
                handleOnClick={() =>
                  window.open("https://mumbai.polygonscan.com/tx/" + txtHash)
                }
              />
            </div>
          </>
        );
      case COMPLETE_REQUEST:
        return (
          <>
            <div className="d-flex justify-content-center mt-4 mb-4">
              <StanTypo
                fontSize={20}
                fontWeight={500}
                content={t("TEXT.ACCEPT_OFFER_SUCCESSFULLY", {
                  ns: "nft",
                })}
              />
            </div>
            <div className="d-flex justify-content-center mb-4">
              <span>
                {nft.name} {t("TEXT.WILL_BE_TRANSFERRED", { ns: "nft" })}{" "}
                {selectedOffer?.buyerWalletAddress}
              </span>
            </div>
            <div style={{ display: "flex" }}>
              <StanButton
                disabled={!txtHash}
                title={t("BUTTON.VIEW_TRANSACTION")}
                type="primary"
                handleOnClick={() => window.open(NETWORK_URL + "tx/" + txtHash)}
              />
              {/* <StanButton
              type="primary"
              title={t("BUTTON.DONE")}
              style={{ margin: "5px" }}
              handleOnClick={() => {
                onClose();
              }}
            /> */}
            </div>
          </>
        );
      case FAILED_REQUEST:
        return (
          <>
            <div className="feeSummary">
              <span>{t("TEXT.TRANSACTION_FAILED", { ns: "nft" })}</span>
            </div>
            <div style={{ marginTop: "20px", display: "flex" }}>
              <StanButton
                type="primary"
                title={t("BUTTON.GO_BACK")}
                handleOnClick={() => {
                  onClose();
                }}
              ></StanButton>
              <StanButton
                disabled={!txtHash}
                title={t("BUTTON.VIEW_TRANSACTION")}
                type="primary"
                handleOnClick={() =>
                  window.open("https://mumbai.polygonscan.com/tx/" + txtHash)
                }
              />
            </div>
          </>
        );
      default:
        return (
          <>
            <FeeSummary />
            <div className="totalEarning">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>{t("TEXT.TOTAL_EARNINGS", { ns: "nft" })}</span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span>
                  <MaticIcon />{" "}
                  {formatStanCryptoDetail(calculateTotal(selectedOffer?.price))}
                </span>
                <span style={{ color: "#CBCBCB" }}>
                  $ {formatStanUsd(calculateTotal(selectedOffer?.priceUsd))}
                </span>
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <StanButton
                type="primary"
                title={t("BUTTON.ACCEPT")}
                handleOnClick={() => handleAccept()}
                disabled={nftStatus === REQUESTING}
              />
            </div>
          </>
        );
    }
  }, [nftStatus, nft, selectedOffer, stanRoyalty, stanServiceFee]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="acceptOffer">
          <div>
            <Image
              width={56}
              height={56}
              style={{ objectFit: "cover" }}
              src={
                nft.mediaPreviewUrl
                  ? nft.mediaPreviewUrl
                  : nft.mediaUrl || nft.nftMediaUrl
              }
              preview={false}
            />
          </div>
          <div className="nftInfo">
            <span style={{ color: "#939393" }}>{nft.collectionName}</span>
            <span>{nft.name}</span>
          </div>
        </div>
        <div className="nftPrice">
          <span style={{ borderRight: "1px solid gray", padding: "0px 5px" }}>
            <MaticIcon /> {formatStanCryptoDetail(selectedOffer?.priceCrypto)}
          </span>
          <span style={{ padding: "0px 5px", color: "#CBCBCB" }}>
            $ {formatStanUsd(selectedOffer?.priceUsd)}
          </span>
        </div>
      </div>
      {renderContent}
    </>
  );
}
