import { Badge, Collapse, Image, Spin } from "antd";
import Cookies from "js-cookie";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useParams } from "react-router-dom";
import { history } from "../../../../App";
import {
  MaticIcon,
  StanPointIcon,
  WETHIcon,
  offersReceiveSvg,
  visitSvg,
} from "../../../../common/commonSvg";
import StanButton from "../../../../component/StanButton/StanButton";
import StanTypo from "../../../../component/StanTypo/StanTypo";
import {
  getListOffer,
  getListProfileOffer,
} from "../../../../redux/action/AutionAction";
import { getReceivedOffers } from "../../../../redux/action/UserAction";
import {
  CLOSE_ACCEPT_OFFER,
  OPEN_ACCEPT_OFFER,
} from "../../../../redux/types/ModalType";
import {
  CLEAR_STATE,
  PROCESSING,
  REQUESTING,
} from "../../../../redux/types/UserType";
import {
  formatStanCryptoDetail,
  formatStanPoint,
  formatStanUsd,
} from "../../../../util/settings/utils";
const { Panel } = Collapse;

export default function OffersReceived(props) {
  const { t } = useTranslation(["common", "nftDetail"]);
  const dispatch = useDispatch();
  const { walletAddress } = useParams();
  const [receivedOfferList, setReceivedOfferList] = useState([]);
  const { nftStatus, listOffer } = useSelector((state) => state.AutionReducer);
  const { listReceivedOffers } = useSelector((state) => state.UserReducer);

  useEffect(() => {
    setReceivedOfferList(listOffer);
  }, [listOffer]);

  const onChange = (key) => {
    setReceivedOfferList([]);
    if (key && key !== "") {
      dispatch(
        getListProfileOffer(
          { nftId: key, isProfile: "RECEIVED", walletAddress: walletAddress },
          50,
          1
        )
      );
    }
  };

  const handleViewDetailNft = (id) => {
    dispatch({ type: CLEAR_STATE });
    history.push(generatePath("/nft/:id", { id }));
  };

  const ReceivedOfferItem = (props) => {
    const dispatch = useDispatch();
    const { offer, nft } = props;

    // function handleAcceptOffer(offer) {
    //   if (offer.isAuction) {
    //     dispatch(
    //       acceptOfferAuction(
    //         { id: offer.nftId },
    //         { id: offer.id, auctionId: offer.auctionId }
    //       )
    //     );
    //   } else {
    //     dispatch(acceptOffer({ id: offer.nftId }, { id: offer.id }));
    //   }
    // }

    return (
      <>
        <div className="offersMadeItem d-flex justify-content-between align-items-center">
          <div
            style={{ width: "43%" }}
            className="d-flex flex-row justify-content-center align-items-center"
          ></div>

          <div
            style={{ width: "25%" }}
            className="d-flex flex-row justify-content-center align-items-center offersMadeItem-price"
          >
            <div className="d-flex flex-column mr-2">
              <StanTypo
                fontSize={20}
                fontWeight={700}
                color="#0D0D0D"
                content={
                  <>
                    <MaticIcon /> {formatStanCryptoDetail(offer.priceCrypto)}
                  </>
                }
              />
              <StanTypo
                fontSize={12}
                fontWeight={400}
                color="##939393"
                content={<>$ {formatStanUsd(offer.priceUsd)}</>}
              />
            </div>
            <div>
              <StanTypo
                fontSize={12}
                fontWeight={400}
                color="#939393"
                content={
                  <>
                    <StanPointIcon />
                    {formatStanPoint(offer.pricePoint)}
                  </>
                }
              />
            </div>
          </div>

          <div
            style={{ width: "25%" }}
            className="d-flex flex-column justify-content-center align-items-start offersMadeItem-info"
          >
            <div style={{ width: "75%" }} className="ellipsisText">
              <span className="mr-3">{t("FROM")}</span>
              <span style={{ color: "#35A075" }}>
                {offer.buyerWalletAddress === Cookies.get("UserAddress")
                  ? "You"
                  : offer.userName}
              </span>
            </div>
            <div>
              <span>
                {moment(offer.startDate).startOf("minutes").fromNow()}{" "}
                {visitSvg(offer.transactionTxUrl)}{" "}
              </span>
              <span>
                {t("TEXT.EXPIRES", {
                  day: moment(offer.endDate).startOf("hour").fromNow(),
                })}
              </span>
            </div>
          </div>

          <div
            style={{ width: "10%" }}
            className="d-flex justify-content-center align-items-center offersMadeItem-button"
          >
            {offer.sellerWalletAddress === Cookies.get("UserAddress") ? (
              <StanButton
                type="primary"
                hidden={nft.isMobile}
                title={t("BUTTON.ACCEPT")}
                handleOnClick={() => {
                  dispatch({
                    type: OPEN_ACCEPT_OFFER,
                    payload: { nft: nft, selectedOffer: offer },
                    onClose: () => {
                      dispatch({ type: CLOSE_ACCEPT_OFFER });
                      dispatch(getListOffer(nft.id, 50, 1));
                      dispatch(getReceivedOffers(walletAddress));
                    },
                  });
                }}
              />
            ) : null}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="moreTab">
      <div className="moreTabContent">
        <div
          style={{
            padding: "15px 30px",
            border: "1px solid #EEEEEE",
            display: "flex",
            alignItems: "center",
          }}
        >
          <h3>
            {offersReceiveSvg()} {t("TABPANE.ACTIVE_OFFER_RECEIVED")}
          </h3>
        </div>
        <Collapse accordion onChange={onChange}>
          {listReceivedOffers.map((item, index) => {
            return (
              <Panel
                key={item.id}
                header={
                  <>
                    <div className="nftWithOffers d-flex justify-content-between align-items-center">
                      <div
                        style={{ width: "40%" }}
                        className="d-flex flex-row justify-content-start align-items-center nftWithOffers-info"
                      >
                        <div className="mr-3">
                          <Badge count={item.totalOffers}>
                            <Image
                              src={item.nftMediaUrl}
                              width={56}
                              height={56}
                              onClick={() => handleViewDetailNft(item.id)}
                              style={{
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                            />
                          </Badge>
                        </div>
                        <div className="d-flex flex-column justify-content-start align-items-start">
                          <span>{item.collectionName}</span>
                          <span style={{ fontWeight: 600 }}>{item.name}</span>
                        </div>
                      </div>

                      <div
                        style={{ width: "25%" }}
                        className="d-flex flex-row justify-content-center align-items-center nftWithOffers-price"
                      >
                        <div className="d-flex flex-column mr-2">
                          <StanTypo
                            fontSize={20}
                            fontWeight={700}
                            color="#0D0D0D"
                            content={
                              <>
                                <MaticIcon />{" "}
                                {formatStanCryptoDetail(item.priceCrypto)}
                              </>
                            }
                          />
                          <StanTypo
                            fontSize={12}
                            fontWeight={400}
                            color="##939393"
                            content={<>$ {formatStanUsd(item.priceUsd)}</>}
                          />
                        </div>
                        <div>
                          <StanTypo
                            fontSize={12}
                            fontWeight={400}
                            color="#939393"
                            content={
                              <>
                                <StanPointIcon />
                                {formatStanPoint(item.pricePoint)}
                              </>
                            }
                          />
                        </div>
                      </div>

                      <div
                        style={{ width: "25%" }}
                        className="d-flex justify-content-start align-items-center nftWithOffers-expiredTime"
                      >
                        <span>
                          {moment(item.startDate).startOf("minutes").fromNow()}
                        </span>
                      </div>

                      <div style={{ width: "10%" }}></div>
                    </div>
                  </>
                }
              >
                <Spin
                  className="mt-5"
                  tip={t("LOADING")}
                  spinning={
                    nftStatus === REQUESTING || nftStatus === PROCESSING
                  }
                >
                  {receivedOfferList.map((offer, index) => {
                    return (
                      <ReceivedOfferItem key={index} offer={offer} nft={item} />
                    );
                  })}
                </Spin>
              </Panel>
            );
          })}
        </Collapse>
      </div>
    </div>
  );
}
