import {
  ConfigProvider,
  Drawer,
  Dropdown,
  Empty,
  Image,
  Input,
  Menu,
  Pagination,
  Select,
  Table,
} from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import { history } from "../../../App";
import {
  MaticIcon,
  StanPointIcon,
  WETHIcon,
  filterSvg,
} from "../../../common/commonSvg";
import StanTypo from "../../../component/StanTypo/StanTypo";
import { searchCollection } from "../../../redux/action/CollectionAction";
import { CLEAR_STATE, REQUESTING } from "../../../redux/types/UserType";
import {
  formatStanCrypto,
  formatStanPoint,
  subStringText,
} from "../../../util/settings/utils";
import "./ExploreCollection.css";

const { Option } = Select;
export default function ExploreCollection(props) {
  const { t } = useTranslation(["common", "explore"]);
  const dispatch = useDispatch();
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [orderBy, setOrderBy] = useState("THIRTY_DAYS");
  const [inputSearch, setInputSearch] = useState("");
  const [dataFilter, setDataFilter] = useState({});
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedPageSize, setSelectedPageSize] = useState(10);
  const [propertySort, setPropertySort] = useState("");
  const [sortType, setSortType] = useState("");
  const { listCollection, totalCollection } = useSelector(
    (state) => state.CollectionReducer
  );
  const { nftStatus } = useSelector((state) => state.AutionReducer);

  useEffect(() => {
    dispatch(searchCollection(10, 1, "", inputSearch));
  }, []);

  useEffect(() => {
    dispatch(
      searchCollection(
        selectedPageSize,
        selectedPage,
        null,
        encodeURIComponent(inputSearch),
        propertySort,
        sortType
      )
    );
  }, [selectedPageSize, selectedPage, dataFilter, propertySort, sortType]);

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      setDataFilter({
        ...dataFilter,
        keyword: encodeURIComponent(inputSearch),
      });
    }
  }

  function redirectToCollection(data) {
    dispatch({ type: CLEAR_STATE });
    history.push(generatePath("/collectionDetail/:id", { id: data.id }));
  }

  function redirectToOwners(data) {
    history.push(
      generatePath("/explore/listOfOwnersInCollection/:collectionId", {
        collectionId: data.id,
      })
    );
  }

  function redirectToItems(data) {
    history.push(generatePath("/collectionDetail/:id", { id: data.id }));
  }

  const ExploreCollectionItem = (props) => {
    const { collection } = props;
    return (
      <div className="hotCollectionItems">
        <div className="hotCollectionItems_1">
          <div className="hotCollectionInfo">
            <div className="mr-2">
              <Image
                src={collection.imageLogo}
                width={44}
                height={44}
                style={{ objectFit: "cover", borderRadius: "12px" }}
                onClick={() => redirectToCollection(collection)}
              />
            </div>
            <div>
              <StanTypo
                color="#000000"
                fontSize={14}
                fontWeight={500}
                content={subStringText(collection.name, 40)}
              />
            </div>
          </div>
          <div className="volume">
            <div className="text-center" style={{ width: "100%" }}>
              <div className="ellipsisText">
                <StanTypo
                  fontSize={18}
                  fontWeight={700}
                  color="#939393"
                  content={
                    collection.totalVolume === 0 ||
                    _.isNull(collection.totalVolume) ? null : (
                      <>
                        <MaticIcon />
                        {formatStanCrypto(
                          collection.totalVolumeDetail.totalVolumeCrypto
                        )}
                      </>
                    )
                  }
                />
              </div>
            </div>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: "100%" }}
            >
              <div className="ellipsisText">
                <StanTypo
                  color="#939393"
                  fontSize={10}
                  fontWeight={400}
                  content={
                    <>
                      <StanPointIcon />
                      {formatStanPoint(
                        collection.totalVolumeDetail.totalVolumePoint
                      )}
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="hotCollectionItems_2">
          <div className="floorPrice" style={{ width: "40%" }}>
            <div className="ellipsisText text-center" style={{ width: "100%" }}>
              <StanTypo
                color="#17875A"
                fontSize={14}
                fontWeight={700}
                content={
                  collection.floorPrice === 0 ||
                  _.isNull(collection.floorPrice) ? (
                    "---"
                  ) : (
                    <>
                      <MaticIcon />{" "}
                      {formatStanCrypto(
                        collection.floorPriceDetail.floorPriceCrypto
                      )}
                    </>
                  )
                }
              />
            </div>
            <div className="ellipsisText text-center" style={{ width: "100%" }}>
              <StanTypo
                color="#17875A"
                fontSize={14}
                fontWeight={700}
                content={
                  collection.floorPrice === 0 ||
                  _.isNull(collection.floorPrice) ? (
                    "---"
                  ) : (
                    <>
                      <StanPointIcon />{" "}
                      {formatStanPoint(
                        collection.floorPriceDetail.floorPricePoint
                      )}
                    </>
                  )
                }
              />
            </div>
            <div>
              <StanTypo
                color="#4D4D4D"
                fontSize={14}
                fontWeight={500}
                content={t("STATS.FLOOR")}
              />
            </div>
          </div>
          <div
            className="owners"
            style={{ width: "30%" }}
            onClick={() => redirectToOwners(collection)}
          >
            <StanTypo
              color="#17875A"
              fontSize={14}
              fontWeight={700}
              content={collection.ownernumber}
            />
            <StanTypo
              color="#4D4D4D"
              fontSize={14}
              fontWeight={500}
              content={t("STATS.OWNERS")}
            />
          </div>
          <div
            className="items"
            style={{ width: "30%" }}
            onClick={() => redirectToItems(collection)}
          >
            <StanTypo
              color="#17875A"
              fontSize={14}
              fontWeight={700}
              content={collection.currentNumberNft}
            />
            <StanTypo
              color="#4D4D4D"
              fontSize={14}
              fontWeight={500}
              content={t("STATS.ITEMS")}
            />
          </div>
        </div>
      </div>
    );
  };

  const NameSort = (
    <Menu
      items={[
        {
          label: (
            <div
              onClick={() => {
                setSortType("ASC");
                setPropertySort("NAME");
              }}
            >
              <span>{t("SORT.NAME_ASC", { ns: "explore" })}</span>
            </div>
          ),
          key: "artistNameAsc",
        },
        {
          label: (
            <div
              onClick={() => {
                setSortType("DESC");
                setPropertySort("NAME");
              }}
            >
              <span>{t("SORT.NAME_DESC", { ns: "explore" })}</span>
            </div>
          ),
          key: "artistNameDesc",
        },
      ]}
    />
  );

  const TotalVolumeSort = (
    <Menu
      items={[
        {
          label: (
            <div
              onClick={() => {
                setSortType("ASC");
                setPropertySort("TOTAL_VOLUME");
              }}
            >
              <span>{t("SORT.TOTAL_VOLUME_ASC", { ns: "explore" })}</span>
            </div>
          ),
          key: "totalVolumeAsc",
        },
        {
          label: (
            <div
              onClick={() => {
                setSortType("DESC");
                setPropertySort("TOTAL_VOLUME");
              }}
            >
              <span>{t("SORT.TOTAL_VOLUME_DESC", { ns: "explore" })}</span>
            </div>
          ),
          key: "totalVolumeDesc",
        },
      ]}
    />
  );

  const FloorPriceSort = (
    <Menu
      items={[
        {
          label: (
            <div
              onClick={() => {
                setSortType("ASC");
                setPropertySort("FLOOR_PRICE");
              }}
            >
              <span>{t("SORT.FLOOR_PRICE_ASC", { ns: "explore" })}</span>
            </div>
          ),
          key: "floorPriceAsc",
        },
        {
          label: (
            <div
              onClick={() => {
                setSortType("DESC");
                setPropertySort("FLOOR_PRICE");
              }}
            >
              <span>{t("SORT.FLOOR_PRICE_DESC", { ns: "explore" })}</span>
            </div>
          ),
          key: "floorPriceDesc",
        },
      ]}
    />
  );

  const OwnersSort = (
    <Menu
      items={[
        {
          label: (
            <div
              onClick={() => {
                setSortType("ASC");
                setPropertySort("OWNERS");
              }}
            >
              <span>{t("SORT.OWNERS_ASC", { ns: "explore" })}</span>
            </div>
          ),
          key: "totalOwnersAsc",
        },
        {
          label: (
            <div
              onClick={() => {
                setSortType("DESC");
                setPropertySort("OWNERS");
              }}
            >
              <span>{t("SORT.OWNERS_DESC", { ns: "explore" })}</span>
            </div>
          ),
          key: "totalOwnersDesc",
        },
      ]}
    />
  );

  const ItemsSort = (
    <Menu
      items={[
        {
          label: (
            <div
              onClick={() => {
                setSortType("ASC");
                setPropertySort("ITEMS");
              }}
            >
              <span>{t("SORT.NUMBER_OF_ITEMS_ASC", { ns: "explore" })}</span>
            </div>
          ),
          key: "numberOfItemsAsc",
        },
        {
          label: (
            <div
              onClick={() => {
                setSortType("DESC");
                setPropertySort("ITEMS");
              }}
            >
              <span>{t("SORT.NUMBER_OF_ITEMS_DESC", { ns: "explore" })}</span>
            </div>
          ),
          key: "numberOfItemsDesc",
        },
      ]}
    />
  );

  const columns = [
    {
      title: (
        <div className="d-flex align-items-center">
          <div className="mr-2">
            <StanTypo
              fontSize={20}
              fontWeight={400}
              color="#4D4D4D"
              content={t("TABLE.TITLE.COLLECTIONS", { ns: "explore" })}
            />
          </div>
          <div>
            <Dropdown overlay={NameSort} trigger={["click"]}>
              <img
                style={{ cursor: "pointer" }}
                width={12}
                alt=""
                src="/svg/filter_list_24px.svg"
              />
            </Dropdown>
          </div>
        </div>
      ),
      dataIndex: "name",
      key: "name",
      width: "25%",
      render: (item, record, index) => (
        <>
          <div className="collectionInfo">
            <div>
              <Image
                width={48}
                height={48}
                preview={false}
                src={record.imageLogo}
                onClick={() => redirectToCollection(record)}
                style={{
                  objectFit: "cover",
                  borderRadius: "12px",
                  cursor: "pointer",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                marginLeft: "10px",
                alignContent: "center",
              }}
            >
              <span style={{ color: "#0D0D0D", fontWeight: 600 }}>
                {record.name}
              </span>
            </div>
          </div>
        </>
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center">
          <div className="mr-2">
            <StanTypo
              fontSize={20}
              fontWeight={400}
              color="#4D4D4D"
              content={t("TABLE.TITLE.VOLUME", { ns: "explore" })}
            />
          </div>
          <div>
            <Dropdown overlay={TotalVolumeSort} trigger={["click"]}>
              <img
                style={{ cursor: "pointer" }}
                width={12}
                alt=""
                src="/svg/filter_list_24px.svg"
              />
            </Dropdown>
          </div>
        </div>
      ),
      dataIndex: "volume",
      key: "volume",
      width: "25%",
      render: (item, record, index) => (
        <>
          <div className="volume d-flex flex-column">
            {record.totalVolume === 0 || _.isNull(record.totalVolume) ? (
              "---"
            ) : (
              <>
                <div className="d-flex align-items-center">
                  <span style={{ color: "#939393", fontWeight: 700 }}>
                    <MaticIcon />{" "}
                    {formatStanCrypto(
                      record.totalVolumeDetail.totalVolumeCrypto
                    )}
                  </span>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <span style={{ color: "#CBCBCB", fontSize: "12px" }}>
                    <StanPointIcon />{" "}
                    {formatStanPoint(record.totalVolumeDetail.totalVolumePoint)}
                  </span>
                </div>
              </>
            )}
          </div>
        </>
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center">
          <div className="mr-2">
            <StanTypo
              fontSize={20}
              fontWeight={400}
              color="#4D4D4D"
              content={t("TABLE.TITLE.FLOOR_PRICE", { ns: "explore" })}
            />
          </div>
          <div>
            <Dropdown overlay={FloorPriceSort} trigger={["click"]}>
              <img
                style={{ cursor: "pointer" }}
                width={12}
                alt=""
                src="/svg/filter_list_24px.svg"
              />
            </Dropdown>
          </div>
        </div>
      ),
      dataIndex: "floorPrice",
      key: "floorPrice",
      render: (item, record, index) => (
        <div className="floorPrice d-flex flex-column">
          <div className="d-flex align-items-center">
            <span
              className="numericInfo"
              style={{ color: "#939393", fontWeight: 700 }}
            >
              {record.floorPrice === 0 ? (
                "---"
              ) : (
                <>
                  <MaticIcon />{" "}
                  {formatStanCrypto(record.floorPriceDetail.floorPriceCrypto)}
                </>
              )}
            </span>
          </div>
          <div className="d-flex align-items-center mt-2">
            <span style={{ color: "#CBCBCB", fontSize: "12px" }}>
              {record.floorPriceDetail.floorPricePoint === 0 ? (
                "---"
              ) : (
                <>
                  <StanPointIcon />{" "}
                  {formatStanPoint(record.floorPriceDetail.floorPricePoint)}
                </>
              )}
            </span>
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center">
          <div className="mr-2">
            <StanTypo
              fontSize={20}
              fontWeight={400}
              color="#4D4D4D"
              content={t("TABLE.TITLE.OWNERS", { ns: "explore" })}
            />
          </div>
          <div>
            <Dropdown overlay={OwnersSort} trigger={["click"]}>
              <img
                style={{ cursor: "pointer" }}
                width={12}
                alt=""
                src="/svg/filter_list_24px.svg"
              />
            </Dropdown>
          </div>
        </div>
      ),
      key: "owner",
      dataIndex: "owner",
      render: (item, record, index) => (
        <div className="owner">
          <span
            onClick={() => redirectToOwners(record)}
            className="numericInfo"
            style={{ color: "#17875A", fontWeight: 700, cursor: "pointer" }}
          >
            {formatStanPoint(record.ownernumber)}
          </span>
        </div>
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center">
          <div className="mr-2">
            <StanTypo
              fontSize={20}
              fontWeight={400}
              color="#4D4D4D"
              content={t("TABPANE.ITEMS")}
            />
          </div>
          <div>
            <Dropdown overlay={ItemsSort} trigger={["click"]}>
              <img
                style={{ cursor: "pointer" }}
                width={12}
                alt=""
                src="/svg/filter_list_24px.svg"
              />
            </Dropdown>
          </div>
        </div>
      ),
      key: "items",
      dataIndex: "items",
      render: (item, record, index) => (
        <div className="items">
          <span
            className="numericInfo"
            onClick={() => redirectToItems(record)}
            style={{ color: "#17875A", fontWeight: 700, cursor: "pointer" }}
          >
            {formatStanPoint(record.currentNumberNft)}
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="exploreCollection">
        <div className="searchSection">
          <div
            className="d-flex justify-content-center mr-3 filterToggle"
            style={{ width: "5%" }}
          >
            {filterSvg(() => setVisibleFilter(true))}
          </div>
          <div style={{ marginRight: 10, width: "100%" }}>
            <Input
              className="inputSearchNft"
              placeholder={t("PLACEHOLDER.SEARCH", { ns: "explore" })}
              onChange={(e) => setInputSearch(e.target.value)}
              onKeyPress={handleKeyPress}
              prefix={
                <svg
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.4167 9.87907H9.75833L9.525 9.65407C10.3417 8.70407 10.8333 7.47074 10.8333 6.12907C10.8333 3.1374 8.40833 0.712402 5.41667 0.712402C2.425 0.712402 0 3.1374 0 6.12907C0 9.12074 2.425 11.5457 5.41667 11.5457C6.75833 11.5457 7.99167 11.0541 8.94167 10.2374L9.16667 10.4707V11.1291L13.3333 15.2874L14.575 14.0457L10.4167 9.87907ZM5.41667 9.87907C3.34167 9.87907 1.66667 8.20407 1.66667 6.12907C1.66667 4.05407 3.34167 2.37907 5.41667 2.37907C7.49167 2.37907 9.16667 4.05407 9.16667 6.12907C9.16667 8.20407 7.49167 9.87907 5.41667 9.87907Z"
                    fill="#CBCBCB"
                  />
                </svg>
              }
            />
          </div>
          {/* <div style={{ width: "10%" }}>
            <Select
              value={orderBy}
              className="inputSelectNft"
              onChange={(e) => setOrderBy(e)}
            >
              <Option value="ONE_DAY">ONE DAY</Option>
              <Option value="SEVEN_DAYS">SEVEN DAYS</Option>
              <Option value="THIRTY_DAYS">THIRTY DAYS</Option>
            </Select>
          </div> */}
        </div>

        <div className="hotCollectionsItemsContainer">
          <ConfigProvider
            renderEmpty={() => (
              <Empty description={t("MSG.NO_RESULTS_FOUND")} />
            )}
          >
            <Table
              className="hotCollectionsItemsTable"
              loading={nftStatus === REQUESTING}
              columns={columns}
              dataSource={listCollection}
              pagination={{
                responsive: true,
                showSizeChanger: true,
                current: selectedPage,
                pageSize: selectedPageSize,
                total: totalCollection,
                onChange: (page, pageSize) => {
                  setSelectedPage(page);
                  setSelectedPageSize(pageSize);
                },
              }}
            />
          </ConfigProvider>
          {/* RESPONSIVE */}
          <div className="collectionListExploreResponsive">
            {totalCollection > 0 ? (
              <>
                {listCollection.map((collection, index) => {
                  return (
                    <ExploreCollectionItem
                      key={collection.id}
                      collection={collection}
                      index={index}
                    />
                  );
                })}
                <Pagination
                  responsive={true}
                  showSizeChanger={true}
                  current={selectedPage}
                  pageSize={selectedPageSize}
                  onChange={(page, pageSize) => {
                    setSelectedPage(page);
                    setSelectedPageSize(pageSize);
                  }}
                  total={totalCollection}
                />
              </>
            ) : (
              <Empty description={t("MSG.NO_RESULTS_FOUND")} />
            )}
          </div>

          {/* RESPONSIVE */}
          {/* DRAWRER */}
          <Drawer
            title={t("TITLE.FILTER_ARTIST")}
            placement={"bottom"}
            closable={true}
            onClose={() => setVisibleFilter(false)}
            visible={visibleFilter}
            className="filterDrawer"
          >
            <div>
              <div>
                <Dropdown overlay={NameSort} trigger={["click"]}>
                  <div>
                    <span>
                      {t("TABLE.TITLE.COLLECTION", { ns: "explore" })}
                    </span>
                    <img
                      style={{ cursor: "pointer" }}
                      width={12}
                      alt=""
                      src="/svg/filter_list_24px.svg"
                    />
                  </div>
                </Dropdown>
                <Dropdown overlay={TotalVolumeSort} trigger={["click"]}>
                  <div>
                    <span>
                      {t("TABLE.TITLE.TOTAL_VOLUME", { ns: "explore" })}
                    </span>
                    <img
                      style={{ cursor: "pointer" }}
                      width={12}
                      alt=""
                      src="/svg/filter_list_24px.svg"
                    />
                  </div>
                </Dropdown>
                <Dropdown overlay={FloorPriceSort} trigger={["click"]}>
                  <div>
                    <span>
                      {t("TABLE.TITLE.FLOOR_PRICE", { ns: "explore" })}
                    </span>
                    <img
                      style={{ cursor: "pointer" }}
                      width={12}
                      alt=""
                      src="/svg/filter_list_24px.svg"
                    />
                  </div>
                </Dropdown>
                <Dropdown overlay={OwnersSort} trigger={["click"]}>
                  <div>
                    <span>{t("TABLE.TITLE.OWNERS", { ns: "explore" })}</span>
                    <img
                      style={{ cursor: "pointer" }}
                      width={12}
                      alt=""
                      src="/svg/filter_list_24px.svg"
                    />
                  </div>
                </Dropdown>
                <Dropdown overlay={ItemsSort} trigger={["click"]}>
                  <div>
                    <span>
                      {t("TABLE.TITLE.NUMBER_OF_ITEMS", { ns: "explore" })}
                    </span>
                    <img
                      style={{ cursor: "pointer" }}
                      width={12}
                      alt=""
                      src="/svg/filter_list_24px.svg"
                    />
                  </div>
                </Dropdown>
              </div>
            </div>
          </Drawer>
          {/* DRAWRER */}
        </div>
      </div>
    </>
  );
}
