import _ from "lodash";
import { ADD_FILTER, REMOVE_FILTER } from "../types/FilterType";

const initialState = {
  listFilterItems: [],
  removedFilterItem: {},
};

export const FilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FILTER:
      console.log("ACTION =>", action);
      let dataFilter = action.dataFilter;
      let categories = action.categories;
      let collections = action.collections;
      let artists = action.artists;
      let newArr = [];
      if (dataFilter.status) {
        newArr.push({ type: "status", value: dataFilter.status });
      }
      if (dataFilter.currency && dataFilter.currency !== "") {
        newArr.push({ type: "currency", value: dataFilter.currency });
      }
      if (
        (dataFilter.fromPrice || dataFilter.toPrice) &&
        (dataFilter.fromPrice !== "" || dataFilter.toPrice !== "")
      ) {
        newArr.push({
          type: "priceRange",
          value:
            (!dataFilter.currency ? "WETH/POINT" : dataFilter.currency) +
            " " +
            (!dataFilter.fromPrice || dataFilter.fromPrice === ""
              ? 0
              : dataFilter.fromPrice) +
            " => " +
            dataFilter.toPrice,
        });
      }
      if (dataFilter.categoryIds && dataFilter.categoryIds !== "") {
        if (categories.length !== 0) {
          categories.map((category, index) => {
            newArr.push({ type: "category", value: category.label });
          });
        }
      }
      if (dataFilter.collectionIds && dataFilter.collectionIds !== "") {
        if (collections.length !== 0) {
          collections.map((collection, index) => {
            newArr.push({
              type: "collection",
              value: collection.name,
              id: collection.id,
            });
          });
        }
      }
      if (dataFilter.walletAddress && dataFilter.walletAddress !== "") {
        if (artists.length !== 0) {
          artists.map((artist, index) => {
            newArr.push({
              type: "artist",
              value: artist?.name,
              id: artist.id,
              walletAddress: artist?.walletAddress,
            });
          });
        }
      }
      return {
        ...state,
        listFilterItems: newArr,
      };
    case REMOVE_FILTER:
      console.log("ACTION =>", action, state);
      return {
        ...state,
        removedFilterItem: action.removedFilter,
        listFilterItems: _.filter(action.listFilterItems, function (o) {
          return o.value !== action.removedFilter?.value;
        }),
      };
    default:
      return state;
  }
};
