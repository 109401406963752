import { CloseCircleFilled } from "@ant-design/icons";
import {
  Badge,
  Col,
  Image,
  Input,
  message,
  Modal,
  Row,
  Select,
  Upload,
} from "antd";
import Cookies from "js-cookie";
import _, { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { history, openNotificationWithIcon } from "../../../App";
import {
  CURRENCY,
  NETWORK_BLOCKCHAIN,
  TOKEN_STANDARD,
} from "../../../common/commonParams";
import { uploadSvg } from "../../../common/commonSvg";
import { MAXIMUM_SIZE } from "../../../common/Constant";
import PropertyModal from "../../../component/PropertyModal/PropertyModal";
import SelectImagesModal from "../../../component/SelectImages/SelectImagesModal";
import StanButton from "../../../component/StanButton/StanButton";
import StanHashtags from "../../../component/StanHashtags/StanHashtags";
import { searchCollection } from "../../../redux/action/CollectionAction";
import { getListCategories, mintNFT } from "../../../redux/action/NftAction";
import {
  getPresignMedia,
  getVimeoUpload,
} from "../../../redux/action/UserAction";
import { OPEN_MODAL_SELECT_IMAGES } from "../../../redux/types/ModalType";
import {
  CLEAR_STATE,
  PROCESSING,
  REMOVE_EXIST_MEDIA,
  REMOVE_LOGO,
  REMOVE_THUMBNAIL,
  REQUESTING,
} from "../../../redux/types/UserType";
import {
  compressImage,
  isValidType,
  isValidTypeNFT,
} from "../../../util/settings/utils";
import "./CreateNFT.css";
const { TextArea } = Input;
const { Option } = Select;

export default function CreateNFT() {
  //____________________________ INITIAL STATE ____________________________//
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "nft", "components", "collection"]);

  const userProfile = JSON.parse(localStorage.getItem("UserProfile"));
  const [impacted, setImpacted] = useState(false);

  const [fileListAvt, setFileListAvt] = useState([]);
  const [fileListThumbnail, setFileListThumbnail] = useState([]);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewVideo, setPreviewVideo] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [previewThumbnail, setPreviewThumbnail] = useState(false);

  const [selectedCollectionName, setSelectedCollectionName] = useState("");
  const [errors, setErrors] = useState({});

  const { logoPath, logoUrl, existMedia, thumbnailPath, thumbnailUrl } =
    useSelector((state) => state.UserReducer);
  const { properties, listCategories } = useSelector(
    (state) => state.NFTReducer
  );
  const { listCollection } = useSelector((state) => state.CollectionReducer);
  const { nftStatus, stanRoyalty } = useSelector(
    (state) => state.AutionReducer
  );

  const [dataSend, setDataSend] = useState({
    name: "",
    description: "",
    mediaUrl: "",
    mediaPreviewUrl: "",
    networkBlockchain: NETWORK_BLOCKCHAIN,
    tokenStandard: TOKEN_STANDARD,
    currency: CURRENCY.POLYGON,
    price: 0,
    collectionId: "",
    quantity: 1,
    hashtag: [],
    categoryId: "",
    audioThumbnailUrl: "",
  });
  //______________________________________________________________________//

  // _________________________HANDLE UPLOAD_________________________ //

  function validateMedia(file) {
    let isValid = true;
    const isValidSize = file.size / 1024 / 1024 < MAXIMUM_SIZE;

    if (!isValidTypeNFT(file.type)) {
      message.error(t("MSG.UNSUPPORTED_FILE"), 5);
      isValid = false;
    }

    if (!isValidSize) {
      message.error(t("MSG.MAX_SIZE_IMAGE_100MB"), 5);
      isValid = false;
    }

    return isValid;
  }

  function validateThumbnail(file) {
    let isValid = true;
    const isValidSize = file.size / 1024 / 1024 < MAXIMUM_SIZE;

    if (!isValidType(file.type)) {
      message.error(t("MSG.UNSUPPORTED_FILE"), 5);
      isValid = false;
    }

    if (!isValidSize) {
      message.error(t("MSG.MAX_SIZE_IMAGE_100MB", 5));
      isValid = false;
    }

    return isValid;
  }

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);

      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const onChangeAvt = async ({ fileList: newFileList }) => {
    if (validateMedia(newFileList[0])) {
      if (!newFileList[0].url && !newFileList[0].preview) {
        newFileList[0].preview = await getBase64(newFileList[0].originFileObj);
        if (newFileList[0].type === "video/mp4") {
          let urlBlob = URL.createObjectURL(newFileList[0].originFileObj);
          setFileListAvt([
            {
              uid: "1",
              name: newFileList[0].name,
              status: "done",
              url: urlBlob,
            },
          ]);
          setPreviewVideo(urlBlob);
        } else {
          setFileListAvt([
            {
              uid: "1",
              name: newFileList[0].name,
              status: "done",
              url: newFileList[0].preview,
            },
          ]);
          setPreviewImage(newFileList[0].preview);
        }
      }
    }
  };

  const onChangeThumbnail = async ({ fileList: newFileList }) => {
    if (validateThumbnail(newFileList[0])) {
      if (!newFileList[0].url && !newFileList[0].preview) {
        newFileList[0].preview = await getBase64(newFileList[0].originFileObj);
      }
      setFileListThumbnail([
        {
          uid: "1",
          name: newFileList[0].name,
          status: "done",
          url: newFileList[0].preview,
        },
      ]);
    }
  };

  const beforeUpload = async (file) => {
    if (validateMedia(file)) {
      if (file.type === "video/mp4") {
        dispatch(getVimeoUpload(file));
      } else {
        let compressedImage = await compressImage(file);
        dispatch(getPresignMedia("collections-nft", "logo", compressedImage));
      }
    }

    return file;
  };

  const beforeUploadThumbnail = async (file) => {
    if (validateThumbnail(file)) {
      let compressedImage = await compressImage(file);
      dispatch(
        getPresignMedia("collections-nft", "thumbnail", compressedImage)
      );
    }

    return file;
  };

  const handleCancel = () => setPreviewVisible(false);

  // _________________________HANDLE UPLOAD_________________________ //

  // _________________________HANDLE FUNCs_________________________ //

  function handleChangeInput(e) {
    const value = e.target.value;
    setDataSend({ ...dataSend, [e.target.name]: value });
  }

  function handleChangeSelect(e) {
    setDataSend({ ...dataSend, collectionId: e.value });
    setSelectedCollectionName(e.label);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    // dispatch(getStanRoyalty());
    dispatch(searchCollection(50, 1, Cookies.get("UserAddress")));
    dispatch(getListCategories());
    if (!Cookies.get("UserAddress")) {
      openNotificationWithIcon("error", "Error", t("MSG.NOT_CONNECT_WALLET"));
      history.push("/home");
    } else if (userProfile && userProfile.role === 1) {
      openNotificationWithIcon(
        "error",
        "Error",
        t("MSG.UNAUTHORIZED_CREATE_COLLECTION")
      );
      history.push("/home");
    }
  }, []);

  useEffect(() => {
    if (impacted) {
      validate();
    }
    // ________DISPLAY PREVIEW IMAGE________//
    // Only display when media is video (publicUrl starts with https || vimeo || video)//
    if (
      dataSend.mediaUrl.includes("vimeo") ||
      dataSend.mediaUrl.includes("video") ||
      dataSend.mediaUrl.includes("audio")
    ) {
      setPreviewThumbnail(true);
    } else {
      setPreviewThumbnail(false);
    }
  }, [dataSend]);

  useEffect(() => {
    if (fileListAvt.length === 0) {
      setDataSend({ ...dataSend, mediaUrl: "" });
    } else {
      setDataSend({
        ...dataSend,
        mediaUrl: logoPath,
        mediaType: logoPath.includes("videos/") ? "VIDEO" : "IMAGE",
      });
    }
  }, [logoPath, fileListAvt]);

  useEffect(() => {
    console.log("EXIST MEDIA =>", existMedia);
    if (_.isEmpty(existMedia)) {
      setDataSend({ ...dataSend, mediaUrl: logoPath });
    } else {
      setDataSend({
        ...dataSend,
        mediaUrl: existMedia.filePath,
        mediaPreviewUrl: existMedia.thumb,
        mediaType: existMedia.fileType.toUpperCase(),
        mediaId: existMedia.mediaId,
        ...(existMedia.fileType === "audio"
          ? { audioThumbnailUrl: existMedia.metadata.thumbnail }
          : {}),
      });
    }
  }, [existMedia]);

  useEffect(() => {
    if (_.isEmpty(thumbnailPath)) {
      setDataSend({ ...dataSend, mediaPreviewUrl: "" });
    } else {
      setDataSend({ ...dataSend, mediaPreviewUrl: thumbnailPath });
    }
  }, [thumbnailPath, fileListThumbnail]);

  useEffect(() => {
    setDataSend({ ...dataSend, properties: properties });
  }, [properties]);

  useEffect(() => {
    if (nftStatus === PROCESSING) {
      openNotificationWithIcon(
        "info",
        t("LOADING.TRANSACTION_IS_INPROGRESS", {
          ns: "components",
        }),
        t("LOADING.TRANSACTION_IS_INPROGRESS_PLEASE_WAIT", {
          ns: "components",
        })
      );
    }
  }, [nftStatus]);

  function createNft() {
    setImpacted(true);
    if (validate()) {
      dispatch({ type: REQUESTING });
      let jsonUpload = {
        name: dataSend.name,
        description: dataSend.description,
        image: !logoUrl ? existMedia.originUrl : logoUrl,
        properties: properties,
      };
      let nftPreviewInfo = {
        imgUrl: logoUrl,
        thumbnailUrl: thumbnailUrl,
        collectionName: selectedCollectionName,
        nftName: dataSend.name,
      };
      console.log("DATA SEND =>", dataSend);
      console.log("json =>", jsonUpload);
      console.log("nft preview =>", nftPreviewInfo);
      dispatch(mintNFT(dataSend, jsonUpload, nftPreviewInfo));
    }
  }

  function validate() {
    let isValid = true;
    let error = {};
    if (dataSend.name === "" || dataSend.name.trim() === "") {
      error["name"] = t("MSG.REQUIRED", { name: "NFT's name" });
      isValid = false;
    }
    if (dataSend.description !== "" && dataSend.description.trim() === "") {
      error["description"] = t("MSG.REQUIRED", { name: "Description" });
      isValid = false;
    }
    if (_.isNull(dataSend.mediaUrl) || dataSend.mediaUrl === "") {
      error["mediaUrl"] = t("MSG.REQUIRED", { name: "NFT's image" });
      isValid = false;
    }
    if (
      (dataSend.mediaUrl.includes("video") ||
        dataSend.mediaUrl.includes("vimeo")) &&
      (dataSend.mediaPreviewUrl === "" || _.isNull(dataSend.mediaPreviewUrl))
    ) {
      error["previewImage"] = t("MSG.REQUIRED", { name: "Preview Image" });
      isValid = false;
    }
    if (dataSend.collectionId === "" || dataSend.collectionId.trim() === "") {
      error["collectionId"] = t("MSG.REQUIRED", { name: "Collection" });
      isValid = false;
    }
    setErrors(error);
    return isValid;
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  // _________________________HANDLE FUNCs_________________________ //

  //__________________________ RENDER _____________________________//
  function renderPreview(previewSrc) {
    if (previewSrc.includes("blob")) {
      return (
        <>
          <video
            src={previewSrc}
            width="100%"
            height="100%"
            controls={true}
            autoPlay={true}
            loop={true}
            muted={true}
          />
        </>
      );
    } else {
      return (
        <>
          <img
            alt={t("ALT_IMAGE_EXAMPLE", { ns: "components" })}
            style={{
              width: "100%",
            }}
            src={previewSrc}
          />
        </>
      );
    }
  }

  return (
    <>
      <SelectImagesModal></SelectImagesModal>
      <PropertyModal></PropertyModal>
      <div
        style={{
          backgroundColor: "#FFFFFF",
          minHeight: "100vh",
          padding: "64px 360px",
        }}
        className="createNFT"
      >
        <div>
          <h1>{t("TITLE.CREATE_NFT", { ns: "nft" })}</h1>
        </div>
        <div style={{ display: "inline-grid" }}>
          <span style={{ fontWeight: "bold" }}>
            {t("TITLE.IMAGE_OR_VIDEO", { ns: "nft" })}
            <span style={{ color: "red" }}> *</span>
          </span>
          <span>{t("TITLE.FILES_SUPPORTED", { ns: "nft" })}</span>
        </div>
        <div className="nftMediaSection mt-3">
          <div className="nftMedia">
            {previewVideo ? (
              <div className="previewVideo">
                <video
                  src={previewVideo}
                  style={{ borderRadius: "16px" }}
                  width="200px"
                  height="200px"
                  controls={false}
                  autoPlay={true}
                  loop={true}
                  muted={true}
                />
              </div>
            ) : null}
            <Upload
              listType="picture-card"
              fileList={fileListAvt}
              onChange={onChangeAvt}
              onPreview={handlePreview}
              beforeUpload={beforeUpload}
              customRequest={dummyRequest}
              onRemove={() => {
                setFileListAvt([]);
                setPreviewImage("");
                setPreviewVideo("");
                dispatch({ type: REMOVE_LOGO });
              }}
              disabled={!_.isEmpty(existMedia)}
              style={
                !_.isEmpty(existMedia)
                  ? { display: "none" }
                  : { width: "500px" }
              }
            >
              {fileListAvt.length === 0 && isEmpty(existMedia) ? (
                <div className="d-flex flex-column justify-content-center align-items-center">
                  {uploadSvg()}
                </div>
              ) : null}
            </Upload>
          </div>
          {!_.isEmpty(existMedia) ? (
            <>
              <div className="existMedia">
                {existMedia.fileType === "video" ? (
                  <Badge
                    count={
                      <CloseCircleFilled
                        onClick={() => {
                          dispatch({ type: REMOVE_EXIST_MEDIA });
                        }}
                        style={{ color: "#f5222d" }}
                      />
                    }
                  >
                    <video
                      src={existMedia.originUrl}
                      style={{
                        borderRadius: "16px",
                        maxWidth: "200px",
                        maxHeight: "200px",
                      }}
                      width={188}
                      height={188}
                      autoPlay={true}
                      loop={true}
                      muted={true}
                    />
                  </Badge>
                ) : existMedia.fileType === "audio" ? (
                  <Badge
                    count={
                      <CloseCircleFilled
                        onClick={() => {
                          dispatch({ type: REMOVE_EXIST_MEDIA });
                        }}
                        style={{ color: "#f5222d" }}
                      />
                    }
                  >
                    <div className="audioWrapperCreate">
                      <img
                        src={existMedia.thumb}
                        className="audioThumbCreate"
                        alt=""
                      />
                      <audio
                        controls
                        autoPlay
                        loop
                        className="audioControllerCreate"
                        src={existMedia?.originUrl}
                      ></audio>
                    </div>
                  </Badge>
                ) : (
                  <Badge
                    count={
                      <CloseCircleFilled
                        onClick={() => {
                          dispatch({ type: REMOVE_EXIST_MEDIA });
                        }}
                        style={{ color: "#f5222d" }}
                      />
                    }
                  >
                    <Image
                      width={188}
                      style={{ borderRadius: "16px", maxHeight: "188px" }}
                      src={existMedia.originUrl}
                    />
                  </Badge>
                )}
              </div>
            </>
          ) : null}
        </div>
        {previewThumbnail ? (
          <div className="nftMediaSection">
            <div className="nftMedia">
              <div className="d-flex flex-column mt-3 mb-3">
                <span style={{ fontWeight: "bold" }}>
                  {t("TITLE.PREVIEW_IMAGE", { ns: "nft" }).toUpperCase()}
                  <span style={{ color: "red" }}> *</span>
                </span>
                <span>{t("TEXT.PREVIEW_IMAGE_LABEL", { ns: "nft" })}</span>
              </div>
              <Upload
                listType="picture-card"
                fileList={fileListThumbnail}
                onChange={onChangeThumbnail}
                onPreview={handlePreview}
                beforeUpload={beforeUploadThumbnail}
                customRequest={dummyRequest}
                onRemove={() => {
                  setFileListThumbnail([]);
                  dispatch({ type: REMOVE_THUMBNAIL });
                }}
                style={{ width: "500px" }}
              >
                {fileListThumbnail.length === 0 ? (
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <span>{t("BUTTON.UPLOAD_THUMBNAIL", { ns: "nft" })}</span>
                    {uploadSvg()}
                  </div>
                ) : null}
              </Upload>
            </div>
          </div>
        ) : null}
        <div
          className="selectExist"
          onClick={() =>
            dispatch({
              type: OPEN_MODAL_SELECT_IMAGES,
            })
          }
          style={
            fileListAvt.length === 0 && isEmpty(existMedia)
              ? {}
              : { display: "none" }
          }
        >
          <span>{t("BUTTON.SELECT_EXSITING", { ns: "nft" })}</span>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-start">
          <span style={{ color: "red" }}>{errors.mediaUrl}</span>
          <span style={{ color: "red" }}>{errors.previewImage}</span>
        </div>
        <div className="collectionInput">
          <span style={{ fontWeight: "bold" }}>
            {t("TITLE.NAME", { ns: "nft" }).toUpperCase()}
            <span style={{ color: "red" }}> *</span>
          </span>
          <Input
            placeholder={t("PLACEHOLDER.NFT_NAME", { ns: "nft" })}
            name="name"
            size="large"
            maxLength={50}
            onChange={(e) => handleChangeInput(e)}
          />
          <span style={{ color: "red" }}>{errors.name}</span>
        </div>
        <div className="collectionInput">
          <span style={{ fontWeight: "bold" }}>
            {t("TITLE.DESCRIPTION", { ns: "nft" })}
          </span>
          <TextArea
            rows={4}
            name="description"
            maxLength={2000}
            onChange={(e) => handleChangeInput(e)}
          />
          <span style={{ color: "red" }}>{errors.description}</span>
        </div>
        <div>
          <span style={{ fontWeight: "bold" }}>
            {t("TITLE.COLLECTION", { ns: "nft" })}
            <span style={{ color: "red" }}> *</span>
          </span>
          <Select
            showSearch
            labelInValue
            className="StanInput"
            onChange={handleChangeSelect}
            placeholder={t("PLACEHOLDER.SEARCH_TO_SELECT")}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes(input)}
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {listCollection?.map((item) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
          <span style={{ color: "red" }}>{errors.collectionId}</span>
        </div>

        {/* PROPERTIES */}
        {/* <div className="collectionInput">
          <span>{t("TITLE.PROPERTIES", { ns: "nft" })} </span>
          <span>{t("TITLE.PROPERTIES_DESC", { ns: "nft" })}</span>
          <div
            className="addProperty"
            onClick={() => dispatch({ type: OPEN_MODAL_ADD_PROPERTY })}
          >
            <span style={{ color: "blue" }}>+</span>
          </div>
        </div>
        {properties.length > 0 ? (
          <div className="propertyContainer">
            {properties.map((item) => {
              return (
                <div className="statsItem">
                  <Tooltip title={item.name}>
                    <span className="statsItemTitle">
                      {subStringText(item.name, 10)}
                    </span>
                  </Tooltip>
                  <Tooltip placement="bottom" title={item.value}>
                    <span className="statsItemValue">
                      {subStringText(item.value, 10)}
                    </span>
                  </Tooltip>
                </div>
              );
            })}
          </div>
        ) : null} */}

        {/* <div className="collectionInput">
          <span>{t("TITLE.QUANTITY", { ns: "nft" }).toUpperCase()} </span>
          <span>{t("TEXT.QUANTITY_DESCRIPTION", { ns: "nft" })}</span>
          <Input
            type="number"
            onChange={(e) =>
              setDataSend({ ...dataSend, quantity: parseInt(e.target.value) })
            }
          />
        </div> */}
        <Row
          className="mt-3 mb-3"
          gutter={[
            { xxl: 240, xl: 120, lg: 60, sm: 16 },
            { xs: 12, sm: 12 },
          ]}
        >
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <div>
              <span style={{ fontWeight: "bold" }}>
                {t("INFO.HASHTAG", { ns: "nftDetail" })}
              </span>
              <StanHashtags
                setHashtags={(hashtags) =>
                  setDataSend({ ...dataSend, hashtag: hashtags })
                }
              />
            </div>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <div>
              <span style={{ fontWeight: "bold" }}>
                {t("INFO.CATEGORY", { ns: "nftDetail" })}
              </span>
              <Select
                className="StanInput"
                allowClear
                placeholder={t("PLACEHOLDER.SEARCH_TO_SELECT")}
                onChange={(value) =>
                  setDataSend({ ...dataSend, categoryId: value })
                }
              >
                {listCategories.map((category) => {
                  return (
                    <Option value={category.id} key={category.id}>
                      {category.name}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </Col>
        </Row>

        {/* 03/04/2023 Keep royalty fee only at creating collection */}
        {/* <div
          className="d-flex flex-column justify-content-center"
          style={{ width: "35%" }}
        >
          <div className="d-flex justify-content-between">
            <span>{t("TITLE.ROYALTY_FEE", { ns: "nft" })}</span>
            <span>
              {+stanRoyalty?.ratioCreatorVal + +stanRoyalty?.ratioStanVal}%
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span>{t("TEXT.CREATOR_EARNING", { ns: "nft" })}</span>
            <span>{stanRoyalty?.ratioCreatorVal}%</span>
          </div>
        </div> */}

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ width: "25%", margin: "0px 5px" }}>
            <StanButton
              title={t("BUTTON.CANCEL")}
              handleOnClick={() => {
                dispatch({ type: CLEAR_STATE });
                history.push("/collections");
              }}
              type="secondary"
            />
          </div>
          <div style={{ width: "25%", margin: "0px 5px" }}>
            <StanButton
              title={t("SAVE", { ns: "collection" })}
              disabled={nftStatus === REQUESTING || nftStatus === PROCESSING}
              handleOnClick={() => createNft()}
              type="primary"
            />
          </div>
        </div>
      </div>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        width={800}
        onCancel={handleCancel}
      >
        {renderPreview(previewImage || previewVideo)}
      </Modal>
    </>
  );
}
