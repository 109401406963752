import Cookies from "js-cookie";

export const TOKENID = "tokenId";
export const TOKEN_COOKIE = Cookies.get("TokenId");
export const WALLET_LOGGED_IN = Cookies.get("UserAddress");
export const USER_LOGIN = "userLogin";
export const USER_ADDRESS = "UserAddress";
export const DOMAIN = process.env.REACT_APP_DOMAIN;
export const DOMAIN_WS = process.env.REACT_APP_DOMAIN_WS;
export const DOMAIN_MEDIA = process.env.REACT_APP_DOMAIN_MEDIA;
export const DEEP_LINK_KEY = process.env.REACT_APP_DEEP_LINK_KEY;
export const DEEP_LINK_URL = process.env.REACT_APP_DEEP_LINK_URL;
export const SOCKET_PATH = process.env.REACT_APP_SOCKET_PATH;
export const NETWORK_URL = process.env.REACT_APP_NETWORK_URL;
export const SITE_URL = process.env.REACT_APP_SITE_URL;
export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;
export const CHAIN_NAME = process.env.REACT_APP_CHAIN_NAME;

// SMART CONTRACT COLLECTION (CollectionNFT)
export const CONTRACT_ADDRESS = process.env.REACT_APP_SC_COLLECTION;

// SMART CONTRACT NFT (StanNFT)
export const CONTRACT_ADDRESS_NFT = process.env.REACT_APP_SC_NFT;

// SMART CONTRACT NFT (AUTION)
export const CONTRACT_ADDRESS_AUTION = process.env.REACT_APP_SC_AUCTION;
// SMART CONTRACT StanToken
// MATIC
// export const CONTRACT_ADDRESS_STAN_TOKEN =
//   "0x2bb42D89b7aF637752D9C23b3381624eC1a6fADa";

// WETH
export const CONTRACT_ADDRESS_STAN_TOKEN = process.env.REACT_APP_SC_TOKEN;

// SMART CONTRACT StanFund
export const CONTRACT_ADDRESS_STAN_FUND = process.env.REACT_APP_SC_FUND;

// SMART CONTRACT StanConfig
export const CONTRACT_ADDRESS_CONFIG = process.env.REACT_APP_SC_CONFIG;

// AUCTION PURE/ FIXED PRICE
export const AUCTION_PURE = process.env.REACT_APP_SC_PURE;
export const AUCTION_FIXED_PRICE = process.env.REACT_APP_SC_FIXED_PRICE;

export const stanWallet = process.env.REACT_APP_STAN_WALLET;
//1 = staging env
export const stagingEnvironment = process.env.REACT_APP_ENV_STAGING === "1";
