import { MinusCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Space } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_MODAL_ADD_PROPERTY } from "../../redux/types/ModalType";
import { SAVE_PROPERTIES } from "../../redux/types/NFTType";
import StanButton from "../StanButton/StanButton";
import "./PropertyModal.css";

export default function PropertyModal() {
  //___________ INITIAL STATE ___________//
  const { t } = useTranslation(["nft", "collection"]);
  const { openModalAddProperty } = useSelector((state) => state.ModalReducer);
  const dispatch = useDispatch();
  //_____________________________________//

  //___________ HANDLE FUNCs ___________//
  const handleOk = () => {
    dispatch({
      type: CLOSE_MODAL_ADD_PROPERTY,
    });
    // history.push("/collections");
  };

  const handleCancel = () => {
    dispatch({
      type: CLOSE_MODAL_ADD_PROPERTY,
    });
  };

  const onFinish = (values) => {
    if (values.properties.length > 0) {
      dispatch({
        type: SAVE_PROPERTIES,
        properties: values.properties,
      });
      dispatch({
        type: CLOSE_MODAL_ADD_PROPERTY,
      });
    } else {
      dispatch({
        type: SAVE_PROPERTIES,
        properties: [],
      });
      dispatch({
        type: CLOSE_MODAL_ADD_PROPERTY,
      });
    }
  };
  //_____________________________________//

  return (
    <>
      <div>
        <Modal
          title={
            <h3
              style={{
                fontFamily: "Space Grotesk",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "20px",
                lineHeight: "24px",
              }}
              className="text-center m-0"
            >
              {t("BUTTON.ADD_PROPERTIES")}
            </h3>
          }
          footer={null}
          className="submitted"
          visible={openModalAddProperty}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div>
            <div className=" d-flex justify-content-center">
              <span>
                {t("TITLE.PROPERTY_INFO")}
              </span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <span>{t("TITLE.TYPE")}</span>
              <span>{t("TITLE.NAME")}</span>
            </div>
            <div>
              <Form
                name="dynamic_form_nest_item"
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.List name="properties">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "name"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing type name",
                              },
                            ]}
                          >
                            <Input placeholder="Character" maxLength={200} />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "value"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing name",
                              },
                            ]}
                          >
                            <Input placeholder="Male" maxLength={200} />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          style={{ width: "40%" }}
                          type="dashed"
                          onClick={() => add()}
                          block
                        >
                          <span style={{ color: "black" }}>{t("TITLE.ADD_MORE")}</span>
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Form.Item>
                    <StanButton title={t("BUTTON.ADD_PROPERTIES").toUpperCase()} type="primary"/>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}
