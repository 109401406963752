import { FilterOutlined } from "@ant-design/icons";
import { Affix, Image, Menu, Pagination, Spin, Table } from "antd";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useParams } from "react-router-dom";
import { history } from "../../../App";
import {
  COLLECTION_DETAIL_TABPANE,
  EXPLORE_PATH,
  NFT_ACTIVITY_TYPE,
} from "../../../common/Constant";
import {
  MaticIcon,
  StanPointIcon,
  WETHIcon,
  cancelSvg,
  listSvg,
  mintSvg,
  offerSvg,
  saleSvg,
  transferSvg2,
  visitSvg,
} from "../../../common/commonSvg";
import StanTypo from "../../../component/StanTypo/StanTypo";
import { getCollectionActivities } from "../../../redux/action/CollectionAction";
import { REQUESTING } from "../../../redux/types/UserType";
import {
  formatStanCrypto,
  formatStanPoint,
  formatedCash,
  usdOnCrypto,
} from "../../../util/settings/utils";

export default function CollectionActivity(props) {
  const { t } = useTranslation(["common"]);
  const { activeTabPane } = props;
  const { collectionId } = useParams();
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedPageSize, setSelectedPageSize] = useState(10);
  const { collectionActivities, totalCollectionActivity } = useSelector(
    (state) => state.CollectionReducer
  );
  const { nftStatus } = useSelector((state) => state.AutionReducer);
  const { rateCrypto } = useSelector((state) => state.UserReducer);

  // RESET OFFSET
  useEffect(() => {
    setSelectedPageSize(10);
    setSelectedPage(1);
  }, [selectedFilter, activeTabPane]);

  useEffect(() => {
    if (activeTabPane === COLLECTION_DETAIL_TABPANE.ACTIVITY) {
      dispatch(
        getCollectionActivities(
          collectionId,
          selectedFilter.toString(),
          selectedPageSize,
          selectedPage
        )
      );
    }
  }, [selectedFilter, selectedPageSize, selectedPage, activeTabPane]);

  function renderType(type) {
    if (
      type === NFT_ACTIVITY_TYPE.TRANSFER ||
      type === NFT_ACTIVITY_TYPE.TRANSFER_NFT_PVP
    ) {
      return transferSvg2();
    } else if (type === NFT_ACTIVITY_TYPE.SALE) {
      return saleSvg();
    } else if (type === NFT_ACTIVITY_TYPE.LIST) {
      return listSvg();
    } else if (
      type === NFT_ACTIVITY_TYPE.MINT ||
      type === NFT_ACTIVITY_TYPE.CREATE_NFT_BY_STAN
    ) {
      return mintSvg();
    } else if (type === NFT_ACTIVITY_TYPE.OFFER) {
      return offerSvg();
    } else if (
      type === NFT_ACTIVITY_TYPE.CANCEL_LISTING ||
      type === NFT_ACTIVITY_TYPE.CANCEL_OFFER
    ) {
      return cancelSvg();
    }
  }

  const columns = [
    {
      title: t("TITLE.TYPE", { ns: "nft" }),
      dataIndex: "activityType",
      key: "activityType",
      render: (text) => {
        return (
          <>
            <div style={{ color: "#0D0D0D" }}>
              {renderType(text)} {t("MENU.NFT_TYPE." + text).toUpperCase()}
            </div>
          </>
        );
      },
    },
    {
      title: "CollectionName",
      dataIndex: "collectionName",
      key: "collectionName",
      render: (text, row) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <Image
                width={56}
                height={56}
                src={row.nftMediaPreviewUrl || row.nftMediaUrl}
                preview={false}
                onClick={() =>
                  history.push(
                    generatePath(EXPLORE_PATH.NFT_DETAIL, {
                      id: row.nftId,
                    })
                  )
                }
                style={{
                  objectFit: "cover",
                  borderRadius: "12px",
                  cursor: "pointer",
                }}
              />
              <div style={{ display: "inline-grid", marginLeft: "5px" }}>
                <span className="collectionName">
                  {text}{" "}
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.42439 4.42256C9.54758 4.42589 9.6634 4.46986 9.75204 4.55433L10.1819 4.97461C10.3826 5.17036 10.3567 5.51203 10.1245 5.73944L6.34954 9.4235C6.16614 9.60256 5.81937 9.61545 5.62169 9.4235L3.87563 7.71858C3.64341 7.49164 3.61889 7.15031 3.81933 6.9549L4.24808 6.53497C4.44979 6.33921 4.79932 6.36304 5.03131 6.58905L5.98551 7.52098L8.96733 4.61024C9.09927 4.48273 9.26655 4.41897 9.42429 4.42243L9.42439 4.42256ZM8.52925 0.326935C8.28276 0.318304 8.02314 0.382863 7.75961 0.521075C7.1827 0.823842 6.74714 0.76171 6.23991 0.521075C5.97349 0.394371 5.71089 0.331307 5.46623 0.338442C5.0574 0.351331 4.69358 0.563884 4.42142 1.00717C4.0765 1.56922 3.66201 1.71031 3.10432 1.76784C2.30267 1.84909 1.79275 2.28915 1.77088 3.09944C1.75361 3.75827 1.46613 4.08696 1.01147 4.41562C0.358102 4.88711 0.138555 5.5219 0.524362 6.23517C0.838549 6.81369 0.754738 7.24119 0.524362 7.75319C0.194849 8.48935 0.3192 9.14798 1.01147 9.57274C1.57205 9.91787 1.71353 10.33 1.77088 10.8887C1.85285 11.69 2.29356 12.1987 3.10432 12.2213C3.76228 12.24 4.09294 12.5252 4.42142 12.9807C4.90853 13.6584 5.51495 13.8436 6.23991 13.4686C6.82383 13.1675 7.24857 13.2316 7.75961 13.4686C8.49887 13.8131 9.09305 13.6584 9.57924 12.9807C9.90772 12.5253 10.2384 12.2399 10.8964 12.2213C11.7073 12.1988 12.1478 11.6901 12.2298 10.8887C12.2872 10.33 12.4286 9.91787 12.9892 9.57274C13.6808 9.14821 13.807 8.48938 13.4763 7.75319C13.2459 7.24119 13.1629 6.81366 13.4763 6.23517C13.863 5.5219 13.6436 4.88711 12.9892 4.41562C12.5336 4.08696 12.2481 3.7583 12.2298 3.09944C12.2087 2.28915 11.698 1.84919 10.8964 1.76784C10.3375 1.7103 9.92488 1.5691 9.57924 1.00717C9.30788 0.563993 8.9382 0.341654 8.52925 0.326923V0.326935Z"
                      fill="#1B9CFC"
                    />
                  </svg>
                </span>
                <span style={{ color: "#00000" }}>{row.nftName}</span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text, row) => {
        return (
          <>
            {_.isNull(text) || text === "0" ? null : (
              <div className="d-flex flex-column">
                <StanTypo
                  fontSize={14}
                  fontWeight={500}
                  color="#0D0D0D"
                  content={
                    <>
                      {/* <WETHIcon /> */}
                      <MaticIcon />
                      {formatStanCrypto(row.priceCrypto)}
                    </>
                  }
                />
                <StanTypo
                  fontSize={10}
                  fontWeight={400}
                  color="#939393"
                  content={
                    <>
                      <StanPointIcon />
                      {formatStanPoint(row.pricePoint)}
                    </>
                  }
                />
              </div>
            )}
          </>
        );
      },
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      render: (text, row) => {
        return (
          <>
            <div style={{ display: "inline-grid" }}>
              <span style={{ color: "#8D8D8D" }}>
                {t("FROM")}{" "}
                <a href="@" style={{ color: "#49D19A" }}>
                  {row.type === NFT_ACTIVITY_TYPE.TRANSFER ||
                  row.type === NFT_ACTIVITY_TYPE.LIST ||
                  row.type === NFT_ACTIVITY_TYPE.AUCTION
                    ? row.sellerName
                    : row.buyerName}
                </a>
              </span>
              {row.type === NFT_ACTIVITY_TYPE.MINT ||
              row.type === NFT_ACTIVITY_TYPE.TRANSFER ||
              row.type === NFT_ACTIVITY_TYPE.LIST ||
              row.type === NFT_ACTIVITY_TYPE.AUCTION ? null : (
                <span style={{ color: "#8D8D8D" }}>
                  {t("TO")}{" "}
                  <a href="@" style={{ color: "#49D19A" }}>
                    {row.type === NFT_ACTIVITY_TYPE.TRANSFER
                      ? row.buyerName
                      : row.type === NFT_ACTIVITY_TYPE.LIST
                      ? null
                      : row.sellerName}
                  </a>
                </span>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, row) => (
        <>
          <div className="historySideTime">
            <StanTypo
              className="mr-2"
              color="#8D8D8D"
              fontSize={14}
              fontWeight={400}
              content={moment(row.createdAt).startOf("minute").fromNow()}
            />
            {row.transactionTX && row.transactionTX !== "transactionTx"
              ? visitSvg(row.transactionTxUrl)
              : null}
          </div>
        </>
      ),
    },
  ];

  const itemsMenuPaneActivities = [
    {
      label: t("MENU.TITLE.FILTER"),
      key: "filterSub",
      icon: <FilterOutlined />,
      children: [
        {
          label: t("MENU.NFT_TYPE.MINT"),
          key: NFT_ACTIVITY_TYPE.CREATE_NFT_BY_STAN,
          // icon: renderActiveIcon("MINT"),
        },
        {
          label: t("MENU.NFT_TYPE.LIST"),
          key: NFT_ACTIVITY_TYPE.LIST,
          // icon: renderActiveIcon("LIST"),
        },
        {
          label: t("MENU.NFT_TYPE.SALE"),
          key: NFT_ACTIVITY_TYPE.SALE,
          // icon: renderActiveIcon("SALE"),
        },
        {
          label: t("MENU.NFT_TYPE.TRANSFER"),
          key: NFT_ACTIVITY_TYPE.TRANSFER_NFT_PVP,
          // icon: renderActiveIcon("TRANSFER"),
        },
        {
          label: t("MENU.NFT_TYPE.OFFER"),
          key: NFT_ACTIVITY_TYPE.OFFER,
          // icon: renderActiveIcon("OFFER"),
        },
        {
          label: t("MENU.NFT_TYPE.CANCEL_LISTING"),
          key: NFT_ACTIVITY_TYPE.CANCEL_LISTING,
          // icon: renderActiveIcon("AUCTION"),
        },
        {
          label: t("MENU.NFT_TYPE.CANCEL_OFFER"),
          key: NFT_ACTIVITY_TYPE.CANCEL_OFFER,
          // icon: renderActiveIcon("BUY"),
        },
        // {
        //   label: t("MENU.NFT_TYPE.AUCTION"),
        //   key: "AUCTION",
        //   // icon: renderActiveIcon("AUCTION"),
        // },
        // {
        //   label: t("MENU.NFT_TYPE.BUY"),
        //   key: "BUY",
        //   // icon: renderActiveIcon("BUY"),
        // },
      ],
    },
  ];

  return (
    <Spin spinning={nftStatus === REQUESTING}>
      <div className="mainSection">
        <div className="searchSection">
          {/* <div
            className="d-flex justify-content-center mr-3 filterToggle"
            style={{ width: "5%" }}
          >
            {filterSvg(handleOpenFilter)}
          </div>
          <div style={{ width: "100%" }}>
            <Input
              className="inputSearchNft"
              placeholder={t("PLACEHOLDER.SEARCH", { ns: "explore" })}
              onChange={(e) => setInputSearch(e.target.value)}
              onKeyPress={handleKeyPress}
              prefix={
                <svg
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.4167 9.87907H9.75833L9.525 9.65407C10.3417 8.70407 10.8333 7.47074 10.8333 6.12907C10.8333 3.1374 8.40833 0.712402 5.41667 0.712402C2.425 0.712402 0 3.1374 0 6.12907C0 9.12074 2.425 11.5457 5.41667 11.5457C6.75833 11.5457 7.99167 11.0541 8.94167 10.2374L9.16667 10.4707V11.1291L13.3333 15.2874L14.575 14.0457L10.4167 9.87907ZM5.41667 9.87907C3.34167 9.87907 1.66667 8.20407 1.66667 6.12907C1.66667 4.05407 3.34167 2.37907 5.41667 2.37907C7.49167 2.37907 9.16667 4.05407 9.16667 6.12907C9.16667 8.20407 7.49167 9.87907 5.41667 9.87907Z"
                    fill="#CBCBCB"
                  />
                </svg>
              }
            />
          </div> */}
        </div>

        <div style={{ display: "flex" }}>
          <Affix offsetTop={10}>
            <div className="menuSide">
              <Menu
                style={{
                  width: 256,
                }}
                defaultOpenKeys={["filterSub"]}
                mode="inline"
                selectedKeys={selectedFilter}
                onSelect={(e) => setSelectedFilter(e.selectedKeys)}
                onDeselect={(e) => setSelectedFilter(e.selectedKeys)}
                multiple={true}
                items={itemsMenuPaneActivities}
              />
            </div>
          </Affix>

          <div
            className="activitiesOfCollection"
            style={{ width: "100%", marginTop: "32px", marginRight: "32px" }}
          >
            <Table
              showHeader={false}
              columns={columns}
              dataSource={collectionActivities}
              pagination={false}
            />
            <div style={{ marginTop: "50px" }}>
              <Pagination
                responsive={true}
                showSizeChanger={true}
                current={selectedPage}
                pageSize={selectedPageSize}
                onChange={(page, pageSize) => {
                  setSelectedPage(page);
                  setSelectedPageSize(pageSize);
                }}
                total={totalCollectionActivity}
              ></Pagination>
            </div>
          </div>
        </div>

        {/* RESPONSIVE */}
        <div className="activitiesOfCollectionResponsive">
          {collectionActivities.length === 0 ? null : (
            <>
              {collectionActivities.map((item) => {
                return (
                  <div className="activitiesItem" key={item.id}>
                    <div>
                      {renderType(item.type)} {item.type}
                    </div>
                    <div className="d-flex mt-2 justify-content-between">
                      <div className="d-flex" style={{ width: "40%" }}>
                        <div className="mr-2">
                          <Image
                            width={56}
                            height={56}
                            src={item.nftMediaUrl}
                            preview={false}
                            onClick={() =>
                              history.push(
                                generatePath("/nft/:nftId", {
                                  nftId: item.nftId,
                                })
                              )
                            }
                            style={{
                              objectFit: "cover",
                              borderRadius: "12px",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                        <div
                          style={{ width: "100%" }}
                          className="d-flex flex-column justify-content-center"
                        >
                          <div className="ellipsisText">
                            <span
                              style={{ fontSize: "14px", color: "#8D8D8D" }}
                            >
                              {item.collectionName}
                            </span>
                          </div>
                          <div className="ellipsisText">
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              {item.nftName}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        className="d-flex flex-column justify-content-center text-right"
                        style={{ width: "30%" }}
                      >
                        <div className="ellipsisText">
                          <div className="d-flex flex-column">
                            <StanTypo
                              fontSize={14}
                              fontWeight={500}
                              color="#0D0D0D"
                              content={
                                <>
                                  {/* <WETHIcon /> */}
                                  <MaticIcon />
                                  {formatStanCrypto(item.priceCrypto)}
                                </>
                              }
                            />
                            <StanTypo
                              fontSize={10}
                              fontWeight={400}
                              color="#939393"
                              content={
                                <>
                                  <StanPointIcon />
                                  {formatStanPoint(item.pricePoint)}
                                </>
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <span>
                            $
                            {formatedCash(
                              usdOnCrypto(item.priceCrypto, rateCrypto)
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex mt-2 justify-content-between">
                      <div className="d-flex">
                        <div className="d-flex flex-column mr-3">
                          <span>{t("MENU.FROM", { ns: "explore" })}</span>
                          <span>{t("MENU.TO", { ns: "explore" })}</span>
                        </div>
                        <div className="d-flex flex-column">
                          <span>
                            <a href="@" style={{ color: "#49D19A" }}>
                              {item.type === "TRANSFER" || item.type === "LIST"
                                ? item.sellerName
                                : item.buyerName}
                            </a>
                          </span>
                          <span>
                            <a href="@" style={{ color: "#49D19A" }}>
                              {item.type === "TRANSFER"
                                ? item.buyerName
                                : item.type === "LIST"
                                ? null
                                : item.sellerName}
                            </a>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <StanTypo
                          className="mr-2"
                          color="#8D8D8D"
                          fontSize={14}
                          fontWeight={400}
                          content={moment(item.createdAt)
                            .startOf("minute")
                            .fromNow()}
                        />
                        {item.transactionTX &&
                        item.transactionTX !== "transactionTx"
                          ? visitSvg(item.transactionTxUrl)
                          : null}
                      </div>
                    </div>
                  </div>
                );
              })}
              <div style={{ marginTop: "50px" }}>
                <Pagination
                  responsive={true}
                  showSizeChanger={true}
                  current={selectedPage}
                  pageSize={selectedPageSize}
                  onChange={(page, pageSize) => {
                    setSelectedPage(page);
                    setSelectedPageSize(pageSize);
                  }}
                  total={totalCollectionActivity}
                ></Pagination>
              </div>
            </>
          )}
        </div>
        {/* RESPONSIVE */}
      </div>
    </Spin>
  );
}
