import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getListOffer } from "../../../redux/action/AutionAction";
import { useParams } from "react-router-dom";
import { Empty, Pagination } from "antd";
import OfferItem from "../../../component/OfferItem/OfferItem";
import { NFT_DETAIL_TABPANE } from "../../../common/Constant";

export default function OfferTab(props) {
  const { t } = useTranslation(["common", "nftDetail", "explore"]);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { activeTabPane } = props;

  const [selectedPage, setSelectedPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { listOffer, totalOffers } = useSelector(
    (state) => state.AutionReducer
  );

  useEffect(() => {
    dispatch(getListOffer(id, pageSize, selectedPage));
  }, [selectedPage, pageSize]);

  useEffect(() => {
    if (activeTabPane === NFT_DETAIL_TABPANE.OFFERS) {
      setSelectedPage(1);
      setPageSize(10);
    }
  }, [activeTabPane]);

  const listOfferActive = listOffer.filter(function (item) {
    return item.type === "OFFER" && item.status === "ACTIVE";
  });

  return (
    <>
      {listOfferActive.length === 0 ? (
        <div className="offerTabContainer">
          <Empty description={t("INFO.NO_OFFER", { ns: "nftDetail" })} />
        </div>
      ) : (
        <>
          <div className="offerTabContainer">
            {listOfferActive.map((item) => {
              return <OfferItem key={item.id} data={item} />;
            })}
          </div>
          <Pagination
            responsive={true}
            showSizeChanger={true}
            current={selectedPage}
            pageSize={pageSize}
            onChange={(page, pageSize) => {
              setSelectedPage(page);
              setPageSize(pageSize);
            }}
            total={totalOffers}
          />
        </>
      )}
    </>
  );
}
