import { Modal, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { grantAccess } from "../../redux/action/Web3Action";
import { CLOSE_CONFIRM_MODAL } from "../../redux/types/ModalType";
import { PROCESSING, REQUESTING } from "../../redux/types/UserType";
import StanButton from "../StanButton/StanButton";
import { useEffect } from "react";

export default function StanConfirmDialog(props) {
  const dispatch = useDispatch();
  const { openConfirm } = useSelector((state) => state.ModalReducer);
  const { nftStatus } = useSelector((state) => state.AutionReducer);
  const { t } = useTranslation(["common"]);

  const handleCancel = () => {
    dispatch({ type: CLOSE_CONFIRM_MODAL });
  };

  return (
    <>
      <Modal
        footer={null}
        visible={openConfirm}
        maskClosable={false}
        onCancel={handleCancel}
      >
        <div className="mt-5">
          <div className="d-flex justify-content-center mb-3">
            <span className="text-center">
              In order to proceed, you fully grant{" "}
              {<img alt="" src="/svg/stan_Logo.svg" />} your NFT ownership and
              the possession rights of the underlying assets on this platform.
            </span>
          </div>
          {nftStatus === PROCESSING ? (
            <div className="d-flex justify-content-center mb-3">
              <Spin className="mr-2" />
              <span>Processing...</span>
            </div>
          ) : null}
          {nftStatus !== PROCESSING ? (
            <div className="d-flex">
              <div style={{ width: "100%" }} className="mr-3">
                <StanButton
                  type="secondary"
                  handleOnClick={handleCancel}
                  title={t("BUTTON.CANCEL")}
                />
              </div>
              <div style={{ width: "100%" }}>
                <StanButton
                  type="primary"
                  disabled={nftStatus === REQUESTING}
                  handleOnClick={() => dispatch(grantAccess())}
                  title={t("BUTTON.ACCEPT")}
                />
              </div>
            </div>
          ) : null}
        </div>
      </Modal>
    </>
  );
}
