import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { BUY } from "../../common/commonStatus";
import StanNFTCard from "../../component/StanNFTCard/StanNFTCard";
import { getFixedPriceNFTs } from "../../redux/action/NftAction";
import "./FixedPriceNFT.css";
import StanNFTFilter from "../../component/StanNFTFilter/StanNFTFilter";
import { Empty, Pagination } from "antd";

export default function FixedPriceNFT(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["common", "homePage", "nft"]);
  const { listFixedPriceNFT, totalFixedPriceNFT } = useSelector(
    (state) => state.NFTReducer
  );
  const [pageSize, setPageSize] = useState(10);
  const [selectedPage, setSelectedPage] = useState(1);
  const [dataFilter, setDataFilter] = useState({});
  // const [listCheck, setListCheck] = useState([]);
  // const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can change this to 'auto' for instant scrolling
    });
    // dispatch(getFixedPriceNFTs(dataFilter, 10, currentPage));
  }, []);

  // useEffect(() => {
  //   setListCheck(listCheck.concat(listNFT));
  // }, [listNFT]);

  // const fetchMoreData = () => {
  //   if (listCheck.length >= totalNFT) {
  //     setHasMore(false);
  //     return;
  //   }
  //   setTimeout(() => {
  //     dispatch(getFixedPriceNFTs(dataFilter, 10, currentPage + 1));
  //   }, 2000);
  //   setCurrentPage(currentPage + 1);
  // };

  useEffect(() => {
    resetOffset();
  }, [dataFilter]);

  useEffect(() => {
    dispatch(getFixedPriceNFTs(dataFilter, pageSize, selectedPage));
  }, [dataFilter, selectedPage, pageSize]);

  function resetOffset() {
    setPageSize(10);
    setSelectedPage(1);
  }

  return (
    <div style={{ minHeight: "100vh" }}>
      <div className="FixedPriceNFT">
        <div className="text-center mt-5">
          <h2>{t("TITLE.FIXED_PRICE_NFT", { ns: "nft" })}</h2>
        </div>

        {/* <InfiniteScroll
          dataLength={listCheck.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          endMessage={<h4>End LIST</h4>}
        >
          {listCheck.map((nft, index) => {
            return <StanNFTCard nft={nft} key={nft.id} />;
          })}
        </InfiniteScroll> */}

        <StanNFTFilter
          showSearch
          showSort
          priceName="Listing"
          dataFilter={dataFilter}
          setDataFilter={setDataFilter}
        />

        {listFixedPriceNFT.length === 0 ? (
          <Empty />
        ) : (
          <>
            <div className="fixedPriceNFTList">
              {listFixedPriceNFT.map((nft) => {
                return <StanNFTCard nft={nft} key={nft.id} />;
              })}
            </div>
            <Pagination
              responsive={true}
              showSizeChanger={true}
              current={selectedPage}
              pageSize={pageSize}
              onChange={(selectedPage, pageSize) => {
                setSelectedPage(selectedPage);
                setPageSize(pageSize);
              }}
              total={totalFixedPriceNFT}
            ></Pagination>
          </>
        )}
      </div>
    </div>
  );
}
