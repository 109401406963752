import { Image, Spin } from "antd";
import Cookies from "js-cookie";
import _ from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import { history } from "../../App";
import { MEDIA_TYPE } from "../../common/Constant";
import { MaticIcon, StanPointIcon, verifiedSvg } from "../../common/commonSvg";
import { getListOffer } from "../../redux/action/AutionAction";
import { detailNFT } from "../../redux/action/NftAction";
import {
  CLOSE_BUY_NOW,
  CLOSE_CANCEL_LISTING_NFT,
  CLOSE_LISTING_NFT,
  CLOSE_MAKE_OFFER,
  CLOSE_PLACE_BID,
  OPEN_BUY_NOW,
  OPEN_CANCEL_LISTING_NFT,
  OPEN_LISTING_NFT,
  OPEN_MAKE_OFFER,
  OPEN_PLACE_BID,
  OPEN_QR_DRAWER,
} from "../../redux/types/ModalType";
import { NFT_STATUS } from "../../redux/types/NFTType";
import {
  CLEAR_STATE,
  PENDING_REQUEST,
  PROCESSING,
  REQUESTING,
} from "../../redux/types/UserType";
import { TOKEN_COOKIE, WALLET_LOGGED_IN } from "../../util/settings/config";
import { formatStanCrypto, formatStanPoint } from "../../util/settings/utils";
import StanButton from "../StanButton/StanButton";
import StanTypo from "../StanTypo/StanTypo";
import "./StanNFTCard.css";

export default function StanNFTCard(props) {
  const { t } = useTranslation(["common"]);

  //__________ INITIAL STATE __________//
  const { nft, type, searchNFT, viewOnly, lang } = props;
  const dispatch = useDispatch();
  const DEFAULT_COUNT_DOWN = ["--", "--", "--", "--"];
  const [countDown, setCountDown] = useState(DEFAULT_COUNT_DOWN);
  const [dates, hours, minutes, seconds] = countDown;
  const [isDisplayCountDown, setIsDisplayCountDown] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [id, setId] = useState(nft.id || nft.nft_id || nft.nftId);
  const [loadedImage, setLoadedImage] = useState(false);
  const [countdownEndTime, setCountdownEndtime] = useState(
    moment().add(nft.countdownEndTime, "seconds")
  );
  const { nftStatus } = useSelector((state) => state.AutionReducer);
  const { transactionList } = useSelector((state) => state.NFTReducer);

  useEffect(() => {
    let interval = setInterval(() => {
      // const then = moment(
      //   moment(nft.end_date).format("DD-MM-YYYY HH:mm:ss"),
      //   "DD-MM-YYYY HH:mm:ss"
      // );
      // const then = moment(countdownEndTime);
      const now = moment();
      const duration = moment.duration(countdownEndTime.diff(now));
      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      setCountDown([days, hours, minutes, seconds]);
      if (days === 0) {
        setIsDisplayCountDown(true);
      } else {
        setIsDisplayCountDown(false);
        clearInterval(interval);
      }
      if (days === 0 && hours === 0 && minutes === 0 && seconds < 0) {
        setIsExpired(true);
        clearInterval(interval);
      }
    }, 1000);
  }, []);

  //___________________________________//

  //__________ HANDLE FUNC __________//
  const handleViewDetailNft = () => {
    dispatch({ type: CLEAR_STATE });
    history.push(
      generatePath("/nft/:id", { id: nft.id || nft.nft_id || nft.nftId })
    );
  };

  function renderImageSrc(nft) {
    let imageSrc = nft.mediaUrl || nft.nftMediaUrl;
    if (
      nft?.mediaType === MEDIA_TYPE.VIDEO ||
      nft?.mediaType === MEDIA_TYPE.LIVESTREAM
    ) {
      imageSrc = nft.mediaPreviewUrl || nft.nftMediaPreviewUrl;
    } else if (nft?.mediaType === MEDIA_TYPE.AUDIO) {
      imageSrc = nft.mediaPreviewUrl;
    }
    return imageSrc;
  }

  // const RenderButton = useMemo(() => {
  //   // 1. WHEN USERS DIDN'T CONNECT WALLET
  //   if (!Cookies.get("UserAddress") && !Cookies.get("TokenId")) {
  //     return (
  //       <StanButton
  //         title={t("BUTTON.VIEW_ITEM")}
  //         type="primary"
  //         handleOnClick={() => {
  //           handleViewDetailNft();
  //         }}
  //       />
  //     );
  //   }
  //   // 2. WHEN USERS CONNECTED WALLET BUT DIDN'T CONNECT WITH STAN
  //   else if (Cookies.get("UserAddress") && !Cookies.get("TokenId")) {
  //     return (
  //       <StanButton
  //         title={t("BUTTON.VIEW_ITEM")}
  //         type="primary"
  //         handleOnClick={() => {
  //           handleViewDetailNft();
  //         }}
  //       />
  //     );
  //   }
  //   // 3. WHEN USERS CONNECTED WALLET AND STAN APP
  //   else if (Cookies.get("UserAddress") && Cookies.get("TokenId")) {
  //     // 3A__IS VIEW ONLY__
  //     if (viewOnly) {
  //       return (
  //         <StanButton
  //           title={t("BUTTON.VIEW_ITEM")}
  //           type="primary"
  //           handleOnClick={() => {
  //             handleViewDetailNft();
  //           }}
  //         />
  //       );
  //     }
  //     // 3B__IS OWNER__
  //     if (nft.ownerAddress === Cookies.get("UserAddress")) {
  //       if (nft.status === NFT_STATUS.SELLABLE) {
  //         return (
  //           <StanButton
  //             title={t("BUTTON.SELL_NOW")}
  //             type="primary"
  //             requireApprove
  //             handleOnClick={() => {
  //               dispatch({
  //                 type: OPEN_LISTING_NFT,
  //                 payload: { nft: nft },
  //                 onClose: () => {
  //                   dispatch({ type: CLOSE_LISTING_NFT });
  //                   dispatch({ type: PENDING_REQUEST });
  //                   searchNFT();
  //                 },
  //               });
  //             }}
  //             disabled={nftStatus !== PENDING_REQUEST}
  //           />
  //         );
  //       } else {
  //         return (
  //           <StanButton
  //             title={t("BUTTON.CANCEL")}
  //             type="stanBtnCancel"
  //             requireApprove
  //             handleOnClick={() => {
  //               dispatch({
  //                 type: OPEN_CANCEL_LISTING_NFT,
  //                 payload: { nft: nft },
  //                 onClose: () => {
  //                   dispatch({ type: CLOSE_CANCEL_LISTING_NFT });
  //                   dispatch({ type: PENDING_REQUEST });
  //                   searchNFT();
  //                 },
  //               });
  //               dispatch(detailNFT(id));
  //             }}
  //             disabled={nftStatus === REQUESTING || nftStatus === PROCESSING}
  //           />
  //         );
  //       }
  //     }
  //     // 3C__IS VIEWER__
  //     else {
  //       if (nft.status === NFT_STATUS.SELLABLE) {
  //         return (
  //           <StanButton
  //             title={t("BUTTON.MAKE_OFFER")}
  //             type="primary"
  //             requireApprove
  //             handleOnClick={() => {
  //               if (Cookies.get("TokenId")) {
  //                 dispatch(getListOffer(nft.id, 500, 1));
  //                 dispatch({
  //                   type: OPEN_MAKE_OFFER,
  //                   payload: { nft: nft },
  //                   onClose: () => {
  //                     dispatch({ type: CLOSE_MAKE_OFFER });
  //                     dispatch({ type: PENDING_REQUEST });
  //                     searchNFT();
  //                   },
  //                 });
  //               } else if (
  //                 !Cookies.get("TokenId") ||
  //                 _.isUndefined(TOKEN_COOKIE)
  //               ) {
  //                 dispatch({ type: OPEN_QR_DRAWER });
  //               }
  //             }}
  //             disabled={nftStatus !== PENDING_REQUEST}
  //           />
  //         );
  //       } else if (nft.status === NFT_STATUS.BUY) {
  //         return (
  //           <StanButton
  //             title={t("BUTTON.BUY_NOW")}
  //             type="primary"
  //             requireApprove
  //             handleOnClick={() => {
  //               if (Cookies.get("TokenId")) {
  //                 dispatch(detailNFT(id));
  //                 dispatch({
  //                   type: OPEN_BUY_NOW,
  //                   payload: { nft: nft },
  //                   onClose: () => {
  //                     dispatch({ type: CLOSE_BUY_NOW });
  //                     dispatch({ type: PENDING_REQUEST });
  //                     searchNFT();
  //                   },
  //                 });
  //               } else if (
  //                 !Cookies.get("TokenId") ||
  //                 _.isUndefined(TOKEN_COOKIE)
  //               ) {
  //                 dispatch({ type: OPEN_QR_DRAWER });
  //               }
  //             }}
  //             disabled={nftStatus !== PENDING_REQUEST}
  //           />
  //         );
  //       } else if (nft.status === NFT_STATUS.ON_BID) {
  //         return (
  //           <StanButton
  //             title={t("BUTTON.PLACE_BID")}
  //             type="primary"
  //             requireApprove
  //             handleOnClick={() => {
  //               if (Cookies.get("TokenId")) {
  //                 dispatch(detailNFT(id));
  //                 dispatch({
  //                   type: OPEN_PLACE_BID,
  //                   payload: { nft: nft, transactionList: transactionList },
  //                   onClose: () => {
  //                     dispatch({ type: CLOSE_PLACE_BID });
  //                     dispatch({ type: PENDING_REQUEST });
  //                     searchNFT();
  //                   },
  //                 });
  //               } else if (
  //                 !Cookies.get("TokenID") ||
  //                 _.isUndefined(TOKEN_COOKIE)
  //               ) {
  //                 dispatch({ type: OPEN_QR_DRAWER });
  //               }
  //             }}
  //             disabled={nftStatus !== PENDING_REQUEST}
  //           />
  //         );
  //       }
  //     }
  //   }
  //   return (
  //     <StanButton
  //       title={t("BUTTON.VIEW_ITEM")}
  //       type="primary"
  //       handleOnClick={() => {
  //         handleViewDetailNft();
  //       }}
  //     />
  //   );
  //   // __3.
  // }, [nftStatus, WALLET_LOGGED_IN, TOKEN_COOKIE, nft, lang, t]);

  // NEED TO OPTIMIZE
  const RenderButton = useMemo(() => {
    // 3A__IS VIEW ONLY__
    if (viewOnly) {
      return (
        <StanButton
          title={t("BUTTON.VIEW_ITEM")}
          type="primary"
          handleOnClick={() => {
            handleViewDetailNft();
          }}
        />
      );
    }
    // 3B__IS OWNER__
    if (
      Cookies.get("UserAddress") &&
      nft.ownerAddress === Cookies.get("UserAddress")
    ) {
      // NFT is sellable
      if (nft.status === NFT_STATUS.SELLABLE) {
        return (
          <StanButton
            title={t("BUTTON.SELL_NOW")}
            type="primary"
            requireApprove
            handleOnClick={() => {
              dispatch({
                type: OPEN_LISTING_NFT,
                payload: { nft: nft },
                onClose: () => {
                  dispatch({ type: CLOSE_LISTING_NFT });
                  dispatch({ type: PENDING_REQUEST });
                  searchNFT();
                },
              });
            }}
            disabled={nftStatus !== PENDING_REQUEST}
          />
        );
      }
      // NFT is being sold
      else {
        return (
          <StanButton
            title={t("BUTTON.CANCEL")}
            type="stanBtnCancel"
            requireApprove
            handleOnClick={() => {
              dispatch({
                type: OPEN_CANCEL_LISTING_NFT,
                payload: { nft: nft },
                onClose: () => {
                  dispatch({ type: CLOSE_CANCEL_LISTING_NFT });
                  dispatch({ type: PENDING_REQUEST });
                  searchNFT();
                },
              });
              dispatch(detailNFT(id));
            }}
            disabled={nftStatus === REQUESTING || nftStatus === PROCESSING}
          />
        );
      }
    }
    // 3C__IS VIEWER__
    else {
      if (nft.status === NFT_STATUS.SELLABLE) {
        return (
          <StanButton
            title={t("BUTTON.MAKE_OFFER")}
            type="primary"
            requireApprove
            handleOnClick={() => {
              dispatch(getListOffer(nft.id, 500, 1));
              dispatch({
                type: OPEN_MAKE_OFFER,
                payload: { nft: nft },
                onClose: () => {
                  dispatch({ type: CLOSE_MAKE_OFFER });
                  dispatch({ type: PENDING_REQUEST });
                  searchNFT();
                },
              });
            }}
            disabled={nftStatus !== PENDING_REQUEST}
          />
        );
      } else if (nft.status === NFT_STATUS.BUY) {
        return (
          <StanButton
            title={t("BUTTON.BUY_NOW")}
            type="primary"
            requireApprove
            handleOnClick={() => {
              dispatch(detailNFT(id));
              dispatch({
                type: OPEN_BUY_NOW,
                payload: { nft: nft },
                onClose: () => {
                  dispatch({ type: CLOSE_BUY_NOW });
                  dispatch({ type: PENDING_REQUEST });
                  searchNFT();
                },
              });
            }}
            disabled={nftStatus !== PENDING_REQUEST}
          />
        );
      } else if (nft.status === NFT_STATUS.ON_BID) {
        return (
          <StanButton
            title={t("BUTTON.PLACE_BID")}
            type="primary"
            requireApprove
            handleOnClick={() => {
              dispatch(detailNFT(id));
              dispatch({
                type: OPEN_PLACE_BID,
                payload: { nft: nft, transactionList: transactionList },
                onClose: () => {
                  dispatch({ type: CLOSE_PLACE_BID });
                  dispatch({ type: PENDING_REQUEST });
                  searchNFT();
                },
              });
            }}
            disabled={nftStatus !== PENDING_REQUEST}
          />
        );
      }
    }
    // __3.
  }, [nftStatus, WALLET_LOGGED_IN, TOKEN_COOKIE, nft, lang, t]);
  //___________________________________//

  return (
    <>
      <div className="StanNFTCard" id="StanNFTCard">
        {type === "LIVE_AUCTION" && isDisplayCountDown ? (
          <div className="StanNFTCard-countDown">
            {!isExpired ? (
              <span style={{ color: "#FFFFFF" }}>
                {hours < 10 ? "0" + hours : hours}:
                {minutes < 10 ? "0" + minutes : minutes}:
                {seconds < 10 ? "0" + seconds : seconds}
              </span>
            ) : (
              <span style={{ color: "#FFFFFF" }}>Expired</span>
            )}
          </div>
        ) : null}
        <div className="StanNFTCard-image">
          <a
            href={
              process.env.REACT_APP_SITE_URL +
              "nft/" +
              (nft.id || nft.nft_id || nft.nftId)
            }
            onClick={(e) => e.preventDefault()}
          >
            <Image
              style={{ cursor: "pointer" }}
              src={renderImageSrc(nft)}
              preview={false}
              onClick={!viewOnly ? handleViewDetailNft : null}
              onLoad={(e) => setLoadedImage(true)}
              fallback="https://www.salonlfc.com/wp-content/uploads/2018/01/image-not-found-scaled-1150x647.png"
            />
            {!loadedImage && (
              <div className="loadingOverlayNFT">
                <Spin
                  size="large"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              </div>
            )}
          </a>
        </div>

        <div className="StanNFTCard-info">
          <div className="StanNFTCard-body d-flex justify-content-between">
            <div style={{ width: "50%" }} className="d-flex flex-column">
              <div style={{ width: "100%" }} className="ellipsisText">
                <StanTypo
                  fontSize={12}
                  fontWeight={600}
                  color="#EDEDED"
                  content={nft.collectionName}
                />
              </div>
              <div style={{ width: "100%" }} className="ellipsisText">
                <StanTypo
                  fontSize={18}
                  fontWeight={700}
                  color="#FFFFFF"
                  content={
                    <>
                      {nft.name || nft.nft_name || nft.nftName}{" "}
                      {!nft.isMobile ? verifiedSvg() : null}
                    </>
                  }
                />
              </div>
              {type === "HOT_OFFER" ? (
                <div style={{ width: "100%" }} className="ellipsisText">
                  <StanTypo
                    fontSize={14}
                    fontWeight={700}
                    color="#FFFFFF"
                    content={t("TITLE.TOP_OFFER")}
                  />
                </div>
              ) : null}
            </div>
            {(nft.status === "SELLABLE" ||
              nft.price === 0 ||
              nft.priceCrypto === 0 ||
              _.isNull(nft.price)) &&
            type !== "LATEST_SALE" ? null : (
              <div
                style={{ width: "50%" }}
                className="d-flex flex-column justify-content-center align-items-end"
              >
                <div>
                  <StanTypo
                    fontSize={12}
                    fontWeight={600}
                    color="#EDEDED"
                    content={t("TEXT.PRICE")}
                  />
                </div>
                <div
                  style={{ width: "100%" }}
                  className="ellipsisText text-right"
                >
                  <StanTypo
                    fontSize={18}
                    fontWeight={700}
                    color="#FFFFFF"
                    content={
                      <>
                        <MaticIcon />{" "}
                        {formatStanCrypto(
                          nft.priceCrypto ||
                            nft.max_price ||
                            nft.transaction_price
                        )}
                      </>
                    }
                  />
                </div>
                <div
                  style={{ width: "100%" }}
                  className="ellipsisText text-right"
                >
                  <StanTypo
                    fontSize={12}
                    fontWeight={600}
                    color="#EDEDED"
                    content={
                      <>
                        <StanPointIcon />{" "}
                        {formatStanPoint(
                          nft.pricePoint ||
                            nft.max_price ||
                            nft.transaction_price
                        )}
                      </>
                    }
                  />
                </div>
              </div>
            )}
          </div>
          {loadedImage ? (
            <div className="StanNFTCard-button fade-in" id="StanNFTCard-button">
              {RenderButton}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
