import { Empty, Pagination } from "antd";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import StanCollectionCard from "../../../../component/StanCollectionCard/StanCollectionCard";
import { getProfileCollections } from "../../../../redux/action/UserAction";
import "./CollectionsTab.css";

export default function CollectionsTab(props) {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const { walletAddress } = useParams();
  const userAddress = Cookies.get("UserAddress");
  const [selectedPageSize, setSelectedPageSize] = useState(10);
  const [selectedPage, setSelectedPage] = useState(1);
  const { listProfileCollections, totalProfileCollections } = useSelector(
    (state) => state.UserReducer
  );

  useEffect(() => {
    dispatch(
      getProfileCollections(
        selectedPageSize,
        selectedPage,
        walletAddress.toLowerCase(),
        ""
      )
    );
  }, [selectedPageSize, selectedPage]);

  return (
    <>
      {listProfileCollections && listProfileCollections?.length === 0 ? (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Empty
            description={
              !Cookies.get("UserAddress")
                ? t("MSG.CONNECT_WALLET")
                : t("MSG.NOT_FOUND_COLLECTION")
            }
          />
        </div>
      ) : (
        <>
          <div className="collectionsTabContainer">
            <div className="circleBackground">
              <img alt="" src="/img/Circle.png" />
            </div>
            <div className="triangleImg">
              <img alt="" src="/img/triangle.png" />
            </div>
            <div className="collectionCardSection">
              {listProfileCollections.map((item) => {
                return <StanCollectionCard key={item.id} collection={item} />;
              })}
            </div>
          </div>
          <div className="collectionPagination">
            <Pagination
              responsive={true}
              showSizeChanger={true}
              current={selectedPage}
              pageSize={selectedPageSize}
              onChange={(page, pageSize) => {
                setSelectedPage(page);
                setSelectedPageSize(pageSize);
              }}
              total={totalProfileCollections}
            />
          </div>
        </>
      )}
    </>
  );
}
