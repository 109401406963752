import {
  CalendarOutlined,
  CaretRightOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Collapse, Input, Select } from "antd";
import Cookies from "js-cookie";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { openNotificationWithIcon } from "../../../App";
import { MINIMUM_VALUE_OF_DOLLAR } from "../../../common/commonParams";
import { MaticIcon } from "../../../common/commonSvg";
import ModalInfoNFT from "../../../component/ModalInfoNFT/ModalInfoNFT";
import StanButton from "../../../component/StanButton/StanButton";
import { getBalanceOfFan, makeOffer } from "../../../redux/action/AutionAction";
import { getUSDRate } from "../../../redux/action/UserAction";
import { OPEN_DEPOSIT } from "../../../redux/types/ModalType";
import {
  COMPLETE_REQUEST,
  PENDING_REQUEST,
  PROCESSING,
  REQUESTING,
} from "../../../redux/types/UserType";
import { NETWORK_URL } from "../../../util/settings/config";
import {
  decimalCharacters,
  formatStanCryptoDetail,
  formatedCash,
  usdOnCrypto,
} from "../../../util/settings/utils";
import "./MakeOffer.css";

export default function MakeOffer(props) {
  const { t } = useTranslation(["common", "nft", "components", "collection"]);
  const [inputPrice, setInputPrice] = useState();
  const [expirationPeriod, setExpirationPeriod] = useState();
  const [error, setError] = useState("");
  const { balanceOfFan, listOffer, nftStatus, txtHash } = useSelector(
    (state) => state.AutionReducer
  );
  const { payload, onClose } = useSelector((state) => state.ModalReducer);
  const { ratePoint, rateCrypto, minCryptoPrice } = useSelector(
    (state) => state.UserReducer
  );

  const { nft } = payload;

  const { Option } = Select;
  const dispatch = useDispatch();

  // RESET FORM
  useEffect(() => {
    if (nftStatus === PENDING_REQUEST) {
      setInputPrice("");
      setError("");
      setExpirationPeriod("3");
    }
  }, [nftStatus]);

  useEffect(() => {
    dispatch(getBalanceOfFan());
    dispatch(getUSDRate());
  }, []);

  let listCalendar = [
    { value: "1", name: `1 ${t("TEXT.DAY", { ns: "nft" })}` },
    { value: "3", name: `3 ${t("TEXT.DAYS", { ns: "nft" })}` },
    { value: "7", name: `7 ${t("TEXT.DAYS", { ns: "nft" })}` },
    { value: "14", name: `14 ${t("TEXT.DAYS", { ns: "nft" })}` },
    { value: "30", name: `30 ${t("TEXT.DAYS", { ns: "nft" })}` },
    { value: "90", name: `90 ${t("TEXT.DAYS", { ns: "nft" })}` },
  ];

  const InputExpirationPeriod = listCalendar.map((e, index) => (
    <Option key={index} value={e.value}>
      <CalendarOutlined style={{ marginRight: 10 }} />
      {e.name}
    </Option>
  ));

  const handleChangeInputPrice = (e) => {
    if (e.target.value.length > 6) {
      setInputPrice(+parseFloat(e.target.value).toFixed(6));
    } else {
      setInputPrice(e.target.value);
    }
  };

  const handleChangeExpirationPeriod = (value) => {
    setExpirationPeriod(value);
  };

  const handleMakeOffer = () => {
    const checkUser = listOffer.filter(
      (offer) =>
        offer.buyerWalletAddress === Cookies.get("UserAddress") &&
        offer.status === "ACTIVE"
    );
    if (!checkInputPrice(+inputPrice)) {
      return;
    }
    // if (checkUser.length === 0) {
    dispatch(
      makeOffer(
        {
          id: nft.id,
          sellerWalletAddress: nft.ownerAddress,
          buyerWalletAddress: Cookies.get("UserAddress"),
          price: inputPrice,
          currency: "POLYGON",
          expirationPeriod: expirationPeriod,
          isAuction: false,
        },
        nft.tokenId
      )
    );
    // } else {
    //   openNotificationWithIcon(
    //     "error",
    //     "Warning",
    //     "You already make offer this nft"
    //   );
    // }
  };

  function checkInputPrice(price) {
    if (price && price <= 0) {
      openNotificationWithIcon("error", "Error", t("MSG.CANNOT_MAKE_OFFER"));
      return false;
    } else if (
      price &&
      // price < (MINIMUM_VALUE_OF_DOLLAR / rateCrypto || 0)
      price < minCryptoPrice
      // price < cryptoOnPoint(MINIMUM_VALUE_OF_POINT, ratePoint, rateCrypto)
    ) {
      openNotificationWithIcon("error", "Error", "Minimum price");
      return false;
    } else if (price && (Number.isNaN(price) || price === "")) {
      setError(t("MSG.NFT_PRICE_MUST_NOT_BE_BLANK"));
      openNotificationWithIcon(
        "error",
        "Error",
        t("MSG.PLEASE_TYPE_INPUT_PRICE")
      );
      return false;
    } else if (price && price > 100000000000000) {
      setError(t("MSG.AMOUNT_CANNOT_EXCEED"));
      return false;
    } else if (price && price.toString().length > 18) {
      setError(t("MSG.AMOUNT_CANNOT_EXCEED"));
      openNotificationWithIcon(
        "error",
        "Error",
        t("MSG.INPUT_PRICE_MAX_18_DIGITS")
      );
      return false;
    }
    return true;
  }

  const CompleteMakeOffer = (props) => {
    const { Panel } = Collapse;
    const { InputPrice } = useSelector((state) => state.AutionReducer);

    const onChange = (key) => {
      console.log(key);
    };

    return (
      <Fragment>
        <div className="CompleteListingHead">
          <ModalInfoNFT nft={nft} InputPrice={InputPrice} currency="STF" />
        </div>
        <div className="CompleteListingBody">
          <Collapse
            bordered={false}
            onChange={onChange}
            ghost
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? -90 : 90} />
            )}
          >
            <Panel
              className="CompleteListingPanel"
              header={
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CheckCircleOutlined
                      style={{
                        borderRadius: "50%",
                        background: "#49D19A",
                        marginRight: 10,
                        fontSize: "16px",
                      }}
                    />
                    <h4>{t("TEXT.APPROVE_CRYPTO", { ns: "nft" })} </h4>
                  </div>
                </>
              }
              key="1"
            >
              <h4>{t("TEXT.APPROVE_WETH_DESCRIPTION", { ns: "nft" })}</h4>
            </Panel>
            <Panel
              className="CompleteListingPanel"
              header={
                <div style={{ display: "flex" }}>
                  {nftStatus === PROCESSING ? (
                    <CheckCircleOutlined
                      style={{
                        borderRadius: "50%",
                        background: "#49D19A",
                        marginRight: 10,
                        fontSize: "16px",
                      }}
                    />
                  ) : (
                    <div className="spinner loading"> 2 </div>
                  )}
                  <h4>{t("TEXT.CONFIRM_MAKE_OFFER", { ns: "nft" })}</h4>
                </div>
              }
              key="2"
            >
              <h4>
                {t("TEXT.ACCEPT_SIGNATURE_REQUEST_MAKING_OFFER", { ns: "nft" })}
              </h4>
            </Panel>
          </Collapse>
        </div>
      </Fragment>
    );
  };

  const CompleteMakeOfferDone = (props) => {
    return (
      <div className="loadingCancelSuccess">
        {/* <img alt="" src="/img/Confetti.png" width={150} height={150} /> */}
        <ModalInfoNFT nft={nft} InputPrice={inputPrice} />
        <p>{t("MSG.MAKE_OFFER_NFT_SUCCESSFULLY", { ns: "nft" })} </p>
        {/* <StanButton
          title={t("BUTTON.DONE")}
          handleOnClick={() => {
            onClose();
          }}
          type="primary"
        /> */}
        <StanButton
          disabled={!txtHash}
          title={t("BUTTON.VIEW_TRANSACTION")}
          type="primary"
          handleOnClick={() => window.open(NETWORK_URL + "tx/" + txtHash)}
        />
      </div>
    );
  };

  const RenderContent = useMemo(() => {
    switch (nftStatus) {
      case PROCESSING:
        return <CompleteMakeOffer nft={nft} />;
      case COMPLETE_REQUEST:
        return <CompleteMakeOfferDone closeModal={onClose} />;
      default:
        return (
          <div>
            <div className="MakeOfferPrice">
              <div className="MakeOfferPriceInfo">
                <h4>{t("TEXT.NFT_PRICE", { ns: "nft" })}</h4>
                <h4>
                  <MaticIcon />
                  {formatStanCryptoDetail(inputPrice)} ($
                  {formatedCash(usdOnCrypto(inputPrice, rateCrypto))})
                </h4>
              </div>
              <Input
                maxLength={11}
                onChange={handleChangeInputPrice}
                onKeyPress={decimalCharacters}
                value={inputPrice}
                placeholder="00.00"
                type="number"
                suffix={<></>}
              />
              <span style={{ color: "red" }}>{error}</span>
              <div className="MakeOfferPriceBalance">
                {+inputPrice > +balanceOfFan ? (
                  <p style={{ color: "red" }}>
                    {t("MSG.NOT_ENOUGH_STF_TO_MAKE_OFFER")}
                  </p>
                ) : (
                  <div>
                    <span style={{ fontSize: "13px" }}>
                      {/* Min: {MINIMUM_VALUE_OF_DOLLAR / rateCrypto || 0} */}
                      Min: {minCryptoPrice}
                    </span>
                  </div>
                )}

                <div className="text-right">
                  <span style={{ fontSize: "13px" }}>
                    {t("TEXT.BALANCE", { ns: "nft" })} <MaticIcon />{" "}
                    {formatStanCryptoDetail(balanceOfFan)}
                  </span>
                </div>
              </div>
            </div>
            <div className="MakeOfferExpiration">
              <p>{t("TEXT.EXPIRATION_PERIOD", { ns: "nft" })}</p>
              <Select
                onChange={handleChangeExpirationPeriod}
                value={expirationPeriod}
                className="expirationPeriod"
                bordered={false}
              >
                {InputExpirationPeriod}
              </Select>
            </div>
            <div style={{ margin: "20px 0" }}>
              <StanButton
                handleOnClick={handleMakeOffer}
                title={t("BUTTON.MAKE_OFFER")}
                type="primary"
                disabled={
                  +inputPrice > +balanceOfFan ||
                  nftStatus === REQUESTING ||
                  !checkInputPrice(+inputPrice)
                }
              />
            </div>
            <div>
              <StanButton
                title={t("TITLE.ADD_FUND")}
                type="secondary"
                handleOnClick={() => dispatch({ type: OPEN_DEPOSIT })}
              />
            </div>
          </div>
        );
    }
  }, [balanceOfFan, nftStatus, inputPrice, expirationPeriod, t]);

  return <>{RenderContent}</>;
}
