import { baseService } from "./baseService";

export class CollectionService extends baseService {
  constructor() {
    super();
  }

  getListCollection = (pageSize, page) => {
    return this.get(
      `web/collection/all-item?limit=${pageSize}&offset=${
        (page - 1) * pageSize
      }`
    );
  };

  searchCollection = (
    pageSize,
    page,
    walletAddress,
    keyword,
    property,
    sort
  ) => {
    let url = `web/collection/all-item?limit=${pageSize}&offset=${
      (page - 1) * pageSize
    }&`;
    if (walletAddress && walletAddress !== "") {
      url += `walletAddress=${walletAddress}&`;
    }
    if (keyword && keyword !== "") {
      url += `keyword=${keyword}`;
    }
    if (property && property !== "") {
      url += `&property=${property}`;
    }
    if (sort && sort !== "") {
      url += `&sort=${sort}`;
    }
    return this.get(url);
  };

  getDetailCollection = (collectionId) => {
    return this.get(`web/collection/${collectionId}`);
  };

  createCollection = (data) => {
    return this.post(`web/collection`, data);
  };

  updateCollection = (data) => {
    return this.put(`web/collection`, data);
  };

  getCollectionActivities = (collectionId, type, pageSize, page) => {
    let url = `web/collection/${collectionId}/activities?limit=${pageSize}&offset=${
      (page - 1) * pageSize
    }`;
    if (type && type !== "") {
      url += `&type=${type}`;
    }
    return this.get(url);
  };

  getCollectionCollected = (data, pageSize, page) => {
    let url = `web/collection/all-collected?limit=${pageSize}&offset=${
      (page - 1) * pageSize
    }&`;
    if (data.walletAddress && data.walletAddress !== "") {
      url += `walletAddress=${data.walletAddress}&`;
    }
    if (data.keyword && data.keyword !== "") {
      url += `keyword=${data.keyword}`;
    }
    return this.get(url);
  };
}
export const collectionService = new CollectionService();
