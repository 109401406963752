export const GAS_LIMIT = 2000000;
export const PRIORITY_FEE = 45000000000; // 45 GWEI
export const MAX_NFT = 50;
export const STAN_FEE = 1;
export const MINIMUM_VALUE_OF_POINT = 10;
export const MINIMUM_VALUE_OF_DOLLAR = 1;

// NFT PARAM //
export const NETWORK_BLOCKCHAIN = "POLYGON";
export const TOKEN_STANDARD = "ERC-721";

export const NFT_STATUS = {
  BUY: "BUY",
  OFFER: "OFFER",
  ON_BID: "ON_BID",
};

export const CURRENCY = {
  ALL: "",
  POLYGON: "POLYGON",
  POINT: "POINT",
};

export const NFT_ORDER = {
  RECENTLY_CREATED: "RECENTLY_CREATED",
  RECENTLY_LISTED: "RECENTLY_LISTED",
  OLDEST: "OLDEST",
  PRICE_LOW_HIGH: "PRICE_LOW_HIGH",
  PRICE_HIGH_LOW: "PRICE_HIGH_LOW",
};

export const COLLECTION_SORT = {
  ONE_DAY: "ONE_DAY",
  SEVEN_DAYS: "SEVEN_DAYS",
  THIRTY_DAYS: "THIRTY_DAYS",
};
