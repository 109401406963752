import { Affix, Drawer, Empty, Input, Pagination, Select, Spin } from "antd";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { filterSvg } from "../../../common/commonSvg";
import StanFilter from "../../../component/StanFilter/StanFilter";
import StanFilterPreview from "../../../component/StanFilter/StanFilterPreview";
import StanNFTCard from "../../../component/StanNFTCard/StanNFTCard";
import { getListCollection } from "../../../redux/action/CollectionAction";
import { searchNFT } from "../../../redux/action/NftAction";
import { getAllArtist } from "../../../redux/action/UserAction";
import { REQUESTING } from "../../../redux/types/UserType";
import "../Explore.css";

const { Option } = Select;
export default function ExploreItem(props) {
  const { t } = useTranslation(["common", "explore", "nftDetail"]);
  const dispatch = useDispatch();
  const [inputSearch, setInputSearch] = useState();
  const [showMenu, setShowMenu] = useState(false);
  const [orderBy, setOrderBy] = useState("RECENTLY_CREATED");
  const [dataFilter, setDataFilter] = useState({
    orderBy: orderBy,
  });
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [activeTabPane, setActiveTabPane] = useState("ITEMS");
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedPageSize, setSelectedPageSize] = useState(10);
  const { listNFT, totalNFT } = useSelector((state) => state.NFTReducer);
  const { listCollection } = useSelector((state) => state.CollectionReducer);
  const { allArtists } = useSelector((state) => state.UserReducer);
  const { nftStatus } = useSelector((state) => state.AutionReducer);

  useEffect(() => {
    dispatch(getListCollection(100, 1));
    dispatch(getAllArtist(100, 1, "", 1));
  }, []);

  useEffect(() => {
    dispatch(searchNFT(dataFilter, selectedPageSize, selectedPage));
  }, [dataFilter, selectedPageSize, selectedPage]);

  useEffect(() => {
    setSelectedPage(1);
  }, [inputSearch, orderBy]);

  useEffect(() => {
    setDataFilter({
      ...dataFilter,
      orderBy: orderBy,
    });
  }, [activeTabPane, orderBy]);

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      setDataFilter({ ...dataFilter, name: encodeURIComponent(inputSearch) });
    }
  }

  function handleOpenFilter() {
    if (Cookies.get("DEVICE") === "MOBILE") {
      setVisibleFilter(true);
    } else {
      setShowMenu(!showMenu);
    }
  }

  function reSearchNFT() {
    dispatch(searchNFT(dataFilter, selectedPageSize, selectedPage));
  }

  function filterNFT(data) {
    setDataFilter({
      ...dataFilter,
      status: data.status,
      currency: data.currency,
      fromPrice: data.fromPrice,
      toPrice: data.toPrice,
      collectionId: data.collectionIds,
      categoryIds: data.categoryIds,
      walletAddress: data.walletAddress,
    });
  }

  return (
    <>
      <div className="exploreItem">
        <Affix offsetTop={0}>
          <div className="searchSection">
            <div
              className="d-flex justify-content-center filterToggle"
              style={{ width: "5%" }}
            >
              <div
                style={
                  showMenu
                    ? {
                        backgroundColor: "#49D19A",
                        borderRadius: "12px",
                        padding: "12px",
                      }
                    : {
                        backgroundColor: "#FFFFFF",
                        borderRadius: "12px",
                        padding: "12px",
                      }
                }
              >
                {filterSvg(handleOpenFilter)}
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <Input
                className="inputSearchNft"
                placeholder={t("PLACEHOLDER.SEARCH", { ns: "explore" })}
                onChange={(e) => setInputSearch(e.target.value)}
                onKeyPress={handleKeyPress}
                prefix={
                  <svg
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.4167 9.87907H9.75833L9.525 9.65407C10.3417 8.70407 10.8333 7.47074 10.8333 6.12907C10.8333 3.1374 8.40833 0.712402 5.41667 0.712402C2.425 0.712402 0 3.1374 0 6.12907C0 9.12074 2.425 11.5457 5.41667 11.5457C6.75833 11.5457 7.99167 11.0541 8.94167 10.2374L9.16667 10.4707V11.1291L13.3333 15.2874L14.575 14.0457L10.4167 9.87907ZM5.41667 9.87907C3.34167 9.87907 1.66667 8.20407 1.66667 6.12907C1.66667 4.05407 3.34167 2.37907 5.41667 2.37907C7.49167 2.37907 9.16667 4.05407 9.16667 6.12907C9.16667 8.20407 7.49167 9.87907 5.41667 9.87907Z"
                      fill="#CBCBCB"
                    />
                  </svg>
                }
              />
            </div>
            <div style={{ width: "10%" }}>
              <Select
                value={orderBy}
                className="inputSelectNft"
                onChange={(e) => setOrderBy(e)}
              >
                <Option value="RECENTLY_CREATED">
                  {t("SELECT.TITLE.RECENTLY_CREATED", {
                    ns: "explore",
                  })}
                </Option>
                <Option value="RECENTLY_LISTED">
                  {t("SELECT.TITLE.RECENTELY_LISTED", {
                    ns: "explore",
                  })}
                </Option>
                <Option value="OLDEST">
                  {t("SELECT.TITLE.OLDEST", {
                    ns: "explore",
                  })}
                </Option>
                <Option value="PRICE_LOW_HIGH">
                  {t("SELECT.TITLE.LOW_TO_HIGH", {
                    ns: "explore",
                  })}
                </Option>
                <Option value="PRICE_HIGH_LOW">
                  {t("SELECT.TITLE.HIGH_TO_LOW", {
                    ns: "explore",
                  })}
                </Option>
              </Select>
            </div>
          </div>
        </Affix>

        <div className="exploreItemContainer">
          <div
            className="menuSide fade-in-2"
            style={!showMenu ? { display: "none" } : null}
          >
            <Affix offsetTop={100}>
              <StanFilter
                showStatus
                showPrice
                showCategory
                showArtist
                showCollection
                listCollection={listCollection}
                listArtist={allArtists}
                filterNFT={filterNFT}
              />
            </Affix>
          </div>

          <div className="contentSide">
            <StanFilterPreview />
            <Spin spinning={nftStatus === REQUESTING}>
              {listNFT.length === 0 ? (
                <Empty
                  className="mt-4"
                  description={t("MSG.NO_RESULTS_FOUND")}
                />
              ) : (
                <div className={showMenu ? "listNft-collapsed" : "listNft"}>
                  {listNFT.map((item) => {
                    return (
                      <StanNFTCard
                        key={item.id}
                        nft={item}
                        searchNFT={reSearchNFT}
                      />
                    );
                  })}
                </div>
              )}
            </Spin>
            <div
              style={{
                marginTop: "50px",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Pagination
                responsive={true}
                showSizeChanger={true}
                current={selectedPage}
                pageSize={selectedPageSize}
                onChange={(page, pageSize) => {
                  setSelectedPage(page);
                  setSelectedPageSize(pageSize);
                }}
                total={totalNFT}
              ></Pagination>
            </div>
          </div>
        </div>
      </div>

      {/* DRAWER */}
      <Drawer
        title={t("TITLE.FILTER_NFT")}
        placement={"bottom"}
        closable={true}
        onClose={() => setVisibleFilter(false)}
        visible={visibleFilter}
        className="filterDrawer"
      >
        <StanFilter
          showStatus
          showPrice
          showCategory
          showArtist
          showCollection
          listCollection={listCollection}
          listArtist={allArtists}
          filterNFT={filterNFT}
        />
      </Drawer>
      {/* DRAWER */}
    </>
  );
}
