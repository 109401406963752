import { Spin } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MaticIcon, WETHIcon } from "../../../common/commonSvg";
import ModalInfoNFT from "../../../component/ModalInfoNFT/ModalInfoNFT";
import StanButton from "../../../component/StanButton/StanButton";
import StanTypo from "../../../component/StanTypo/StanTypo";
import {
  cancelOffer,
  cancelOfferAuction,
  getStanServiceFee,
} from "../../../redux/action/AutionAction";
import {
  COMPLETE_REQUEST,
  FAILED_REQUEST,
  PROCESSING,
  REQUESTING,
} from "../../../redux/types/UserType";
import { NETWORK_URL } from "../../../util/settings/config";
import { formatStanCryptoDetail, formatStanUsd } from "../../../util/settings/utils";

export default function CancelOffer(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "nft"]);
  const { nftStatus, txtHash, stanServiceFee, InputPrice } = useSelector(
    (state) => state.AutionReducer
  );
  const { payload, onClose } = useSelector((state) => state.ModalReducer);
  const { nft, selectedOffer } = payload;

  useEffect(() => {
    dispatch(getStanServiceFee());
  }, []);

  function handleCancel() {
    dispatch({
      type: "InputPrice",
      data: selectedOffer.price,
    });
    if (selectedOffer.isAuction) {
      dispatch(cancelOfferAuction(nft, selectedOffer));
    } else {
      dispatch(cancelOffer(nft, selectedOffer));
    }
  }

  const RenderContent = () => {
    switch (nftStatus) {
      case PROCESSING:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "20px 0px",
            }}
          >
            <Spin style={{ margin: "0px 10px" }} />
            <span> {t("TEXT.PROCESSING", { ns: "nft" })}</span>
          </div>
        );
      case FAILED_REQUEST:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "20px 0px",
            }}
          >
            <span>{t("TEXT.FAILED_TRANSACTION", { ns: "nft" })}</span>
          </div>
        );
      case COMPLETE_REQUEST:
        return (
          <div className="d-flex flex-column justify-content-center align-items-center">
            {console.log("prosp =>", props)}

            <ModalInfoNFT nft={nft} InputPrice={props.payload.selectedOffer.priceCrypto} />
            <span className="mt-4 mb-4">
              {t("TEXT.CANCEL_OFFER_SUCCESSFULLY", { ns: "nft" })}
            </span>
            <StanButton
              disabled={!txtHash}
              title={t("BUTTON.VIEW_TRANSACTION")}
              type="primary"
              handleOnClick={() => window.open(NETWORK_URL + "tx/" + txtHash)}
            />
          </div>
        );
      default:
        return (
          <>
            <div className="d-flex flex-column">
              {console.log("NFT =>", nft)}
              {console.log("prosp =>", props)}
              <span>
                {t("TEXT.CANCELLING_LISTINGS_DESCRIPTION", { ns: "nft" })}
              </span>
              <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
                <span>
                  {t("TITLE.OFFER_PRICE", { ns: "nft" }).toUpperCase()}
                </span>
                <span>
                  <MaticIcon /> {selectedOffer?.price}
                </span>
              </div>
              {!selectedOffer?.isAuction &&
              stanServiceFee?.ratioCancelOfferPvPVal > 0 ? (
                <>
                  <div className="d-flex flex-column mt-3">
                    <div>
                      <span>
                        {t("TITLE.FEE_SUMMARY", { ns: "nft" }).toUpperCase()}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>{t("TEXT.SERVICE_FEE", { ns: "nft" })}</span>
                      <span>
                        <MaticIcon /> {stanServiceFee?.ratioCancelOfferPvPVal}%
                      </span>
                    </div>
                    <StanTypo
                      fontSize={14}
                      fontWeight={400}
                      color="#939393"
                      content={t("TEXT.THIS_IS_SV_MAKE_OFFER", { ns: "nft" })}
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div>
                      <span>
                        {t("TITLE.TOTAL_FEE", { ns: "nft" }).toUpperCase()}
                      </span>
                    </div>
                    <div className="d-flex flex-column align-items-end">
                      <span>
                        <MaticIcon />{" "}
                        {formatStanCryptoDetail(
                          (+selectedOffer?.price *
                            +stanServiceFee?.ratioCancelOfferPvPVal) /
                            100
                        )}
                      </span>
                      <StanTypo
                        fontSize={16}
                        fontWeight={400}
                        color="#4D4D4D"
                        content={
                          formatStanUsd(
                            (selectedOffer?.priceUsd *
                              stanServiceFee?.ratioCancelOfferPvPVal) /
                              100
                          ) + "$"
                        }
                      />
                    </div>
                  </div>
                </>
              ) : null}
              <div
                style={{ borderTop: "1px solid #CBCBCB" }}
                className="d-flex flex-column mt-3 pt-4"
              >
                <StanButton
                  type="secondary"
                  style={{ margin: "5px 0px" }}
                  title={t("BUTTON.GO_BACK")}
                  handleOnClick={() => {
                    onClose();
                  }}
                ></StanButton>
                <StanButton
                  type="primary"
                  title={t("BUTTON.CONFIRM")}
                  style={{ margin: "5px 0px" }}
                  handleOnClick={() => handleCancel()}
                  disabled={
                    nftStatus === REQUESTING || nftStatus === PROCESSING
                  }
                ></StanButton>
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <>
      <RenderContent />
    </>
  );
}
