import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import StanButton from "../../../component/StanButton/StanButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { translateDescription } from "../../../redux/action/UserAction";
import {
  copyToClipboard,
  subStringAddress,
} from "../../../util/settings/utils";
import StanTypo from "../../../component/StanTypo/StanTypo";
import { history } from "../../../App";
import { generatePath } from "react-router-dom";
import { Tooltip } from "antd";
import { useEffect } from "react";

export default function AboutTab(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "nftDetail", "explore"]);
  const [nftDescription, setNftDescription] = useState("");
  const [isTranslated, setIsTranslated] = useState(false);
  const { nft } = useSelector((state) => state.NFTReducer);
  // const { stanRoyalty } = useSelector((state) => state.AutionReducer);
  const { translatedDescription, creatorRoyalty } = useSelector((state) => state.UserReducer);

  useEffect(() => {
    setNftDescription(nft.description);
  }, [nft]);

  const handleTranslate = (description) => {
    if (!isTranslated) {
      dispatch(
        translateDescription({
          content: description,
          lang: i18next.language === "kr" ? "ko" : i18next.language,
        })
      );
    } else {
      setNftDescription(nft.description);
    }
  };

  useEffect(() => {
    if (translatedDescription !== "" && isTranslated) {
      setNftDescription(translatedDescription);
    }
  }, [translatedDescription, isTranslated]);

  useEffect(() => {
    setIsTranslated(false);
    setNftDescription(nft.description);
  }, [i18next.language]);

  return (
    <>
      <div>
        <div className="mb-4">
          <span>{nftDescription}</span>
          {nft.descriptionLang === i18next.language ||
          nft.description === "" ? null : (
            <div className="mt-4" style={{ width: "25%" }}>
              <StanButton
                noUpperCase
                style={{ maxHeight: "24px", fontSize: "10px" }}
                type="primary"
                title={
                  !isTranslated
                    ? t("BUTTON.SEE_TRANSLATION", { ns: "common" })
                    : t("BUTTON.SEE_ORIGINAL", { ns: "common" })
                }
                handleOnClick={() => {
                  setIsTranslated(!isTranslated);
                  handleTranslate(nft.description);
                }}
              />
            </div>
          )}
        </div>
        <div>
          <div className="aboutTabInfo">
            <p>{t("INFO.TITLE", { ns: "nftDetail" })}</p>
          </div>
          <div className="aboutTabInfoDetail">
            <p>{t("INFO.TOKEN_ID", { ns: "nftDetail" })}</p>
            <span
              onClick={() => copyToClipboard(nft.tokenId)}
              className="tokenId"
            >
              {nft.tokenId}
            </span>
          </div>
          <div className="aboutTabInfoDetail">
            <p>{t("INFO.CONTRACT", { ns: "nftDetail" })}</p>
            <span
              onClick={() => copyToClipboard(nft.contractAddress)}
              className="contractAddress"
              style={{ fontWeight: "bold" }}
            >
              {subStringAddress(nft.contractAddress)}
            </span>
          </div>
          <div className="aboutTabInfoDetail">
            <p>{t("INFO.BLOCKCHAIN", { ns: "nftDetail" })}</p>
            <span>{nft.networkBlockchain}</span>
          </div>
          <div className="aboutTabInfoDetail">
            <p>{t("INFO.TOKEN_STANDARD", { ns: "nftDetail" })}</p>
            <span>{nft.tokenStandard}</span>
          </div>
          <div className="aboutTabInfoDetail">
            <p>{t("INFO.CREATOR_ROYALTIES", { ns: "nftDetail" })}</p>
            <span>{nft.creatorRoyalty}%</span>
          </div>
        </div>

        <div
          className="aboutCategory"
          style={
            !nft.categoryId || nft.categoryId === ""
              ? { display: "none" }
              : null
          }
        >
          <p>{t("INFO.CATEGORY", { ns: "nftDetail" })}</p>
          <StanTypo
            fontSize={20}
            fontWeight={500}
            color="#17875A"
            content={nft.category?.name}
            onClick={() =>
              history.push(
                generatePath("/explore/category/:category", {
                  category: nft.category?.name,
                }),
                { categoryId: nft.category?.id }
              )
            }
            customStyle={{ cursor: "pointer" }}
          />
          {/* <div className="aboutCategoryList">
              {nft.properties?.map((item, index) => {
                return (
                  <div className="property" key={index}>
                    <span style={{ color: "grey" }}>{item.name}</span>
                    <span>{item.value}</span>
                  </div>
                );
              })}
            </div> */}
        </div>

        <div
          className="aboutHashTag"
          style={
            !nft.hashtag || nft.hashtag?.length === 0
              ? { display: "none" }
              : null
          }
        >
          <p>{t("INFO.HASHTAG", { ns: "nftDetail" })}</p>
          <div className="mt-3 d-flex flex-wrap">
            {nft?.hashtag?.map((item, index) => {
              return (
                <Tooltip title={item} key={index}>
                  <div
                    style={{ cursor: "pointer" }}
                    className="ellipsisText hashtag-item mr-3 mb-3"
                    onClick={() =>
                      history.push(
                        generatePath("/explore/hashtag/:hashtag", {
                          hashtag: item,
                        })
                      )
                    }
                  >
                    {item}
                  </div>
                </Tooltip>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
