import { SearchOutlined } from "@ant-design/icons";
import { Image, Input, Menu, Select } from "antd";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { openNotificationWithIcon } from "../../App";
import { NFT_STATUS } from "../../common/commonParams";
import {
  MaticIcon,
  StanPointIcon,
  buyStatusSvg,
  offerStatusSvg,
  onBidStatusSvg,
} from "../../common/commonSvg";
import { getListCategories } from "../../redux/action/NftAction";
import { ADD_FILTER } from "../../redux/types/FilterType";
import StanButton from "../StanButton/StanButton";
import "./StanFilter.css";

const { Option } = Select;
export default function StanFilter(props) {
  // ___________STATE____________
  const {
    showStatus,
    showPrice,
    showCollection,
    showCategory,
    showArtist,
    listCollection,
    listArtist,
    filterNFT,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "explore"]);
  const [dataFilter, setDataFilter] = useState({});
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [currency, setCurrency] = useState("");
  const [fromPrice, setFromPrice] = useState(null);
  const [toPrice, setToPrice] = useState(null);
  const [collectionName, setCollectionName] = useState("");
  const [collectionToFilter, setCollectionToFilter] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [artistToFilter, setArtistToFilter] = useState([]);
  const [selectedArtist, setSelectedArtist] = useState([]);

  // ___________REDUX STATES_______
  const { listCategories } = useSelector((state) => state.NFTReducer);
  const { listOfOwnersInCollection } = useSelector(
    (state) => state.UserReducer
  );
  const { listFilterItems, removedFilterItem } = useSelector(
    (state) => state.FilterReducer
  );

  // __________ useEffects _________
  useEffect(() => {
    dispatch(getListCategories());
  }, []);
  useEffect(() => {
    filterNFT(dataFilter);
    handleFilterItem();
  }, [dataFilter]);

  useEffect(() => {
    if (selectedStatus.length > 0) {
      setDataFilter({ ...dataFilter, status: selectedStatus[0] });
    }
  }, [selectedStatus]);

  useEffect(() => {
    if (removedFilterItem.type === "status") {
      setSelectedStatus([]);
      setDataFilter({ ...dataFilter, status: null });
    }
    if (removedFilterItem.type === "currency") {
      setCurrency("");
      setDataFilter({ ...dataFilter, currency: "" });
    }
    if (removedFilterItem.type === "priceRange") {
      setDataFilter({ ...dataFilter, fromPrice: "", toPrice: "" });
      setFromPrice("");
      setToPrice("");
    }
    if (removedFilterItem.type === "category") {
      let filteredCategory = _.filter(selectedCategory, function (o) {
        return o.label !== removedFilterItem.value;
      });
      setSelectedCategory(filteredCategory);
      let categories = [];
      filteredCategory.map((category, index) => {
        categories.push(category.value);
      });
      setDataFilter({
        ...dataFilter,
        categoryIds: categories.toString(),
      });
    }
    if (removedFilterItem.type === "collection") {
      let filteredCollection = selectedCollection.filter(
        (e) => e !== removedFilterItem.id
      );
      setSelectedCollection(filteredCollection);
    }
    if (removedFilterItem.type === "artist") {
      let filteredArtist = selectedArtist.filter(
        (e) => e !== removedFilterItem.walletAddress
      );
      setSelectedArtist(filteredArtist);
    }
  }, [removedFilterItem]);

  useEffect(() => {
    setDataFilter({
      ...dataFilter,
      collectionIds: selectedCollection.toString(),
    });
  }, [selectedCollection]);

  useEffect(() => {
    setDataFilter({
      ...dataFilter,
      walletAddress: selectedArtist.toString(),
    });
  }, [selectedArtist]);

  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  function handleFilterItem() {
    let selectedCollectionsItem = [];
    let selectedArtistItem = [];
    selectedCollection.map((collection, index) => {
      selectedCollectionsItem.push(
        _.find(collectionToFilter, function (o) {
          return o.id === collection;
        })
      );
    });
    selectedArtist.map((artist, index) => {
      selectedArtistItem.push(
        _.find(artistToFilter, function (o) {
          return o.walletAddress === artist;
        })
      );
    });
    dispatch({
      type: ADD_FILTER,
      dataFilter: dataFilter,
      categories: selectedCategory,
      collections: selectedCollectionsItem,
      artists: selectedArtistItem,
    });
  }

  const RenderStatusFilter = useMemo(() => {
    function renderActiveRadio(key) {
      const found = selectedStatus.filter((item) => item === key);
      if (found.length > 0) {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM15 10C15 12.7614 12.7614 15 10 15C7.23858 15 5 12.7614 5 10C5 7.23858 7.23858 5 10 5C12.7614 5 15 7.23858 15 10Z"
              fill="#17875A"
            />
          </svg>
        );
      }
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10ZM2 10C2 14.42 5.58 18 10 18C14.42 18 18 14.42 18 10C18 5.58 14.42 2 10 2C5.58 2 2 5.58 2 10Z"
            fill="#4D4D4D"
          />
        </svg>
      );
    }

    const statusFilter = [
      {
        label: (
          <span style={{ fontWeight: 600 }}>{t("MENU.TITLE.STATUS")}</span>
        ),
        key: "statusFilter",
        children: [
          {
            label: (
              <span>
                {buyStatusSvg()} {t("MENU.NFT_TYPE.BUY", { ns: "explore" })}
              </span>
            ),
            key: NFT_STATUS.BUY,
            icon: renderActiveRadio(NFT_STATUS.BUY),
          },
          {
            label: (
              <span>
                {offerStatusSvg()} {t("MENU.NFT_TYPE.OFFER", { ns: "explore" })}
              </span>
            ),
            key: NFT_STATUS.OFFER,
            icon: renderActiveRadio(NFT_STATUS.OFFER),
          },
          {
            label: (
              <span>
                {onBidStatusSvg()}{" "}
                {t("MENU.NFT_TYPE.ON_BID", { ns: "explore" })}
              </span>
            ),
            key: NFT_STATUS.ON_BID,
            icon: renderActiveRadio(NFT_STATUS.ON_BID),
          },
        ],
      },
    ];

    return (
      <div className="StanFilter-status">
        <Menu
          defaultOpenKeys={["statusFilter"]}
          style={{ width: 400 }}
          mode="inline"
          selectedKeys={selectedStatus}
          onSelect={(e) => setSelectedStatus(e.selectedKeys)}
          onDeselect={(e) => {
            setSelectedStatus([]);
            setDataFilter(_.omit(dataFilter, ["status"]));
          }}
          multiple={false}
          items={statusFilter}
        />
      </div>
    );
  }, [selectedStatus]);

  const RenderPriceFilter = useMemo(() => {
    function cancelPriceFilter() {
      setCurrency("");
      setFromPrice("");
      setToPrice("");
      setDataFilter(_.omit(dataFilter, ["currency", "fromPrice", "toPrice"]));
    }

    function applyPriceFilter() {
      if (+fromPrice > +toPrice) {
        openNotificationWithIcon(
          "error",
          t("MSG.TO_PRICE_GREATER_THAN_FROM_PRICE")
        );
      } else {
        setDataFilter({
          ...dataFilter,
          currency: currency,
          fromPrice: fromPrice,
          toPrice: toPrice,
        });
      }
    }

    function renderPriceFilter() {
      return (
        <>
          <Select
            width={"100px"}
            className="networkBlockchain"
            value={currency}
            onChange={(e) => setCurrency(e)}
          >
            <Option value="">{t("MENU.TITLE.ALL", { ns: "explore" })}</Option>
            <Option value="POLYGON">
              {/* <WETHIcon /> */}
              <MaticIcon />
              {/* {t("MENU.STAN_FUND", { ns: "explore" })} */}
            </Option>
            <Option value="POINT">
              <StanPointIcon />
              {/* {t("MENU.STAN_POINT", { ns: "explore" })} */}
            </Option>
          </Select>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Input
              type="number"
              className="fromPrice"
              width={30}
              placeholder={t("MENU.FROM", { ns: "explore" })}
              onChange={(e) => setFromPrice(e.target.value)}
              value={fromPrice}
            />
            <Input
              type="number"
              className="toPrice"
              width={30}
              placeholder={t("MENU.TO", { ns: "explore" })}
              onChange={(e) => setToPrice(e.target.value)}
              value={toPrice}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <StanButton
              type="secondary"
              title={t("BUTTON.CANCEL")}
              style={{ margin: "0px 5px" }}
              handleOnClick={cancelPriceFilter}
            />
            <StanButton
              type="primary"
              title={t("BUTTON.APPLY")}
              style={{ margin: "0px 5px" }}
              handleOnClick={applyPriceFilter}
            />
          </div>
        </>
      );
    }

    const priceFilter = [
      {
        label: (
          <span style={{ fontWeight: 600 }}>
            {t("MENU.TITLE.PRICE", { ns: "explore" })}
          </span>
        ),
        key: "priceSub",
        children: [
          {
            className: "customMenu",
            label: renderPriceFilter(),
            disabled: true,
            key: "priceInput",
          },
        ],
      },
    ];

    return (
      <div className="StanFilter-price">
        <Menu
          defaultOpenKeys={["priceSub"]}
          style={{ width: 400 }}
          mode="inline"
          items={priceFilter}
        />
      </div>
    );
  }, [currency, fromPrice, toPrice, dataFilter]);

  const RenderCollectionFilter = useMemo(() => {
    function renderActiveIcon(key) {
      const found = selectedCollection.filter((item) => item === key);
      if (found.length > 0) {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 6C0 2.68629 2.68629 0 6 0H18C21.3137 0 24 2.68629 24 6V18C24 21.3137 21.3137 24 18 24H6C2.68629 24 0 21.3137 0 18V6Z"
              fill="#49D19A"
            />
            <path
              d="M8.99997 16.17L4.82997 12L3.40997 13.41L8.99997 19L21 7L19.59 5.59L8.99997 16.17Z"
              fill="white"
            />
          </svg>
        );
      }
    }

    function renderCollectionFilter(item) {
      return (
        <>
          <div className="d-flex justify-content-start align-items-center">
            <div className="mr-2">
              <Image
                style={{
                  borderRadius: "16px",
                  boxSizing: "border-box",
                  border: "2px solid #FFFFFF",
                  objectFit: "cover",
                }}
                src={item.imageLogo || item.colMediaUrl}
                fallback="https://www.salonlfc.com/wp-content/uploads/2018/01/image-not-found-scaled-1150x647.png"
                preview={false}
                width={42}
                height={42}
              />
            </div>
            <div>{item.name}</div>
          </div>
        </>
      );
    }

    async function onChangeCollectionNameFilter(value) {
      if (value === "") {
        setCollectionToFilter(listCollection);
      } else {
        await delay(2000);
        let filteredCollections = _.filter(collectionToFilter, function (o) {
          return o.name.includes(value);
        });
        setCollectionToFilter(filteredCollections);
      }
    }

    const collectionFilter = [
      {
        label: (
          <>
            <span style={{ fontWeight: 600 }}>
              {t("TABLE.TITLE.COLLECTION", { ns: "explore" })}
            </span>
          </>
        ),
        key: "collectionFilter",
        children: [
          {
            type: "group",
            key: "collectionFilter-list",
            label: (
              <>
                <Input
                  onChange={(e) => {
                    onChangeCollectionNameFilter(e.target.value);
                  }}
                  style={{ borderRadius: "8px" }}
                  prefix={<SearchOutlined />}
                  placeholder={t("PLACEHOLDER.SEARCH")}
                />
              </>
            ),
            children:
              collectionToFilter && collectionToFilter.length !== 0
                ? collectionToFilter.map((item) => ({
                    label: renderCollectionFilter(item),
                    key: item.id,
                    value: item.name,
                    // icon: renderActiveIcon(item.id),
                  }))
                : null,
          },
        ],
      },
    ];

    return (
      <div className="StanFilter-collection">
        <Menu
          className="collectionFilterMenu"
          style={{ width: 400 }}
          selectedKeys={selectedCollection}
          onOpenChange={() => setCollectionToFilter(listCollection)}
          onSelect={(e) => {
            setSelectedCollection(e.selectedKeys);
          }}
          onDeselect={(e) => setSelectedCollection(e.selectedKeys)}
          multiple={true}
          mode="inline"
          items={collectionFilter}
        />
      </div>
    );
  }, [collectionToFilter, selectedCollection, collectionName]);

  const RenderCategoryFilter = useMemo(() => {
    const categoryFilter = [
      {
        label: (
          <span style={{ fontWeight: 600 }}>
            {t("INFO.CATEGORY", { ns: "nftDetail" })}
          </span>
        ),
        key: "categoryFilter",
        children: [
          {
            className: "customMenuCategory",
            label: (
              <>
                <Select
                  mode="multiple"
                  allowClear
                  labelInValue
                  value={selectedCategory}
                  filterOption={(input, option) =>
                    (option?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder={t("PLACEHOLDER.SEARCH_TO_SELECT")}
                  onChange={(value) => {
                    let categories = [];
                    value.map((category, index) => {
                      categories.push(category.value);
                    });
                    setDataFilter({
                      ...dataFilter,
                      categoryIds: categories.toString(),
                    });
                    setSelectedCategory(value);
                  }}
                >
                  {listCategories.map((category) => {
                    return (
                      <Option value={category.id} key={category.id}>
                        {category.name}
                      </Option>
                    );
                  })}
                </Select>
              </>
            ),
            disabled: true,
            key: "categoryInput",
          },
        ],
      },
    ];

    return (
      <div className="StanFilter-category">
        <Menu
          //   defaultOpenKeys={["categoryFilter"]}
          style={{ width: 400 }}
          mode="inline"
          items={categoryFilter}
        />
      </div>
    );
  }, [listCategories, selectedCategory]);

  const RenderArtistFilter = useMemo(() => {
    function renderActiveIcon(key) {
      const found = selectedArtist.filter((item) => item === key);
      if (found.length > 0) {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 6C0 2.68629 2.68629 0 6 0H18C21.3137 0 24 2.68629 24 6V18C24 21.3137 21.3137 24 18 24H6C2.68629 24 0 21.3137 0 18V6Z"
              fill="#49D19A"
            />
            <path
              d="M8.99997 16.17L4.82997 12L3.40997 13.41L8.99997 19L21 7L19.59 5.59L8.99997 16.17Z"
              fill="white"
            />
          </svg>
        );
      }
    }

    function onChangeArtistFilter(value) {
      let defaultArr = listArtist;
      if (value === "" || !value) {
        setArtistToFilter(defaultArr);
      } else {
        let filteredArtist = _.filter(artistToFilter, function (o) {
          return o.name.includes(value.toString());
        });
        setArtistToFilter(filteredArtist);
      }
    }

    const artistFilter = [
      {
        label: (
          <>
            <span style={{ fontWeight: 600 }}>
              {t("TABLE.TITLE.ARTISTS", { ns: "explore" })}
            </span>
          </>
        ),
        key: "artistSub",
        children: [
          {
            type: "group",
            label: (
              <>
                <Input
                  onChange={(e) => {
                    onChangeArtistFilter(e.target.value);
                  }}
                  style={{ borderRadius: "8px" }}
                  prefix={<SearchOutlined />}
                  placeholder="Search"
                />
              </>
            ),
            children: artistToFilter.map((item) => ({
              label: (
                <>
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="mr-2">
                      <Image
                        style={{
                          borderRadius: "16px",
                          boxSizing: "border-box",
                          border: "2px solid #FFFFFF",
                          objectFit: "cover",
                        }}
                        src={item?.user?.avatar || item.avatar}
                        preview={false}
                        width={42}
                        height={42}
                      />
                    </div>
                    <div>{item?.user?.name || item.name}</div>
                  </div>
                </>
              ),
              key: item?.user?.walletAddress || item.walletAddress,
              // icon: renderActiveIcon(item.id),
            })),
          },
        ],
      },
    ];

    return (
      <div className="StanFilter-artist">
        <Menu
          className="collectionFilterMenu"
          style={{ width: 400 }}
          //   defaultOpenKeys={["artistSub"]}
          selectedKeys={selectedArtist}
          onOpenChange={() => {
            setArtistToFilter(listArtist);
          }}
          onSelect={(e) => {
            setSelectedArtist(e.selectedKeys);
          }}
          onDeselect={(e) => setSelectedArtist(e.selectedKeys)}
          multiple={true}
          mode="inline"
          items={artistFilter}
        />
      </div>
    );
  }, [artistToFilter, selectedArtist, listArtist, listOfOwnersInCollection]);

  return (
    <div className="StanFilter">
      {showStatus ? RenderStatusFilter : null}
      {showPrice ? RenderPriceFilter : null}
      {showCollection ? RenderCollectionFilter : null}
      {showCategory ? RenderCategoryFilter : null}
      {showArtist ? RenderArtistFilter : null}
    </div>
  );
}
