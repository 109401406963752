import { Empty } from "antd";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { history } from "../../../App";
import {
  SampleNextArrow,
  SamplePrevArrow,
  ShoppingCartIcon,
} from "../../../common/commonSvg";
import StanNFTCard from "../../../component/StanNFTCard/StanNFTCard";
import StanTypo from "../../../component/StanTypo/StanTypo";
import { getLatestSale } from "../../../redux/action/NftAction";
import "./LatestSale.css";
import { EXPLORE_PATH } from "../../../common/Constant";

export default function LatestSale() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["common", "homePage"]);
  const { latestSale } = useSelector((state) => state.NFTReducer);

  const settingsLatestSale = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow:
      latestSale.length === 3 || latestSale.length === 4
        ? latestSale.length
        : 5,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: latestSale.length < 4 ? latestSale.length : 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  useEffect(() => {
    dispatch(getLatestSale({}, 10, 1));
  }, []);

  const RenderList = useMemo(() => {
    if (latestSale.length === 0) {
      return <Empty description={t("NONE_COLLECTION", { ns: "homePage" })} />;
    }
    if (latestSale.length <= 2) {
      return (
        <>
          <div className="LatestSale-list">
            {latestSale.map((nft) => {
              return (
                <StanNFTCard
                  type="LATEST_SALE"
                  // viewOnly
                  nft={nft}
                  key={nft.id}
                />
              );
            })}
          </div>
        </>
      );
    } else {
      return (
        <>
          <Slider {...settingsLatestSale} className="LatestSaleSlider">
            {latestSale.map((nft) => {
              return (
                <StanNFTCard
                  type="LATEST_SALE"
                  // viewOnly
                  nft={nft}
                  key={nft.id}
                />
              );
            })}
          </Slider>
        </>
      );
    }
  }, [latestSale, t]);

  return (
    <>
      <div className="LatestSale">
        <div className="LatestSale-header">
          <div className="d-flex align-items-center">
            <div className="LatestSale-icon mr-2">
              <ShoppingCartIcon />
            </div>
            <h2>{t("LATEST_SALE", { ns: "homePage" })}</h2>
          </div>
          <div>
            <StanTypo
              fontWeight={400}
              fontSize={16}
              color="#49D19A"
              customStyle={{ cursor: "pointer" }}
              onClick={() => history.push(EXPLORE_PATH.LATEST_SALE)}
              content={t("BUTTON.SEE_ALL", { ns: "common" }).toUpperCase()}
            />
          </div>
        </div>
        {RenderList}
      </div>
    </>
  );
}
