import { GET_CREATED_NFT } from "../types/NFTType";
import {
  CLEAR_STATE,
  GET_ALL_ARTISTS,
  GET_BANNER_MEDIA_PATH,
  GET_HOT_COLLECTION,
  GET_HOT_OFFER,
  GET_LIST_ARTIST,
  GET_LIST_ARTIST_EXPLORE,
  GET_LIST_MEDIA,
  GET_LIST_OF_OWNERS,
  GET_LIST_OF_OWNERS_IN_COLLECTION,
  GET_LIVE_AUCTION,
  GET_LOGO_MEDIA_PATH,
  GET_MADE_OFFERS,
  GET_NFT_PROFILE,
  GET_OWNED_NFT,
  GET_PROFILE_ACTIVITIES,
  GET_PROFILE_COLLECTIONS,
  GET_PROFILE_LISTINGS,
  GET_QR_CODE,
  GET_RECEIVED_OFFERS,
  GET_RPC_URL,
  GET_STAN_FEE,
  GET_THUMBNAIL_PATH,
  GET_USD_RATE,
  REMOVE_BANNER,
  REMOVE_EXIST_MEDIA,
  REMOVE_LOGO,
  REMOVE_THUMBNAIL,
  SEARCHING_FOR_ARTIST,
  SELECT_EXIST_MEDIA,
  SOCKET_STATUS,
  TRANSLATE_DESCRIPTION,
} from "../types/UserType";

const initialState = {
  qrCode: "",
  logoPath: "",
  bannerPath: "",
  thumbnailPath: "",
  logoUrl: "",
  thumbnailUrl: "",
  listMedia: [],
  existMedia: {},
  listHotCollections: [],
  listHotOffers: [],
  listLiveAuction: [],
  listProfileActivities: [],
  listCreatedNFT: [],
  listOwnedNFT: [],
  listProfileListings: [],
  listMadeOffers: [],
  listReceivedOffers: [],
  listCollectionToFilter: [],
  listProfileCollections: [],
  listArtist: [],
  listArtistExplore: [],
  listOfOwners: [],
  listOfOwnersInCollection: [],
  totalProfileCollections: 0,
  totalHotOffers: 0,
  totalHotCollections: 0,
  totalLiveAuction: 0,
  totalProfileActivities: 0,
  totalCreatedNFT: 0,
  totalOwnedNFT: 0,
  totalListArtistExplore: 0,
  totalMedia: "",
  nftProfileUser: {},
  socketStatus: "",
  translatedDescription: "",
  rateCrypto: 0,
  ratePoint: 0,
  minCryptoPrice: 0,
  rpcUrl: "",
  creatorRoyalty: 0,
  stanRoyalty: 0,
  // TEMPORARY
  listArtistHeader: [],
  allArtists: [],
};

export const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QR_CODE: {
      return { ...state, qrCode: action.qrCode };
    }
    case GET_LOGO_MEDIA_PATH: {
      return {
        ...state,
        logoPath: action.logoPath,
        logoUrl: action.logoUrl,
      };
    }
    case REMOVE_LOGO: {
      return {
        ...state,
        logoPath: "",
        logoUrl: "",
      };
    }
    case GET_BANNER_MEDIA_PATH: {
      return { ...state, bannerPath: action.bannerPath };
    }
    case REMOVE_BANNER: {
      return { ...state, bannerPath: "" };
    }
    case GET_LIST_MEDIA: {
      return {
        ...state,
        listMedia: action.listMedia,
        totalMedia: action.totalMedia,
      };
    }
    case SELECT_EXIST_MEDIA: {
      return { ...state, existMedia: action.existMedia };
    }
    case REMOVE_EXIST_MEDIA: {
      return { ...state, existMedia: {} };
    }
    case GET_THUMBNAIL_PATH: {
      return {
        ...state,
        thumbnailPath: action.thumbnailPath,
        thumbnailUrl: action.thumbnailUrl,
      };
    }
    case REMOVE_THUMBNAIL: {
      return { ...state, thumbnailPath: "" };
    }
    case GET_HOT_COLLECTION: {
      return {
        ...state,
        listHotCollections: action.listHotCollections,
        totalHotCollections: action.totalHotCollections,
      };
    }
    case GET_HOT_OFFER: {
      return {
        ...state,
        listHotOffers: action.listHotOffers,
        totalHotOffers: action.totalHotOffers,
      };
    }
    case GET_LIVE_AUCTION: {
      return {
        ...state,
        listLiveAuction: action.listLiveAuction,
        totalLiveAuction: action.totalLiveAuction,
      };
    }
    case GET_PROFILE_ACTIVITIES: {
      return {
        ...state,
        listProfileActivities: action.listProfileActivities,
        totalProfileActivities: action.totalProfileActivities,
      };
    }
    case GET_CREATED_NFT:
      return {
        ...state,
        listCollectionToFilter: action.listCollectionToFilter,
        listCreatedNFT: action.listCreatedNFT,
        totalCreatedNFT: action.totalCreatedNFT,
      };
    case GET_OWNED_NFT: {
      return {
        ...state,
        listCollectionToFilter: action.listCollectionToFilter,
        listOwnedNFT: action.listOwnedNFT,
        totalOwnedNFT: action.totalOwnedNFT,
      };
    }
    case GET_PROFILE_LISTINGS: {
      return {
        ...state,
        listProfileListings: action.listProfileListings,
      };
    }
    case GET_MADE_OFFERS: {
      return {
        ...state,
        listMadeOffers: action.listMadeOffers,
      };
    }
    case GET_RECEIVED_OFFERS: {
      return {
        ...state,
        listReceivedOffers: action.listReceivedOffers,
      };
    }
    case GET_PROFILE_COLLECTIONS: {
      return {
        ...state,
        listProfileCollections: action.listProfileCollections,
        totalProfileCollections: action.totalProfileCollections,
      };
    }
    case GET_LIST_ARTIST: {
      return {
        ...state,
        listArtist: action.listArtist,
      };
    }
    case GET_LIST_ARTIST_EXPLORE: {
      return {
        ...state,
        listArtistExplore: action.listArtistExplore,
        totalListArtistExplore: action.totalListArtistExplore,
      };
    }
    case GET_LIST_OF_OWNERS: {
      return {
        ...state,
        listOfOwners: action.listOfOwners,
      };
    }
    case GET_LIST_OF_OWNERS_IN_COLLECTION: {
      return {
        ...state,
        listOfOwnersInCollection: action.listOfOwnersInCollection,
      };
    }
    case GET_ALL_ARTISTS: {
      return {
        ...state,
        allArtists: action.allArtists,
      };
    }
    // TEMPORARY (INPUT SEARCH 13/10/2022)
    case SEARCHING_FOR_ARTIST: {
      return {
        ...state,
        listArtistHeader: action.listArtist,
      };
    }
    case GET_NFT_PROFILE: {
      return {
        ...state,
        nftProfileUser: action.nftProfileUser,
      };
    }
    case TRANSLATE_DESCRIPTION: {
      return {
        ...state,
        translatedDescription: action.translatedDescription,
      };
    }
    case GET_USD_RATE: {
      return {
        ...state,
        rateCrypto: action.rateCrypto,
        ratePoint: action.ratePoint,
        minCryptoPrice: action.minCryptoPrice,
      };
    }
    case GET_RPC_URL: {
      return {
        ...state,
        rpcUrl: action.rpcUrl,
      };
    }
    case CLEAR_STATE: {
      return (state = initialState);
    }
    case SOCKET_STATUS: {
      return { ...state, socketStatus: action.socketStatus };
    }
    case GET_STAN_FEE: {
      return {
        ...state,
        creatorRoyalty: action.creatorRoyalty,
        stanRoyalty: action.stanRoyalty,
      };
    }
    default:
      return state;
  }
};
