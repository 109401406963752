import { Drawer, Image, Input, Select, Spin, Table } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import { history } from "../../App";
import { MaticIcon, StanPointIcon, rankSvg } from "../../common/commonSvg";
import StanTypo from "../../component/StanTypo/StanTypo";
import { getHotCollections } from "../../redux/action/UserAction";
import { CLEAR_STATE, REQUESTING } from "../../redux/types/UserType";
import {
  formatStanCrypto,
  formatStanPoint,
  subStringText,
} from "../../util/settings/utils";
import "./HotCollections.css";

const { Option } = Select;
export default function HotCollections(props) {
  const { t } = useTranslation(["common", "homePage", "nft", "explore"]);
  const dispatch = useDispatch();
  const [inputSearch, setInputSearch] = useState();
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [selectedPageSize, setSelectedPageSize] = useState(10);
  const [selectedPage, setSelectedPage] = useState(1);
  const { nftStatus } = useSelector((state) => state.AutionReducer);
  const { listHotCollections, totalHotCollections } = useSelector(
    (state) => state.UserReducer
  );
  const { hotCollectionSort } = useSelector((state) => state.CollectionReducer);
  const [orderBy, setOrderBy] = useState(
    hotCollectionSort === "" ? "ONE_DAY" : hotCollectionSort
  );
  const [dataFilter, setDataFilter] = useState({ time: orderBy });

  useEffect(() => {
    dispatch(
      getHotCollections({ time: orderBy }, selectedPageSize, selectedPage)
    );
  }, []);

  // useEffect(() => {
  //   setSelectedPage(1);
  //   setSelectedPageSize(10);
  // }, [dataFilter]);

  useEffect(() => {
    setDataFilter({ ...dataFilter, time: orderBy });
  }, [orderBy]);

  useEffect(() => {
    dispatch(getHotCollections(dataFilter, selectedPageSize, selectedPage));
  }, [dataFilter, selectedPage, selectedPageSize]);

  const HotCollectionItem = (props) => {
    const { collection, index } = props;
    return (
      <div
        className="hotCollectionItems"
        onClick={() => {
          dispatch({ type: CLEAR_STATE });
          history.push(
            generatePath("/collectionDetail/:id", { id: collection.id })
          );
        }}
      >
        <div className="hotCollectionItems_1">
          <div className="mr-2">{rankSvg(index)}</div>
          <div className="hotCollectionInfo">
            <div className="mr-2">
              <Image
                src={collection.imageLogo}
                width={44}
                height={44}
                onClick={() => {
                  dispatch({ type: CLEAR_STATE });
                  redirectToCollection(collection);
                }}
                style={{ objectFit: "cover", borderRadius: "12px" }}
              />
            </div>
            <div>
              <StanTypo
                color="#000000"
                fontSize={14}
                fontWeight={500}
                content={subStringText(collection.name, 40)}
              />
            </div>
          </div>
          <div className="volume">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: "100%" }}
            >
              <div className="ellipsisText">
                <StanTypo
                  color="#0D0D0D"
                  fontSize={16}
                  fontWeight={500}
                  content={
                    collection.totalVolumeDetail.totalVolumePoint === 0 ||
                    _.isNull(collection.totalVolume) ? null : (
                      <>
                        <MaticIcon />
                        {formatStanCrypto(
                          collection.totalVolumeDetail.totalVolumeCrypto
                        )}
                      </>
                    )
                  }
                />
              </div>
            </div>
            <div className="text-center" style={{ width: "100%" }}>
              <div className="ellipsisText">
                <StanTypo
                  fontSize={14}
                  fontWeight={400}
                  color="#4D4D4D"
                  content={
                    <>
                      <StanPointIcon />
                      {formatStanPoint(
                        collection.totalVolumeDetail.totalVolumePoint
                      )}
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="hotCollectionItems_2">
          <div className="floorPrice" style={{ width: "40%" }}>
            <div className="ellipsisText text-center" style={{ width: "100%" }}>
              <StanTypo
                color="#17875A"
                fontSize={14}
                fontWeight={700}
                content={
                  collection.floorPrice === 0 ||
                  _.isNull(collection.floorPrice) ? (
                    "---"
                  ) : (
                    <>
                      <MaticIcon />{" "}
                      {formatStanCrypto(
                        collection.floorPriceDetail.floorPriceCrypto
                      )}
                    </>
                  )
                }
              />
            </div>
            <div className="ellipsisText text-center" style={{ width: "100%" }}>
              <StanTypo
                color="#17875A"
                fontSize={14}
                fontWeight={700}
                content={
                  collection.floorPrice === 0 ||
                  _.isNull(collection.floorPrice) ? (
                    "---"
                  ) : (
                    <>
                      <StanPointIcon />{" "}
                      {formatStanPoint(
                        collection.floorPriceDetail.floorPricePoint
                      )}
                    </>
                  )
                }
              />
            </div>
            <StanTypo
              color="#4D4D4D"
              fontSize={14}
              fontWeight={500}
              content={t("STATS.FLOOR")}
            />
          </div>
          <div className="owners">
            <StanTypo
              color="#17875A"
              onClick={() => redirectToOwners(collection)}
              fontSize={14}
              fontWeight={700}
              content={collection.totalOwners}
            />
            <StanTypo
              color="#4D4D4D"
              fontSize={14}
              fontWeight={500}
              content={t("STATS.OWNERS")}
            />
          </div>
          <div className="items">
            <StanTypo
              color="#17875A"
              onClick={() => redirectToCollection(collection)}
              fontSize={14}
              fontWeight={700}
              content={collection.items}
            />
            <StanTypo
              color="#4D4D4D"
              fontSize={14}
              fontWeight={500}
              content="Items"
            />
          </div>
        </div>
      </div>
    );
  };

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      setDataFilter({
        ...dataFilter,
        keyword: encodeURIComponent(inputSearch),
      });
    }
  }

  function redirectToOwners(data) {
    history.push(
      generatePath("/explore/listOfOwnersInCollection/:collectionId", {
        collectionId: data.collectionId,
      })
    );
  }

  function redirectToCollection(data) {
    history.push(
      generatePath("/collectionDetail/:collectionId", {
        collectionId: data.collectionId,
      })
    );
  }

  const columns = [
    {
      title: t("TABLE.TITLE.RANK", { ns: "explore" }),
      dataIndex: "collectionId",
      key: "collectionId",
      render: (item, record, index) => <>{rankSvg(index)}</>,
    },
    {
      title: t("TABLE.TITLE.COLLECTION", { ns: "explore" }),
      dataIndex: "name",
      key: "name",
      render: (item, record, index) => (
        <>
          <div className="d-flex collectionInfo">
            <div>
              <Image
                width={48}
                height={48}
                preview={false}
                src={record.imageLogo}
                onClick={() => {
                  dispatch({ type: CLEAR_STATE });
                  redirectToCollection(record);
                }}
                style={{
                  objectFit: "cover",
                  borderRadius: "20%",
                  cursor: "pointer",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                marginLeft: "10px",
                alignContent: "center",
              }}
            >
              <span style={{ color: "#0D0D0D", fontWeight: 600 }}>
                {record.name}
              </span>
            </div>
          </div>
        </>
      ),
    },
    {
      title: t("TABLE.TITLE.VOLUME", { ns: "explore" }),
      dataIndex: "volume",
      key: "volume",
      render: (item, record, index) => (
        <>
          <div className="volume d-flex flex-column justify-content-center">
            <StanTypo
              fontSize={20}
              fontWeight={700}
              color="#939393"
              content={
                record.totalVolumeDetail.totalVolumeCrypto === 0 ? (
                  "---"
                ) : (
                  <>
                    <MaticIcon />{" "}
                    {formatStanCrypto(
                      record.totalVolumeDetail.totalVolumeCrypto
                    )}
                  </>
                )
              }
            />
            <StanTypo
              fontSize={12}
              fontWeight={400}
              color="#CBCBCB"
              content={
                record.totalVolumeDetail.totalVolumePoint === 0 ? (
                  "---"
                ) : (
                  <>
                    <StanPointIcon />
                    {formatStanPoint(record.totalVolumeDetail.totalVolumePoint)}
                  </>
                )
              }
            />
          </div>
        </>
      ),
    },
    {
      title: t("TABLE.TITLE.FLOOR_PRICE", { ns: "explore" }),
      dataIndex: "floorPrice",
      key: "floorPrice",
      render: (item, record, index) => (
        <div className="floorPrice d-flex flex-column justify-content-center">
          <StanTypo
            fontSize={20}
            fontWeight={700}
            color="#939393"
            content={
              record.floorPriceDetail.floorPriceCrypto === 0 ? (
                "---"
              ) : (
                <>
                  <MaticIcon />{" "}
                  {formatStanCrypto(record.floorPriceDetail.floorPriceCrypto)}
                </>
              )
            }
          />
          <StanTypo
            fontSize={12}
            fontWeight={400}
            color="#CBCBCB"
            content={
              record.floorPriceDetail.floorPricePoint === 0 ? (
                "---"
              ) : (
                <>
                  <StanPointIcon />
                  {formatStanPoint(record.floorPriceDetail.floorPricePoint)}
                </>
              )
            }
          />
        </div>
      ),
    },
    {
      title: t("TABLE.TITLE.OWNER", { ns: "explore" }),
      key: "owner",
      dataIndex: "owner",
      render: (item, record, index) => (
        <div className="owner">
          <span
            onClick={() => redirectToOwners(record)}
            className="numericInfo"
            style={{ color: "#17875A", fontWeight: 700, cursor: "pointer" }}
          >
            {formatStanPoint(record.totalOwners)}
          </span>
        </div>
      ),
    },
    {
      title: t("TABLE.TITLE.ITEMS", { ns: "explore" }),
      key: "items",
      dataIndex: "items",
      render: (item, record, index) => (
        <div className="items">
          <span
            className="numericInfo"
            onClick={() => redirectToCollection(record)}
            style={{ color: "#17875A", fontWeight: 700 }}
          >
            {formatStanPoint(record.items)}
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="hotCollections" style={{ minHeight: "100vh" }}>
        <div className="text-center mt-5 mb-5">
          <h1>{t("HOT_COLLECTION", { ns: "homePage" })} 🔥</h1>
        </div>

        <div className="searchSection">
          <div className="hotCollection-searchBox" style={{ marginRight: 10 }}>
            <Input
              className="inputSearchNft"
              placeholder={t("PLACEHOLDER.SEARCH", { ns: "explore" })}
              onChange={(e) => setInputSearch(e.target.value)}
              onKeyPress={handleKeyPress}
              prefix={
                <svg
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.4167 9.87907H9.75833L9.525 9.65407C10.3417 8.70407 10.8333 7.47074 10.8333 6.12907C10.8333 3.1374 8.40833 0.712402 5.41667 0.712402C2.425 0.712402 0 3.1374 0 6.12907C0 9.12074 2.425 11.5457 5.41667 11.5457C6.75833 11.5457 7.99167 11.0541 8.94167 10.2374L9.16667 10.4707V11.1291L13.3333 15.2874L14.575 14.0457L10.4167 9.87907ZM5.41667 9.87907C3.34167 9.87907 1.66667 8.20407 1.66667 6.12907C1.66667 4.05407 3.34167 2.37907 5.41667 2.37907C7.49167 2.37907 9.16667 4.05407 9.16667 6.12907C9.16667 8.20407 7.49167 9.87907 5.41667 9.87907Z"
                    fill="#CBCBCB"
                  />
                </svg>
              }
            />
          </div>
          <div className="hotCollection-selectBox">
            <Select
              value={orderBy}
              className="inputSelectNft"
              onChange={(e) => setOrderBy(e)}
            >
              <Option value="ONE_DAY">
                {t("LAST_24H", { ns: "homePage" })}
              </Option>
              <Option value="SEVEN_DAYS">
                {t("LAST_7_DAYS", { ns: "homePage" })}
              </Option>
              <Option value="THIRTY_DAYS">
                {t("LAST_30_DAYS", { ns: "homePage" })}
              </Option>
            </Select>
          </div>
        </div>

        <Spin spinning={nftStatus === REQUESTING}>
          <div className="hotCollectionsItemsContainer">
            <Table
              className="hotCollectionsItemsTable"
              columns={columns}
              dataSource={listHotCollections}
              pagination={false}
              // onRow={(record, rowIndex) => {
              //   return {
              //     onClick: (event) => {
              //       history.push(
              //         generatePath("/collectionDetail/:collectionId", {
              //           collectionId: record.collectionId,
              //         })
              //       );
              //     },
              //   };
              // }}
            />

            {/* RESPONSIVE */}
            <div style={{ padding: "10px" }}>
              {listHotCollections.map((collection, index) => {
                return (
                  <HotCollectionItem collection={collection} index={index} />
                );
              })}
            </div>
            {/* RESPONSIVE */}

            {/* <Pagination
              responsive={true}
              showSizeChanger={true}
              current={selectedPage}
              pageSize={selectedPageSize}
              onChange={(selectedPage, pageSize) => {
                setSelectedPageSize(pageSize);
                setSelectedPage(selectedPage);
              }}
              total={totalHotCollections}
            /> */}
          </div>
        </Spin>
        <Drawer
          title={t("TITLE.FILTER_HOT_COLLECTION")}
          placement={"bottom"}
          closable={true}
          onClose={() => setVisibleFilter(false)}
          visible={visibleFilter}
        >
          <div>
            <span>{t("TITLE.NAME", { ns: "nft" })}: </span>
            <Input
              className="inputSearchNft"
              placeholder={t("PLACEHOLDER.SEARCH", { ns: "explore" })}
              onChange={(e) => setInputSearch(e.target.value)}
              onKeyPress={handleKeyPress}
              prefix={
                <svg
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.4167 9.87907H9.75833L9.525 9.65407C10.3417 8.70407 10.8333 7.47074 10.8333 6.12907C10.8333 3.1374 8.40833 0.712402 5.41667 0.712402C2.425 0.712402 0 3.1374 0 6.12907C0 9.12074 2.425 11.5457 5.41667 11.5457C6.75833 11.5457 7.99167 11.0541 8.94167 10.2374L9.16667 10.4707V11.1291L13.3333 15.2874L14.575 14.0457L10.4167 9.87907ZM5.41667 9.87907C3.34167 9.87907 1.66667 8.20407 1.66667 6.12907C1.66667 4.05407 3.34167 2.37907 5.41667 2.37907C7.49167 2.37907 9.16667 4.05407 9.16667 6.12907C9.16667 8.20407 7.49167 9.87907 5.41667 9.87907Z"
                    fill="#CBCBCB"
                  />
                </svg>
              }
            />
          </div>
          <div>
            <span>{t("TITLE.TYPE", { ns: "nft" })}: </span>
            <Select value={orderBy} onChange={(e) => setOrderBy(e)}>
              <Option value="ONE_DAY">
                {t("LAST_24H", { ns: "homePage" })}
              </Option>
              <Option value="SEVEN_DAYS">
                7 {t("TEXT.DAYS", { ns: "nft" })}
              </Option>
              <Option value="THIRTY_DAYS">
                30 {t("TEXT.DAYS", { ns: "nft" })}
              </Option>
            </Select>
          </div>
        </Drawer>
      </div>
    </>
  );
}
