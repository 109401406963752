import { ArrowDownOutlined } from "@ant-design/icons";
import branch from "branch-sdk";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { userService } from "../../services/UserService";
import { DEEP_LINK_KEY } from "../../util/settings/config";
import "./QRAuthen.css";

export default function QRAuthentication() {
  const { t } = useTranslation(["common", "qr"]);
  const [qrCode, setQrCode] = useState("");

  async function getQrCode() {
    const result = await userService.verifyMessage({
      walletAddress: Cookies.get("UserAddress"),
      signedMessage: Cookies.get("SignedMessage"),
    });
    setQrCode(result.data.data.qrToken);
  }

  useEffect(() => {
    branch.init(DEEP_LINK_KEY, function (err, data) {
      console.log(err, data);
    });
    getQrCode();
  }, []);

  function createDeepLink() {
    var linkData = {
      data: {
        data: JSON.stringify({
          content: qrCode,
          type: "nft_qr_token",
        }),
        $og_title: "STAN",
        $og_description: "STAN Marketplace",
        $og_image_url:
          "https://stan.win/wp-content/uploads/2021/08/stan-App-Icon500px-150x150.jpg",
      },
    };
    branch.link(linkData, function (err, link) {
      console.log(link);
      window.location.href = link;
    });
  }

  return (
    <div
      style={{
        backgroundColor: "#181818",
        overflow: "hidden",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          paddingTop: "140px",
          paddingBottom: "100px",
        }}
        className="container"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexFlow: "column",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <h3 className="h3_login">{t("TITLE.LOGIN", { ns: "qr" })}</h3>
            <p className="p_login">
              {t("REDIRECT_STEPS.STEP_1", { ns: "qr" })} <ArrowDownOutlined />
              <br />
              {t("REDIRECT_STEPS.STEP_2", { ns: "qr" })}
              <br />
            </p>
          </div>
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              marginTop: "20px",
            }}
          >
            <QRCode value={qrCode} onClick={() => createDeepLink()} />
          </div>
          <h5
            style={{ color: "#F6B24F", cursor: "pointer", marginTop: "10px" }}
            onClick={() => getQrCode()}
          >
            {t("TITLE.RELOAD")}
          </h5>
        </div>
      </div>
    </div>
  );
}
