// import _ from "lodash";
import Cookies from "js-cookie";
import _ from "lodash";
import { generatePath } from "react-router-dom";
import { io } from "socket.io-client";
import { history, openNotificationWithIcon } from "../../App";
import { EXPLORE_PATH } from "../../common/Constant";
import { nftService } from "../../services/NftService";
import { userService } from "../../services/UserService";
import { DOMAIN_WS, SOCKET_PATH } from "../../util/settings/config";
import { GET_CREATED_NFT } from "../types/NFTType";
import {
  GET_ALL_ARTISTS,
  GET_BANNER_MEDIA_PATH,
  GET_HOT_COLLECTION,
  GET_HOT_OFFER,
  GET_LIST_ARTIST,
  GET_LIST_ARTIST_EXPLORE,
  GET_LIST_MEDIA,
  GET_LIST_OF_OWNERS,
  GET_LIST_OF_OWNERS_IN_COLLECTION,
  GET_LIVE_AUCTION,
  GET_LOGO_MEDIA_PATH,
  GET_MADE_OFFERS,
  GET_NFT_PROFILE,
  GET_OWNED_NFT,
  GET_PROFILE_ACTIVITIES,
  GET_PROFILE_COLLECTIONS,
  GET_PROFILE_LISTINGS,
  GET_RECEIVED_OFFERS,
  GET_STAN_FEE,
  GET_THUMBNAIL_PATH,
  GET_USD_RATE,
  PENDING_REQUEST,
  REQUESTING,
  SEARCHING_FOR_ARTIST,
  SOCKET_STATUS,
  TRANSLATE_DESCRIPTION,
} from "../types/UserType";

export const getMetamaskMessage = (walletAddress) => {
  return async (dispatch) => {
    try {
      const result = await userService.getMetamaskMessage(walletAddress);
      Cookies.set("MetamaskMessage", result.data.data);
      if (result && result.data.data) {
        // Sign user's wallet address with message from BE
        window.ethereum
          .request({
            method: "personal_sign",
            params: [result.data.data, walletAddress],
          })
          .then((response) => {
            userService
              .verifyMessage({
                walletAddress: walletAddress,
                signedMessage: response,
              })
              .then((resultQR) => {
                Cookies.set("UserAddress", walletAddress);
                Cookies.set("QRToken", resultQR.data.data.qrToken);
                // WALLET LINKED TO STAN APP
                if (
                  resultQR.data.data.accessToken &&
                  resultQR.data.data.accessToken !== ""
                ) {
                  Cookies.set("TokenId", resultQR.data.data.accessToken);
                  if (history.location.pathname === "/deepLinked") {
                    history.push(
                      generatePath(EXPLORE_PATH.PROFILE, {
                        walletAddress: walletAddress,
                      })
                    );
                  }
                  getUserProfile(
                    resultQR.data.data.userId,
                    resultQR.data.data.accessToken
                  );
                } else {
                  // WALLET NOT LINKED TO STAN APP
                  const socket = io(DOMAIN_WS, {
                    auth: {
                      token: resultQR.data.data.qrToken,
                    },
                    path: SOCKET_PATH,
                    transports: ["websocket"],
                  });
                  socket.on("connect", () => {
                    console.log("CONNECTED SOCKET");
                    dispatch({
                      type: SOCKET_STATUS,
                      socketStatus: "CONNECTED",
                    });
                  });
                  socket.on("room.events", function (data) {
                    if (data.payload && data.payload.data.token) {
                      Cookies.set("TokenId", data.payload.data.token);
                      Cookies.set("UserAddress", walletAddress);
                      Cookies.remove("SignedMessage");
                      history.push(
                        generatePath(EXPLORE_PATH.PROFILE, {
                          walletAddress: walletAddress,
                        })
                      );
                      getUserProfile(
                        resultQR.data.data.userId,
                        data.payload.data.token
                      );
                      // window.location.reload();
                    }
                  });
                  socket.on("disconnect", () => {
                    console.log("DISCONNECTED");
                    dispatch({
                      type: SOCKET_STATUS,
                      socketStatus: "DISCONNECTED",
                    });
                    socket.disconnect();
                  });
                  Cookies.set("SignedMessage", response);
                  history.push("/home");
                }
              });
          });
      } else if (!result) {
        openNotificationWithIcon(
          "error",
          "Warning",
          "Something is wrong! Please try again!"
        );
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
};

//___________UPLOAD MEDIA (IMAGES)___________//
export const getPresignMedia = (mediaType, imageType, file) => {
  return async (dispatch) => {
    try {
      const result = await userService.getPresignMedia(mediaType, file);
      if (result && result.data.data) {
        if (imageType === "logo") {
          dispatch({
            type: GET_LOGO_MEDIA_PATH,
            logoPath: result.data.data.path,
            logoUrl: result.data.data.public_url,
          });
        }
        if (imageType === "banner") {
          dispatch({
            type: GET_BANNER_MEDIA_PATH,
            bannerPath: result.data.data.path,
          });
        }
        if (imageType === "thumbnail") {
          dispatch({
            type: GET_THUMBNAIL_PATH,
            thumbnailPath: result.data.data.path,
            thumbnailUrl: result.data.data.public_url,
          });
        }
        const uploadURL = result.data.data.url;
        const resultUpload = await userService.uploadMedia(uploadURL, file);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

//___________UPLOAD MEDIA (VIDEOS)___________//
export const getVimeoUpload = (file) => {
  return async (dispatch) => {
    try {
      const result = await userService.getVimeoUpload(file.size);
      if (result && result.data.data) {
        const uploadURL = result.data.data.uploadLink;
        dispatch({
          type: GET_LOGO_MEDIA_PATH,
          logoPath: result.data.data.uri,
        });
        const resultUpload = await userService.uploadVimeo(uploadURL, file);
        console.log("RESULT UPLOAD ===>", resultUpload);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

//_______GET LIST MEDIA IN 'SELECT EXIST'______//
export const getListMedia = (limit, page, sort) => {
  return async (dispatch) => {
    try {
      const result = await userService.getListMedia(limit, page, sort);
      if (result && result.data.data.totalItems > 0) {
        dispatch({
          type: GET_LIST_MEDIA,
          listMedia: result.data.data.items,
          totalMedia: result.data.data.totalItems,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getListArtist = (limit, page, name, sortBy, connectedWallet) => {
  return async (dispatch) => {
    try {
      dispatch({ type: REQUESTING });
      const result = await userService.getListArtist(
        limit,
        page,
        name,
        sortBy,
        connectedWallet
      );
      if (result && result.data.status === "success") {
        dispatch({
          type: GET_LIST_ARTIST,
          listArtist: result.data.data.talents,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

export const getAllArtist = (limit, page, sortBy, connectedWallet) => {
  return async (dispatch) => {
    try {
      dispatch({ type: REQUESTING });
      const result = await userService.getListArtist(
        limit,
        page,
        null,
        sortBy,
        connectedWallet
      );
      if (result && result.data.status === "success") {
        dispatch({
          type: GET_ALL_ARTISTS,
          allArtists: result.data.data.talents,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

export const getListArtistExplore = (limit, page, keyword, property, sort) => {
  return async (dispatch) => {
    dispatch({ type: REQUESTING });
    try {
      const result = await userService.getListArtistExplore(
        limit,
        page,
        keyword,
        property,
        sort
      );
      if (result && result.data.status === "success") {
        dispatch({
          type: GET_LIST_ARTIST_EXPLORE,
          listArtistExplore: result.data.data.users,
          totalListArtistExplore: result.data.data.total,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

export const getListOfOwners = (
  limit,
  page,
  keyword,
  walletAddress,
  property,
  sort
) => {
  return async (dispatch) => {
    dispatch({ type: REQUESTING });
    try {
      const result = await userService.getListOfOwners(
        limit,
        page,
        keyword,
        walletAddress,
        property,
        sort
      );
      if (result && result.data.status === "success") {
        dispatch({
          type: GET_LIST_OF_OWNERS,
          listOfOwners: result.data.data.users,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

export const getListOfOwnersInCollection = (
  limit,
  page,
  keyword,
  collectionId,
  property,
  sort
) => {
  return async (dispatch) => {
    dispatch({ type: REQUESTING });
    try {
      const result = await userService.getListOfOwnersInCollection(
        limit,
        page,
        keyword,
        collectionId,
        property,
        sort
      );
      if (result && result.data.status === "success") {
        dispatch({
          type: GET_LIST_OF_OWNERS_IN_COLLECTION,
          listOfOwnersInCollection: result.data.data.users,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

// TEMPORARY (INPUT SEARCH 13/10/2022)
export const getListArtistHeader = (
  limit,
  page,
  name,
  sortBy,
  connectedWallet
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: REQUESTING });
      const result = await userService.getListArtist(
        limit,
        page,
        name,
        sortBy,
        connectedWallet
      );
      if (result && result.data.status === "success") {
        dispatch({
          type: SEARCHING_FOR_ARTIST,
          listArtist: result.data.data.talents,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

//________GET USER PROFILE________//
export const getUserProfile = async (userId, accessToken) => {
  //FOR LOGIN
  try {
    const result = await userService.getUserProfile(userId, accessToken);
    if (result && result.data.data) {
      localStorage.setItem(
        "UserProfile",
        JSON.stringify(result.data.data.user)
      );
      window.location.reload();
    }
  } catch (error) {
    console.log(error);
  }
};

// export const getTalentByAddress = (walletAddress, accessToken) => {
//   //FOR NFT PROFILE
//   return async (dispatch) => {
//     try {
//       const result = await userService.getTalentByAddress(
//         walletAddress,
//         accessToken
//       );
//       if (result && result.data.data) {
//         dispatch({
//           type: GET_NFT_PROFILE,
//           nftProfileUser: result.data.data.talent,
//         });
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
// };

export const getUserProfileByAddress = (walletAddress) => {
  //FOR NFT PROFILE
  return async (dispatch) => {
    try {
      const result = await userService.getUserProfileByAddress(walletAddress);
      if (result && result.data.data) {
        dispatch({
          type: GET_NFT_PROFILE,
          nftProfileUser: result.data.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

//________GET HOT COLLECTION________//
export const getHotCollections = (data, pageSize, page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: REQUESTING });
      const result = await userService.getHotCollections(data, pageSize, page);
      if (result && result.data.status === "success") {
        dispatch({
          type: GET_HOT_COLLECTION,
          listHotCollections: result.data.data.collections,
          totalHotCollections: result.data.data.total,
        });
      }
    } catch (error) {
      console.log("ERROR =>", error);
    } finally {
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

//________GET HOT OFFER________//
export const getHotOffers = (filterData, pageSize, page) => {
  return async (dispatch) => {
    try {
      const result = await userService.getHotOffers(filterData, pageSize, page);
      if (result && result.data.status === "success") {
        dispatch({
          type: GET_HOT_OFFER,
          listHotOffers: result.data.data.hotOffers,
          totalHotOffers: result.data.data.total,
        });
      }
    } catch (error) {
      console.log("ERROR =>", error);
    }
  };
};

//________GET LIVE AUCTION________//
export const getLiveAuction = (filterData, pageSize, page) => {
  return async (dispatch) => {
    try {
      const result = await userService.getLiveAuction(
        filterData,
        pageSize,
        page
      );
      if (result && result.data.status === "success") {
        dispatch({
          type: GET_LIVE_AUCTION,
          listLiveAuction: result.data.data.liveAuction,
          totalLiveAuction: result.data.data.total,
        });
      }
    } catch (error) {
      console.log("ERROR =>", error);
    }
  };
};

//________GET PROFILE COLLECTIONS (PROFILE)________//
export const getProfileCollections = (
  pageSize,
  page,
  walletAdress,
  keyword
) => {
  return async (dispatch) => {
    try {
      const result = await userService.getProfileCollections(
        pageSize,
        page,
        walletAdress,
        keyword
      );
      if (result && result.data.status === "success") {
        dispatch({
          type: GET_PROFILE_COLLECTIONS,
          listProfileCollections: result.data.data.list,
          totalProfileCollections: result.data.data.total,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

//________GET ACTIVITIES (PROFILE)________//
export const getProfileActivities = (data, pageSize, page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: REQUESTING });
      const result = await userService.getProfileActivities(
        data,
        pageSize,
        page
      );
      if (result && result.data.status === "success") {
        dispatch({
          type: GET_PROFILE_ACTIVITIES,
          listProfileActivities: result.data.data.items,
          totalProfileActivities: result.data.data.total,
        });
        dispatch({ type: PENDING_REQUEST });
      }
    } catch (error) {
      console.log("ERROR =>", error);
    }
  };
};

//________GET CREATED NFT (PROFILE)________//
export const getCreatedNFT = (data, pageSize, page) => {
  return async (dispatch) => {
    try {
      const result = await nftService.getCreatedNFT(data, pageSize, page);
      if (result.data.data && result.data.status === "success") {
        dispatch({
          type: GET_CREATED_NFT,
          listCreatedNFT: result.data.data.nfts,
          totalCreatedNFT: result.data.data.total,
          listCollectionToFilter: result.data.data.collections,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

//________GET OWNED NFT (PROFILE)________//
export const getOwnedNFT = (data, pageSize, page) => {
  return async (dispatch) => {
    try {
      const result = await userService.getOwnedNFT(data, pageSize, page);
      if (result && result.data.status === "success") {
        dispatch({
          type: GET_OWNED_NFT,
          listOwnedNFT: result.data.data.nfts,
          totalOwnedNFT: result.data.data.total,
          listCollectionToFilter: result.data.data.collections,
        });
      }
    } catch (error) {
      console.log("ERROR =>", error);
    }
  };
};

//________GET NFT LISTED (PROFILE)________//
export const getProfileListing = (collectionId, collectionName) => {
  return async (dispatch) => {
    try {
      const result = await userService.getProfileListing(
        collectionId,
        collectionName
      );
      if (result && result.data.status === "success") {
        dispatch({
          type: GET_PROFILE_LISTINGS,
          listProfileListings: result.data.data,
        });
      }
    } catch (error) {
      console.log("ERROR =>", error);
    }
  };
};

//________GET MADE OFFERS (PROFILE)________//
export const getMadeOffers = (walletAddress) => {
  return async (dispatch) => {
    try {
      const result = await userService.getMadeOffers(walletAddress);
      if (result && result.data.status === "success") {
        dispatch({
          type: GET_MADE_OFFERS,
          listMadeOffers: result.data.data,
        });
      }
    } catch (error) {
      console.log("ERROR =>", error);
    }
  };
};

//________GET RECEIVED OFFERS (PROFILE)________//
export const getReceivedOffers = (walletAddress) => {
  return async (dispatch) => {
    try {
      const result = await userService.getReceivedOffers(walletAddress);
      if (result && result.data.status === "success") {
        dispatch({
          type: GET_RECEIVED_OFFERS,
          listReceivedOffers: result.data.data,
        });
      }
    } catch (error) {
      console.log("ERROR =>", error);
    }
  };
};

//________TRANSLATE DESCRIPTION (NFT_DETAIL)___//
export const translateDescription = (data) => {
  return async (dispatch) => {
    try {
      const result = await userService.translateDescription(data);
      if (result && result.data.status === "success") {
        dispatch({
          type: TRANSLATE_DESCRIPTION,
          translatedDescription: result.data.data,
        });
      }
    } catch (error) {
      console.log("ERROR =>", error);
    }
  };
};

//_______ GET USD RATE _________//
export const getUSDRate = () => {
  return async (dispatch) => {
    try {
      const result = await userService.getUSDRate();
      if (result && result.data.status === "success") {
        dispatch({
          type: GET_USD_RATE,
          rateCrypto: result.data.data.rateCrypto,
          ratePoint: result.data.data.ratePoint,
          minCryptoPrice: result.data.data.minCryptoPrice,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

//_______ GET STAN FEE _________//
export const getStanFee = () => {
  return async (dispatch) => {
    try {
      const result = await userService.getStanFee();
      if (result && result.data.data) {
        const arr = result.data.data;
        const creatorRoyalty = _.find(arr, { key: "RATIO_CREATOR" });
        const stanRoyalty = _.find(arr, { key: "RATIO_STAN" });
        dispatch({
          type: GET_STAN_FEE,
          creatorRoyalty: creatorRoyalty.value,
          stanRoyalty: stanRoyalty.value,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};
