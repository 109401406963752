import { Affix, Drawer, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NFT_SORT_BY } from "../../common/Constant";
import { filterSvg } from "../../common/commonSvg";
import { getMobileOPSystyem } from "../../util/settings/utils";
const { Option } = Select;

export default function StanNFTFilter(props) {
  const { showSearch, showSort, dataFilter, setDataFilter, priceName } = props;
  const { t } = useTranslation(["common", "explore", "nftDetail"]);
  const [inputSearch, setInputSearch] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [visibleFilter, setVisibleFilter] = useState(false);

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      setDataFilter({
        ...dataFilter,
        keyword: encodeURIComponent(inputSearch),
      });
    }
  }

  function handleOpenFilter() {
    if (getMobileOPSystyem() !== "PC") {
      setVisibleFilter(true);
    }
  }

  useEffect(() => {
    switch (orderBy) {
      case NFT_SORT_BY.PRICE_LOW_HIGH:
        setDataFilter({ ...dataFilter, property: "PRICE", sortDir: "ASC" });
        break;
      case NFT_SORT_BY.PRICE_HIGH_LOW:
        setDataFilter({ ...dataFilter, property: "PRICE", sortDir: "DESC" });
        break;
      case NFT_SORT_BY.NAME_ASC:
        setDataFilter({ ...dataFilter, property: "NAME", sortDir: "ASC" });
        break;
      case NFT_SORT_BY.NAME_DESC:
        setDataFilter({ ...dataFilter, property: "NAME", sortDir: "DESC" });
        break;
      case NFT_SORT_BY.DATE_TIME_ASC:
        setDataFilter({ ...dataFilter, property: "DATE_TIME", sortDir: "ASC" });
        break;
      case NFT_SORT_BY.DATE_TIME_DESC:
        setDataFilter({
          ...dataFilter,
          property: "DATE_TIME",
          sortDir: "DESC",
        });
        break;

      default:
        setDataFilter({ ...dataFilter, property: "", sortDir: "" });
    }
  }, [orderBy]);

  const SortSelection = () => {
    return (
      <Select
        value={orderBy}
        className="inputSelectNft"
        onChange={(e) => setOrderBy(e)}
      >
        <Option value="">Sort by</Option>
        <Option value={NFT_SORT_BY.PRICE_LOW_HIGH}>
          {t("SORT.PRICE_ASC", {
            ns: "explore",
            priceName: priceName ? priceName : "",
          })}
        </Option>
        <Option value={NFT_SORT_BY.PRICE_HIGH_LOW}>
          {t("SORT.PRICE_DESC", {
            ns: "explore",
            priceName: priceName ? priceName : "",
          })}
        </Option>
        <Option value={NFT_SORT_BY.NAME_ASC}>
          {t("SORT.NAME_ASC", {
            ns: "explore",
          })}
        </Option>
        <Option value={NFT_SORT_BY.NAME_DESC}>
          {t("SORT.NAME_DESC", {
            ns: "explore",
          })}
        </Option>
        <Option value={NFT_SORT_BY.DATE_TIME_ASC}>
          {t("SORT.DATE_ASC", {
            ns: "explore",
          })}
        </Option>
        <Option value={NFT_SORT_BY.DATE_TIME_DESC}>
          {t("SORT.DATE_DESC", {
            ns: "explore",
          })}
        </Option>
      </Select>
    );
  };

  return (
    <>
      <Affix offsetTop={0}>
        <div className="searchSection">
          {getMobileOPSystyem() !== "PC" ? (
            <div>{filterSvg(handleOpenFilter)}</div>
          ) : null}
          <div style={showSearch ? { width: "100%" } : { display: "none" }}>
            <Input
              className="inputSearchNft"
              placeholder={t("PLACEHOLDER.SEARCH", { ns: "explore" })}
              onChange={(e) => setInputSearch(e.target.value)}
              onKeyPress={handleKeyPress}
              prefix={
                <svg
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.4167 9.87907H9.75833L9.525 9.65407C10.3417 8.70407 10.8333 7.47074 10.8333 6.12907C10.8333 3.1374 8.40833 0.712402 5.41667 0.712402C2.425 0.712402 0 3.1374 0 6.12907C0 9.12074 2.425 11.5457 5.41667 11.5457C6.75833 11.5457 7.99167 11.0541 8.94167 10.2374L9.16667 10.4707V11.1291L13.3333 15.2874L14.575 14.0457L10.4167 9.87907ZM5.41667 9.87907C3.34167 9.87907 1.66667 8.20407 1.66667 6.12907C1.66667 4.05407 3.34167 2.37907 5.41667 2.37907C7.49167 2.37907 9.16667 4.05407 9.16667 6.12907C9.16667 8.20407 7.49167 9.87907 5.41667 9.87907Z"
                    fill="#CBCBCB"
                  />
                </svg>
              }
            />
          </div>
          <div style={showSort ? { width: "18%" } : { display: "none" }}>
            <SortSelection />
          </div>
        </div>
      </Affix>
      <Drawer
        title={t("TITLE.FILTER_NFT")}
        placement={"bottom"}
        closable={true}
        onClose={() => setVisibleFilter(false)}
        visible={visibleFilter}
        className="filterDrawer"
      >
        <SortSelection />
      </Drawer>
    </>
  );
}
