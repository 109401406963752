import {
  ConfigProvider,
  Drawer,
  Dropdown,
  Empty,
  Image,
  Input,
  Menu,
  Pagination,
  Table,
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import { history } from "../../../App";
import { filterSvg, MaticIcon, StanPointIcon, WETHIcon } from "../../../common/commonSvg";
import StanTypo from "../../../component/StanTypo/StanTypo";
import { getListArtistExplore } from "../../../redux/action/UserAction";
import {
  REQUESTING,
  SET_ACTIVE_TAB_PROFILE,
} from "../../../redux/types/UserType";
import {
  formatStanCrypto,
  formatStanPoint,
} from "../../../util/settings/utils";
import "./ExploreArtist.css";
import { EXPLORE_PATH, PROFILE_TABPANE } from "../../../common/Constant";

export default function ExploreArtist(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "explore"]);
  const [inputSearch, setInputSearch] = useState("");
  const [dataFilter, setDataFilter] = useState({});
  const [propertySort, setPropertySort] = useState("");
  const [sortType, setSortType] = useState("");
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedPageSize, setSelectedPageSize] = useState(100);
  const { listArtistExplore, totalListArtistExplore } = useSelector(
    (state) => state.UserReducer
  );
  const { nftStatus } = useSelector((state) => state.AutionReducer);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      setDataFilter({
        ...dataFilter,
        keyword: encodeURIComponent(inputSearch),
      });
    }
  }

  useEffect(() => {
    dispatch(
      getListArtistExplore(
        selectedPageSize,
        selectedPage,
        encodeURIComponent(inputSearch),
        propertySort,
        sortType
      )
    );
  }, [dataFilter, propertySort, sortType, selectedPageSize, selectedPage]);

  function redirectToUser(data) {
    history.push(
      generatePath(EXPLORE_PATH.PROFILE, {
        walletAddress: data.walletAddress,
      })
    );
  }

  function redirectToCollections(data) {
    dispatch({
      type: SET_ACTIVE_TAB_PROFILE,
      profileCurrentActiveTab: PROFILE_TABPANE.PROFILE_COLLECTIONS,
    });
    history.push(
      generatePath(EXPLORE_PATH.PROFILE, {
        walletAddress: data.walletAddress,
      })
    );
  }

  function redirectToOwners(data) {
    history.push(
      generatePath(EXPLORE_PATH.LIST_OF_OWNERS, {
        walletAddress: data.walletAddress,
      })
    );
  }

  function redirectToItems(data) {
    dispatch({
      type: SET_ACTIVE_TAB_PROFILE,
      profileCurrentActiveTab: PROFILE_TABPANE.PROFILE_CREATED,
    });
    history.push(
      generatePath(EXPLORE_PATH.PROFILE, {
        walletAddress: data.walletAddress,
      })
    );
  }

  const NameSort = (
    <Menu
      items={[
        {
          label: (
            <div
              onClick={() => {
                setSortType("ASC");
                setPropertySort("artistName");
              }}
            >
              <span>{t("SORT.NAME_ASC", { ns: "explore" })}</span>
            </div>
          ),
          key: "artistNameAsc",
        },
        {
          label: (
            <div
              onClick={() => {
                setSortType("DESC");
                setPropertySort("artistName");
              }}
            >
              <span>{t("SORT.NAME_DESC", { ns: "explore" })}</span>
            </div>
          ),
          key: "artistNameDesc",
        },
      ]}
    />
  );

  const NumberOfCollectionsSort = (
    <Menu
      items={[
        {
          label: (
            <div
              onClick={() => {
                setSortType("ASC");
                setPropertySort("numberOfCollections");
              }}
            >
              <span>
                {t("SORT.NUMBER_OF_COLLECTIONS_ASC", { ns: "explore" })}
              </span>
            </div>
          ),
          key: "numOfCollectionsAsc",
        },
        {
          label: (
            <div
              onClick={() => {
                setSortType("DESC");
                setPropertySort("numberOfCollections");
              }}
            >
              <span>
                {t("SORT.NUMBER_OF_COLLECTIONS_DESC", { ns: "explore" })}
              </span>
            </div>
          ),
          key: "numOfCollectionsDesc",
        },
      ]}
    />
  );

  const TotalVolumeSort = (
    <Menu
      items={[
        {
          label: (
            <div
              onClick={() => {
                setSortType("ASC");
                setPropertySort("totalVolume");
              }}
            >
              <span>{t("SORT.TOTAL_VOLUME_ASC", { ns: "explore" })}</span>
            </div>
          ),
          key: "totalVolumeAsc",
        },
        {
          label: (
            <div
              onClick={() => {
                setSortType("DESC");
                setPropertySort("totalVolume");
              }}
            >
              <span>{t("SORT.TOTAL_VOLUME_DESC", { ns: "explore" })}</span>
            </div>
          ),
          key: "totalVolumeDesc",
        },
      ]}
    />
  );

  const OwnersSort = (
    <Menu
      items={[
        {
          label: (
            <div
              onClick={() => {
                setSortType("ASC");
                setPropertySort("totalOwners");
              }}
            >
              <span>{t("SORT.OWNERS_ASC", { ns: "explore" })}</span>
            </div>
          ),
          key: "totalOwnersAsc",
        },
        {
          label: (
            <div
              onClick={() => {
                setSortType("DESC");
                setPropertySort("totalOwners");
              }}
            >
              <span>{t("SORT.OWNERS_DESC", { ns: "explore" })}</span>
            </div>
          ),
          key: "totalOwnersDesc",
        },
      ]}
    />
  );

  const ItemsSort = (
    <Menu
      items={[
        {
          label: (
            <div
              onClick={() => {
                setSortType("ASC");
                setPropertySort("numberOfItems");
              }}
            >
              <span>{t("SORT.NUMBER_OF_ITEMS_ASC", { ns: "explore" })}</span>
            </div>
          ),
          key: "numberOfItemsAsc",
        },
        {
          label: (
            <div
              onClick={() => {
                setSortType("DESC");
                setPropertySort("numberOfItems");
              }}
            >
              <span>{t("SORT.NUMBER_OF_ITEMS_DESC", { ns: "explore" })}</span>
            </div>
          ),
          key: "numberOfItemsDesc",
        },
      ]}
    />
  );

  const columns = [
    {
      title: (
        <div className="d-flex align-items-center">
          <div className="mr-2">
            <StanTypo
              fontSize={20}
              fontWeight={400}
              color="#4D4D4D"
              content={t("TABLE.TITLE.ARTISTS", { ns: "explore" })}
            />
          </div>
          <div>
            <Dropdown overlay={NameSort} trigger={["click"]}>
              <img
                style={{ cursor: "pointer" }}
                width={12}
                alt=""
                src="/svg/filter_list_24px.svg"
              />
            </Dropdown>
          </div>
        </div>
      ),
      dataIndex: "userId",
      key: "userId",
      width: "20%",
      render: (text, row) => (
        <div className="d-flex align-items-center justify-content-start">
          <div className="mr-3">
            <Image
              src={row.user?.avatar}
              className="artistAvatar"
              preview={false}
              onClick={() => redirectToUser(row)}
            />
          </div>
          <div>
            <StanTypo
              fontSize={16}
              fontWeight={700}
              color="#000000"
              content={row.user?.name}
            />
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center justify-content-center">
          <div className="mr-2">
            <StanTypo
              fontSize={20}
              fontWeight={400}
              color="#4D4D4D"
              content={t("TABLE.TITLE.COLLECTIONS", {
                ns: "explore",
              })}
            />
          </div>
          <div>
            <Dropdown overlay={NumberOfCollectionsSort} trigger={["click"]}>
              <img
                style={{ cursor: "pointer" }}
                width={12}
                alt=""
                src="/svg/filter_list_24px.svg"
              />
            </Dropdown>
          </div>
        </div>
      ),
      dataIndex: "numberOfCollections",
      key: "userId",
      width: "20%",
      align: "center",
      render: (text, row) => (
        <div
          onClick={() => redirectToCollections(row)}
          style={{ cursor: "pointer" }}
        >
          <StanTypo
            fontSize={20}
            fontWeight={500}
            color="#35A075"
            content={text}
          />
        </div>
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center justify-content-center">
          <div className="mr-2">
            <StanTypo
              fontSize={20}
              fontWeight={400}
              color="#4D4D4D"
              content={t("TABLE.TITLE.TOTAL_VOLUME", { ns: "explore" })}
            />
          </div>
          <div>
            <Dropdown overlay={TotalVolumeSort} trigger={["click"]}>
              <img
                style={{ cursor: "pointer" }}
                width={12}
                alt=""
                src="/svg/filter_list_24px.svg"
              />
            </Dropdown>
          </div>
        </div>
      ),
      dataIndex: "totalVolume",
      key: "userId",
      align: "center",
      width: "20%",
      render: (text, row) => (
        <div
          onClick={() => {
            dispatch({
              type: SET_ACTIVE_TAB_PROFILE,
              profileCurrentActiveTab: "PROFILE_COLLECTIONS",
            });
            history.push(
              generatePath("/profile/:walletAddress", {
                walletAddress: row.walletAddress,
              })
            );
          }}
          className="d-flex justify-content-center"
          style={{ cursor: "pointer" }}
        >
          {row.totalVolume !== 0 ? (
            <>
              <div className="d-flex flex-column justify-content-center">
                <MaticIcon />
                <StanPointIcon />
              </div>
              <div className="d-flex flex-column justify-content-center align-items-start">
                <StanTypo
                  fontSize={20}
                  fontWeight={700}
                  color="#0D0D0D"
                  content={formatStanCrypto(
                    row.totalVolumeDetail.totalVolumeCrypto
                  )}
                />
                <StanTypo
                  fontSize={12}
                  fontWeight={400}
                  color="#939393"
                  content={formatStanPoint(
                    row.totalVolumeDetail.totalVolumePoint
                  )}
                />
              </div>
            </>
          ) : (
            "---"
          )}
        </div>
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center justify-content-center">
          <div className="mr-2">
            <StanTypo
              fontSize={20}
              fontWeight={400}
              color="#4D4D4D"
              content={t("TABLE.TITLE.OWNERS", { ns: "explore" })}
            />
          </div>
          <div>
            <Dropdown overlay={OwnersSort} trigger={["click"]}>
              <img
                style={{ cursor: "pointer" }}
                width={12}
                alt=""
                src="/svg/filter_list_24px.svg"
              />
            </Dropdown>
          </div>
        </div>
      ),
      dataIndex: "totalOwners",
      key: "userId",
      align: "center",
      width: "10%",
      // defaultSortOrder: "descend",
      // sorter: (a, b) => a.totalOwners - b.totalOwners,
      render: (text, row) => (
        <div
          onClick={() => redirectToOwners(row)}
          style={{ cursor: "pointer" }}
        >
          <StanTypo
            fontSize={20}
            fontWeight={500}
            color="#35A075"
            content={text}
          />
        </div>
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center justify-content-center">
          <div className="mr-2">
            <StanTypo
              fontSize={20}
              fontWeight={400}
              color="#4D4D4D"
              content={t("TABLE.TITLE.ITEMS", { ns: "explore" })}
            />
          </div>
          <div>
            <Dropdown overlay={ItemsSort} trigger={["click"]}>
              <img
                style={{ cursor: "pointer" }}
                width={12}
                alt=""
                src="/svg/filter_list_24px.svg"
              />
            </Dropdown>
          </div>
        </div>
      ),
      dataIndex: "numberOfItems",
      key: "userId",
      align: "center",
      width: "10%",
      // defaultSortOrder: "descend",
      // sorter: (a, b) => a.numberOfItems - b.numberOfItems,
      render: (text, row) => (
        <div style={{ cursor: "pointer" }} onClick={() => redirectToItems(row)}>
          <StanTypo
            fontSize={20}
            fontWeight={500}
            color="#35A075"
            content={text}
          />
        </div>
      ),
    },
  ];

  const ArtistItemResponsive = (props) => {
    const { item } = props;
    return (
      <div className="ArtistItemResponsive">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center" style={{ width: "70%" }}>
            <div className="mr-2">
              <Image
                src={item.user?.avatar}
                className="artistAvatar"
                preview={false}
                onClick={() => redirectToUser(item)}
              />
            </div>
            <div className="ellipsisText" style={{ width: "100%" }}>
              <StanTypo
                fontSize={14}
                fontWeight={500}
                color="#000000"
                content={item.user?.name}
              />
            </div>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="d-flex align-items-center">
              <MaticIcon />{" "}
              <StanTypo
                fontSize={14}
                fontWeight={500}
                color="#000000"
                content={formatStanCrypto(
                  item.totalVolumeDetail.totalVolumeCrypto
                )}
              />
            </div>
            <div className="d-flex align-items-center">
              <StanPointIcon />{" "}
              <StanTypo
                fontSize={10}
                fontWeight={400}
                color="#939393"
                content={formatStanPoint(
                  item.totalVolumeDetail.totalVolumePoint
                )}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            onClick={() => redirectToCollections(item)}
          >
            <StanTypo
              fontSize={14}
              fontWeight={700}
              color="#17875A"
              content={item.numberOfCollections}
            />
            <StanTypo
              fontSize={14}
              fontWeight={700}
              color="#4D4D4D"
              content="Collections"
            />
          </div>
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            onClick={() => redirectToOwners(item)}
          >
            <StanTypo
              fontSize={14}
              fontWeight={700}
              color="#17875A"
              content={item.totalOwners}
            />
            <StanTypo
              fontSize={14}
              fontWeight={700}
              color="#4D4D4D"
              content="Owners"
            />
          </div>
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            onClick={() => redirectToItems(item)}
          >
            <StanTypo
              fontSize={14}
              fontWeight={700}
              color="#17875A"
              content={item.numberOfItems}
            />
            <StanTypo
              fontSize={14}
              fontWeight={700}
              color="#4D4D4D"
              content="Items"
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="exploreArtist" style={{ minHeight: "150vh" }}>
        <div className="searchSection">
          <div
            className="d-flex justify-content-center filterToggle"
            style={{ width: "5%" }}
          >
            {filterSvg(() => setVisibleFilter(true))}
          </div>
          <div style={{ marginRight: 10, width: "100%" }}>
            <Input
              className="inputSearchNft"
              placeholder={t("PLACEHOLDER.SEARCH", { ns: "explore" })}
              onChange={(e) => setInputSearch(e.target.value)}
              onKeyPress={handleKeyPress}
              prefix={
                <svg
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.4167 9.87907H9.75833L9.525 9.65407C10.3417 8.70407 10.8333 7.47074 10.8333 6.12907C10.8333 3.1374 8.40833 0.712402 5.41667 0.712402C2.425 0.712402 0 3.1374 0 6.12907C0 9.12074 2.425 11.5457 5.41667 11.5457C6.75833 11.5457 7.99167 11.0541 8.94167 10.2374L9.16667 10.4707V11.1291L13.3333 15.2874L14.575 14.0457L10.4167 9.87907ZM5.41667 9.87907C3.34167 9.87907 1.66667 8.20407 1.66667 6.12907C1.66667 4.05407 3.34167 2.37907 5.41667 2.37907C7.49167 2.37907 9.16667 4.05407 9.16667 6.12907C9.16667 8.20407 7.49167 9.87907 5.41667 9.87907Z"
                    fill="#CBCBCB"
                  />
                </svg>
              }
            />
          </div>
        </div>

        <div className="artistListContainer">
          <ConfigProvider
            renderEmpty={() => (
              <Empty description={t("MSG.NO_RESULTS_FOUND")} />
            )}
          >
            <Table
              loading={nftStatus === REQUESTING}
              className="hotCollectionsItemsTable"
              dataSource={listArtistExplore}
              columns={columns}
              pagination={{
                responsive: true,
                showSizeChanger: true,
                current: selectedPage,
                pageSize: selectedPageSize,
                total: totalListArtistExplore,
                onChange: (page, pageSize) => {
                  setSelectedPage(page);
                  setSelectedPageSize(pageSize);
                },
              }}
            />
          </ConfigProvider>
          <div className="artistListExploreResponsive">
            {listArtistExplore.length > 0 ? (
              <>
                {listArtistExplore.map((item, index) => {
                  return <ArtistItemResponsive key={item.userId} item={item} />;
                })}
                <Pagination
                  responsive={true}
                  current={selectedPage}
                  disabled={totalListArtistExplore === 0}
                  pageSize={selectedPageSize}
                  onChange={(page, pageSize) => {
                    setSelectedPage(page);
                    setSelectedPageSize(pageSize);
                  }}
                  showSizeChanger
                  total={totalListArtistExplore}
                />
              </>
            ) : (
              <Empty description={t("MSG.NO_RESULTS_FOUND")} />
            )}
          </div>
        </div>
      </div>
      {/* DRAWRER */}
      <Drawer
        title={t("TITLE.FILTER_ARTIST")}
        placement={"bottom"}
        closable={true}
        onClose={() => setVisibleFilter(false)}
        visible={visibleFilter}
        className="filterDrawer"
      >
        <div>
          <div>
            <Dropdown overlay={NameSort} trigger={["click"]}>
              <div>
                <span>
                  {t("TABLE.TITLE.ARTIST_PROFILE", { ns: "explore" })}
                </span>
                <img
                  style={{ cursor: "pointer" }}
                  width={12}
                  alt=""
                  src="/svg/filter_list_24px.svg"
                />
              </div>
            </Dropdown>
            <Dropdown overlay={NumberOfCollectionsSort} trigger={["click"]}>
              <div>
                <span>
                  {t("TABLE.TITLE.NUMBER_OF_COLLECTIONS", { ns: "explore" })}
                </span>
                <img
                  style={{ cursor: "pointer" }}
                  width={12}
                  alt=""
                  src="/svg/filter_list_24px.svg"
                />
              </div>
            </Dropdown>
            <Dropdown overlay={TotalVolumeSort} trigger={["click"]}>
              <div>
                <span>{t("TABLE.TITLE.TOTAL_VOLUME", { ns: "explore" })}</span>
                <img
                  style={{ cursor: "pointer" }}
                  width={12}
                  alt=""
                  src="/svg/filter_list_24px.svg"
                />
              </div>
            </Dropdown>
            <Dropdown overlay={OwnersSort} trigger={["click"]}>
              <div>
                <span>{t("TABLE.TITLE.OWNERS", { ns: "explore" })}</span>
                <img
                  style={{ cursor: "pointer" }}
                  width={12}
                  alt=""
                  src="/svg/filter_list_24px.svg"
                />
              </div>
            </Dropdown>
            <Dropdown overlay={ItemsSort} trigger={["click"]}>
              <div>
                <span>
                  {t("TABLE.TITLE.NUMBER_OF_ITEMS", { ns: "explore" })}
                </span>
                <img
                  style={{ cursor: "pointer" }}
                  width={12}
                  alt=""
                  src="/svg/filter_list_24px.svg"
                />
              </div>
            </Dropdown>
          </div>
        </div>
      </Drawer>
      {/* DRAWRER */}
    </>
  );
}
