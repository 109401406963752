import { Drawer, Dropdown, Image, Input, Menu, Table } from "antd";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useParams } from "react-router-dom";
import { history } from "../../App";
import { filterSvg, MaticIcon, StanPointIcon, WETHIcon } from "../../common/commonSvg";
import StanTypo from "../../component/StanTypo/StanTypo";
import { getListOfOwners } from "../../redux/action/UserAction";
import { REQUESTING } from "../../redux/types/UserType";
import { formatStanCrypto, formatStanPoint } from "../../util/settings/utils";
import "./ListOfOwners.css";

export default function ListOfOwners(props) {
  const { t } = useTranslation(["common", "explore"]);
  const dispatch = useDispatch();
  const [propertySort, setPropertySort] = useState("");
  const [sortType, setSortType] = useState("");
  const [inputSearch, setInputSearch] = useState("");
  const [dataFilter, setDataFilter] = useState({});
  const [visibleFilter, setVisibleFilter] = useState(false);
  const { walletAddress } = useParams();
  const { listOfOwners } = useSelector((state) => state.UserReducer);
  const { nftStatus } = useSelector((state) => state.AutionReducer);

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      setDataFilter({
        ...dataFilter,
        keyword: encodeURIComponent(inputSearch),
      });
    }
  }

  useEffect(() => {
    dispatch(
      getListOfOwners(
        50,
        1,
        encodeURIComponent(inputSearch),
        encodeURIComponent(walletAddress),
        propertySort,
        sortType
      )
    );
  }, [dataFilter, walletAddress, propertySort, sortType]);

  const NameSort = (
    <Menu
      items={[
        {
          label: (
            <div
              onClick={() => {
                setSortType("ASC");
                setPropertySort("artistName");
              }}
            >
              <span>{t("SORT.NAME_ASC", { ns: "explore" })}</span>
            </div>
          ),
          key: "artistNameAsc",
        },
        {
          label: (
            <div
              onClick={() => {
                setSortType("DESC");
                setPropertySort("artistName");
              }}
            >
              <span>{t("SORT.NAME_DESC", { ns: "explore" })}</span>
            </div>
          ),
          key: "artistNameDesc",
        },
      ]}
    />
  );

  const CreatedAtSort = (
    <Menu
      items={[
        {
          label: (
            <div
              onClick={() => {
                setSortType("ASC");
                setPropertySort("artistCreatedAt");
              }}
            >
              <span>{t("SORT.MEMBER_SINCE_ASC", { ns: "explore" })}</span>
            </div>
          ),
          key: "memberSinceAsc",
        },
        {
          label: (
            <div
              onClick={() => {
                setSortType("DESC");
                setPropertySort("artistCreatedAt");
              }}
            >
              <span>{t("SORT.MEMBER_SINCE_DESC", { ns: "explore" })}</span>
            </div>
          ),
          key: "memberSinceDesc",
        },
      ]}
    />
  );

  const TotalPurchaseSort = (
    <Menu
      items={[
        {
          label: (
            <div
              onClick={() => {
                setSortType("ASC");
                setPropertySort("totalVolume");
              }}
            >
              <span>{t("SORT.TOTAL_PURCHASE_ASC", { ns: "explore" })}</span>
            </div>
          ),
          key: "totalPurchaseAsc",
        },
        {
          label: (
            <div
              onClick={() => {
                setSortType("DESC");
                setPropertySort("totalVolume");
              }}
            >
              <span>{t("SORT.TOTAL_PURCHASE_DESC", { ns: "explore" })}</span>
            </div>
          ),
          key: "totalPurchaseDesc",
        },
      ]}
    />
  );

  const columns = [
    {
      title: (
        <div className="d-flex align-items-center">
          <div className="mr-2">{t("TABLE.TITLE.NAME", { ns: "explore" })}</div>
          <div>
            <Dropdown overlay={NameSort} trigger={["click"]}>
              <img
                style={{ cursor: "pointer" }}
                width={12}
                alt=""
                src="/svg/filter_list_24px.svg"
              />
            </Dropdown>
          </div>
        </div>
      ),
      dataIndex: "userId",
      key: "userId",
      width: "20%",
      render: (text, row, index) => (
        <div className="d-flex align-items-center justify-content-start">
          <div className="mr-3">
            <Image
              src={row.user?.avatar}
              className="artistAvatar"
              onClick={() =>
                history.push(
                  generatePath("/profile/:walletAddress", {
                    walletAddress: row.walletAddress,
                  })
                )
              }
              preview={false}
            />
          </div>
          <div>
            <StanTypo
              fontSize={16}
              fontWeight={700}
              color="#000000"
              content={row.user.name}
            />
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center justify-content-center">
          <div className="mr-2">
            {t("TABLE.TITLE.STAN_MEMBER_SINCE", { ns: "explore" })}
          </div>
          <div>
            <Dropdown overlay={CreatedAtSort} trigger={["click"]}>
              <img
                style={{ cursor: "pointer" }}
                width={12}
                alt=""
                src="/svg/filter_list_24px.svg"
              />
            </Dropdown>
          </div>
        </div>
      ),
      dataIndex: "totalCrypto",
      key: "userId",
      align: "center",
      width: "20%",
      // defaultSortOrder: "descend",
      // sorter: (a, b) => a.numberOfCollections - b.numberOfCollections,
      render: (text, row) => (
        <>
          <StanTypo
            fontSize={20}
            fontWeight={600}
            color="#0D0D0D"
            content={moment.unix(row.user?.createdAt).format("DD/MM/YYYY")}
          />
        </>
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center justify-content-center">
          <div className="mr-2">
            {t("TABLE.TITLE.TOTAL_PURCHASE", { ns: "explore" })}
          </div>
          <div>
            <Dropdown overlay={TotalPurchaseSort} trigger={["click"]}>
              <img
                style={{ cursor: "pointer" }}
                width={12}
                alt=""
                src="/svg/filter_list_24px.svg"
              />
            </Dropdown>
          </div>
        </div>
      ),
      dataIndex: "totalCrypto",
      key: "userId",
      align: "center",
      width: "20%",
      render: (text, row) => (
        <div className="d-flex flex-column justify-content-center">
          <div className="d-flex align-items-center justify-content-center mb-2">
            <MaticIcon />
            <StanTypo
              fontSize={20}
              fontWeight={700}
              color="#0D0D0D"
              content={formatStanCrypto(
                row.totalVolumeDetail.totalVolumeCrypto
              )}
            />
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <StanPointIcon />
            <StanTypo
              fontSize={12}
              fontWeight={400}
              color="#939393"
              content={formatStanPoint(row.totalVolumeDetail.totalVolumePoint)}
            />
          </div>
        </div>
      ),
    },
  ];

  const OwnerItem = (props) => {
    const { owner } = props;
    return (
      <div className="ownerItem">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div className="mr-3">
              <Image
                style={{ borderRadius: "12px", objectFit: "cover" }}
                width={44}
                height={44}
                preview={false}
                src={owner.user?.avatar}
                onClick={() =>
                  history.push(
                    generatePath("/profile/:walletAddress", {
                      walletAddress: owner.walletAddress,
                    })
                  )
                }
              />
            </div>
            <div>
              <StanTypo
                fontSize={14}
                fontWeight={500}
                color="#000000"
                content={owner.user?.name}
              />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div>
              <MaticIcon />
              <StanTypo
                fontSize={14}
                fontWeight={500}
                color="#000000"
                content={formatStanCrypto(
                  owner.totalVolumeDetail?.totalVolumeCrypto
                )}
              />
            </div>
            <div>
              <StanPointIcon />
              <StanTypo
                fontSize={10}
                fontWeight={400}
                color="#939393"
                content={formatStanPoint(
                  owner.totalVolumeDetail?.totalVolumePoint
                )}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <StanTypo
            fontSize={14}
            fontWeight={400}
            color="#939393"
            content={t("TABLE.TITLE.STAN_MEMBER_SINCE", { ns: "explore" })}
          />
          <StanTypo
            fontSize={14}
            fontWeight={500}
            color="#4D4D4D"
            content={moment.unix(owner.user?.createdAt).format("DD/MM/YYYY")}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-center mt-5">
        <h2>{t("TITLE.LIST_OF_OWNERS")}</h2>
      </div>
      <div className="searchSection mt-3">
        <div
          className="d-flex justify-content-center filterToggle"
          style={{ width: "5%" }}
        >
          {filterSvg(() => setVisibleFilter(true))}
        </div>
        <div style={{ marginRight: 10, width: "100%" }}>
          <Input
            className="inputSearchNft"
            placeholder={t("PLACEHOLDER.SEARCH", { ns: "explore" })}
            onChange={(e) => setInputSearch(e.target.value)}
            onKeyPress={handleKeyPress}
            prefix={
              <svg
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.4167 9.87907H9.75833L9.525 9.65407C10.3417 8.70407 10.8333 7.47074 10.8333 6.12907C10.8333 3.1374 8.40833 0.712402 5.41667 0.712402C2.425 0.712402 0 3.1374 0 6.12907C0 9.12074 2.425 11.5457 5.41667 11.5457C6.75833 11.5457 7.99167 11.0541 8.94167 10.2374L9.16667 10.4707V11.1291L13.3333 15.2874L14.575 14.0457L10.4167 9.87907ZM5.41667 9.87907C3.34167 9.87907 1.66667 8.20407 1.66667 6.12907C1.66667 4.05407 3.34167 2.37907 5.41667 2.37907C7.49167 2.37907 9.16667 4.05407 9.16667 6.12907C9.16667 8.20407 7.49167 9.87907 5.41667 9.87907Z"
                  fill="#CBCBCB"
                />
              </svg>
            }
          />
        </div>
      </div>
      <div className="listOfOwnersContainer" style={{ minHeight: "100vh" }}>
        <Table
          className="listOfOwnersTable"
          dataSource={listOfOwners}
          columns={columns}
          loading={nftStatus === REQUESTING}
        />
        <div className="listOfOwners-responsive">
          {listOfOwners.map((owner, index) => {
            return <OwnerItem key={owner.userId} owner={owner} />;
          })}
        </div>
      </div>

      {/* DRAWER */}
      <Drawer
        title={t("TITLE.FILTER_ARTIST")}
        placement={"bottom"}
        closable={true}
        onClose={() => setVisibleFilter(false)}
        visible={visibleFilter}
        className="filterDrawer"
      >
        <div>
          <Dropdown overlay={NameSort} trigger={["click"]}>
            <div>
              <span>{t("TABLE.TITLE.ARTIST_PROFILE", { ns: "explore" })}</span>
              <img
                style={{ cursor: "pointer" }}
                width={12}
                alt=""
                src="/svg/filter_list_24px.svg"
              />
            </div>
          </Dropdown>
          <Dropdown overlay={CreatedAtSort} trigger={["click"]}>
            <div>
              <span>
                {t("TABLE.TITLE.STAN_MEMBER_SINCE", { ns: "explore" })}
              </span>
              <img
                style={{ cursor: "pointer" }}
                width={12}
                alt=""
                src="/svg/filter_list_24px.svg"
              />
            </div>
          </Dropdown>
          <Dropdown overlay={TotalPurchaseSort} trigger={["click"]}>
            <div>
              <span>{t("TABLE.TITLE.TOTAL_PURCHASE", { ns: "explore" })}</span>
              <img
                style={{ cursor: "pointer" }}
                width={12}
                alt=""
                src="/svg/filter_list_24px.svg"
              />
            </div>
          </Dropdown>
        </div>
      </Drawer>
      {/* DRAWER */}
    </>
  );
}
