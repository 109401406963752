import {
  CalendarOutlined,
  CaretRightOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Collapse, Input, Select, Spin } from "antd";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBalanceOfFan,
  getStanFixed,
  getStanRoyalty,
  getStanServiceFee,
  postListingFixPrice,
  postListingHighestBid,
} from "../../../redux/action/AutionAction";

import _ from "lodash";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { openNotificationWithIcon } from "../../../App";
import { MaticIcon } from "../../../common/commonSvg";
import ModalInfoNFT from "../../../component/ModalInfoNFT/ModalInfoNFT";
import StanButton from "../../../component/StanButton/StanButton";
import StanTypo from "../../../component/StanTypo/StanTypo";
import { getUSDRate } from "../../../redux/action/UserAction";
import { OPEN_DEPOSIT } from "../../../redux/types/ModalType";
import {
  COMPLETE_REQUEST,
  FAILED_REQUEST,
  INSUFFICENT_FUND,
  PENDING_REQUEST,
  PROCESSING,
  REQUESTING,
} from "../../../redux/types/UserType";
import { NETWORK_URL } from "../../../util/settings/config";
import { fromEtherToValue } from "../../../util/settings/utils";
import "./ListingNft.css";
import { MsgInsufficientBalance } from "../../../common/Constant";

const { Option } = Select;
export default function ListingNFT(props) {
  const { t } = useTranslation(["common", "nft"]);
  const [saleMethod, setSaleMethod] = useState("FIXED_PRICE");
  const [inputPrice, setInputPrice] = useState("");
  const [expirationPeriod, setExpirationPeriod] = useState(7);
  // const [isValid, setIsValid] = useState(true);
  const [error, setError] = useState("");
  const {
    nftStatus,
    stanRoyalty,
    stanFixed,
    stanServiceFee,
    txtHash,
    balanceOfFan,
  } = useSelector((state) => state.AutionReducer);
  const { payload, onClose } = useSelector((state) => state.ModalReducer);
  const { ratePoint, rateCrypto, minCryptoPrice } = useSelector(
    (state) => state.UserReducer
  );
  const isInsufficientStanFund =
    Number(balanceOfFan) <
    Number(fromEtherToValue(stanFixed?.feeListingNFTAuction));

  const { nft } = payload;
  const dispatch = useDispatch();

  // EFFECTS
  // RESET FORM
  useEffect(() => {
    if (nftStatus === PENDING_REQUEST) {
      setInputPrice();
      setSaleMethod("FIXED_PRICE");
      setExpirationPeriod(7);
    }
  }, [nftStatus]);

  useEffect(() => {
    dispatch(getUSDRate());
    dispatch(getStanRoyalty());
    dispatch(getStanFixed());
    dispatch(getStanServiceFee());
    console.log("RENDER LISTING");
  }, [nft]);

  useEffect(() => {
    dispatch(getBalanceOfFan());
  }, []);

  // InputExpirationPeriod
  let listCalendar = [
    { value: 1, name: `1 ${t("TEXT.DAY", { ns: "nft" })}` },
    { value: 3, name: `3 ${t("TEXT.DAYS", { ns: "nft" })}` },
    { value: 7, name: `7 ${t("TEXT.DAYS", { ns: "nft" })}` },
    { value: 14, name: `14 ${t("TEXT.DAYS", { ns: "nft" })}` },
    { value: 30, name: `30 ${t("TEXT.DAYS", { ns: "nft" })}` },
    { value: 90, name: `90 ${t("TEXT.DAYS", { ns: "nft" })}` },
  ];

  const InputExpirationPeriod = listCalendar.map((e) => (
    <Option value={e.value}>
      <CalendarOutlined style={{ marginRight: 10 }} />
      {e.name}
    </Option>
  ));
  // InputExpirationPeriod

  // EFFECTS

  // HANDLE
  const handleChangeMethod = (value) => {
    setSaleMethod(value);
  };

  function checkInputPrice(price) {
    // if (!Number.isInteger(price) && saleMethod === "HIGHEST_BID") {
    //   // only check decimal number in auction
    //   setError("Invalid amount");
    //   openNotificationWithIcon(
    //     "error",
    //     "Error",
    //     t("MSG.INVALID_DECIMAL_NUMBER")
    //   );
    //   return false;
    // } else
    if (price <= 0) {
      openNotificationWithIcon(
        "error",
        "Error",
        t("MSG.CANNOT_MAKE_LISTING_NFT")
      );
      return false;
    } else if (price && price < minCryptoPrice) {
      openNotificationWithIcon("error", "Error", "Minimum price");
      return false;
    } else if ((price && Number.isNaN(price)) || price === "") {
      setError(t("MSG.NFT_PRICE_MUST_NOT_BE_BLANK"));
      openNotificationWithIcon(
        "error",
        "Error",
        t("MSG.PLEASE_TYPE_INPUT_PRICE")
      );
      return false;
    } else if (price && price > 100000000000000) {
      setError(t("MSG.AMOUNT_CANNOT_EXCEED"));
      return false;
    } else if (price && price.toString().length > 18) {
      setError(t("MSG.AMOUNT_CANNOT_EXCEED"));
      openNotificationWithIcon(
        "error",
        "Error",
        t("MSG.INPUT_PRICE_MAX_18_DIGITS")
      );
      return false;
    }
    return true;
  }

  const handlePostListing = () => {
    if (isInsufficientStanFund && saleMethod === "HIGHEST_BID") {
      dispatch({ type: FAILED_REQUEST });
      openNotificationWithIcon("error", "Error", MsgInsufficientBalance);
      return;
    }
    const data = {
      nftId: nft.id,
      saleMethod: saleMethod,
      currency: "POLYGON",
      price: +inputPrice,
      expirationPeriod:
        saleMethod === "FIXED_PRICE" ? 999999999 : expirationPeriod,
    };
    if (!checkInputPrice(+inputPrice)) {
      return;
    }
    if (saleMethod === "FIXED_PRICE") {
      dispatch(postListingFixPrice(data, nft.tokenId));
    } else {
      dispatch(postListingHighestBid(data, nft.tokenId));
    }
  };

  const handleChangeInputPrice = (e) => {
    if (e.target.value.length > 6) {
      setInputPrice(+parseFloat(e.target.value).toFixed(6));
    } else {
      setInputPrice(e.target.value);
    }
  };
  // HANDLE

  // __________________________________________________CONTENT UI__________________________________________________
  const CompleteListingDone = (props) => {
    const { InputPrice } = useSelector((state) => state.AutionReducer);
    return (
      <div className="loadingCancelSuccess">
        <ModalInfoNFT
          nft={nft}
          InputPrice={InputPrice}
          currency={saleMethod === "FIXED_PRICE" ? "STC" : "STF"}
        />
        <p>{t("TEXT.LISTING_NFT_SUCCESSFULLY", { ns: "nft" })}</p>
        {/* <StanButton
          title={t("BUTTON.VIEW_ITEM")}
          type="primary"
          handleOnClick={() => window.open("/nft/" + nft.id)}
        /> */}
        <StanButton
          title={t("BUTTON.VIEW_TRANSACTION")}
          type="primary"
          handleOnClick={() => window.open(NETWORK_URL + "tx/" + txtHash)}
        />
      </div>
    );
  };

  const CompleteListing = (props) => {
    const { InputPrice } = useSelector((state) => state.AutionReducer);
    const { Panel } = Collapse;
    const onChange = (key) => {
      console.log(key);
    };
    return (
      <Fragment>
        <div className="CompleteListingHead">
          <ModalInfoNFT
            nft={nft}
            InputPrice={InputPrice}
            currency={saleMethod === "FIXED_PRICE" ? "STC" : "STF"}
          />
          <div className="CompleteListingBody">
            <Collapse
              bordered={false}
              onChange={onChange}
              ghost
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? -90 : 90} />
              )}
            >
              <Panel
                className="CompleteListingPanel"
                header={
                  <div className="CompleteListingStep">
                    <CheckCircleOutlined className="CompleteListingCheck" />
                    <h4>{t("TEXT.APPROVE_WETH", { ns: "nft" })}</h4>
                  </div>
                }
                key="1"
              >
                <h4>{t("TEXT.APPROVE_WETH_DESCRIPTION", { ns: "nft" })}</h4>
              </Panel>
              <Panel
                className="CompleteListingPanel"
                header={
                  <div className="CompleteListingStep">
                    {nftStatus === PROCESSING ? (
                      <CheckCircleOutlined className="CompleteListingCheck" />
                    ) : (
                      <div className="spinner loading"> 2 </div>
                    )}
                    <h4>{t("TEXT.CONFIRM_MAKE_OFFER", { ns: "nft" })}</h4>
                  </div>
                }
                key="2"
              >
                <h4>
                  {t("TEXT.ACCEPT_SIGNATURE_REQUEST_LISTING", { ns: "nft" })}
                </h4>
              </Panel>
            </Collapse>
          </div>
        </div>
      </Fragment>
    );
  };

  // __________________________________________________CONTENT UI__________________________________________________

  const RenderContent = useMemo(() => {
    switch (nftStatus) {
      case PROCESSING:
        return <CompleteListing nft={nft} />;
      case COMPLETE_REQUEST:
        return <CompleteListingDone nft={nft} />;
      case INSUFFICENT_FUND:
        return (
          <div className="completeTransferNFT d-flex flex-column justify-content-center align-items-center">
            <img
              alt=""
              src="/img/insufficent-fund.png"
              width={150}
              height={150}
            />
            <p>{t("TEXT.INSUFFICIENT_FUND", { ns: "nft" })}</p>
            <StanButton
              title={t("BUTTON.RECHARGE")}
              handleOnClick={() => {
                dispatch({ type: OPEN_DEPOSIT });
              }}
              type="primary"
            />
            <StanButton
              title={t("BUTTON.CANCEL")}
              handleOnClick={() => {
                onClose();
              }}
              type="secondary"
            />
          </div>
        );
      default:
        return (
          <>
            <div>
              <div>
                <span>{t("TEXT.SALE_METHOD", { ns: "nft" })}</span>
                <Select
                  className="saleMethod"
                  onChange={handleChangeMethod}
                  notFoundContent={null}
                  defaultValue="FIXED_PRICE"
                  bordered={false}
                >
                  <Option value="FIXED_PRICE">
                    {t("SALE_METHOD_OPTIONS.FIXED_PRICE", { ns: "nft" })}
                  </Option>
                  <Option value="HIGHEST_BID">
                    {t("SALE_METHOD_OPTIONS.HIGHEST_BID", { ns: "nft" })}
                  </Option>
                </Select>
              </div>

              <div style={{ margin: "30px 0" }}>
                {saleMethod === "FIXED_PRICE" ? (
                  <>
                    <p>{t("TEXT.NFT_PRICE", { ns: "nft" })}</p>
                    <Input
                      className="saleMethodFixPrice"
                      placeholder="00.00 "
                      maxLength={11}
                      onChange={handleChangeInputPrice}
                      // suffix={<InputCurrency />}
                      value={inputPrice}
                      type="number"
                    />
                    <span style={{ color: "red" }}>{error}</span>
                    <span>
                      Min:{" "}
                      {/* {cryptoOnPoint(
                        MINIMUM_VALUE_OF_POINT,
                        ratePoint,
                        rateCrypto
                      ) || 0} */}
                      {minCryptoPrice || 0}
                    </span>
                  </>
                ) : (
                  <Fragment>
                    {/* <div>
                      <span>{t("TEXT.METHOD", { ns: "nft" })}</span>
                      <Select
                        className="saleMethodAuction"
                        defaultActiveFirstOption={false}
                        filterOption={false}
                        notFoundContent={null}
                        defaultValue="fixedPrice"
                        bordered={false}
                      >
                        <Option value="fixedPrice">
                          {t("METHOD_OPTIONS.THE_HIGHEST_PRICE", { ns: "nft" })}
                        </Option>
                      </Select>
                    </div> */}

                    <div style={{ marginTop: 30 }}>
                      <span>{t("TEXT.STARTING_PRICE", { ns: "nft" })}</span>
                      <Input
                        placeholder="00.00"
                        // Ticket 578 (25/10/2022)
                        // onKeyPress={(e) => preventDecimal(e)}
                        maxLength={11}
                        type="number"
                        onChange={handleChangeInputPrice}
                        value={inputPrice}
                        // suffix={<InputCurrency />}
                        className="listingStartPrice"
                      />
                      <span>
                        Min:{" "}
                        {/* {cryptoOnPoint(
                          MINIMUM_VALUE_OF_POINT,
                          ratePoint,
                          rateCrypto
                        ) || 0} */}
                        {minCryptoPrice || 0}
                      </span>
                    </div>
                  </Fragment>
                )}
              </div>

              {saleMethod === "HIGHEST_BID" ? (
                <div style={{ marginTop: 30 }}>
                  <span>{t("TEXT.EXPIRATION_PERIOD", { ns: "nft" })}</span>
                  <Select
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onChange={(e) => setExpirationPeriod(e)}
                    notFoundContent={null}
                    defaultValue={7}
                    className="expirationPeriod"
                    bordered={false}
                  >
                    {InputExpirationPeriod}
                  </Select>
                </div>
              ) : null}
            </div>
            {saleMethod === "HIGHEST_BID" ? (
              <div className="d-flex flex-column">
                <span>{t("TITLE.FEE_SUMMARY", { ns: "nft" })}</span>
                <div className="d-flex justify-content-between mt-3">
                  <span>{t("TITLE.AUCTION_LISTING", { ns: "nft" })}</span>
                  <span>
                    <MaticIcon />
                    {fromEtherToValue(stanFixed?.feeListingNFTAuction)}
                  </span>
                </div>
                <StanTypo
                  fontSize={14}
                  fontWeight={400}
                  color="#939393"
                  content={t("TEXT.AUCTION_LISTING_DESC", { ns: "nft" })}
                />
                <div className="d-flex justify-content-between mt-3">
                  <span>{t("TITLE.AUCTION_FINISH", { ns: "nft" })}</span>
                  {stanFixed && stanRoyalty && stanServiceFee ? (
                    <span>
                      {+stanServiceFee?.ratioFinishAuctionVal +
                        +nft?.creatorRoyalty +
                        +stanRoyalty.ratioStanVal}
                      %
                    </span>
                  ) : null}
                </div>
                <StanTypo
                  fontSize={14}
                  fontWeight={400}
                  color="#939393"
                  content={t("TEXT.AUCTION_FINISH_DESC", { ns: "nft" })}
                />
              </div>
            ) : (
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-between">
                  <span>
                    {t("TITLE.FIXED_PRICE_FINISH", { ns: "nft" }).toUpperCase()}
                  </span>
                  {!_.isEmpty(stanFixed) && !_.isEmpty(stanServiceFee) ? (
                    <span>
                      {+nft?.creatorRoyalty +
                        +stanRoyalty?.ratioStanVal +
                        +stanServiceFee?.ratioBuyFixedPriceVal}
                      %
                    </span>
                  ) : (
                    <Spin />
                  )}
                </div>
                <StanTypo
                  fontSize={14}
                  fontWeight={400}
                  color="#939393"
                  content={t("TEXT.FIXED_PRICE_FINISH_DESC", { ns: "nft" })}
                />
              </div>
            )}
            <div className="mt-3 mb-3">
              <ModalInfoNFT nft={nft} InputPrice={inputPrice} />
            </div>
            <div className="ButtonListing">
              <StanButton
                handleOnClick={handlePostListing}
                title={t("BUTTON.POST_LISTING")}
                disabled={nftStatus === REQUESTING}
                hidden={!inputPrice}
                type="primary"
              />
            </div>
          </>
        );
    }
  }, [
    nft,
    nftStatus,
    saleMethod,
    inputPrice,
    expirationPeriod,
    stanFixed,
    stanRoyalty,
    stanServiceFee,
    t,
  ]);

  return <>{RenderContent}</>;
}
