import { CaretRightOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { Collapse, Input } from "antd";
import React, { Fragment, useEffect, useState } from "react";

import { t } from "i18next";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MaticIcon } from "../../../common/commonSvg";
import ModalInfoNFT from "../../../component/ModalInfoNFT/ModalInfoNFT";
import StanButton from "../../../component/StanButton/StanButton";
import {
  getBalanceOfFan,
  getStanFixed,
  placeBid,
  reOffer,
} from "../../../redux/action/AutionAction";
import { OPEN_DEPOSIT } from "../../../redux/types/ModalType";
import {
  COMPLETE_REQUEST,
  INSUFFICENT_FUND,
  PROCESSING,
  REQUESTING,
} from "../../../redux/types/UserType";
import { NETWORK_URL } from "../../../util/settings/config";
import {
  formatStanCryptoDetail,
  formatedCash,
  fromEtherToValue,
  usdOnCrypto,
} from "../../../util/settings/utils";
import "./PlaceBidNFT.css";
export default function PlaceBidNFT(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "nft", "components", "collection"]);
  const [inputPrice, setInputPrice] = useState(0);
  const [error, setError] = useState("");
  const [currentHighestBid, setCurrentHighestBid] = useState();
  const { balanceOfFan, nftStatus, stanFixed, txtHash } = useSelector(
    (state) => state.AutionReducer
  );
  const { transactionList } = useSelector((state) => state.NFTReducer);
  const { payload, onClose } = useSelector((state) => state.ModalReducer);
  const { rateCrypto } = useSelector((state) => state.UserReducer);

  const { nft } = payload;

  useEffect(() => {
    dispatch(getBalanceOfFan());
    dispatch(getStanFixed());
    // CHECK HIGHEST BID ACTIVE
    // const offersActive = transactionList.filter(
    //   (e) => e.type === "OFFER" && e.status === "ACTIVE"
    // );
    // if (offersActive.length !== 0) {
    //   const highestBid = Math.max.apply(
    //     Math,
    //     offersActive.map(function (offer) {
    //       return offer.price;
    //     })
    //   );
    //   setCurrentHighestBid(highestBid);
    // } else {
    //   setCurrentHighestBid(nft.price || nft.priceCrypto);
    // }
    setCurrentHighestBid(nft.price || nft.priceCrypto);
  }, [transactionList]);

  const handleChangeInputPrice = (e) => {
    if (e.target.value.length > 6) {
      setInputPrice(+parseFloat(e.target.value).toFixed(6));
    } else {
      setInputPrice(e.target.value);
    }
  };

  function renderContent() {
    switch (nftStatus) {
      case COMPLETE_REQUEST:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <img alt="" src="/img/Confetti.png" width={150} height={150} /> */}
            <ModalInfoNFT nft={nft} InputPrice={inputPrice} />
            <span className="m-3">{t("MSG.PLACE_BID_NFT_SUCCESSFULLY")} </span>
            {/* <StanButton
              title="Done"
              handleOnClick={() => {
                onClose();
              }}
              type="primary"
            /> */}
            <StanButton
              disabled={!txtHash}
              title={t("BUTTON.VIEW_TRANSACTION")}
              type="primary"
              handleOnClick={() => window.open(NETWORK_URL + "tx/" + txtHash)}
            />
          </div>
        );
      case PROCESSING:
        return <CompletePlaceBidNFT nft={nft} inputPrice={inputPrice} />;
      case INSUFFICENT_FUND:
        return (
          <div className="completeTransferNFT">
            <img src="/img/insufficent-fund.png" width={150} height={150} />
            <p>{t("TEXT.INSUFFICIENT_FUND", { ns: "nft" })}</p>
            <StanButton
              title={t("BUTTON.RECHARGE")}
              handleOnClick={() => {
                dispatch({ type: OPEN_DEPOSIT });
              }}
              type="primary"
            />
            <StanButton
              title={t("BUTTON.CANCEL")}
              handleOnClick={() => {
                onClose();
              }}
              type="secondary"
            />
          </div>
        );
      default:
        return (
          <div>
            <div className="PlaceBidPrice">
              <div className="currentHighestBid">
                <span>{t("TEXT.CURRENT_HIGHEST_BID", { ns: "nft" })}</span>
                <span>
                  <MaticIcon /> {currentHighestBid}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "10px 0px",
                }}
              >
                <span>{t("TEXT.NFT_PRICE", { ns: "nft" })}</span>
                {inputPrice === 0 ? null : (
                  <span>
                    ${formatedCash(usdOnCrypto(inputPrice, rateCrypto))}
                  </span>
                )}
              </div>
              <Input
                maxLength={11}
                type="number"
                onChange={handleChangeInputPrice}
                value={inputPrice}
                placeholder="00.00"
                // onKeyPress={(e) => preventDecimal(e)}
                suffix={
                  <>
                    <MaticIcon />
                  </>
                }
              />
              <p style={{ color: "red" }}>{error}</p>

              <div className="PlaceBidPriceBalance">
                <span style={{ fontSize: "14px" }}>
                  {t("TEXT.BALANCE", { ns: "nft" })} <MaticIcon />
                  {formatStanCryptoDetail(balanceOfFan)}{" "}
                </span>

                {+inputPrice > +balanceOfFan ? (
                  <p style={{ color: "red" }}>
                    {t("MSG.NOT_ENOUGH_STF_TO_PLACE_BID")}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <span>{t("TEXT.SERVICE_FEE", { ns: "nft" }).toUpperCase()}</span>
              <span>
                <MaticIcon /> {fromEtherToValue(stanFixed?.feePlaceBidAuction)}
              </span>
            </div>
            <div>
              <div style={{ margin: "10px 0px" }}>
                <StanButton
                  title={t("BUTTON.PLACE_BID")}
                  handleOnClick={handlePlaceBidNFT}
                  disabled={
                    +inputPrice > +balanceOfFan ||
                    nftStatus === REQUESTING ||
                    nftStatus === PROCESSING
                      ? "disabled"
                      : null
                  }
                  type="primary"
                ></StanButton>
              </div>
              <div style={{ margin: "10px 0px" }}>
                <StanButton
                  title={t("BUTTON.ADD_FUNDS", { ns: "common" })}
                  handleOnClick={() => dispatch({ type: OPEN_DEPOSIT })}
                  type="secondary"
                ></StanButton>
              </div>
            </div>
          </div>
        );
    }
  }

  const handlePlaceBidNFT = () => {
    const Auction = transactionList.find(
      (e) => e.type === "AUCTION" && e.status === "ACTIVE"
    );
    const existedOffer = transactionList.find(
      (offer) =>
        offer.type === "OFFER" &&
        offer.status === "ACTIVE" &&
        offer.createdBy === Cookies.get("UserAddress")
    );
    if (inputPrice <= currentHighestBid) {
      setError(t("MSG.OFER_LARGER_THAN_CURRENT_BID"));
      return;
    } else {
      setError("");
      if (existedOffer) {
        console.log("RE OFFER");
        dispatch(
          reOffer({
            auctionId: existedOffer.auctionId,
            offerId: existedOffer.id,
            price: +inputPrice,
          })
        );
      } else {
        dispatch(
          placeBid(
            {
              id: Auction.id,
              sellerWalletAddress: nft.ownerAddress,
              buyerWalletAddress: Cookies.get("UserAddress"),
              price: +inputPrice,
              currency: "POLYGON",
              expirationPeriod: 5, // Only for testing (21/07/2022) [mins]
              isAuction: true,
            },
            nft.tokenId,
            Auction.endDate
          )
        );
      }
    }
  };

  return renderContent();
}

export function CompletePlaceBidNFT(props) {
  const { balanceOfFan, nftStatus, InputPrice } = useSelector(
    (state) => state.AutionReducer
  );

  const { inputPrice } = props;

  const { Panel } = Collapse;
  const onChange = (key) => {
    console.log(key);
  };
  return (
    <Fragment>
      <div className="CompleteListingHead">
        <ModalInfoNFT nft={props.nft} InputPrice={inputPrice} />
      </div>
      <div className="CompleteListingBody">
        <Collapse
          bordered={false}
          onChange={onChange}
          ghost
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? -90 : 90} />
          )}
        >
          <Panel
            className="CompleteListingPanel"
            header={
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleOutlined
                    style={{
                      borderRadius: "50%",
                      background: "#49D19A",
                      marginRight: 10,
                      fontSize: "16px",
                    }}
                  />
                  <h4>{t("TEXT.APPROVE_CRYPTO", { ns: "nft" })} </h4>
                </div>
              </>
            }
            key="1"
          >
            <h4>{t("TEXT.APPROVE_WETH_DESCRIPTION", { ns: "nft" })}</h4>
          </Panel>
          <Panel
            className="CompleteListingPanel"
            header={
              <div style={{ display: "flex" }}>
                {nftStatus === PROCESSING ? (
                  <CheckCircleOutlined
                    style={{
                      borderRadius: "50%",
                      background: "#49D19A",
                      marginRight: 10,
                      fontSize: "16px",
                    }}
                  />
                ) : (
                  <div className="spinner loading"> 2 </div>
                )}
                <h4>{t("TEXT.CONFIRM_MAKE_OFFER", { ns: "nft" })}</h4>
              </div>
            }
            key="2"
          >
            <h4>
              {t("TEXT.ACCEPT_SIGNATURE_REQUEST_MAKING_OFFER", { ns: "nft" })}
            </h4>
          </Panel>
        </Collapse>
      </div>
    </Fragment>
  );
}
