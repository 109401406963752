import {
  CLAIM_NFT,
  DEPOSIT_NFT,
  GET_ACTIVITIES,
  GET_ARTIST_LATEST_NFT,
  GET_COIN_PRICE,
  GET_DETAIL_NFT,
  GET_LATEST_SALE,
  GET_LIST_CATEGORIES,
  GET_LIST_FIXED_PRICE_NFTS,
  GET_POSITION_SECTION,
  SAVE_PROPERTIES,
  SEARCH_NFT,
  SEARCH_NFT_HEADER,
  SHUFFLE_NFT,
} from "../types/NFTType";
import { CLEAR_STATE } from "../types/UserType";

const initialState = {
  listNFT: [],
  totalNFT: "",
  nft: {},
  user: {},
  properties: [],
  coinPrice: 0,
  transactionList: [],
  activitiesList: [],
  totalActivities: 0,
  txtHash: "",
  listNFTHeader: [],
  nftShuffle: [],
  artistLatestNFTs: [],
  totalArtistLatestNFTs: 0,
  latestSale: [],
  totalLatestSale: 0,
  positionSection: [],
  listCategories: [],
  listFixedPriceNFT: [],
  totalFixedPriceNFT: 0,
};

export const NFTReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_NFT:
      return { ...state, listNFT: action.listNFT, totalNFT: action.totalNFT };
    case GET_DETAIL_NFT:
      return {
        ...state,
        nft: action.nft,
        transactionList: action.transactionList,
        user: action.user,
      };
    case SAVE_PROPERTIES:
      return {
        ...state,
        properties: action.properties,
      };
    case GET_COIN_PRICE:
      return {
        ...state,
        coinPrice: action.data,
      };
    case GET_ACTIVITIES:
      return {
        ...state,
        activitiesList: action.activities,
        totalActivities: action.totalActivities,
      };
    case DEPOSIT_NFT:
      return {
        ...state,
        txtHash: action.txtHash,
      };
    case CLAIM_NFT:
      return {
        ...state,
        txtHash: action.txtHash,
      };
    case SEARCH_NFT_HEADER:
      return { ...state, listNFTHeader: action.listNFT };
    case SHUFFLE_NFT:
      return { ...state, nftShuffle: action.nftShuffle };
    case GET_ARTIST_LATEST_NFT:
      return {
        ...state,
        artistLatestNFTs: action.artistLatestNFTs,
        totalArtistLatestNFTs: action.totalArtistLatestNFTs,
      };
    case GET_LATEST_SALE:
      return {
        ...state,
        latestSale: action.latestSale,
        totalLatestSale: action.totalLatestSale,
      };
    case GET_POSITION_SECTION:
      return {
        ...state,
        positionSection: action.positionSection,
      };
    case GET_LIST_CATEGORIES:
      return {
        ...state,
        listCategories: action.listCategories,
      };
    case GET_LIST_FIXED_PRICE_NFTS:
      return {
        ...state,
        listFixedPriceNFT: action.listFixedPriceNFT,
        totalFixedPriceNFT: action.totalFixedPriceNFT,
      };
    case CLEAR_STATE:
      return (state = initialState);
    default:
      return state;
  }
};
