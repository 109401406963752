import { ethers } from "ethers";
import Cookies from "js-cookie";
import Web3 from "web3";
import {
  ABI_NFT_MAINNET,
  ABI_NFT_MAINNET_STAGING,
  ABI_NFT_TESTNET,
} from "../../common/ABIs";
import { PRIORITY_FEE } from "../../common/commonParams";
import { userService } from "../../services/UserService";
import {
  AUCTION_FIXED_PRICE,
  AUCTION_PURE,
  CHAIN_ID,
  CHAIN_NAME,
  CONTRACT_ADDRESS_NFT,
  DEEP_LINK_URL,
  NETWORK_URL,
  stagingEnvironment,
} from "../../util/settings/config";
import { CLOSE_CONFIRM_MODAL, CLOSE_CONNECT_WALLET } from "../types/ModalType";
import { PENDING_REQUEST, PROCESSING, REQUESTING } from "../types/UserType";
import { getMetamaskMessage } from "./UserAction";

const ethereum = window?.ethereum;

const Web3Client = new Web3(ethereum);
const nft_contract = new Web3Client.eth.Contract(
  CHAIN_ID === "137"
    ? stagingEnvironment
      ? ABI_NFT_MAINNET_STAGING
      : ABI_NFT_MAINNET
    : ABI_NFT_TESTNET,
  CONTRACT_ADDRESS_NFT
);

export const checkWalletIsConnected = () => {
  return async (dispatch) => {
    if (!ethereum) {
      alert("Please continue using Metamask extension!");
      window.location.href = DEEP_LINK_URL;
      return;
    }
    try {
      await window.ethereum
        .request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(CHAIN_ID) }],
        })
        .then((res) => dispatch(connectWallet()));
    } catch (error) {
      console.log("error check", error);
      // IF USER IS USING DIFFERENT NETWORK OR HAVEN'T ADD NETWORK
      if (error.code === 4902 || error.code === -32603) {
        dispatch(addNetwork());
      }
    }
  };
};

export const addNetwork = () => {
  return async (dispatch) => {
    try {
      await window.ethereum.request({
        id: 1,
        jsonrpc: "2.0",
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: Web3.utils.toHex(CHAIN_ID),
            chainName: CHAIN_NAME,
            nativeCurrency: {
              name: "Matic",
              symbol: "MATIC",
              decimals: 18,
            },
            blockExplorerUrls: [NETWORK_URL],
            rpcUrls: [await getRpcUrl()],
          },
        ],
      });
      // .then(dispatch(connectWallet()));
    } catch (error) {
      console.log("ADD NETWORK Error: ", error);
    }
  };
};

export const connectWallet = () => {
  return async (dispatch) => {
    try {
      await ethereum
        .request({
          method: "eth_requestAccounts",
        })
        .then((accounts) => {
          dispatch(getMetamaskMessage(accounts[0]));
          dispatch({ type: CLOSE_CONNECT_WALLET });
        });
    } catch (error) {
      console.log("CONNECT WALLET ERROR: ", error);
    }
  };
};

export const grantAccess = () => {
  return async (dispatch) => {
    dispatch({ type: REQUESTING });
    try {
      await nft_contract.methods
        .approveForAll(AUCTION_FIXED_PRICE, AUCTION_PURE, true)
        .send(
          CHAIN_ID === "137"
            ? {
                from: Cookies.get("UserAddress"),
                maxPriorityFeePerGas: ethers.BigNumber.from(PRIORITY_FEE),
              }
            : { from: Cookies.get("UserAddress") }
        )
        .on("transactionHash", async function (hash) {
          dispatch({ type: PROCESSING });
        })
        .on("receipt", async function (receipt) {
          dispatch({ type: CLOSE_CONFIRM_MODAL });
        });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

export const isApprovedForAll = async () => {
  let isApproved;
  if (Cookies.get("UserAddress")) {
    try {
      await nft_contract.methods
        .isApprovedForAll(Cookies.get("UserAddress"), AUCTION_PURE)
        .call({ from: Cookies.get("UserAddress") })
        .then(async (result) => {
          isApproved = result;
        });
      return isApproved;
    } catch (error) {
      console.log(error);
    }
  }
};

//_______ GET RPC URL _________//
export const getRpcUrl = async () => {
  let rpcUrl = "";
  try {
    const result = await userService.getRpcUrl();
    if (result && result.data.status === "success") {
      rpcUrl = result.data.data.url;
    }
    return rpcUrl;
  } catch (error) {
    console.log(error);
  }
};
