import { Empty, Select, Spin } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../App";
import { MaticIcon, stanTokenSvg, WETHIcon } from "../../common/commonSvg";
import { searchCollectionHeader } from "../../redux/action/CollectionAction";
import { searchNFTHeader } from "../../redux/action/NftAction";
import { getListArtistHeader } from "../../redux/action/UserAction";
import { REQUESTING } from "../../redux/types/UserType";
import { formatStanCryptoDetail } from "../../util/settings/utils";
import "./StanSearchInput.css";

const { Option, OptGroup } = Select;
export default function StanSearchInput(props) {
  const dispatch = useDispatch();
  const [inputSearch, setInputSearch] = useState();
  const { t, i18n } = useTranslation(["components"]);
  const { listCollectionHeader } = useSelector(
    (state) => state.CollectionReducer
  );
  const { listNFTHeader } = useSelector((state) => state.NFTReducer);
  const { listArtistHeader } = useSelector((state) => state.UserReducer);
  const { nftStatus } = useSelector((state) => state.AutionReducer);

  // HANDLE FUNCTIONS
  function handleKeyPress(event) {
    if (event.key === "Enter") {
      if (inputSearch.trim() !== "") {
        history.push("/search/" + inputSearch.trim());
      } else if (!inputSearch || inputSearch.trim() === "") {
        history.push("/explore");
      }
    }
  }

  const handleSearch = (newValue) => {
    setInputSearch(newValue.trim() === "" ? undefined : newValue);
    if (newValue.length >= 3) {
      dispatch(
        searchCollectionHeader(4, 1, null, encodeURIComponent(newValue))
      );
      dispatch(getListArtistHeader(4, 1, encodeURIComponent(newValue), "", 1));
      dispatch(searchNFTHeader({ name: newValue }, 4, 1));
    }
  };

  const handleChange = (selectedResult, option) => {
    if (selectedResult === "default") {
      history.push("/search/" + inputSearch.trim());
    } else {
      if (option.type === "COLLECTION") {
        history.push("/collectionDetail/" + option.key);
      } else if (option.type === "ITEM") {
        history.push("/nft/" + option.key);
      } else if (option.type === "USER") {
        history.push("/profile/" + option.value);
      }
    }
  };
  // HANDLE FUNCTIONS

  // OPTION GROUP AND ITEMS
  const CollectionOptGroup = useMemo(() => {
    if (listCollectionHeader.length !== 0) {
      return (
        <OptGroup
          className="inputSearchHeader-group"
          label={t("TEXT.COLLECTIONS")}
        >
          {listCollectionHeader.map((collection) => {
            return (
              <Option
                value={collection.name}
                key={collection.id}
                type="COLLECTION"
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="inputSearchHeader-image mr-3">
                      <img alt="" src={collection.imageLogo}></img>
                    </div>
                    <div className="inputSearchHeader-name d-flex flex-column">
                      <span>{collection.name}</span>
                      <span>{collection.currentNumberNft} items</span>
                    </div>
                  </div>
                  {collection.totalVolume !== null ? (
                    <div className="d-flex">
                      <span style={{ color: "#17875A" }}>
                        {formatStanCryptoDetail(collection.totalVolume)} WETH
                      </span>
                    </div>
                  ) : null}
                </div>
              </Option>
            );
          })}
        </OptGroup>
      );
    } else {
      return null;
    }
  }, [listCollectionHeader]);

  const ItemOptGroup = useMemo(() => {
    if (listNFTHeader.length !== 0) {
      return (
        <OptGroup label={t("TEXT.ITEMS")}>
          {listNFTHeader.map((nft) => {
            return (
              <Option key={nft.id} value={nft.name} type="ITEM">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="inputSearchHeader-image mr-3">
                      <img
                        alt=""
                        src={
                          nft.mediaType === "VIDEO"
                            ? nft.mediaPreviewUrl
                            : nft.mediaUrl
                        }
                      ></img>
                    </div>
                    <div className="inputSearchHeader-name">
                      <span>{nft.name}</span>
                    </div>
                  </div>
                  {nft.priceCrypto > 0 ? (
                    <div className="d-flex">
                      <span style={{ color: "#17875A" }}>
                        {formatStanCryptoDetail(nft.priceCrypto)} <MaticIcon />
                        {/* {nft.status === "BUY" ? (
                          <img alt="" src="/svg/StanPoint.svg" />
                        ) : (
                          stanTokenSvg()
                        )} */}
                      </span>
                    </div>
                  ) : null}
                </div>
              </Option>
            );
          })}
        </OptGroup>
      );
    } else {
      return null;
    }
  }, [listNFTHeader]);

  const ArtistOptGroup = useMemo(() => {
    if (listArtistHeader.length !== 0) {
      return (
        <OptGroup label={t("TEXT.USERS")}>
          {listArtistHeader.map((artist) => {
            return (
              <Option key={artist.id} value={artist.walletAddress} type="USER">
                <div className="d-flex align-items-center">
                  <div className="inputSearchHeader-image mr-3">
                    <img alt="" src={artist.avatar}></img>
                  </div>
                  <div className="inputSearchHeader-name">
                    <span>{artist.name}</span>
                  </div>
                </div>
              </Option>
            );
          })}
        </OptGroup>
      );
    } else {
      return null;
    }
  }, [listArtistHeader]);
  // OPTION GROUP AND ITEMS

  // UI RENDER
  return (
    <Select
      showSearch
      value={null}
      placeholder={props.placeholder}
      style={props.style}
      className="inputSearchHeader"
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      onKeyDown={handleKeyPress}
      notFoundContent={null}
      dropdownAlign={{
        offset: [0, 30],
      }}
      dropdownStyle={{ borderRadius: "16px" }}
    >
      {nftStatus === REQUESTING ? (
        <>
          <Option key="loading">
            <Spin /> Loading...
          </Option>
        </>
      ) : (
        <>
          {CollectionOptGroup}
          {ItemOptGroup}
          {ArtistOptGroup}
          {listCollectionHeader.length === 0 &&
          listNFTHeader.length === 0 &&
          listArtistHeader.length === 0 ? (
            <Option key="notFound">
              <Empty description="Not found" />
            </Option>
          ) : (
            <Option key="default">{t("TEXT.PRESS_ENTER")}</Option>
          )}
        </>
      )}
    </Select>
  );
}
