import { notification } from "antd";
import { createBrowserHistory } from "history";
import Cookies from "js-cookie";
import _ from "lodash";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Router, Switch, generatePath } from "react-router-dom";
import { io } from "socket.io-client";
import "./App.css";
import { COMMON_PATH, EXPLORE_PATH } from "./common/Constant";
import StanConfirmDialog from "./component/StanConfirmDialog/StanConfirmDialog";
import StanModal from "./component/StanModal/StanModal";
import ArtistLatestNFT from "./pages/ArtistLatestNFT/ArtistLatestNFT";
import Category from "./pages/Category/Category";
import CollectionDetail from "./pages/CollectionDetail/CollectionDetail";
import CreateCollection from "./pages/Collections/CreateCollection";
import EditCollection from "./pages/Collections/EditCollection";
import Profile from "./pages/Collections/Profile";
import ConnectWallet from "./pages/ConnectWallet/ConnectWallet";
import Explore from "./pages/Explore/Explore";
import Hashtag from "./pages/Hashtag/Hashtag";
import Home from "./pages/Home/Home";
import HotCollections from "./pages/HotCollections/HotCollections";
import HotOffers from "./pages/HotOffers/HotOffers";
import LatestSale from "./pages/LatestSale/LatestSale";
import ListOfOwners from "./pages/ListOfOwners/ListOfOwners";
import ListOfOwnersInCollection from "./pages/ListOfOwnersInCollection/ListOfOwnersInCollection";
import LiveAuction from "./pages/LiveAuction/LiveAuction";
import AcceptOffer from "./pages/Nft/AcceptOffer/AcceptOffer";
import BuyNFT from "./pages/Nft/BuyNFT/BuyNFT";
import CancelListing from "./pages/Nft/CancelListing/CancelListing";
import CancelOffer from "./pages/Nft/CancelOffer/CancelOffer";
import ClaimNFT from "./pages/Nft/ClaimNFT/ClaimNFT";
import CreateNFT from "./pages/Nft/CreateNFT/CreateNFT";
import DepositNFT from "./pages/Nft/DepositNFT/DepositNFT";
import MakeOffer from "./pages/Nft/MakeOffer/MakeOffer";
import PlaceBidNFT from "./pages/Nft/PlaceBidNFT/PlaceBidNFT";
import ListingNFT from "./pages/Nft/SellNFT/ListingNft";
import TransferNft from "./pages/Nft/TransferNFT/TransferNft";
import NftDetail from "./pages/NftDetail/NftDetail";
import QRAuthentication from "./pages/QRAuthentication/QRAuthen";
import SearchPage from "./pages/SearchPage/SearchPage";
import { SOCKET_STATUS } from "./redux/types/UserType";
import { HomeTemplate } from "./template/HomeTemplate/HomeTemplate";
import { DOMAIN_WS, SOCKET_PATH } from "./util/settings/config";
import FixedPriceNFT from "./pages/FixedPriceNFT/FixedPriceNFT";
import Page404 from "./common/Page404";
import LoadingScreen from "./component/LoadingScreen/LoadingScreen";
require("dotenv").config();

export const history = createBrowserHistory();

export const openNotificationWithIcon = (type, mes, des, onClose) => {
  notification[type]({
    message: mes,
    description: des,
    onClose: onClose,
  });
};

function App() {
  const {
    openConnectWallet,
    openDepositNFT,
    openClaimNFT,
    openListingNFT,
    openCancelListingNFT,
    openMakeOffer,
    openPlaceBid,
    openBuyNow,
    openAcceptOffer,
    openCancelOffer,
    openTransferNFT,
    payload,
    onClose,
  } = useSelector((state) => state.ModalReducer);
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "nftDetail"]);

  // SCAN QR CODE CHECK
  useEffect(() => {
    if (Cookies.get("UserAddress") && _.isUndefined(Cookies.get("TokenId"))) {
      const socket = io(DOMAIN_WS, {
        auth: {
          token: Cookies.get("QRToken"),
        },
        path: SOCKET_PATH,
        transports: ["websocket"],
      });
      socket.on("connect", () => {
        console.log("CONNECTED SOCKET");
        io.connect(DOMAIN_WS, {
          forceNew: true,
          path: SOCKET_PATH,
          transports: ["websocket"],
        });
        dispatch({
          type: SOCKET_STATUS,
          socketStatus: "CONNECTED",
        });
      });
      socket.on("room.events", function (data) {
        if (data.payload && data.payload.data.token) {
          Cookies.set("TokenId", data.payload.data.token);
          Cookies.remove("SignedMessage");
          history.push(
            generatePath(EXPLORE_PATH.PROFILE, {
              walletAddress: Cookies.get("UserAddress"),
            })
          );
          socket.disconnect();
          window.location.reload();
        }
      });
    }
  }, []);

  return (
    <>
      {/* MODAL INITIAL */}
      <>
        <StanModal
          title={t("TITLE.CONNECT_WALLET")}
          width={800}
          content={<ConnectWallet payload={payload} />}
          visible={openConnectWallet}
          onClose={onClose}
        ></StanModal>
        <StanModal
          title={t("TITLE.DEPOSIT_NFT")}
          width={742}
          content={<DepositNFT payload={payload} />}
          visible={openDepositNFT}
          onClose={onClose}
        ></StanModal>
        <StanModal
          title={t("TITLE.CLAIM_NFT")}
          width={742}
          content={<ClaimNFT payload={payload} />}
          visible={openClaimNFT}
          onClose={onClose}
        ></StanModal>
        {/* _______________AUCTION___________ */}
        <StanModal
          title={t("TITLE.LIST_NFT", { ns: "nftDetail" })}
          content={<ListingNFT payload={payload} />}
          visible={openListingNFT}
          onClose={onClose}
        ></StanModal>
        <StanModal
          title={t("TITLE.CANCEL_LISTING", { ns: "nftDetail" })}
          width={800}
          content={<CancelListing payload={payload} />}
          visible={openCancelListingNFT}
          onClose={onClose}
        ></StanModal>
        <StanModal
          title={t("TITLE.MAKE_OFFER", { ns: "nftDetail" })}
          content={<MakeOffer payload={payload} />}
          visible={openMakeOffer}
          onClose={onClose}
        ></StanModal>
        <StanModal
          title={t("TITLE.BUY_NFT", { ns: "nftDetail" })}
          content={<BuyNFT payload={payload} />}
          visible={openBuyNow}
          onClose={onClose}
        ></StanModal>
        <StanModal
          title={t("TITLE.PLACE_BID", { ns: "nftDetail" })}
          content={<PlaceBidNFT payload={payload} />}
          visible={openPlaceBid}
          onClose={onClose}
        ></StanModal>
        <StanModal
          title={t("BUTTON.ACCEPT_OFFER", { ns: "common" })}
          width={1000}
          content={<AcceptOffer payload={payload} />}
          visible={openAcceptOffer}
          onClose={onClose}
        ></StanModal>
        <StanModal
          title={t("BUTTON.CANCEL_OFFER", { ns: "common" })}
          width={800}
          content={<CancelOffer payload={payload} />}
          visible={openCancelOffer}
          onClose={onClose}
        ></StanModal>
        <StanModal
          title={t("TITLE.TRANSFER", { ns: "nftDetail" })}
          width={766}
          content={<TransferNft payload={payload} />}
          visible={openTransferNFT}
          onClose={onClose}
        ></StanModal>
      </>
      {/* MODAL INITIAL */}

      <LoadingScreen></LoadingScreen>
      <StanConfirmDialog></StanConfirmDialog>
      <Helmet>
        <meta charSet="utf-8" />
        <title>STAN</title>
      </Helmet>
      <Router history={history}>
        <Switch>
          {/* <Route path={"/login"} exact component={Login} /> */}
          <HomeTemplate
            path={EXPLORE_PATH.CATEGORY}
            exact
            Component={Category}
          />
          <HomeTemplate path={EXPLORE_PATH.HASHTAG} exact Component={Hashtag} />
          <HomeTemplate
            path={EXPLORE_PATH.LIST_OF_OWNERS_IN_COLLECTION}
            exact
            Component={ListOfOwnersInCollection}
          />
          <HomeTemplate
            path={EXPLORE_PATH.LIST_OF_OWNERS}
            exact
            Component={ListOfOwners}
          />
          <HomeTemplate
            path={EXPLORE_PATH.SEARCH}
            exact
            Component={SearchPage}
          />
          <HomeTemplate
            path={EXPLORE_PATH.HOT_COLLECTIONS}
            exact
            Component={HotCollections}
          />
          <HomeTemplate
            path={EXPLORE_PATH.HOT_OFFERS}
            exact
            Component={HotOffers}
          />
          <HomeTemplate
            path={EXPLORE_PATH.LIVE_AUCTION}
            exact
            Component={LiveAuction}
          />
          <HomeTemplate
            path={EXPLORE_PATH.ARTIST_LATEST_NFT}
            exact
            Component={ArtistLatestNFT}
          />
          <HomeTemplate
            path={EXPLORE_PATH.LATEST_SALE}
            exact
            Component={LatestSale}
          />
          <HomeTemplate
            path={EXPLORE_PATH.FIXED_PRICE}
            exact
            Component={FixedPriceNFT}
          />
          {/* PROFILE */}
          <HomeTemplate path={EXPLORE_PATH.PROFILE} exact Component={Profile} />
          <HomeTemplate path={EXPLORE_PATH.EXPLORE} exact Component={Explore} />
          <HomeTemplate
            path={EXPLORE_PATH.COLLECTION_DETAIL}
            exact
            Component={CollectionDetail}
          />
          <HomeTemplate
            path={EXPLORE_PATH.CREATE_COLLECTION}
            exact
            Component={CreateCollection}
          />
          <HomeTemplate
            path={EXPLORE_PATH.EDIT_COLLECTION}
            exact
            Component={EditCollection}
          />
          <HomeTemplate
            path={EXPLORE_PATH.CREATE_NFT}
            exact
            Component={CreateNFT}
          />
          <HomeTemplate
            path={EXPLORE_PATH.NFT_DETAIL}
            exact
            Component={NftDetail}
          />
          <HomeTemplate
            path={EXPLORE_PATH.QR_AUTHENTICATION}
            exact
            Component={QRAuthentication}
          />
          <HomeTemplate
            path={COMMON_PATH.NOT_FOUND_404}
            exact
            Component={Page404}
          />
          <HomeTemplate path={EXPLORE_PATH.HOME} exact Component={Home} />
          <HomeTemplate path={EXPLORE_PATH.TOKEN} exact Component={Home} />
          <HomeTemplate path="*" exact Component={Home} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
