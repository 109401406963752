import { Image, Spin } from "antd";
import Cookies from "js-cookie";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import { history } from "../../App";
import {
  MaticIcon,
  StanPointIcon,
  verifiedSvg,
  WETHIcon,
} from "../../common/commonSvg";
import { getListOffer } from "../../redux/action/AutionAction";
import { detailNFT } from "../../redux/action/NftAction";
import {
  CLOSE_BUY_NOW,
  CLOSE_CANCEL_LISTING_NFT,
  CLOSE_LISTING_NFT,
  CLOSE_MAKE_OFFER,
  CLOSE_PLACE_BID,
  OPEN_BUY_NOW,
  OPEN_CANCEL_LISTING_NFT,
  OPEN_LISTING_NFT,
  OPEN_MAKE_OFFER,
  OPEN_PLACE_BID,
} from "../../redux/types/ModalType";
import { NFT_STATUS } from "../../redux/types/NFTType";
import {
  PENDING_REQUEST,
  PROCESSING,
  REQUESTING,
} from "../../redux/types/UserType";
import { TOKEN_COOKIE, WALLET_LOGGED_IN } from "../../util/settings/config";
import { formatStanCrypto, formatStanPoint } from "../../util/settings/utils";
import StanButton from "../StanButton/StanButton";
import StanTypo from "../StanTypo/StanTypo";
import "./StanArtistNFT.css";
import { MEDIA_TYPE } from "../../common/Constant";

export default function StanArtistNFT(props) {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const [loadedImage, setLoadedImage] = useState(false);
  const { nftStatus } = useSelector((state) => state.AutionReducer);
  const { transactionList } = useSelector((state) => state.NFTReducer);
  const { nft, searchNFT, type, homePage = false } = props;

  const handleViewDetailNft = () => {
    history.push(
      generatePath("/nft/:id", { id: nft.id || nft.nft_id || nft.nftId })
    );
  };

  function renderImageSrc(nft) {
    let imageSrc = nft.mediaUrl || nft.nftMediaUrl;
    if (nft.mediaType === MEDIA_TYPE.VIDEO) {
      imageSrc = nft.mediaPreviewUrl || nft.nftMediaPreviewUrl;
    }
    if (nft.mediaType === MEDIA_TYPE.AUDIO) {
      imageSrc = nft.mediaPreviewUrl;
    }
    if (nft.mediaType === MEDIA_TYPE.LIVESTREAM) {
      imageSrc = nft.mediaPreviewUrl;
    }
    return imageSrc;
  }

  const RenderButton = useMemo(() => {
    // 3B__IS OWNER__
    if (
      Cookies.get("UserAddress") &&
      nft.ownerAddress === Cookies.get("UserAddress")
    ) {
      if (nft.status === NFT_STATUS.SELLABLE) {
        return (
          <StanButton
            title={t("BUTTON.SELL_NOW")}
            type="primary"
            requireApprove
            handleOnClick={() => {
              dispatch({
                type: OPEN_LISTING_NFT,
                payload: { nft: nft },
                onClose: () => {
                  dispatch({ type: CLOSE_LISTING_NFT });
                  dispatch({ type: PENDING_REQUEST });
                  searchNFT();
                },
              });
            }}
            disabled={nftStatus !== PENDING_REQUEST}
          />
        );
      } else {
        return (
          <StanButton
            title={t("BUTTON.CANCEL")}
            type="stanBtnCancel"
            requireApprove
            handleOnClick={() => {
              dispatch({
                type: OPEN_CANCEL_LISTING_NFT,
                payload: { nft: nft },
                onClose: () => {
                  dispatch({ type: CLOSE_CANCEL_LISTING_NFT });
                  dispatch({ type: PENDING_REQUEST });
                  searchNFT();
                },
              });
              dispatch(detailNFT(nft.id));
            }}
            disabled={nftStatus === REQUESTING || nftStatus === PROCESSING}
          />
        );
      }
    }
    // 3C__IS VIEWER__
    else {
      if (nft.status === NFT_STATUS.SELLABLE) {
        return (
          <StanButton
            title={t("BUTTON.MAKE_OFFER")}
            type="primary"
            requireApprove
            handleOnClick={() => {
              dispatch(getListOffer(nft.id, 500, 1));
              dispatch({
                type: OPEN_MAKE_OFFER,
                payload: { nft: nft },
                onClose: () => {
                  dispatch({ type: CLOSE_MAKE_OFFER });
                  dispatch({ type: PENDING_REQUEST });
                  searchNFT();
                },
              });
            }}
            disabled={nftStatus !== PENDING_REQUEST}
          />
        );
      } else if (nft.status === NFT_STATUS.BUY) {
        return (
          <StanButton
            title={t("BUTTON.BUY_NOW")}
            type="primary"
            requireApprove
            handleOnClick={() => {
              dispatch(detailNFT(nft.id));
              dispatch({
                type: OPEN_BUY_NOW,
                payload: { nft: nft },
                onClose: () => {
                  dispatch({ type: CLOSE_BUY_NOW });
                  dispatch({ type: PENDING_REQUEST });
                  searchNFT();
                },
              });
            }}
            disabled={nftStatus !== PENDING_REQUEST}
          />
        );
      } else if (nft.status === NFT_STATUS.ON_BID) {
        return (
          <StanButton
            title={t("BUTTON.PLACE_BID")}
            type="primary"
            requireApprove
            handleOnClick={() => {
              dispatch(detailNFT(nft.id));
              dispatch({
                type: OPEN_PLACE_BID,
                payload: { nft: nft, transactionList: transactionList },
                onClose: () => {
                  dispatch({ type: CLOSE_PLACE_BID });
                  dispatch({ type: PENDING_REQUEST });
                  searchNFT();
                },
              });
            }}
            disabled={nftStatus !== PENDING_REQUEST}
          />
        );
      }
    }
    // __3.
  }, [nftStatus, WALLET_LOGGED_IN, TOKEN_COOKIE, nft, t]);

  return (
    <>
      <div
        className={
          type === "sliderItem" ? "StanArtistNFT-slider" : "StanArtistNFT"
        }
        id="StanArtistNFT"
      >
        <div className="StanArtistNFT-image">
          <div>
            <a
              href={process.env.REACT_APP_SITE_URL + "nft/" + nft.id}
              onClick={(e) => e.preventDefault()}
            >
              <Image
                width="100%"
                height="100%"
                onLoad={(e) => setLoadedImage(true)}
                fallback="https://www.salonlfc.com/wp-content/uploads/2018/01/image-not-found-scaled-1150x647.png"
                preview={false}
                onClick={() => handleViewDetailNft()}
                style={{ cursor: "pointer" }}
                src={renderImageSrc(nft)}
              />
              {!loadedImage && (
                <div className="loadingOverlayNFT">
                  <Spin
                    size="large"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                </div>
              )}
            </a>
          </div>
          <div className="StanArtistNFT-info">
            {loadedImage ? (
              <>
                <div
                  className={`NFT-name ${!homePage && "NFT-name-responsive"}`}
                >
                  <div
                    style={
                      (!nft.price || nft.price === 0) && homePage
                        ? { lineHeight: "75px" }
                        : null
                    }
                    className="ellipsisText"
                  >
                    <StanTypo
                      fontSize={20}
                      fontWeight={400}
                      color="#F6F6F6"
                      content={nft.name}
                    />
                  </div>
                </div>
                {nft.price && nft.price !== 0 ? (
                  <div className="NFT-price">
                    <div className="ellipsisText text-right">
                      {/* <WETHIcon /> */}
                      <MaticIcon />
                      <StanTypo
                        fontSize={20}
                        fontWeight={700}
                        color="#F6F6F6"
                        content={formatStanCrypto(nft.priceWithCrypto) || "--"}
                      />
                    </div>
                    <div className="ellipsisText text-right">
                      <StanPointIcon />
                      <StanTypo
                        fontSize={12}
                        fontWeight={400}
                        color="#F6F6F6"
                        content={formatStanPoint(nft.priceWithPoint) || "--"}
                      />
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
          <div
            id="StanArtistNFT-button"
            className="StanArtistNFT-button fade-in"
          >
            {/* <StanButton
              title={t("BUTTON.VIEW_ITEM")}
              type="primary"
              handleOnClick={() => {
                handleViewDetailNft();
              }}
            /> */}
            {RenderButton}
          </div>
        </div>
        <div className="StanArtistNFT-artist">
          <div className="artist-container">
            <div className="artist-avt">
              <a href={process.env.REACT_APP_SITE_URL + "nft/" + nft.id}>
                <Image
                  preview={false}
                  fallback="https://www.salonlfc.com/wp-content/uploads/2018/01/image-not-found-scaled-1150x647.png"
                  src={nft.owner?.avatar}
                />
              </a>
            </div>
            <div className="artist-name">
              <div className="ellipsisText">
                <StanTypo
                  fontSize={16}
                  fontWeight={700}
                  color="#000000"
                  content={nft.owner?.name}
                  onLoad={loadedImage}
                  loading={true}
                />
              </div>
              {nft.owner?.isFollowing === 1 ? (
                <div className="ml-1">{verifiedSvg()}</div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
