import { Empty, Input, Pagination } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import StanNFTCard from "../../component/StanNFTCard/StanNFTCard";
import { getLatestSale } from "../../redux/action/NftAction";
import "./LatestSale.css";
import StanNFTFilter from "../../component/StanNFTFilter/StanNFTFilter";

export default function LatestSale(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["common", "homePage", "explore"]);
  const [dataFilter, setDataFilter] = useState({});
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedPageSize, setSelectedPageSize] = useState(10);

  const { latestSale, totalLatestSale } = useSelector(
    (state) => state.NFTReducer
  );

  function resetOffset() {
    setSelectedPage(1);
    setSelectedPageSize(10);
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can change this to 'auto' for instant scrolling
    });
  }, []);

  useEffect(() => {
    resetOffset();
  }, [dataFilter]);

  useEffect(() => {
    dispatch(getLatestSale(dataFilter, selectedPageSize, selectedPage));
  }, [dataFilter, selectedPage, selectedPageSize]);

  return (
    <div style={{ minHeight: "100vh" }}>
      <div className="Explore-LatestSaleNFT">
        <div className="d-flex justify-content-center mt-5 mb-3">
          <h2>{t("LATEST_SALE", { ns: "homePage" })}</h2>
        </div>
        <StanNFTFilter
          showSearch
          showSort
          priceName="NFT"
          dataFilter={dataFilter}
          setDataFilter={setDataFilter}
        />
        {totalLatestSale > 0 ? (
          <>
            <div className="Explore-LatestSaleNFT-list">
              {latestSale.map((nft) => {
                return (
                  <StanNFTCard
                    type="LATEST_SALE"
                    // viewOnly
                    nft={nft}
                    key={nft.id}
                  />
                );
              })}
            </div>
            <Pagination
              responsive={true}
              showSizeChanger={true}
              current={selectedPage}
              pageSize={selectedPageSize}
              onChange={(selectedPage, pageSize) => {
                setSelectedPage(selectedPage);
                setSelectedPageSize(pageSize);
              }}
              total={totalLatestSale}
            />
          </>
        ) : (
          <Empty
            className="mt-5"
            description={t("MSG.NO_RESULTS_FOUND", { ns: "common" })}
          />
        )}
      </div>
    </div>
  );
}
