import { Modal } from "antd";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { history, openNotificationWithIcon } from "../../App";
import { CLOSE_LOADING } from "../../redux/types/LoadingType";
import { CLEAR_STATE } from "../../redux/types/UserType";
import { NETWORK_URL } from "../../util/settings/config";
import StanButton from "../StanButton/StanButton";
import StanTypo from "../StanTypo/StanTypo";
import "./LoadingScreen.css";

export default function LoadingScreen() {
  //__________ INITIAL STATE __________//
  const {
    visibleLoading,
    receipt,
    txtHash,
    urlNavigate,
    isError,
    createType,
    nftPreviewInfo,
  } = useSelector((state) => state.LoadingReducer);
  const { t } = useTranslation(["common", "nft", "collection"]);
  const dispatch = useDispatch();
  //___________________________________//

  //__________ HANDLE FUNC __________//
  const handleOk = () => {
    if (urlNavigate && urlNavigate !== "") {
      dispatch({
        type: CLOSE_LOADING,
      });
      if (!isError) {
        dispatch({
          type: CLEAR_STATE,
        });
      }
      history.push(urlNavigate);
    } else {
      history.push("/profile/" + Cookies.get("UserAddress"));
      dispatch({
        type: CLOSE_LOADING,
      });
      if (!isError) {
        dispatch({
          type: CLEAR_STATE,
        });
      }
    }
  };

  const handleCancel = () => {
    if (!receipt) {
      openNotificationWithIcon(
        "error",
        "Warning",
        "Transaction is inprogress!"
      );
    } else {
      if (urlNavigate && urlNavigate !== "") {
        history.push(urlNavigate);
      }
      dispatch({
        type: CLOSE_LOADING,
      });
      if (!isError) {
        dispatch({
          type: CLEAR_STATE,
        });
      }
    }
  };

  const RenderContent = () => {
    if (receipt) {
      if (createType === "Collection") {
        return (
          <>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div>
                <img alt="" src="/img/Frame.png" width={150} height={150} />
              </div>
              <div className="mt-2 mb-3">
                <StanTypo
                  fontSize={20}
                  fontWeight={500}
                  color="#0D0D0D"
                  content={t("CREATE_COLLECTION_SUCCESSFULLY", {
                    ns: "collection",
                  })}
                />
              </div>
              <div style={{ width: "100%" }}>
                <StanButton
                  title={t("BUTTON.VIEW_ITEM")}
                  type="primary"
                  handleOnClick={() => window.open(urlNavigate)}
                />
              </div>
            </div>
          </>
        );
      } else if (createType === "NFT") {
        return (
          <>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ width: "100%" }}
              >
                <img
                  alt=""
                  src={nftPreviewInfo?.thumbnailUrl || nftPreviewInfo?.imgUrl}
                  width={56}
                  height={56}
                  style={{ objectFit: "cover", borderRadius: "6px" }}
                />
                <div className="d-flex flex-column ml-3 ellipsisText">
                  <StanTypo
                    fontSize={14}
                    fontWeight={400}
                    content={nftPreviewInfo?.collectionName}
                  />
                  <StanTypo
                    fontSize={16}
                    fontWeight={700}
                    content={nftPreviewInfo?.nftName}
                  />
                </div>
              </div>
              <div className="mt-2 mb-3">
                <StanTypo
                  fontSize={20}
                  fontWeight={500}
                  color="#0D0D0D"
                  content={t("TEXT.CREATE_NFT_SUCCESSFULLY", {
                    ns: "nft",
                  })}
                />
              </div>
              <div style={{ width: "100%" }}>
                <StanButton
                  title={t("BUTTON.VIEW_TRANSACTION")}
                  type="primary"
                  handleOnClick={() =>
                    window.open(NETWORK_URL + "tx/" + txtHash)
                  }
                />
              </div>
            </div>
          </>
        );
      }
    }
    return null;
  };
  //___________________________________//

  return (
    <>
      <div>
        <Modal
          title={
            <h3>
              {createType === "Collection"
                ? t("CREATE_COLLECTION", { ns: "collection" })
                : t("TITLE.CREATE_NFT", { ns: "nft" })}
            </h3>
          }
          footer={null}
          className="submitted"
          visible={visibleLoading}
          maskClosable={false}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div>
            <RenderContent />
          </div>
        </Modal>
      </div>
    </>
  );
}
