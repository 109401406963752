import {
  CREATE_COLLECTION,
  EDIT_COLLECTION,
  GET_COLLECTION_ACTIVITIES,
  GET_COLLECTION_COLLECTED,
  GET_DETAIL_COLLECTION,
  GET_LIST_COLLECTION,
  SEARCHING_FOR_COLLECTION,
  SET_HOT_COLLECTION_SORT,
} from "../types/CollectionType";
import { CLEAR_STATE } from "../types/UserType";

const initialState = {
  listCollection: [],
  collection: {},
  totalCollection: "",
  collectionActivities: [],
  collectionCollected: [],
  totalCollectionActivity: 0,
  totalCollectionCollected: 0,
  listCollectionHeader: [],
  hotCollectionSort: "",
};

export const CollectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_COLLECTION:
      return {
        ...state,
        listCollection: action.listCollection,
        totalCollection: action.totalCollection,
      };
    case GET_DETAIL_COLLECTION:
      return { ...state, collection: action.collection };
    case CREATE_COLLECTION:
      return { ...state, collection: action.collection };
    case EDIT_COLLECTION:
      return { ...state, collection: action.collection };
    case GET_COLLECTION_ACTIVITIES:
      return {
        ...state,
        collectionActivities: action.collectionActivities,
        totalCollectionActivity: action.totalCollectionActivity,
      };
    case GET_COLLECTION_COLLECTED:
      return {
        ...state,
        collectionCollected: action.collectionCollected,
        totalCollectionCollected: action.totalCollectionCollected,
      };
    // TEMPORARY (INPUT SEARCH 13/10/2022)
    case SEARCHING_FOR_COLLECTION:
      return {
        ...state,
        listCollectionHeader: action.listCollection,
      };
    case SET_HOT_COLLECTION_SORT:
      return {
        ...state,
        hotCollectionSort: action.hotCollectionSort,
      };
    case CLEAR_STATE:
      return (state = initialState);
    default:
      return state;
  }
};
