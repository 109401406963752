import {
  CLOSE_LOADING, OPEN_LOADING
} from "../types/LoadingType";
import { CLEAR_STATE } from "../types/UserType";

const initialState = {
  visibleLoading: false,
  receipt: null,
  txtHash: "",
  urlNavigate: "",
  isError: false,
  createType: "",
  nftPreviewInfo: {}
};

export const LoadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_LOADING:
      return {
        ...state,
        visibleLoading: true,
        receipt: action.receipt,
        txtHash: action.txtHash,
        urlNavigate: action.urlNavigate,
        isError: action.isError,
        createType: action.createType,
        nftPreviewInfo: action.nftPreviewInfo
      };
    case CLOSE_LOADING:
      return { ...state, visibleLoading: false };
    case CLEAR_STATE:
      return (state = initialState);
    default:
      return state;
  }
};
