import { LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import Cookies from "js-cookie";
import _ from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { isApprovedForAll } from "../../redux/action/Web3Action";
import {
  CLOSE_CONNECT_WALLET,
  OPEN_CONFIRM_MODAL,
  OPEN_CONNECT_WALLET,
  OPEN_QR_DRAWER,
} from "../../redux/types/ModalType";
import "./StanButton.css";
export default function StanButton(props) {
  const dispatch = useDispatch();

  const handleOnClick = async () => {
    if (props.requireApprove && !Cookies.get("UserAddress")) {
      dispatch({
        type: OPEN_CONNECT_WALLET,
        onClose: () => dispatch({ type: CLOSE_CONNECT_WALLET }),
      });
    } else {
      if (props.requireApprove && _.isUndefined(Cookies.get("TokenId"))) {
        dispatch({ type: OPEN_QR_DRAWER });
      } else {
        if (props.requireApprove && (await isApprovedForAll()) === false) {
          dispatch({ type: OPEN_CONFIRM_MODAL });
        } else {
          return props.handleOnClick();
        }
      }
    }
  };

  return (
    <Button
      htmlType="submit"
      icon={props.icon}
      hidden={props.hidden}
      className={props.type}
      disabled={props.disabled}
      onClick={handleOnClick}
      style={props.style}
    >
      {props.disabled ? (
        <LoadingOutlined />
      ) : props.noUpperCase ? (
        props.title
      ) : (
        props.title.toUpperCase()
      )}
    </Button>
  );
}
