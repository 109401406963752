import { DownOutlined, QrcodeOutlined } from "@ant-design/icons";
import { Button, Dropdown, Image, Menu, Select, Tooltip } from "antd";
import i18next from "i18next";
import Cookies from "js-cookie";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, generatePath } from "react-router-dom";
import { history } from "../../../../App";
import { EXPLORE_PATH } from "../../../../common/Constant";
import {
  EngFlag,
  KorFlag,
  MaticIcon,
  ThaiFlag,
  VietFlag,
  addFundSvg,
  balanceSvg,
  bannerStan,
  disconnectSvg,
  recentTransSvg,
  withdrawSvg,
} from "../../../../common/commonSvg";
import StanModal from "../../../../component/StanModal/StanModal";
import StanSearchInput from "../../../../component/StanSearchInput/StanSearchInput";
import AddFund from "../../../../pages/AddFund/AddFund";
import Withdraw from "../../../../pages/Withdraw/Withdraw";
import { getBalanceOfFan } from "../../../../redux/action/AutionAction";
import { getStanToken } from "../../../../redux/action/StanTokenAction";
import { isApprovedForAll } from "../../../../redux/action/Web3Action";
import {
  CLOSE_CONNECT_WALLET,
  CLOSE_DEPOSIT,
  CLOSE_WITHDRAW,
  OPEN_CONFIRM_MODAL,
  OPEN_CONNECT_WALLET,
  OPEN_QR_DRAWER,
} from "../../../../redux/types/ModalType";
import { PENDING_REQUEST } from "../../../../redux/types/UserType";
import {
  CHAIN_ID,
  NETWORK_URL,
  TOKEN_COOKIE,
} from "../../../../util/settings/config";
import { formatStanCryptoDetail } from "../../../../util/settings/utils";
import "./Header.css";

const { Option } = Select;
export default function Header(props) {
  const { t } = useTranslation(["common", "homePage", "nft"]);
  const dispatch = useDispatch();
  const [visibleAddFund, setVisibleAddFund] = useState(false);
  const [visibleWithdraw, setvisibleWithdraw] = useState(false);
  const [approveStatus, setApproveStatus] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(
    !Cookies.get("CURRENT_LANG") ? "en" : Cookies.get("CURRENT_LANG")
  );
  const { balanceOfFan } = useSelector((state) => state.AutionReducer);
  const { openDeposit, openWithdraw } = useSelector(
    (state) => state.ModalReducer
  );

  useEffect(() => {
    async function checkApproveStatus() {
      if (await isApprovedForAll()) {
        setApproveStatus(true);
      } else {
        setApproveStatus(false);
      }
    }
    checkApproveStatus();
  }, []);

  // WALLET/ NETWORK CHANGING
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", function (accounts) {
      if (!_.isEmpty(accounts)) {
      } else {
        Cookies.remove("UserAddress");
        window.location.reload();
      }
      if (
        Cookies.get("UserAddress") &&
        accounts[0] !== Cookies.get("UserAddress")
      ) {
        Cookies.remove("UserAddress");
        Cookies.remove("TokenId");
        window.location.reload();
      }
    });
    window.ethereum.on("chainChanged", function (chainId) {
      if (Cookies.get("TokenId") && chainId !== CHAIN_ID) {
        Cookies.remove("UserAddress");
        Cookies.remove("TokenId");
        window.location.reload();
      }
    });
  }

  i18next.on("languageChanged", (lng) => {
    setCurrentLanguage(lng);
  });

  useEffect(() => {
    i18next.changeLanguage(currentLanguage);
    Cookies.set("CURRENT_LANG", currentLanguage);
  }, [currentLanguage]);

  const addButtonDropdown = (
    <Menu
      items={[
        {
          label: (
            <div
              onClick={async () => {
                if (await isApprovedForAll()) {
                  history.push("/createNFT");
                } else {
                  dispatch({ type: OPEN_CONFIRM_MODAL });
                }
              }}
            >
              <span>{t("BUTTON.CREATE_NFT")}</span>
            </div>
          ),
          key: "0",
        },
        {
          label: (
            <div
              onClick={async () => {
                if (await isApprovedForAll()) {
                  history.push("/createCollection");
                } else {
                  dispatch({ type: OPEN_CONFIRM_MODAL });
                }
              }}
            >
              <span>{t("BUTTON.CREATE_COLLECTION")}</span>
            </div>
          ),
          key: "1",
        },
      ]}
    />
  );

  const functionDropdown = (
    <Menu
      items={[
        {
          label: (
            <div
              onClick={() =>
                window.open(
                  NETWORK_URL + "address/" + Cookies.get("UserAddress")
                )
              }
            >
              <span className="functionTitle">
                {recentTransSvg()} {t("TITLE.RECENT_TRANS").toUpperCase()}
              </span>
            </div>
          ),
          key: "0",
        },
        {
          label: (
            <div>
              <span className="functionTitle">
                {balanceSvg()} {t("TITLE.BALANCE").toUpperCase()}{" "}
                <MaticIcon />{" "}
                {!balanceOfFan ? "0" : formatStanCryptoDetail(balanceOfFan)}
              </span>
            </div>
          ),
          key: "1",
        },
        TOKEN_COOKIE
          ? {
              label: (
                <div
                  onClick={async () => {
                    if (await isApprovedForAll()) {
                      setVisibleAddFund(true);
                    } else {
                      dispatch({ type: OPEN_CONFIRM_MODAL });
                    }
                  }}
                >
                  <span className="functionTitle">
                    {addFundSvg()} {t("TITLE.DEPOSIT").toUpperCase()}
                  </span>
                </div>
              ),
              key: "2",
            }
          : null,
        TOKEN_COOKIE
          ? {
              label: (
                <div
                  onClick={async () => {
                    if (await isApprovedForAll()) {
                      setvisibleWithdraw(true);
                    } else {
                      dispatch({ type: OPEN_CONFIRM_MODAL });
                    }
                  }}
                >
                  <span className="functionTitle">
                    {withdrawSvg()} {t("TITLE.WITHDRAW").toUpperCase()}
                  </span>
                </div>
              ),
              key: "3",
            }
          : null,
        {
          label: (
            <div
              onClick={() => {
                Cookies.remove("UserAddress");
                Cookies.remove("TokenId");
                Cookies.remove("SignedMessage");
                Cookies.remove("MetamaskMessage");
                localStorage.removeItem("UserProfile");
                window.location.reload();
              }}
            >
              <span style={{ color: "red" }}>
                {disconnectSvg()} {t("TITLE.DISCONNECT").toUpperCase()}
              </span>
            </div>
          ),
          key: "4",
        },
      ]}
    />
  );

  const languagesChangerDropdown = (
    <Menu
      items={[
        {
          label: (
            <div onClick={() => i18next.changeLanguage("en")}>
              <EngFlag />
            </div>
          ),
          key: "en",
        },
        {
          label: (
            <div onClick={() => i18next.changeLanguage("kr")}>
              <KorFlag />
            </div>
          ),
          key: "kr",
        },
        {
          label: (
            <div onClick={() => i18next.changeLanguage("vi")}>
              <VietFlag />
            </div>
          ),
          key: "vi",
        },
        {
          label: (
            <div onClick={() => i18next.changeLanguage("th")}>
              <ThaiFlag />
            </div>
          ),
          key: "th",
        },
      ]}
    />
  );

  function closeModal() {
    setVisibleAddFund(false);
    setvisibleWithdraw(false);
    dispatch({ type: CLOSE_DEPOSIT });
    dispatch({ type: CLOSE_WITHDRAW });
    dispatch({ type: PENDING_REQUEST });
  }

  function renderFlag() {
    if (i18next.language === "en") {
      return (
        <div>
          <EngFlag />
        </div>
      );
    } else if (i18next.language === "kr") {
      return (
        <div>
          <KorFlag />
        </div>
      );
    } else if (i18next.language === "vi") {
      return (
        <div>
          <VietFlag />
        </div>
      );
    } else if (i18next.language === "th") {
      return (
        <div>
          <ThaiFlag />
        </div>
      );
    } else {
      return null;
    }
  }

  const renderButtonConnect = () => {
    const userProfile = JSON.parse(localStorage.getItem("UserProfile"));
    // A. DOESN'T CONNECT TO WALLET YET
    if (!Cookies.get("UserAddress")) {
      return (
        <>
          <div className="d-flex">
            <div style={{ cursor: "pointer" }} className="mr-3">
              <img alt="" src="/svg/profile-circle.svg" />
            </div>
            <div
              style={{ cursor: "pointer" }}
              className="mr-3"
              onClick={() =>
                dispatch({
                  type: OPEN_CONNECT_WALLET,
                  onClose: () => dispatch({ type: CLOSE_CONNECT_WALLET }),
                })
              }
            >
              <img alt="" src="/svg/wallet.svg" />
            </div>
            <LanguageChanger />
          </div>
        </>
      );
    } else {
      // B. CONNECTED TO WALLET
      return (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* B.1. WALLET IS LINKED WITH STAN APP */}
            {Cookies.get("TokenId") ? (
              <>
                {userProfile && userProfile.role === 2 ? (
                  <div className="m-1" style={{ cursor: "pointer" }}>
                    <Dropdown
                      overlay={addButtonDropdown}
                      placement="bottomRight"
                    >
                      <img alt="" src="/svg/box-add.svg" />
                    </Dropdown>
                  </div>
                ) : null}
                <div className="userAvatar m-1" style={{ cursor: "pointer" }}>
                  <Tooltip
                    mouseEnterDelay={2}
                    title={approveStatus ? "Approved" : "Disapproved"}
                  >
                    <Image
                      preview={false}
                      width={36}
                      height={36}
                      onClick={() =>
                        history.push(
                          generatePath(EXPLORE_PATH.PROFILE, {
                            walletAddress:
                              Cookies.get("UserAddress").toLowerCase(),
                          })
                        )
                      }
                      style={{ objectFit: "cover" }}
                      src={userProfile?.avatar}
                    />
                  </Tooltip>
                </div>
              </>
            ) : (
              <div className="m-1">
                <Button
                  type="primary"
                  icon={<QrcodeOutlined />}
                  onClick={() => dispatch({ type: OPEN_QR_DRAWER })}
                />
              </div>
            )}
            <Dropdown
              trigger={"click"}
              overlay={functionDropdown}
              placement="bottomLeft"
            >
              <div
                className="m-1"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(getBalanceOfFan());
                  dispatch(getStanToken());
                }}
              >
                <img alt="" src="/svg/wallet.svg" />
              </div>
            </Dropdown>
            <LanguageChanger />
          </div>
        </>
      );
    }
  };

  const renderLogin = () => {
    return (
      <div className="d-flex justify-content-start align-items-center align-content-center">
        <div className="d-flex justify-content-start">
          {renderButtonConnect()}
        </div>
      </div>
    );
  };

  const renderNavFunc = () => {
    const userProfile = JSON.parse(localStorage.getItem("UserProfile"));
    if (!Cookies.get("UserAddress")) {
      return (
        <>
          <div className="d-flex">
            <div className="mr-2">
              <img
                width={24}
                height={24}
                alt=""
                src="/svg/profile-circle.svg"
              />
            </div>
            <div
              className="mr-2"
              onClick={() =>
                dispatch({
                  type: OPEN_CONNECT_WALLET,
                  onClose: () => dispatch({ type: CLOSE_CONNECT_WALLET }),
                })
              }
            >
              <img width={24} height={24} alt="" src="/svg/wallet.svg" />
            </div>
            <div>
              <Dropdown
                trigger={"click"}
                overlay={languagesChangerDropdown}
                placement="bottom"
              >
                {renderFlag()}
              </Dropdown>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="d-flex justify-content-center align-items-center">
            {!_.isEmpty(Cookies.get("TokenId")) ? (
              <>
                {userProfile && userProfile.role === 2 ? (
                  <div className="mr-1">
                    <Dropdown
                      overlay={addButtonDropdown}
                      placement="bottomLeft"
                    >
                      <img
                        width={24}
                        height={24}
                        alt=""
                        src="/svg/box-add.svg"
                      />
                    </Dropdown>
                  </div>
                ) : null}
                <div
                  className="userAvatar ml-1 mr-1"
                  onClick={() => {
                    history.push(
                      generatePath("/profile/:walletAddress", {
                        walletAddress: Cookies.get("UserAddress"),
                      })
                    );
                  }}
                >
                  <Image
                    preview={false}
                    style={{ objectFit: "cover" }}
                    width={24}
                    height={24}
                    src={userProfile?.avatar}
                  />
                </div>
              </>
            ) : (
              <div className="m-1">
                {/* QR AUTHEN */}
                <Button
                  type="primary"
                  style={{ width: "24px", height: "24px" }}
                  icon={<QrcodeOutlined />}
                  onClick={() => dispatch({ type: OPEN_QR_DRAWER })}
                />
              </div>
            )}
            {/* FLAGS */}
            <div className="ml-1">
              <Dropdown
                trigger={"click"}
                overlay={languagesChangerDropdown}
                placement="bottom"
              >
                {renderFlag()}
              </Dropdown>
            </div>
          </div>
        </>
      );
    }
  };

  const renderCollapseNavFunc = () => {
    return (
      <div className="d-flex justify-content-start align-items-center align-content-center">
        <div className="d-flex justify-content-start">{renderNavFunc()}</div>
      </div>
    );
  };

  const LanguageChanger = () => {
    return (
      <>
        <div className="mr-2">
          <Select
            defaultValue="en"
            value={currentLanguage}
            onChange={(e) => i18next.changeLanguage(e)}
          >
            <Option value="kr">
              <KorFlag /> KOR
            </Option>
            <Option value="en">
              <EngFlag /> ENG
            </Option>
            <Option value="vi">
              <VietFlag /> VIE
            </Option>
            <Option value="th">
              <ThaiFlag /> TH
            </Option>
          </Select>
        </div>
      </>
    );
  };

  return (
    <>
      {/* MODAL INIT */}
      <StanModal
        title={t("TITLE.DEPOSIT")}
        width={624}
        content={<AddFund closeModal={closeModal} />}
        visible={visibleAddFund || openDeposit}
        onClose={closeModal}
      ></StanModal>
      <StanModal
        title={t("TITLE.WITHDRAW")}
        width={624}
        content={<Withdraw closeModal={closeModal} />}
        visible={visibleWithdraw || openWithdraw}
        onClose={closeModal}
      ></StanModal>
      {/* MODAL INIT */}

      <div>
        <header className="header">
          <nav className="navbar navbar-expand-xl">
            <div>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i style={{ color: "#00000" }} className="fa fa-bars" />
              </button>
              {/* <button
                className="navbar-toggler"
                onClick={() => setVisibleNavbar(!visibleNavbar)}
              >
                <i style={{ color: "#00000" }} className="fa fa-bars" />
              </button> */}
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i style={{ color: "#00000" }} className="fa fa-search" />
              </button>
            </div>
            {/* LOGO */}
            <div
              onClick={() => {
                history.push("/home");
              }}
              className="logoStan"
              style={{ cursor: "pointer" }}
            >
              <div>{bannerStan()}</div>
            </div>
            {/* LOGO */}
            <div className="navFunc">{renderCollapseNavFunc()}</div>

            <div
              className="collapse navbar-collapse justify-content-between"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav" style={{ width: "100%" }}>
                <li
                  style={{
                    padding: "30px",
                  }}
                  className="d-flex justify-content-start justify-content-xl-center align-items-xl-center"
                >
                  <NavLink
                    activeStyle={{
                      color: "#49D19A",
                    }}
                    className="nav_item nav_link"
                    to="/explore"
                  >
                    {t("BUTTON.EXPLORE", { ns: "homePage" })}
                  </NavLink>
                </li>
                {/* {Cookies.get("UserAddress") && Cookies.get("TokenId") ? (
                  <li
                    style={{
                      padding: "30px",
                    }}
                    className="d-flex justify-content-start justify-content-xl-center align-items-xl-center"
                  >
                    <NavLink
                      activeStyle={{
                        color: "#49D19A",
                      }}
                      className="nav_item nav_link"
                      to={generatePath("/profile/:walletAddress", {
                        walletAddress: Cookies.get("UserAddress").toLowerCase(),
                      })}
                    >
                      {t("BUTTON.PROFILE", { ns: "homePage" })}
                    </NavLink>
                  </li>
                ) : null} */}
                {Cookies.get("UserAddress") ? (
                  <li
                    style={{
                      padding: "30px",
                    }}
                    className="d-flex justify-content-start justify-content-xl-center align-items-xl-center walletManagementNav"
                  >
                    <Dropdown
                      trigger={"click"}
                      overlay={functionDropdown}
                      overlayStyle={{ width: "100%" }}
                      placement="bottom"
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          dispatch(getBalanceOfFan());
                          dispatch(getStanToken());
                        }}
                      >
                        {t("BUTTON.WALLET_MANAGEMENT", { ns: "homePage" })}{" "}
                        <DownOutlined />
                      </div>
                    </Dropdown>
                  </li>
                ) : null}
                <li
                  style={{
                    padding: "30px",
                    width: "100%",
                  }}
                  className="d-flex align-items-center justify-content-xl-center align-items-xl-center"
                >
                  <StanSearchInput
                    placeholder={
                      <div className="d-flex align-items-center">
                        <img alt="" src="/svg/searchIcon.svg" />
                        {t("PLACEHOLDER.HEADER_SEARCH")}
                      </div>
                    }
                  />
                </li>
              </ul>
              <div className="pb-5 pb-md-0 collapsedFunc">{renderLogin()}</div>
            </div>
          </nav>
        </header>
      </div>
    </>
  );
}
