import { baseService } from "./baseService";

export class NftService extends baseService {
  constructor() {
    super();
  }

  createNft = (data) => {
    return this.post(`web/nft`, data);
  };

  nftDetail = (nftId) => {
    return this.get(`web/nft/view/${nftId}`);
  };

  nftSearch = (data, pageSize, page) => {
    let url = `web/nft/search?`;
    if (data.name && data.name !== "") {
      url += `name=${data.name}&`;
    }
    if (data.collectionId && data.colletionId !== "") {
      url += `collectionId=${data.collectionId}&`;
    }
    if (data.status && data.status !== "") {
      url += `status=${data.status}&`;
    }
    if (data.currency && data.currency !== "") {
      url += `currency=${data.currency}&`;
    }
    if (data.fromPrice && data.fromPrice !== "") {
      url += `fromPrice=${data.fromPrice}&`;
    }
    if (data.toPrice && data.toPrice !== "") {
      url += `toPrice=${data.toPrice}&`;
    }
    if (data.orderBy && data.orderBy !== "") {
      url += `orderBy=${data.orderBy}&`;
    }
    if (data.hashtag && data.hashtag !== "") {
      url += `hashtag=${data.hashtag}&`;
    }
    if (data.categoryIds && data.categoryIds !== "") {
      url += `categoryIds=${data.categoryIds}&`;
    }
    if (data.walletAddress && data.walletAddress !== "") {
      url += `ownerAddresses=${data.walletAddress}&`;
    }
    url += `limit=${pageSize}&offset=${(page - 1) * pageSize}`;
    return this.get(url);
  };

  transferNft = (data) => {
    return this.post(`web/nft/request-transfer`, {
      nftId: data.nftId,
      receiverWalletAddress: data.receiverAddress,
    });
  };

  getCoinPrice = (coin) => {
    return this.get(`web/nft/crypto-price?cryptoCurrency=${coin}`);
  };

  listNFT = (data) => {
    return this.post(`web/nft/listing`, data);
  };

  buyNft = (data) => {
    return this.post(`web/nft/buy`, data);
  };

  cancelListing = (data) => {
    return this.put(`web/nft/cancel-listing`, data);
  };

  getActivities = (id, filterData, pageSize, page) => {
    let url = `web/nft/${id}/activities?`;
    if (filterData.status && filterData.status !== "") {
      url += `status=${filterData.status}&`;
    }
    if (filterData.type && filterData.type !== "") {
      url += `type=${filterData.type}&`;
    }
    if (filterData.by && filterData.by !== "") {
      url += `by=${filterData.by}&`;
    }
    url += `limit=${pageSize}&offset=${(page - 1) * pageSize}`;
    return this.get(url);
  };

  getCreatedNFT = (filterData, pageSize, page) => {
    let url = `web/nft/listCreated?`;
    if (filterData.name && filterData.name !== "") {
      url += `name=${filterData.name}&`;
    }
    if (filterData.collectionIds && filterData.collectionIds.length > 0) {
      url += `collectionId=${filterData.collectionIds}&`;
    }
    if (filterData.status && filterData.status !== "") {
      url += `status=${filterData.status}&`;
    }
    if (filterData.currency && filterData.currency !== "") {
      url += `currency=${filterData.currency}&`;
    }
    if (filterData.fromPrice && filterData.fromPrice !== "") {
      url += `fromPrice=${filterData.fromPrice}&`;
    }
    if (filterData.toPrice && filterData.toPrice !== "") {
      url += `toPrice=${filterData.toPrice}&`;
    }
    if (filterData.orderBy && filterData.orderBy !== "") {
      url += `orderBy=${filterData.orderBy}&`;
    }
    if (filterData.walletAddress && filterData.walletAddress !== "") {
      url += `walletAddress=${filterData.walletAddress}&`;
    }
    if (filterData.categoryIds && filterData.categoryIds !== "") {
      url += `categoryIds=${filterData.categoryIds}&`;
    }
    url += `limit=${pageSize}&offset=${(page - 1) * pageSize}`;
    return this.get(url);
  };

  updateTxMint = (data) => {
    return this.post(`web/nft/update-tx-mint`, data);
  };

  // SHUFFLE NFT
  shuffleNFT = () => {
    return this.get(`web/nft/shuffle-nft`);
  };

  // CLAIM/ DEPOSIT NFT
  depositNFT = (nftId) => {
    return this.put(`web/nft/deposit/${nftId}`);
  };

  claimNFT = (nftId) => {
    return this.put(`web/nft/claim/${nftId}`);
  };
  // CLAIM/ DEPOSIT NFT

  // VIEWS/LIKES/SHARE
  trackingViewNFT = (data) => {
    return this.post(`web/nft/tracking/view-nft`, data);
  };

  likeNFT = (nftId) => {
    return this.post(`web/nft/${nftId}/like`);
  };

  unlikeNFT = (nftId) => {
    return this.post(`web/nft/${nftId}/unlike`);
  };
  // VIEWS/LIKES/SHARE

  getArtistLatestNFTs = (filterData, pageSize, page) => {
    let url = `web/nft/artist-latest-nft?`;
    if (filterData.keyword && filterData.keyword !== "") {
      url += `keyword=${filterData.keyword}&`;
    }
    if (filterData.property && filterData.property !== "") {
      url += `property=${filterData.property}&`;
    }
    if (filterData.sortDir && filterData.sortDir !== "") {
      url += `sort=${filterData.sortDir}&`;
    }
    url += `limit=${pageSize}&offset=${(page - 1) * pageSize}`;
    return this.get(url);
  };

  getLatestSale = (filterData, pageSize, page) => {
    let url = `web/nft/latest-sale?`;
    if (filterData.keyword && filterData.keyword !== "") {
      url += `keyword=${filterData.keyword}&`;
    }
    if (filterData.property && filterData.property !== "") {
      url += `property=${filterData.property}&`;
    }
    if (filterData.sortDir && filterData.sortDir !== "") {
      url += `sort=${filterData.sortDir}&`;
    }
    url += `limit=${pageSize}&offset=${(page - 1) * pageSize}`;
    return this.get(url);
  };

  getPositionSection = () => {
    return this.get(`web/nft/position-section`);
  };

  getListCategories = () => {
    return this.get(`web/nft/categories`);
  };

  getFixedPriceNFTs = (filterData, pageSize, page) => {
    let url = `web/nft/fixed-price?`;
    if (filterData.keyword && filterData.keyword !== "") {
      url += `keyword=${filterData.keyword}&`;
    }
    if (filterData.property && filterData.property !== "") {
      url += `property=${filterData.property}&`;
    }
    if (filterData.sortDir && filterData.sortDir !== "") {
      url += `sort=${filterData.sortDir}&`;
    }
    url += `limit=${pageSize}&offset=${(page - 1) * pageSize}`;
    return this.get(url);
  };
}

export const nftService = new NftService();
