// import _ from "lodash";
import { ethers } from "ethers";
import Cookies from "js-cookie";
import Web3 from "web3";
import { history, openNotificationWithIcon } from "../../App";
import { COMMON_PATH } from "../../common/Constant";
import { PRIORITY_FEE } from "../../common/commonParams";
import { collectionService } from "../../services/collectionService";
import {
  CHAIN_ID,
  CONTRACT_ADDRESS,
  stagingEnvironment,
} from "../../util/settings/config";
import { convertStringToBytes } from "../../util/settings/utils";
import {
  GET_COLLECTION_ACTIVITIES,
  GET_COLLECTION_COLLECTED,
  GET_DETAIL_COLLECTION,
  GET_LIST_COLLECTION,
  SEARCHING_FOR_COLLECTION,
} from "../types/CollectionType";
import { CLOSE_LOADING, OPEN_LOADING } from "../types/LoadingType";
import {
  CLEAR_STATE,
  COMPLETE_REQUEST,
  PENDING_REQUEST,
  PROCESSING,
  REQUESTING,
} from "../types/UserType";
import {
  ABI_COLLECTION_MAINNET,
  ABI_COLLECTION_MAINNET_STAGING,
  ABI_COLLECTION_TESTNET,
} from "../../common/ABIs";

const ethereum = window?.ethereum;

const Web3Client = new Web3(ethereum);

const contract = new Web3Client.eth.Contract(
  CHAIN_ID === "137"
    ? stagingEnvironment
      ? ABI_COLLECTION_MAINNET_STAGING
      : ABI_COLLECTION_MAINNET
    : ABI_COLLECTION_TESTNET,
  CONTRACT_ADDRESS
);

export const getListCollection = (pageSize, page) => {
  return async (dispatch) => {
    try {
      const result = await collectionService.getListCollection(pageSize, page);
      if (result && result.data.data.total > 0) {
        dispatch({
          type: GET_LIST_COLLECTION,
          listCollection: result.data.data.list,
          totalCollection: result.data.data.total,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const searchCollection = (
  pageSize,
  page,
  walletAdress,
  keyword,
  property,
  sort
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: REQUESTING });
      const result = await collectionService.searchCollection(
        pageSize,
        page,
        walletAdress,
        keyword,
        property,
        sort
      );
      if (result && result.data.status === "success") {
        dispatch({
          type: GET_LIST_COLLECTION,
          listCollection: result.data.data.list,
          totalCollection: result.data.data.total,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

// TEMPORARY (INPUT SEARCH 13/10/2022)
export const searchCollectionHeader = (
  pageSize,
  page,
  walletAdress,
  keyword
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: REQUESTING });
      const result = await collectionService.searchCollection(
        pageSize,
        page,
        walletAdress,
        keyword
      );
      if (result && result.data.status === "success") {
        dispatch({
          type: SEARCHING_FOR_COLLECTION,
          listCollection: result.data.data.list,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

export const getDetailCollection = (collectionId) => {
  return async (dispatch) => {
    try {
      const result = await collectionService.getDetailCollection(collectionId);
      if (result && result.data.data) {
        dispatch({
          type: GET_DETAIL_COLLECTION,
          collection: result.data.data,
        });
      } else {
        history.push(COMMON_PATH.NOT_FOUND_404);
        openNotificationWithIcon("error", "Error", result.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const createCollection = (data) => {
  return async (dispatch) => {
    try {
      const result = await collectionService.createCollection(data);
      if (result && result.data.status === "success") {
        await contract.methods
          .createCollection(
            convertStringToBytes(result.data.data.requestId), //requestId (bytes)
            convertStringToBytes(result.data.data.requestId), // collectionId(bytes)
            Number(data.creatorRoyalty), // ratioCreatorVal (uint128)
            2, // ratioCreatorDenomination (uint128)
            true // isWeb (bool)
          )
          .send(
            CHAIN_ID === "137"
              ? {
                  from: Cookies.get("UserAddress"),
                  maxPriorityFeePerGas: ethers.BigNumber.from(PRIORITY_FEE),
                }
              : { from: Cookies.get("UserAddress") }
          )
          .on("transactionHash", function (hash) {
            if (hash) {
              dispatch({ type: PROCESSING });
            }
          })
          .on("receipt", function (receipt) {
            if (receipt) {
              dispatch({
                type: OPEN_LOADING,
                receipt: receipt,
                txtHash: receipt.transactionHash,
                urlNavigate: "/collectionDetail/" + result.data.data.requestId,
                createType: "Collection",
              });
            }
          });
      }
    } catch (error) {
      console.log(error);
      if (error.code === 4001) {
        openNotificationWithIcon(
          "error",
          "Error",
          "You declined the action in your wallet"
        );
        dispatch({ type: COMPLETE_REQUEST });
        dispatch({
          type: CLOSE_LOADING,
        });
      } else {
        openNotificationWithIcon("error", "Error", "Error. Please try again!");
      }
    } finally {
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

export const updateCollection = (data) => {
  return async (dispatch) => {
    try {
      const result = await collectionService.updateCollection(data);
      if (result.data.data && result.data.status === "success") {
        openNotificationWithIcon(
          "success",
          "Success",
          "Update collection successfully! "
        );
        dispatch({ type: CLEAR_STATE });
        history.push("/collectionDetail/" + data.id);
      }
    } catch (error) {
      dispatch({ type: CLEAR_STATE });
      console.log(error);
    }
  };
};

export const getCollectionActivities = (collectionId, type, pageSize, page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: REQUESTING });
      const result = await collectionService.getCollectionActivities(
        collectionId,
        type,
        pageSize,
        page
      );
      if (result && result.data.data) {
        dispatch({
          type: GET_COLLECTION_ACTIVITIES,
          collectionActivities: result.data.data.items,
          totalCollectionActivity: result.data.data.total,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

export const getCollectionCollected = (data, pageSize, page) => {
  return async (dispatch) => {
    try {
      const result = await collectionService.getCollectionCollected(
        data,
        pageSize,
        page
      );
      if (result && result.data.data) {
        dispatch({
          type: GET_COLLECTION_COLLECTED,
          collectionCollected: result.data.data.list,
          totalCollectionCollected: result.data.data.total,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};
