import { Empty } from "antd";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { history } from "../../../App";
import {
  ProfileCircleIcon,
  SampleNextArrow,
  SamplePrevArrow,
} from "../../../common/commonSvg";
import StanArtistNFT from "../../../component/StanArtistNFT/StanArtistNFT";
import StanTypo from "../../../component/StanTypo/StanTypo";
import { getArtistLatestNFTs } from "../../../redux/action/NftAction";
import "./ArtistLatestNFT.css";
import { EXPLORE_PATH } from "../../../common/Constant";

export default function ArtistLatestNFT() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["common", "homePage"]);
  const { artistLatestNFTs } = useSelector((state) => state.NFTReducer);

  const settingsArtistLatestNFTs = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow:
      artistLatestNFTs.length === 3 || artistLatestNFTs.length === 4
        ? artistLatestNFTs.length
        : 5,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow:
            artistLatestNFTs.length < 4 ? artistLatestNFTs.length : 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getArtistLatestNFTs({}, 10, 1));
  }, []);

  const RenderList = useMemo(() => {
    if (artistLatestNFTs.length === 0) {
      return <Empty description={t("NONE_COLLECTION", { ns: "homePage" })} />;
    }
    if (artistLatestNFTs.length <= 2) {
      return (
        <>
          <div className="ArtistLatestNFT-list">
            {artistLatestNFTs.map((nft) => {
              return (
                <StanArtistNFT
                  nft={nft}
                  key={nft.id}
                  searchNFT={() => dispatch(getArtistLatestNFTs(10, 1, ""))}
                  homePage={true}
                />
              );
            })}
          </div>
        </>
      );
    } else {
      return (
        <>
          <Slider {...settingsArtistLatestNFTs}>
            {artistLatestNFTs.map((nft) => {
              return (
                <StanArtistNFT
                  nft={nft}
                  key={nft.id}
                  type="sliderItem"
                  searchNFT={() => dispatch(getArtistLatestNFTs(10, 1))}
                  homePage={true}
                />
              );
            })}
          </Slider>
        </>
      );
    }
  }, [artistLatestNFTs, t]);

  return (
    <>
      <div className="ArtistLatestNFT">
        <div className="ArtistLatestNFT-header">
          <div className="d-flex align-items-center">
            <div className="ArtistLatest-icon mr-2">
              <ProfileCircleIcon />
            </div>
            <h2>{t("ARTIST_LATEST_NFT", { ns: "homePage" })}</h2>
          </div>
          <div>
            <StanTypo
              fontWeight={400}
              fontSize={16}
              color="#35A075"
              customStyle={{ cursor: "pointer" }}
              onClick={() => history.push(EXPLORE_PATH.ARTIST_LATEST_NFT)}
              content={t("BUTTON.SEE_ALL", { ns: "common" }).toUpperCase()}
            />
          </div>
        </div>
        {RenderList}
      </div>
    </>
  );
}
