import {
  Affix,
  Drawer,
  Empty,
  Image,
  Input,
  Pagination,
  Select,
  Skeleton,
  Spin,
  Tabs,
  Tooltip,
} from "antd";
import i18next from "i18next";
import Cookies from "js-cookie";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useParams } from "react-router-dom";
import { history } from "../../App";
import {
  COLLECTION_DETAIL_TABPANE,
  EXPLORE_PATH,
  NFT_SORT_BY,
} from "../../common/Constant";
import {
  MaticIcon,
  StanPointIcon,
  WETHIcon,
  activityTabPaneSvg,
  editCollectionSvg,
  exploreItemsSvg,
  filterSvg,
  floorWidgetIcon,
  itemWidgetIcon,
  ownerWidgetIcon,
  totalWidgetIcon,
} from "../../common/commonSvg";
import StanFilter from "../../component/StanFilter/StanFilter";
import StanFilterPreview from "../../component/StanFilter/StanFilterPreview";
import StanNFTCard from "../../component/StanNFTCard/StanNFTCard";
import StanTypo from "../../component/StanTypo/StanTypo";
import { getDetailCollection } from "../../redux/action/CollectionAction";
import { getListCategories, searchNFT } from "../../redux/action/NftAction";
import {
  getListOfOwnersInCollection,
  getUSDRate,
} from "../../redux/action/UserAction";
import { EDIT_COLLECTION } from "../../redux/types/CollectionType";
import { REQUESTING } from "../../redux/types/UserType";
import {
  formatStanCryptoDetail,
  formatStanPointDetail,
  generateMediaSvg,
  subStringText,
} from "../../util/settings/utils";
import CollectionActivity from "./CollectionActivity/CollectionActivity";
import "./CollectionDetail.css";
const { TabPane } = Tabs;
const { Option } = Select;

const CollectionHeader = () => {
  const { t } = useTranslation(["common", "nft", "explore"]);
  //_____________ INITIAL STATE _____________//
  const dispatch = useDispatch();
  const [expandDesc, setExpandDesc] = useState(false);
  const { collectionId } = useParams();
  const { collection } = useSelector((state) => state.CollectionReducer);

  //________________________________________//

  //_____________ GET DETAIL INFO OF COLLECTION _____________//
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getDetailCollection(collectionId));
  }, []);
  //________________________________________//

  function handleEditCollection() {
    dispatch({
      type: EDIT_COLLECTION,
      collection: collection,
    });
    history.push(
      generatePath(EXPLORE_PATH.EDIT_COLLECTION, {
        collectionId: collectionId,
      })
    );
  }

  function redirectToOwners() {
    history.push(
      generatePath(EXPLORE_PATH.LIST_OF_OWNERS_IN_COLLECTION, {
        collectionId: collectionId,
      })
    );
  }

  function redirectToUser(walletAddress) {
    history.push(
      generatePath(EXPLORE_PATH.PROFILE, { walletAddress: walletAddress })
    );
  }

  return (
    <>
      <div className="collectionHeader">
        {_.isNull(collection.imageBanner) || collection.imageBanner === "" ? (
          <div className="collectionBanner">
            <Image
              style={{ objectFit: "cover" }}
              width={"100%"}
              src={"/img/Homepage-Intro.png"}
            />
          </div>
        ) : (
          <div className="collectionBanner">
            {collection.imageBanner ? (
              <Image width={"100%"} src={collection.imageBanner} />
            ) : (
              <Skeleton />
            )}
          </div>
        )}
        <div className="headerContainer">
          <div className="collectionProfile">
            <div className="collectionInfoDetail">
              {Cookies.get("UserAddress") === collection.ownerWalletAddress ? (
                <div className="editCollectionBtn">
                  {editCollectionSvg(handleEditCollection)}
                </div>
              ) : null}
              <div>
                {_.isNull(collection.imageLogo) ||
                collection.imageLogo === "" ? null : (
                  <div className="collectionLogo">
                    {collection.imageLogo ? (
                      <Image
                        width={127}
                        height={127}
                        className="collectionDetailAvt"
                        src={collection.imageLogo}
                      />
                    ) : (
                      <Skeleton.Image />
                    )}
                  </div>
                )}
              </div>
              <div className="nameAndDesc mt-3">
                <div className="ellipsisText">
                  <Tooltip title={collection.name}>
                    <h3>{subStringText(collection.name, 28)}</h3>
                  </Tooltip>
                </div>
                <div
                  className="d-flex justify-content-center align-items-center mt-2 mb-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => redirectToUser(collection?.ownerWalletAddress)}
                >
                  <Image
                    width={42}
                    height={42}
                    preview={false}
                    className="collectionOwner-avt"
                    src={collection?.user?.avatar}
                  />
                  <div className="ellipsisText" style={{ maxWidth: "300px" }}>
                    <StanTypo
                      fontSize={16}
                      fontWeight={700}
                      color="#35A075"
                      customStyle={{ marginLeft: "10px" }}
                      content={collection?.user?.name}
                    />
                  </div>
                </div>
                <div className="socialMedia">
                  {collection.socialLink?.map((item, index) => {
                    return (
                      <div key={index}>
                        {generateMediaSvg(item.type, item.url)}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="collectionDesc">
              {collection.description?.length > 150 ? (
                <span>
                  {!expandDesc
                    ? subStringText(collection.description, 180)
                    : collection.description}
                  <span
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontStyle: "italic",
                    }}
                    onClick={() => setExpandDesc(!expandDesc)}
                  >
                    {" "}
                    {!expandDesc ? (
                      <span>{t("TEXT.MORE")}</span>
                    ) : (
                      <span>{t("TEXT.LESS")}</span>
                    )}
                  </span>
                </span>
              ) : (
                <span>{collection.description}</span>
              )}
            </div>
            <div className="collectionStats">
              <div className="d-flex flex-column align-items-start justify-content-center mr-5">
                <div className="statsItem">
                  <div className="statsItemIcon">
                    <span>{itemWidgetIcon()}</span>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-start">
                    <span className="statsItemValue">
                      {collection.currentNumberNft
                        ? collection.currentNumberNft
                        : 0}
                    </span>
                    <span className="statsItemTitle">{t("STATS.ITEMS")}</span>
                  </div>
                </div>
                <div className="statsItem">
                  <div className="statsItemIcon">
                    <span>{floorWidgetIcon()}</span>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-start">
                    {collection.floorPrice ? (
                      <>
                        <span className="statsItemValue">
                          {/* <WETHIcon /> */}
                          <MaticIcon />
                          {formatStanCryptoDetail(
                            collection.floorPriceDetail.floorPriceCrypto
                          )}
                        </span>
                        <StanTypo
                          fontSize={12}
                          fontWeight={400}
                          color="#CBCBCB"
                          content={
                            <>
                              <StanPointIcon />
                              {formatStanPointDetail(
                                collection.floorPriceDetail.floorPricePoint
                              )}
                            </>
                          }
                        />
                      </>
                    ) : (
                      <span className="statsItemValue">---</span>
                    )}
                    <span className="statsItemTitle">
                      {t("STATS.FLOOR_PRICE")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column align-items-start justify-content-center">
                <div
                  className="statsItem"
                  style={{ cursor: "pointer" }}
                  onClick={redirectToOwners}
                >
                  <div className="statsItemIcon">
                    <span>{ownerWidgetIcon()}</span>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-start">
                    <span className="statsItemValue">
                      {collection.totalOwners}
                    </span>
                    <span className="statsItemTitle">{t("STATS.OWNERS")}</span>
                  </div>
                </div>
                <div className="statsItem">
                  <div className="statsItemIcon">
                    <span>{totalWidgetIcon()}</span>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-start">
                    {collection.totalVolume ? (
                      <>
                        <span className="statsItemValue">
                          {/* <WETHIcon /> */}
                          <MaticIcon />
                          {formatStanCryptoDetail(
                            collection.totalVolumeDetail.totalVolumeCrypto
                          )}
                        </span>
                        <StanTypo
                          fontSize={12}
                          fontWeight={400}
                          color="#CBCBCB"
                          content={
                            <>
                              <StanPointIcon />
                              {formatStanPointDetail(
                                collection.totalVolumeDetail.totalVolumePoint
                              )}
                            </>
                          }
                        />
                      </>
                    ) : (
                      <span className="statsItemValue">---</span>
                    )}
                    <span className="statsItemTitle">
                      {t("STATS.TOTAL_VOLUME")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="collectionStats-responsive">
              <div className="statsItem">
                <span>{itemWidgetIcon()}</span>
                <span>
                  {collection.currentNumberNft
                    ? collection.currentNumberNft
                    : 0}
                </span>
                <span className="statsItemTitle">{t("STATS.ITEMS")}</span>
              </div>
              <div className="statsItem" onClick={redirectToOwners}>
                <span>{ownerWidgetIcon()}</span>
                <span>{collection.totalOwners}</span>
                <span className="statsItemTitle">{t("STATS.OWNERS")}</span>
              </div>
              <div className="statsItem">
                <span>{floorWidgetIcon()}</span>
                {collection.floorPrice ? (
                  <span>
                    {/* <WETHIcon /> */}
                    <MaticIcon />
                    {formatStanCryptoDetail(
                      collection.floorPriceDetail.floorPriceCrypto
                    )}
                  </span>
                ) : (
                  <span>---</span>
                )}
                <span className="statsItemTitle">{t("STATS.FLOOR_PRICE")}</span>
              </div>
              <div className="statsItem">
                <span>{totalWidgetIcon()}</span>
                {collection.totalVolume ? (
                  <span>
                    {/* <WETHIcon /> */}
                    <MaticIcon />
                    {formatStanCryptoDetail(
                      collection.totalVolumeDetail.totalVolumeCrypto
                    )}
                  </span>
                ) : (
                  <span>---</span>
                )}
                <span className="statsItemTitle">
                  {t("STATS.TOTAL_VOLUME")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Sider = () => {
  const { t } = useTranslation(["common"]);
  //_____________ INITIAL STATE _____________//
  const dispatch = useDispatch();
  const { collectionId } = useParams();
  const [activeTabPane, setActiveTabPane] = useState("ITEMS");
  const [inputSearch, setInputSearch] = useState("");
  const [orderBy, setOrderBy] = useState(NFT_SORT_BY.RECENTLY_CREATED);
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedPageSize, setSelectedPageSize] = useState(10);
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    collectionId: collectionId,
    orderBy: orderBy,
  });
  const { listNFT, totalNFT } = useSelector((state) => state.NFTReducer);
  const { listOfOwnersInCollection } = useSelector(
    (state) => state.UserReducer
  );
  const { nftStatus } = useSelector((state) => state.AutionReducer);

  //__________________________________________//

  useEffect(() => {
    dispatch(getUSDRate());
    dispatch(
      getListOfOwnersInCollection(
        50,
        1,
        null,
        encodeURIComponent(collectionId),
        null,
        null
      )
    );
  }, []);

  useEffect(() => {
    setSelectedPageSize(10);
    setSelectedPage(1);
  }, [activeTabPane]);

  useEffect(() => {
    setDataFilter({
      ...dataFilter,
      orderBy: orderBy,
    });
  }, [orderBy]);

  //_________GET LIST OF NFTs in COLLECTION________
  useEffect(() => {
    if (activeTabPane === COLLECTION_DETAIL_TABPANE.ITEMS) {
      console.log("Heressss", dataFilter, selectedPageSize, selectedPage);
      dispatch(searchNFT(dataFilter, selectedPageSize, selectedPage));
    }
  }, [dataFilter, selectedPageSize, selectedPage, activeTabPane]);

  //_____________ HANDLE FUNCs _____________//
  function filterNFT(data) {
    setDataFilter({
      ...dataFilter,
      status: data.status,
      currency: data.currency,
      fromPrice: data.fromPrice,
      toPrice: data.toPrice,
      categoryIds: data.categoryIds,
      walletAddress: data.walletAddress,
    });
    setSelectedPage(1);
    setSelectedPageSize(10);
  }

  function reSearchNFT() {
    dispatch(searchNFT(dataFilter, selectedPageSize, selectedPage));
  }

  function renderTabPaneTitle(title) {
    let icon;
    if (title === t("TABPANE.ITEMS")) {
      icon = exploreItemsSvg(activeTabPane === title ? true : false);
    } else if (title === t("TABPANE.ACTIVITY")) {
      icon = activityTabPaneSvg(activeTabPane === title ? true : false);
    }
    return (
      <span style={{ color: activeTabPane === title ? "#49D19A" : "#CBCBCB" }}>
        {icon} {title}
      </span>
    );
  }

  function handleChangeTabPane(e) {
    setActiveTabPane(e);
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      setSelectedPage(1);
      setDataFilter({
        ...dataFilter,
        name: encodeURIComponent(inputSearch),
      });
    }
  }

  function handleOpenFilter() {
    if (Cookies.get("DEVICE") === "MOBILE") {
      showDrawer();
    } else {
      setShowMenu(!showMenu);
    }
  }

  const showDrawer = () => {
    setVisibleFilter(true);
  };

  const onClose = () => {
    setVisibleFilter(false);
  };

  const RenderNFTList = useMemo(() => {
    return (
      <Spin spinning={nftStatus === REQUESTING}>
        <StanFilterPreview />
        {listNFT?.length > 0 ? (
          <>
            <div
              className={
                showMenu ? "nftCardSection" : "nftCardSection-collapsed"
              }
            >
              {listNFT.map((item) => {
                return (
                  <StanNFTCard
                    key={item.id}
                    nft={item}
                    lang={i18next.language}
                    searchNFT={reSearchNFT}
                  />
                );
              })}
            </div>
            <div style={{ marginTop: "50px" }}>
              <Pagination
                responsive={true}
                showSizeChanger={true}
                current={selectedPage}
                pageSize={selectedPageSize}
                onChange={(page, pageSize) => {
                  setSelectedPage(page);
                  setSelectedPageSize(pageSize);
                }}
                total={totalNFT}
              ></Pagination>
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Empty description={t("MSG.NO_RESULTS_FOUND")} />
          </div>
        )}
      </Spin>
    );
  }, [nftStatus, listNFT, showMenu, i18next.language]);

  return (
    <>
      <div
        style={{
          backgroundColor: "#FFFFFF",
          display: "flex",
          overflow: "hidden",
          paddingBottom: "50px",
        }}
      >
        <div className="collectionDetailTabPane">
          <Tabs
            defaultActiveKey={COLLECTION_DETAIL_TABPANE.ITEMS}
            onChange={(e) => handleChangeTabPane(e)}
          >
            <TabPane
              tab={renderTabPaneTitle(t("TABPANE.ITEMS"))}
              key={COLLECTION_DETAIL_TABPANE.ITEMS}
            >
              <div className="mainSection">
                <Affix offsetTop={0}>
                  <div className="searchSection">
                    <div
                      className="d-flex justify-content-center mr-3 filterToggle"
                      style={{ width: "5%" }}
                    >
                      <div
                        style={
                          showMenu
                            ? {
                                backgroundColor: "#49D19A",
                                borderRadius: "12px",
                                padding: "12px",
                              }
                            : {
                                backgroundColor: "#FFFFFF",
                                borderRadius: "12px",
                                padding: "12px",
                              }
                        }
                      >
                        {filterSvg(handleOpenFilter)}
                      </div>
                    </div>
                    <div style={{ marginRight: 10, width: "85%" }}>
                      <Input
                        className="inputSearchNft"
                        placeholder={t("PLACEHOLDER.SEARCH", { ns: "explore" })}
                        onChange={(e) => setInputSearch(e.target.value)}
                        onKeyPress={handleKeyPress}
                        prefix={
                          <svg
                            width="15"
                            height="16"
                            viewBox="0 0 15 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.4167 9.87907H9.75833L9.525 9.65407C10.3417 8.70407 10.8333 7.47074 10.8333 6.12907C10.8333 3.1374 8.40833 0.712402 5.41667 0.712402C2.425 0.712402 0 3.1374 0 6.12907C0 9.12074 2.425 11.5457 5.41667 11.5457C6.75833 11.5457 7.99167 11.0541 8.94167 10.2374L9.16667 10.4707V11.1291L13.3333 15.2874L14.575 14.0457L10.4167 9.87907ZM5.41667 9.87907C3.34167 9.87907 1.66667 8.20407 1.66667 6.12907C1.66667 4.05407 3.34167 2.37907 5.41667 2.37907C7.49167 2.37907 9.16667 4.05407 9.16667 6.12907C9.16667 8.20407 7.49167 9.87907 5.41667 9.87907Z"
                              fill="#CBCBCB"
                            />
                          </svg>
                        }
                      />
                    </div>
                    <div style={{ width: "10%" }}>
                      <Select
                        value={orderBy}
                        className="inputSelectNft"
                        onChange={(e) => setOrderBy(e)}
                      >
                        <Option value="RECENTLY_CREATED">
                          {t("SELECT.TITLE.RECENTLY_CREATED", {
                            ns: "explore",
                          })}
                        </Option>
                        <Option value="RECENTLY_LISTED">
                          {t("SELECT.TITLE.RECENTELY_LISTED", {
                            ns: "explore",
                          })}
                        </Option>
                        <Option value="OLDEST">
                          {t("SELECT.TITLE.OLDEST", {
                            ns: "explore",
                          })}
                        </Option>
                        <Option value="PRICE_LOW_HIGH">
                          {t("SELECT.TITLE.LOW_TO_HIGH", {
                            ns: "explore",
                          })}
                        </Option>
                        <Option value="PRICE_HIGH_LOW">
                          {t("SELECT.TITLE.HIGH_TO_LOW", {
                            ns: "explore",
                          })}
                        </Option>
                      </Select>
                    </div>
                  </div>
                </Affix>
                <div style={{ display: "flex" }}>
                  <div
                    className="menuSide fade-in-2"
                    style={!showMenu ? { display: "none" } : null}
                  >
                    <Affix offsetTop={100}>
                      <StanFilter
                        showStatus
                        showPrice
                        showCategory
                        showArtist
                        listArtist={listOfOwnersInCollection}
                        filterNFT={filterNFT}
                      />
                    </Affix>
                  </div>
                  <div style={{ width: "100%" }}>{RenderNFTList}</div>
                </div>
              </div>
            </TabPane>
            <TabPane
              tab={renderTabPaneTitle(t("TABPANE.ACTIVITY"))}
              key={COLLECTION_DETAIL_TABPANE.ACTIVITY}
            >
              <CollectionActivity activeTabPane={activeTabPane} />
            </TabPane>
          </Tabs>
        </div>
      </div>
      <Drawer
        title={t("TITLE.FILTER_NFT")}
        placement={"bottom"}
        closable={true}
        onClose={onClose}
        visible={visibleFilter}
      >
        <div>
          <StanFilter
            showStatus
            showPrice
            showCategory
            showArtist
            listArtist={listOfOwnersInCollection}
            filterNFT={filterNFT}
          />
        </div>
      </Drawer>
    </>
  );
};

export default function CollectionDetail() {
  return (
    <>
      <CollectionHeader />
      <Sider />
    </>
  );
}
