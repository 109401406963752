import { Avatar, Select, Spin } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { openNotificationWithIcon } from "../../../App";
import {
  MaticIcon,
  receiverSvg,
  transferSvg,
  WETHIcon,
} from "../../../common/commonSvg";
import ModalInfoNFT from "../../../component/ModalInfoNFT/ModalInfoNFT";
import StanButton from "../../../component/StanButton/StanButton";
import StanTypo from "../../../component/StanTypo/StanTypo";
import { transferNft } from "../../../redux/action/AutionAction";
import { OPEN_DEPOSIT } from "../../../redux/types/ModalType";
import {
  COMPLETE_REQUEST,
  FAILED_REQUEST,
  INSUFFICENT_FUND,
  PROCESSING,
  REQUESTING,
} from "../../../redux/types/UserType";
import { userService } from "../../../services/UserService";
import { NETWORK_URL } from "../../../util/settings/config";
import { fromEtherToValue } from "../../../util/settings/utils";
import "./TransferNft.css";
import { MsgInsufficientBalance } from "../../../common/Constant";

const messageError = "This user has not connected the Wallet Address yet! ";
/* <--  TransferNftBody  --> */
export default function TransferNft(props) {
  const { t } = useTranslation(["common", "nft"]);
  const { nftStatus, txtHash, stanFixed, balanceOfFan } = useSelector(
    (state) => state.AutionReducer
  );
  const { payload, onClose } = useSelector((state) => state.ModalReducer);
  const { nft } = payload;
  console.log("NFT =-", nft);
  const [receiver, setReceiver] = useState();
  const [walletAddress, setWalletAddress] = useState("");
  const dispatch = useDispatch();
  const isInsufficientStanFund =
    Number(balanceOfFan) <
    Number(fromEtherToValue(stanFixed?.feeListingNFTAuction));

  function renderContent() {
    switch (nftStatus) {
      case REQUESTING:
        return (
          <div className="loadingTransferNFT">
            <Spin style={{ margin: "0px 10px" }} />
            <p> {t("TEXT.PROCESSING", { ns: "nft" })}</p>
          </div>
        );
      case PROCESSING:
        return (
          <div className="loadingTransferNFT">
            <Spin style={{ margin: "0px 10px" }} />
            <span>{t("TEXT.PROCESSING", { ns: "nft" })}</span>
          </div>
        );
      case COMPLETE_REQUEST:
        return (
          <div className="completeTransferNFT">
            <div className="d-flex justify-content-center">
              <img
                alt=""
                src={
                  nft?.mediaType === "VIDEO" || nft?.mediaType === "AUDIO"
                    ? nft?.mediaPreviewUrl
                    : nft?.mediaUrl
                }
                style={{ objectFit: "cover", borderRadius: "6px" }}
                width={56}
                height={56}
              />
              <div className="ml-3 d-flex flex-column justify-content-center">
                <StanTypo
                  fontSize={14}
                  fontWeight={400}
                  color="#8D8D8D"
                  content={nft.collectionName}
                />
                <StanTypo
                  fontSize={16}
                  fontWeight={600}
                  color="#0D0D0D"
                  content={nft.name}
                />
              </div>
              {/* <ModalInfoNFT nft={nft} disableRight={true} /> */}
            </div>
            <div className="text-center m-3">
              <span>
                {nft.name} {t("TEXT.WILL_BE_TRANSFERRED", { ns: "nft" })}{" "}
                {walletAddress}
              </span>
            </div>
            <StanButton
              disabled={!txtHash}
              title={t("BUTTON.VIEW_TRANSACTION")}
              type="primary"
              handleOnClick={() => window.open(NETWORK_URL + "tx/" + txtHash)}
            />
          </div>
        );
      case INSUFFICENT_FUND:
        return (
          <div className="completeTransferNFT">
            <img
              alt=""
              src="/img/insufficent-fund.png"
              width={150}
              height={150}
            />
            <p>{t("TEXT.INSUFFICIENT_FUND", { ns: "nft" })}</p>
            <StanButton
              title={t("BUTTON.RECHARGE")}
              handleOnClick={() => {
                dispatch({ type: OPEN_DEPOSIT });
              }}
              type="primary"
            />
            <StanButton
              title={t("BUTTON.CANCEL")}
              handleOnClick={() => {
                props.closeModal();
              }}
              type="secondary"
            />
          </div>
        );

      default:
        return <ReceiverTransfer nft={nft} />;
    }
  }

  const ReceiverTransfer = (props) => {
    const [listUser, setListUser] = useState([]);
    const [error, setError] = useState("");
    const { nftStatus, txtHash } = useSelector((state) => state.AutionReducer);

    const { Option } = Select;

    const handleSearch = async (data) => {
      await userService.getUserByName(data).then((result) => {
        setListUser(result.data.data.users);
      });
    };

    const handleTransfer = () => {
      if (isInsufficientStanFund) {
        dispatch({ type: FAILED_REQUEST });
        openNotificationWithIcon("error", "Error", MsgInsufficientBalance);
        return;
      }
      if (walletAddress) {
        dispatch(
          transferNft({
            receiverAddress: walletAddress,
            nftId: props.nft.id,
            collectionId: props.nft.collectionId,
            tokenId: props.nft.tokenId,
          })
        );
      } else if (!receiver || receiver.trim() === "") {
        setError(t("MSG.RECEIVER_MUST_NOT_BE_BLANK"));
      } else {
        openNotificationWithIcon(
          "error",
          "Warning",
          t("MSG.RECEIVER_WALLET_ADDRESS_NOT_FOUND")
        );
      }
    };

    const handleChange = async (newValue) => {
      const currentUser = listUser.filter((e) => e.id == newValue)[0];
      setReceiver(currentUser);
      // search wallet address of user
      const getWalletAddress = await userService.getWalletAddress({
        id: currentUser.id,
      });
      let walletAddressUser = getWalletAddress.data.data.walletAddress;
      if (walletAddressUser == null) {
        openNotificationWithIcon("error", "Warning", messageError);
        walletAddressUser = messageError;
      }
      setWalletAddress(walletAddressUser);
    };

    const options = listUser.map((d) => (
      <Option key={d.id} className="selectOption">
        <img alt="" className="selectOptionImg" src={d.avatar} /> {d.name}
      </Option>
    ));

    return (
      <div className="TransferNftBody">
        <ModalInfoNFT nft={nft} disableRight={true} />
        {console.log("ALOHA =>", props.nft)}
        <div className="TransferNftBodyReceiver">
          <span>{t("TEXT.RECEIVER", { ns: "nft" })}</span>
          {receiver ? (
            <>
              <div className="ReceiverNFT">
                <div className="ReceiverNFTAvatar">
                  <Avatar src={receiver.avatar} size="large" />
                  <p> {receiver.name}</p>
                </div>
                <button
                  onClick={() => {
                    setReceiver();
                    setWalletAddress();
                  }}
                >
                  {receiverSvg()}
                </button>
              </div>
              <div className="ReceiverWalletAddresss">
                <h4>{t("TEXT.WALLET_ADDRESS", { ns: "nft" })}</h4>
                <p>{walletAddress}</p>
              </div>
            </>
          ) : (
            <>
              <Select
                style={{ width: "100%", color: "white", marginBottom: 0 }}
                autoFocus
                showSearch
                placeholder={
                  <React.Fragment>
                    {receiverSvg()} {t("BUTTON.ADD_RECEIVER")}
                  </React.Fragment>
                }
                value={receiver}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearch}
                onChange={handleChange}
                notFoundContent={null}
              >
                {options}
              </Select>
              <span style={{ color: "red" }}>{error}</span>
            </>
          )}
        </div>
        <div className="stanFee">
          <span>{t("TEXT.SERVICE_FEE", { ns: "nft" }).toUpperCase()}</span>
          <span>
            <MaticIcon /> {fromEtherToValue(stanFixed?.feeTransferNFTPvP)}
          </span>
        </div>
        <div className="TransferNftBodyButton">
          <StanButton
            handleOnClick={handleTransfer}
            disabled={nftStatus === REQUESTING}
            title={t("MENU.NFT_TYPE.TRANSFER", { ns: "common" })}
            type="primary"
          />
        </div>
      </div>
    );
  };

  return renderContent();
}
