import { Input } from "antd";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MaticIcon, stanPointSvg, WETHIcon } from "../../common/commonSvg";
import StanButton from "../../component/StanButton/StanButton";
import { getBalanceOfFan, withdraw } from "../../redux/action/AutionAction";
import { getStanToken } from "../../redux/action/StanTokenAction";
import {
  COMPLETE_REQUEST,
  PENDING_REQUEST,
  PROCESSING,
  REQUESTING,
} from "../../redux/types/UserType";
import {
  decimalCharacters,
  formatStanCryptoDetail,
  subStringAddress,
} from "../../util/settings/utils";

export default function Withdraw(props) {
  const { t } = useTranslation(["common"]);
  const [stanFund, setStanFund] = useState();
  const [error, setError] = useState("");
  const { nftStatus, balanceOfFan, txtHash } = useSelector(
    (state) => state.AutionReducer
  );
  const { stanToken } = useSelector((state) => state.StanTokenReducer);
  const dispatch = useDispatch();

  // RESET FORM
  useEffect(() => {
    if (nftStatus === PENDING_REQUEST) {
      setStanFund("");
    }
  }, [nftStatus]);

  useEffect(() => {
    dispatch(getBalanceOfFan());
    dispatch(getStanToken());
  }, []);

  const handleChange = (e) => {
    if (e.target.value.length > 6) {
      setStanFund(+parseFloat(e.target.value).toFixed(6));
    } else {
      setStanFund(e.target.value);
    }
  };

  function confirmWithdraw() {
    if (validate()) {
      dispatch(withdraw(stanFund));
    }
  }

  function validate() {
    let isValid = true;
    setError("");
    if (stanFund === "") {
      setError(t("MSG.AMOUNT_STAN_FUNDS_REQUIRED"));
      isValid = false;
    } else if (stanFund <= 0) {
      setError(t("MSG.AMOUNT_STAN_FUND"));
      isValid = false;
    } else if (stanFund > balanceOfFan) {
      setError(t("MSG.CANT_WITHDRAW"));
      isValid = false;
    }
    return isValid;
  }

  function renderContent() {
    switch (nftStatus) {
      case COMPLETE_REQUEST:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img alt="" src="/img/Frame.png" width={150} height={150} />
            <span>{t("MSG.WITHDRAW_REQUEST_SUBMITTED")} </span>
            <div
              style={{
                width: "100%",
              }}
              className="d-flex justify-content-between"
            >
              <span>{t("TITLE.AMOUNT")}</span>
              <span>
                <MaticIcon /> {formatStanCryptoDetail(stanFund)}
              </span>
            </div>
            <div
              style={{
                width: "100%",
                padding: "20px 0px",
                margin: "10px 0px",
                borderBottom: "1px solid #EEEEEE",
              }}
              className="d-flex justify-content-between"
            >
              <span>{t("TITLE.WALLET_ADDRESS")}</span>
              <span>{subStringAddress(Cookies.get("UserAddress"))}</span>
            </div>
            <StanButton
              title="View Transaction"
              handleOnClick={() => {
                window.open("https://mumbai.polygonscan.com/tx/" + txtHash);
              }}
              type="primary"
            />
          </div>
        );
      default:
        return (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "10px 0px",
              }}
            >
              <div className="d-flex justify-content-between">
                <span>{t("TITLE.AVAILBLE_BALANCE")}</span>
                <span>
                  <MaticIcon /> {balanceOfFan}
                </span>
              </div>
              <div className="d-flex justify-content-between mt-3">
                <span>{t("TITLE.AMOUNT")}</span>
              </div>
            </div>
            <Input
              placeholder={t("PLACEHOLDER.INPUT_AMOUNT")}
              type="number"
              value={stanFund}
              onKeyPress={decimalCharacters}
              addonAfter={<MaticIcon />}
              onChange={handleChange}
            />
            <span style={{ color: "red" }}>{error === "" ? null : error}</span>
            <div className="d-flex justify-content-between mt-3">
              <span>{t("TITLE.WALLET_ADDRESS")}</span>
              <span>{subStringAddress(Cookies.get("UserAddress"))}</span>
            </div>
            <div style={{ display: "flex", margin: "20px 0px" }}>
              {/* <StanButton
                title={t("BUTTON.GO_BACK")}
                handleOnClick={() => props.closeModal()}
                type="secondary"
                style={{margin:"0px 5px"}}
              /> */}
              <StanButton
                title={t("BUTTON.WITHDRAW")}
                disabled={
                  +stanFund > +balanceOfFan ||
                  nftStatus === REQUESTING ||
                  nftStatus === PROCESSING
                }
                handleOnClick={() => confirmWithdraw()}
                type="primary"
                style={{ margin: "0px 5px" }}
              />
            </div>
          </>
        );
    }
  }

  return renderContent();
}
