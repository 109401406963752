import Cookies from "js-cookie";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { history } from "../../App";
import { EXPLORE_PATH, HOME_SECTIONS, STAN_ROLE } from "../../common/Constant";
import {
  MaticIcon,
  SampleNextArrow,
  SamplePrevArrow,
} from "../../common/commonSvg";
import StanButton from "../../component/StanButton/StanButton";
import StanNFTCard from "../../component/StanNFTCard/StanNFTCard";
import { getPositionSection, shuffleNFT } from "../../redux/action/NftAction";
import { getUSDRate } from "../../redux/action/UserAction";
import { checkWalletIsConnected } from "../../redux/action/Web3Action";
import ArtistLatestNFT from "./ArtistLatestNFTSection/ArtistLatestNFT";
import "./Home.css";
import HotBid from "./HotBidSection/HotBid";
import HotCollectionSection from "./HotCollectionSection/HotCollectionSection";
import LatestSale from "./LatestSaleSection/LatestSale";
import LiveAuctionSection from "./LiveAuctionSection/LiveAuctionSection";
import {
  getBalanceOfFan,
  getStanBalance,
  getUserMatics,
} from "../../redux/action/AutionAction";
import FixedPriceNFTSection from "./FixedPriceNFTSection/FixedPriceNFTSection";
export default function Home() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["common", "homePage"]);
  const userProfile = JSON.parse(localStorage.getItem("UserProfile"));
  const { token } = useParams();
  const { nftShuffle, positionSection, listNFT } = useSelector(
    (state) => state.NFTReducer
  );

  const settingsHotOfferLanding = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    centerPadding: "0px",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  async function validateJwt() {
    let jwtRegex = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/;
    if (!_.isUndefined(token) && jwtRegex.test(token)) {
      Cookies.set("TokenId", token);
      window.location.replace("/");
    }
  }

  useEffect(() => {
    if (token && token !== "deepLinked") {
      validateJwt();
    }
    if (token && token === "deepLinked") {
      dispatch(checkWalletIsConnected());
    }
  }, []);

  //Call API
  useEffect(() => {
    dispatch(getUSDRate());
    dispatch(shuffleNFT());
    dispatch(getPositionSection());
    // dispatch(getStanBalance());
  }, []);

  const RenderSection = useMemo(
    () => (
      <>
        {positionSection.map((sectionItem, index) => {
          if (sectionItem.section === HOME_SECTIONS.ARTIST_LATEST_NFT) {
            return (
              <div key={index} className="artistLatestContainer fade-in-3">
                <ArtistLatestNFT />
              </div>
            );
          }
          if (sectionItem.section === HOME_SECTIONS.LATEST_SALE) {
            return (
              <div key={index} className="latestSaleContainer fade-in-3">
                <LatestSale />
              </div>
            );
          }
          if (sectionItem.section === HOME_SECTIONS.LIVE_AUCTION) {
            return (
              <div key={index} className="liveAuctionContainer fade-in-3">
                <LiveAuctionSection />
              </div>
            );
          }
          if (sectionItem.section === HOME_SECTIONS.HOT_COLLECTION) {
            return (
              <div key={index} className="hotCollectionContainer fade-in-3">
                <HotCollectionSection />
              </div>
            );
          }
          if (sectionItem.section === HOME_SECTIONS.HOT_OFFER) {
            return (
              <div key={index} className="hotBidContainer fade-in-3">
                <HotBid />
              </div>
            );
          }
          if (sectionItem.section === HOME_SECTIONS.FIXED_PRICE) {
            return (
              <div key={index} className="fixedPriceContainer fade-in-3">
                <FixedPriceNFTSection />
              </div>
            );
          }
          return null;
        })}
      </>
    ),
    [positionSection]
  );

  return (
    <>
      <div
        className="homePage"
        style={{
          backgroundColor: "#FFFFFF",
          overflow: "hidden",
          minHeight: "200vh",
        }}
      >
        <div className="landingHome">
          <div className="introduceTextContainer" style={{ width: "30%" }}>
            <span style={{ fontFamily: "Inter" }}>
              {t("HOME_PAGE_TITLE", { ns: "homePage" })}
            </span>
            <div style={{ marginTop: "20px" }}>
              <span>{t("HOME_PAGE_DESCRIPTION", { ns: "homePage" })}</span>
            </div>
            <div className="introduceButton">
              <StanButton
                title={t("BUTTON.EXPLORE")}
                type="primary"
                handleOnClick={() => history.push(EXPLORE_PATH.EXPLORE)}
              />
              <StanButton
                title={t("BUTTON.CREATE")}
                hidden={
                  (userProfile && userProfile.role !== STAN_ROLE.TALENT) ||
                  !Cookies.get("TokenId")
                }
                type="transfer"
                handleOnClick={() => history.push(EXPLORE_PATH.CREATE_NFT)}
              />
            </div>
          </div>
          <div
            className="landingHome-coverFlow"
            style={
              nftShuffle.length >= 5 ? { width: "60%" } : { display: "none" }
            }
          >
            <Slider {...settingsHotOfferLanding}>
              {nftShuffle.map((nft) => {
                return (
                  <StanNFTCard
                    // viewOnly
                    type="HOT_OFFER"
                    nft={nft}
                    key={nft.id}
                  />
                );
              })}
            </Slider>
          </div>
          <div className="circleBackground">
            <img width={500} alt="" src="/img/Circle.png" />
          </div>
        </div>
        {RenderSection}
      </div>
    </>
  );
}
