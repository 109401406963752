import { Affix, Empty, Select, Spin } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { NFT_SORT_BY } from "../../common/Constant";
import { SampleNextArrow, SamplePrevArrow } from "../../common/commonSvg";
import StanCollectionCard from "../../component/StanCollectionCard/StanCollectionCard";
import StanFilter from "../../component/StanFilter/StanFilter";
import StanFilterPreview from "../../component/StanFilter/StanFilterPreview";
import StanNFTCard from "../../component/StanNFTCard/StanNFTCard";
import StanTypo from "../../component/StanTypo/StanTypo";
import { StanUserItem } from "../../component/StanUserItem/StanUserItem";
import { searchCollection } from "../../redux/action/CollectionAction";
import { searchNFT } from "../../redux/action/NftAction";
import { getAllArtist, getListArtist } from "../../redux/action/UserAction";
import { CLEAR_STATE, REQUESTING } from "../../redux/types/UserType";
import "./SearchPage.css";

const { Option } = Select;
export default function SearchPage(props) {
  const { t, i18n } = useTranslation(["common", "homePage", "explore"]);
  const dispatch = useDispatch();
  const { keyword } = useParams();

  //   ______________ INITIAL STATE ______________
  const [sortBy, setSortBy] = useState(NFT_SORT_BY.RECENTLY_CREATED);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [dataFilter, setDataFilter] = useState({
    name: encodeURIComponent(keyword),
  });
  const [collectionPageSize, setCollectionPageSize] = useState(25);
  const [collectionSelectedPage, setCollectionSelectedPage] = useState(1);

  // const [nftPageSize, setNftPageSize] = useState(25);
  const nftPageSize = 1000; // dont use paging when search
  const [nftSelectedPage, setNftSelectedPage] = useState(1);

  // const [artistPageSize, setArtistPageSize] = useState(50);
  // const [artistSelectedPage, setArtistSelectedPage] = useState(1);

  const { listCollection, totalCollection } = useSelector(
    (state) => state.CollectionReducer
  );
  const { listNFT, totalNFT } = useSelector((state) => state.NFTReducer);

  const { listArtist, allArtists } = useSelector((state) => state.UserReducer);

  const { nftStatus } = useSelector((state) => state.AutionReducer);

  //   ______________ INITIAL STATE ______________

  //   ______________ USE EFFECTS ______________
  useEffect(() => {
    dispatch({ type: CLEAR_STATE });
    dispatch(getAllArtist(50, 1, "", 1));
  }, []);

  useEffect(() => {
    setDataFilter({ ...dataFilter, name: encodeURIComponent(keyword) });
    dispatch(
      searchCollection(
        collectionPageSize,
        collectionSelectedPage,
        null,
        encodeURIComponent(keyword)
      )
    );
    dispatch(getListArtist(25, 1, encodeURIComponent(keyword), "", 1));
  }, [keyword]);

  useEffect(() => {
    setDataFilter({ ...dataFilter, orderBy: sortBy });
  }, [sortBy]);

  useEffect(() => {
    dispatch(searchNFT(dataFilter, nftPageSize, nftSelectedPage));
  }, [dataFilter, nftPageSize, nftSelectedPage]);

  //   ______________ USE EFFECTS ______________

  //   ______________ RENDER UI  ______________
  const sliderSettingCollection = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1880,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const sliderSettingUser = {
    infinite: true,
    dots: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    centerPadding: "0px",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1880,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const RenderCollectionList = useMemo(() => {
    if (listCollection.length !== 0 && listCollection.length <= 4) {
      return (
        <div className="collectionContainer">
          {listCollection.map((collection) => {
            return (
              <StanCollectionCard
                style={{ margin: "0px 12px !important" }}
                key={collection.id}
                collection={collection}
              />
            );
          })}
        </div>
      );
    } else if (listCollection.length !== 0 && listCollection.length >= 4) {
      return (
        <Slider {...sliderSettingCollection}>
          {listCollection.map((collection) => {
            return (
              <StanCollectionCard
                style={{ margin: "0px 12px !important" }}
                key={collection.id}
                collection={collection}
              />
            );
          })}
        </Slider>
      );
    } else if (listCollection.length === 0) {
      return (
        <div className="collectionContainer">
          <Empty
            description={"Not found any collection named as '" + keyword + "'"}
          />
        </div>
      );
    }
  }, [listCollection]);

  const RenderNFTList = useMemo(() => {
    if (listNFT.length > 0) {
      return (
        <>
          <div className="itemsContainer">
            {listNFT.map((nft, index) => {
              return <StanNFTCard nft={nft} key={nft.id} />;
            })}
          </div>
          {/* <div style={{ marginTop: "50px" }}>
            <Pagination
              responsive={true}
              showSizeChanger={true}
              current={nftSelectedPage}
              pageSize={nftPageSize}
              onChange={(page, pageSize) => {
                setNftSelectedPage(page);
                setNftPageSize(pageSize);
              }}
              total={totalNFT}
            />
          </div> */}
        </>
      );
    } else if (listNFT.length === 0) {
      return (
        <div className="itemsContainer">
          <Empty description={"Not found any NFT named as '" + keyword + "'"} />
        </div>
      );
    }
  }, [listNFT]);

  const RenderUserList = useMemo(() => {
    if (listArtist.length !== 0 && listArtist.length <= 6) {
      return (
        <div className="userContainer">
          {listArtist.map((artist) => {
            return <StanUserItem key={artist.id} artist={artist} />;
          })}
        </div>
      );
    } else if (listArtist.length !== 0 && listArtist.length > 6) {
      return (
        <>
          <Slider {...sliderSettingUser}>
            {listArtist.map((artist) => {
              return <StanUserItem key={artist.id} artist={artist} />;
            })}
          </Slider>
        </>
      );
    } else if (listArtist.length === 0) {
      return (
        <div className="userContainer">
          <Empty
            description={"Not found any User named as '" + keyword + "'"}
          />
        </div>
      );
    }
  }, [listArtist]);

  //   ______________ RENDER UI  ______________

  function sortSection(section) {
    let totalItem = totalNFT;
    let totalCollection = listCollection.length;
    let totalArtist = listArtist.length;
    const totalRecord = [
      {
        section: "collections",
        records: totalCollection,
      },
      {
        section: "users",
        records: totalArtist,
      },
      {
        section: "items",
        records: totalItem,
      },
    ];
    const sortedRecord = totalRecord.sort(function (a, b) {
      return b.records - a.records;
    });
    if (sortedRecord[0].section === section) {
      return "firstSection";
    } else if (sortedRecord[1].section === section) {
      return "secondSection";
    } else {
      return "thirdSection";
    }
  }

  function filterNFT(data) {
    setDataFilter({
      ...dataFilter,
      status: data.status,
      currency: data.currency,
      fromPrice: data.fromPrice,
      toPrice: data.toPrice,
      collectionId: data.collectionIds,
      categoryIds: data.categoryIds,
      walletAddress: data.walletAddress,
    });
  }

  return (
    <>
      <div className="SearchPage">
        <div className="SearchPage-header">
          <Select
            defaultValue={NFT_SORT_BY.RECENTLY_CREATED}
            value={sortBy}
            className="SearchPage-sort"
            onChange={(e) => setSortBy(e)}
          >
            <Option value={NFT_SORT_BY.RECENTLY_CREATED}>
              {t("SELECT.TITLE.RECENTLY_CREATED", { ns: "explore" })}
            </Option>
            <Option value={NFT_SORT_BY.RECENTLY_LISTED}>
              {t("SELECT.TITLE.RECENTELY_LISTED", { ns: "explore" })}
            </Option>
            <Option value={NFT_SORT_BY.OLDEST}>
              {t("SELECT.TITLE.OLDEST", { ns: "explore" })}
            </Option>
            <Option value={NFT_SORT_BY.PRICE_LOW_HIGH}>
              {t("SELECT.TITLE.LOW_TO_HIGH", { ns: "explore" })}
            </Option>
            <Option value={NFT_SORT_BY.PRICE_HIGH_LOW}>
              {t("SELECT.TITLE.HIGH_TO_LOW", { ns: "explore" })}
            </Option>
          </Select>
        </div>

        <Spin spinning={nftStatus === REQUESTING}>
          <div className="SearchPage-container">
            <div className="SearchPage-menuSide menuSide">
              <Affix offsetTop={10}>
                <StanFilter
                  showStatus
                  showPrice
                  showCategory
                  showArtist
                  showCollection
                  listCollection={listCollection}
                  listArtist={allArtists}
                  filterNFT={filterNFT}
                />
              </Affix>
            </div>
            <div className="SearchPage-content">
              <StanFilterPreview />
              <div id={sortSection("collections")}>
                <div className="mt-4 mb-4">
                  <StanTypo
                    fontSize={24}
                    fontWeight={700}
                    color="#000000"
                    content={t("RESULT.COLLECTIONS", {
                      result: listCollection.length,
                      ns: "homePage",
                    })}
                  />
                </div>
                {RenderCollectionList}
              </div>

              <div id={sortSection("users")}>
                <div className="mt-5 mb-4">
                  <StanTypo
                    fontSize={24}
                    fontWeight={700}
                    color="#000000"
                    content={t("RESULT.USERS", {
                      result: listArtist.length,
                      ns: "homePage",
                    })}
                  />
                </div>
                {RenderUserList}
              </div>

              <div id={sortSection("items")}>
                <div className="mt-5 mb-4">
                  <StanTypo
                    fontSize={24}
                    fontWeight={700}
                    color="#000000"
                    content={t("RESULT.ITEMS", {
                      result: totalNFT,
                      ns: "homePage",
                    })}
                  />
                </div>
                {RenderNFTList}
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </>
  );
}
