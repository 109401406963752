import { Empty, Select, Spin } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { history } from "../../../App";
import {
  HotCollectionIcon,
  SampleNextArrow,
  SamplePrevArrow,
} from "../../../common/commonSvg";
import StanButton from "../../../component/StanButton/StanButton";
import StanCollectionCard from "../../../component/StanCollectionCard/StanCollectionCard";
import { getHotCollections } from "../../../redux/action/UserAction";
import { SET_HOT_COLLECTION_SORT } from "../../../redux/types/CollectionType";
import { REQUESTING } from "../../../redux/types/UserType";
import "./HotCollectionSection.css";
import { COLLECTION_SORT } from "../../../common/commonParams";
import { EXPLORE_PATH } from "../../../common/Constant";
const { Option } = Select;

export default function HotCollectionSection() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["common", "homePage"]);
  const [activeSort, setActiveSort] = useState(COLLECTION_SORT.ONE_DAY);

  const { listHotCollections } = useSelector((state) => state.UserReducer);
  const { nftStatus } = useSelector((state) => state.AutionReducer);

  //Call API
  useEffect(() => {
    dispatch(getHotCollections({ time: activeSort }, 10, 1));
  }, [activeSort]);

  const settingsHotCollection = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow:
      listHotCollections.length === 3 || listHotCollections.length === 4
        ? listHotCollections.length
        : 5,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow:
            listHotCollections.length < 4 ? listHotCollections.length : 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const HotCollection = useMemo(() => {
    if (listHotCollections.length === 0) {
      return (
        <div className="mt-5">
          <Empty description={t("NONE_COLLECTION", { ns: "homePage" })} />
        </div>
      );
    } else if (listHotCollections.length <= 2) {
      return (
        <>
          <div className="HotCollectionSection-list">
            {listHotCollections.map((collection) => {
              return (
                <StanCollectionCard
                  type="HOT_COLLECTION"
                  collection={collection}
                  key={collection.collectionId}
                />
              );
            })}
          </div>
        </>
      );
    } else {
      return (
        <Slider {...settingsHotCollection}>
          {listHotCollections.map((collection) => {
            return (
              <StanCollectionCard
                type="HOT_COLLECTION"
                collection={collection}
                key={collection.collectionId}
              />
            );
          })}
        </Slider>
      );
    }
  }, [listHotCollections]);

  return (
    <>
      <div className="HotCollectionSection">
        <div className="HotCollectionSection-header">
          <div className="d-flex" style={{ width: "50%" }}>
            <div className="HotCollectionIcon mr-2">
              <HotCollectionIcon />
            </div>
            <h2>{t("HOT_COLLECTION", { ns: "homePage" })}</h2>
          </div>
          <div
            style={{ width: "35%" }}
            className="hotCollection-sort d-flex justify-content-end"
          >
            <StanButton
              noUpperCase
              style={{ maxHeight: "32px", borderRadius: "10px" }}
              type={
                activeSort === COLLECTION_SORT.ONE_DAY ? "primary" : "secondary"
              }
              handleOnClick={() => {
                setActiveSort(COLLECTION_SORT.ONE_DAY);
                dispatch({
                  type: SET_HOT_COLLECTION_SORT,
                  hotCollectionSort: COLLECTION_SORT.ONE_DAY,
                });
              }}
              title={t("LAST_24H", { ns: "homePage" })}
            />
            <StanButton
              noUpperCase
              style={{ maxHeight: "32px", borderRadius: "10px" }}
              type={
                activeSort === COLLECTION_SORT.SEVEN_DAYS
                  ? "primary"
                  : "secondary"
              }
              handleOnClick={() => {
                setActiveSort(COLLECTION_SORT.SEVEN_DAYS);
                dispatch({
                  type: SET_HOT_COLLECTION_SORT,
                  hotCollectionSort: COLLECTION_SORT.SEVEN_DAYS,
                });
              }}
              title={t("LAST_7_DAYS", { ns: "homePage" })}
            />
            <StanButton
              noUpperCase
              style={{ maxHeight: "32px", borderRadius: "10px" }}
              type={
                activeSort === COLLECTION_SORT.THIRTY_DAYS
                  ? "primary"
                  : "secondary"
              }
              handleOnClick={() => {
                setActiveSort(COLLECTION_SORT.THIRTY_DAYS);
                dispatch({
                  type: SET_HOT_COLLECTION_SORT,
                  hotCollectionSort: COLLECTION_SORT.THIRTY_DAYS,
                });
              }}
              title={t("LAST_30_DAYS", { ns: "homePage" })}
            />
          </div>
          <div className="d-flex justify-content-end seeAll">
            <span
              style={{ color: "#49D19A", cursor: "pointer" }}
              onClick={() => history.push(EXPLORE_PATH.HOT_COLLECTIONS)}
            >
              {t("BUTTON.SEE_ALL").toUpperCase()}
            </span>
          </div>
        </div>
        <div className="hotCollection-sortSelect">
          <Select value={activeSort} onChange={(e) => setActiveSort(e)}>
            <Option value={COLLECTION_SORT.ONE_DAY}>
              {t("LAST_24H", { ns: "homePage" })}
            </Option>
            <Option value={COLLECTION_SORT.SEVEN_DAYS}>
              {t("LAST_7_DAYS", { ns: "homePage" })}
            </Option>
            <Option value={COLLECTION_SORT.THIRTY_DAYS}>
              {t("LAST_30_DAYS", { ns: "homePage" })}
            </Option>
          </Select>
        </div>
        <Spin spinning={nftStatus === REQUESTING}>{HotCollection}</Spin>
      </div>
    </>
  );
}
