import {
  GET_BALANCE_OF_BUYER,
  GET_BALANCE_OF_FAN,
  GET_LIST_OFFER,
  GET_STAN_FIXED,
  GET_STAN_ROYALTY_FEE,
  GET_STAN_SERVICE_FEE,
} from "../types/AutionType";
import {
  CLEAR_STATE,
  COMPLETE_REQUEST,
  FAILED_REQUEST,
  INSUFFICENT_FUND,
  PENDING_REQUEST,
  PROCESSING,
  REJECT_REQUEST,
  REQUESTING,
} from "../types/UserType";

const initialState = {
  balanceOfFan: 0,
  listOffer: [],
  totalOffers: 0,
  InputPrice: null,
  nftStatus: PENDING_REQUEST,
  txtHash: "",
  stanRoyalty: {},
  stanFixed: {},
  stanServiceFee: {},
  balanceOfBuyer: 0,
};

export const AutionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STAN_ROYALTY_FEE:
      return { ...state, stanRoyalty: action.stanRoyalty };
    case GET_STAN_FIXED:
      return { ...state, stanFixed: action.stanFixed };
    case GET_STAN_SERVICE_FEE:
      return { ...state, stanServiceFee: action.stanServiceFee };
    case GET_BALANCE_OF_FAN:
      return { ...state, balanceOfFan: action.data };
    case GET_BALANCE_OF_BUYER:
      return { ...state, balanceOfBuyer: action.data };
    case GET_LIST_OFFER:
      return {
        ...state,
        listOffer: action.data,
        totalOffers: action.totalOffers,
      };
    case PENDING_REQUEST:
      return {
        ...state,
        nftStatus: PENDING_REQUEST,
      };
    case REQUESTING:
      return {
        ...state,
        nftStatus: REQUESTING,
      };
    case PROCESSING:
      return {
        ...state,
        nftStatus: PROCESSING,
        txtHash: action.txtHash,
      };
    case COMPLETE_REQUEST:
      return {
        ...state,
        nftStatus: COMPLETE_REQUEST,
        txtHash: action.txtHash,
      };
    case FAILED_REQUEST:
      return {
        ...state,
        nftStatus: FAILED_REQUEST,
        txtHash: action.txtHash,
      };
    case INSUFFICENT_FUND:
      return {
        ...state,
        nftStatus: INSUFFICENT_FUND,
      };
    case REJECT_REQUEST:
      return {
        ...state,
        nftStatus: PENDING_REQUEST,
      };
    case "InputPrice":
      return {
        ...state,
        InputPrice: action.data,
      };
    case CLEAR_STATE:
      return (state = initialState);
    default:
      return state;
  }
};
