import Cookies from "js-cookie";
import Web3 from "web3";
import {
  ABI_STAN_TOKEN_MAINNET,
  ABI_STAN_TOKEN_MAINNET_STAGING,
  ABI_STAN_TOKEN_TESTNET,
} from "../../common/ABIs";
import {
  CHAIN_ID,
  CONTRACT_ADDRESS_STAN_TOKEN,
  stagingEnvironment,
} from "../../util/settings/config";
import { GET_STAN_TOKEN } from "../types/StanTokenType";

const ethereum = window?.ethereum;

const Web3Client = new Web3(ethereum);
const contract = new Web3Client.eth.Contract(
  CHAIN_ID === "137"
    ? stagingEnvironment
      ? ABI_STAN_TOKEN_MAINNET_STAGING
      : ABI_STAN_TOKEN_MAINNET
    : ABI_STAN_TOKEN_TESTNET,
  CONTRACT_ADDRESS_STAN_TOKEN
);

export const getStanToken = (data) => {
  return async (dispatch) => {
    try {
      await contract.methods
        .balanceOf(Cookies.get("UserAddress"))
        .call({ from: Cookies.get("UserAddress") })
        .then((result) => {
          // convert wei to ETH
          let Eth = result / Math.pow(10, 18);
          dispatch({
            type: GET_STAN_TOKEN,
            data: Eth,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
};
