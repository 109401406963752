import { Modal } from "antd";
import { useDispatch } from "react-redux";
import "./StanModal.css";

export default function StanModal(props) {
  const dispatch = useDispatch();

  const { width, title, content, visible, footer } = props;

  const handleOk = () => {
    props.onClose();
  };

  const handleCancel = () => {
    props.onClose();
  };

  return (
    <>
      <div>
        <Modal
          width={width}
          title={
            <h3
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "40px",
                lineHeight: "56px",
                color: "#000000",
              }}
              className="text-left ml-3"
            >
              {title}
            </h3>
          }
          footer={footer ? footer : null}
          className="submitted"
          visible={visible}
          maskClosable={false}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div>{content}</div>
        </Modal>
      </div>
    </>
  );
}
