import { Empty } from "antd";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { history } from "../../../App";
import {
  SampleNextArrow,
  SamplePrevArrow,
  WalletIcon,
} from "../../../common/commonSvg";
import StanNFTCard from "../../../component/StanNFTCard/StanNFTCard";
import StanTypo from "../../../component/StanTypo/StanTypo";
import { getLiveAuction } from "../../../redux/action/UserAction";
import "./LiveAuctionSection.css";
import { EXPLORE_PATH } from "../../../common/Constant";

export default function LiveAuctionSection() {
  const { t, i18n } = useTranslation(["common", "homePage"]);
  const dispatch = useDispatch();
  const { listLiveAuction } = useSelector((state) => state.UserReducer);

  const settingsLiveAuction = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow:
      listLiveAuction.length === 3 || listLiveAuction.length === 4
        ? listLiveAuction.length
        : 5,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: listLiveAuction.length < 4 ? listLiveAuction.length : 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  useEffect(() => {
    dispatch(getLiveAuction({}, 10, 1));
  }, []);

  const LiveAuction = useMemo(() => {
    if (listLiveAuction.length === 0) {
      return (
        <div>
          <Empty description={t("NONE_NFT", { ns: "homePage" })} />
        </div>
      );
    } else if (listLiveAuction.length <= 2) {
      return (
        <>
          <div className="LiveAuction-list">
            {listLiveAuction.map((nft) => {
              return (
                <StanNFTCard nft={nft} key={nft.nft_id} type="LIVE_AUCTION" />
              );
            })}
          </div>
        </>
      );
    } else {
      return (
        <Slider {...settingsLiveAuction}>
          {listLiveAuction.map((nft) => {
            return (
              <StanNFTCard nft={nft} key={nft.nft_id} type="LIVE_AUCTION" />
            );
          })}
        </Slider>
      );
    }
  }, [listLiveAuction]);

  return (
    <>
      <div className="LiveAuction">
        <div className="LiveAuction-header">
          <div className="d-flex align-items-center">
            <div className="LiveAuction-icon mr-2">
              <WalletIcon />
            </div>
            <h2>{t("LIVE_AUCTION", { ns: "homePage" })}</h2>
          </div>
          <div
            className="d-flex"
            onClick={() => history.push(EXPLORE_PATH.LIVE_AUCTION)}
          >
            <StanTypo
              fontSize={16}
              fontWeight={400}
              color="#49D19A"
              customStyle={{ cursor: "pointer" }}
              content={t("BUTTON.SEE_ALL", { ns: "common" }).toUpperCase()}
            />
          </div>
        </div>
        {LiveAuction}
      </div>
    </>
  );
}
