import { Empty, Input, Pagination, Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import StanNFTCard from "../../component/StanNFTCard/StanNFTCard";
import { getLiveAuction } from "../../redux/action/UserAction";
import "./LiveAuction.css";
import StanNFTFilter from "../../component/StanNFTFilter/StanNFTFilter";

const { Option } = Select;
export default function LiveAuction(props) {
  const { t } = useTranslation(["common", "explore"]);
  const dispatch = useDispatch();
  const [dataFilter, setDataFilter] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [selectedPage, setSelectedPage] = useState(1);

  const { listLiveAuction, totalLiveAuction } = useSelector(
    (state) => state.UserReducer
  );

  function resetOffset() {
    setSelectedPage(1);
    setPageSize(10);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    resetOffset();
  }, [dataFilter]);

  useEffect(() => {
    dispatch(getLiveAuction(dataFilter, pageSize, selectedPage));
  }, [dataFilter, selectedPage, pageSize]);

  return (
    <>
      <div
        className="liveAuction"
        style={{
          backgroundColor: "#FFFFFF",
          overflow: "hidden",
          minHeight: "600vh",
        }}
      >
        <h1 style={{ textAlign: "center", margin: "54px 0px 34px 0px" }}>
          {t("LIVE_AUCTION", { ns: "homePage" })} 🎉
        </h1>
        <StanNFTFilter
          showSearch
          showSort
          priceName="Listing"
          dataFilter={dataFilter}
          setDataFilter={setDataFilter}
        />
        {listLiveAuction.length === 0 ? (
          <Empty />
        ) : (
          <>
            <div className="auctionList">
              {listLiveAuction.map((nft) => {
                return (
                  <StanNFTCard type="LIVE_AUCTION" nft={nft} key={nft.nft_id} />
                );
              })}
            </div>
            <Pagination
              responsive={true}
              showSizeChanger={true}
              current={selectedPage}
              pageSize={pageSize}
              onChange={(selectedPage, pageSize) => {
                setSelectedPage(selectedPage);
                setPageSize(pageSize);
              }}
              total={totalLiveAuction}
            ></Pagination>
          </>
        )}
      </div>
    </>
  );
}
