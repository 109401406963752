import Cookies from "js-cookie";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MaticIcon, StanPointIcon } from "../../common/commonSvg";
import { getListOffer } from "../../redux/action/AutionAction";
import { detailNFT } from "../../redux/action/NftAction";
import {
  CLOSE_ACCEPT_OFFER,
  CLOSE_CANCEL_OFFER,
  OPEN_ACCEPT_OFFER,
  OPEN_CANCEL_OFFER,
} from "../../redux/types/ModalType";
import { PENDING_REQUEST } from "../../redux/types/UserType";
import {
  formatStanCrypto,
  formatStanPoint,
  formatStanUsd
} from "../../util/settings/utils";
import StanButton from "../StanButton/StanButton";
import StanTypo from "../StanTypo/StanTypo";
import "./OfferItem.css";

export default function OfferItem(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "nftDetail"]);
  const [selectedOffer, setSelectedOffer] = useState({});
  const { nft, transactionList } = useSelector((state) => state.NFTReducer);

  let startDate = new Date(props.data.startDate).toDateString();
  let endDate = props.data.endDate
    ? new Date(props.data.endDate).toDateString()
    : null;

  function closeModal() {
    dispatch({ type: CLOSE_ACCEPT_OFFER });
    dispatch({ type: CLOSE_CANCEL_OFFER });
    dispatch(detailNFT(props.data.nftId));
    dispatch(getListOffer(props.data.nftId, 10, 1));
    dispatch({ type: PENDING_REQUEST });
  }

  function renderOfferButton(offer) {
    if (nft.ownerAddress === Cookies.get("UserAddress")) {
      if (offer.buyerWalletAddress === Cookies.get("UserAddress")) {
        return (
          <div className="btnAcceptOffer">
            <StanButton
              handleOnClick={() => {
                setSelectedOffer(offer);
                dispatch({
                  type: OPEN_CANCEL_OFFER,
                  payload: { nft: nft, selectedOffer: offer },
                  onClose: () => {
                    closeModal();
                  },
                });
              }}
              title={"Cancel"}
              type="stanBtnCancel"
              requireApprove
            />
          </div>
        );
      } else if (offer.buyerWalletAdress !== Cookies.get("UserAddress")) {
        return (
          <div className="btnAcceptOffer">
            <StanButton
              handleOnClick={() => {
                dispatch({
                  type: OPEN_ACCEPT_OFFER,
                  payload: { nft: nft, selectedOffer: offer },
                  onClose: () => {
                    closeModal();
                  },
                });
              }}
              title={"Accept"}
              type="transfer"
              requireApprove
            />
          </div>
        );
      }
    } else if (offer.buyerWalletAddress === Cookies.get("UserAddress")) {
      return (
        <div className="btnAcceptOffer">
          <StanButton
            handleOnClick={() => {
              dispatch({
                type: OPEN_CANCEL_OFFER,
                payload: { nft: nft, selectedOffer: offer },
                onClose: () => {
                  closeModal();
                },
              });
            }}
            title={"Cancel"}
            type="stanBtnCancel"
            requireApprove
          />
        </div>
      );
    }
  }

  return (
    <>
      <div className="offerTab">
        <div className="offerSide">
          <div className="offerSidePrice">
            <div className="d-flex align-items-center">
              {/* <WETHIcon /> */}
              <MaticIcon />
              <StanTypo
                className="ml-2 mr-2"
                color="#0D0D0D"
                fontSize={20}
                fontWeight={500}
                content={formatStanCrypto(props.data.priceCrypto)}
              />
              <StanTypo
                color="#8D8D8D"
                fontSize={14}
                content={"($" + formatStanUsd(props.data.priceUsd) + ")"}
              />
            </div>
            <div className="d-flex align-items-center">
              <StanPointIcon />
              <StanTypo
                className="ml-2 mr-2"
                color="#0D0D0D"
                fontSize={15}
                fontWeight={400}
                content={formatStanPoint(props.data.pricePoint)}
              />
            </div>
          </div>
          <div className="offerSideUser">
            <span>
              From{" "}
              <a
                href={"/profile/" + props.data.userName}
                style={{ color: "green", fontSize: "16px" }}
              >
                {props.data.buyerWalletAddress === Cookies.get("UserAddress")
                  ? "You"
                  : props.data.userName}
              </a>{" "}
            </span>
            <div className="offerSideExpiration">
              <span style={{ fontSize: "16px", color: "#8D8D8D" }}>
                {moment(props.data.startDate).startOf("minutes").fromNow()}
              </span>
              <span style={{ fontSize: "16px", color: "#8D8D8D" }}>
                {/* Expires in{" "}
                {dateDiff(props.data.endDate).toString().replace("-", "") > 0
                  ? dateDiff(props.data.endDate).toString().replace("-", "")
                  : null}{" "}
                days
                {dateDiff(props.data.endDate).toString().replace("-", "") === 0
                  ? moment(props.data.endDate).startOf("day").fromNow("false")
                  : null} */}
                {t("TEXT.EXPIRES", {
                  day: moment(props.data.endDate).startOf("hour").fromNow(),
                })}
              </span>
            </div>
          </div>
        </div>
        {renderOfferButton(props.data)}
      </div>
    </>
  );
}
