import { useDispatch, useSelector } from "react-redux";
import { XIcon } from "../../common/commonSvg";
import { REMOVE_FILTER } from "../../redux/types/FilterType";
import StanTypo from "../StanTypo/StanTypo";

export default function StanFilterPreview(props) {
  const dispatch = useDispatch();
  const { listFilterItems } = useSelector((state) => state.FilterReducer);

  const RenderFilterItem = () => {
    if (listFilterItems.length > 0) {
      return (
        <div className="d-flex flex-wrap align-items-center mt-4">
          <StanTypo
            fontSize={16}
            fontWeight={500}
            color="#8D8D8D"
            content="FILTERS"
          />
          {listFilterItems.map((item, index) => {
            return (
              <>
                {item?.type ? (
                  <div className="selectedFilter" key={index}>
                    <span>{item?.value}</span>
                    <XIcon
                      onClick={() =>
                        dispatch({
                          type: REMOVE_FILTER,
                          listFilterItems: listFilterItems,
                          removedFilter: item,
                        })
                      }
                    />
                  </div>
                ) : null}
              </>
            );
          })}
        </div>
      );
    }
    return null;
  };

  return <RenderFilterItem />;
}
