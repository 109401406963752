import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { CollectionReducer } from "./reducer/CollectionReducer";
import { LoadingReducer } from "./reducer/LoadingReducer";
import { ModalReducer } from "./reducer/ModalReducer";
import { NFTReducer } from "./reducer/NFTReducer";
import { UserReducer } from "./reducer/UserReducer";
import { AutionReducer } from "./reducer/AutionReducer";
import { StanTokenReducer } from "./reducer/StanTokenReducer";
import { FilterReducer } from "./reducer/FilterReducer";

const rootReducer = combineReducers({
  UserReducer,
  ModalReducer,
  LoadingReducer,
  CollectionReducer,
  NFTReducer,
  AutionReducer,
  StanTokenReducer,
  FilterReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
