import { Image, Skeleton } from "antd";
import Cookies from "js-cookie";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { generatePath } from "react-router-dom";
import { history } from "../../App";
import { MaticIcon, StanPointIcon, WETHIcon } from "../../common/commonSvg";
import { CLEAR_STATE } from "../../redux/types/UserType";
import { formatStanCrypto, formatStanPoint } from "../../util/settings/utils";
import StanTypo from "../StanTypo/StanTypo";
import "./StanCollectionCard.css";

export default function StanCollectionCard(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "explore"]);
  const [loadedBanner, setLoadedBanner] = useState(false);
  const [loadedAvt, setLoadedAvt] = useState(false);
  const { style, collection, type } = props;

  //________________ HANDLE ROUTING ________________//
  const handleViewDetailCollection = async (data) => {
    dispatch({ type: CLEAR_STATE });
    history.push(
      generatePath("/collectionDetail/:collectionId", {
        collectionId: data,
      })
    );
  };
  //________________ HANDLE ROUTING ________________//

  //________________ RENDER UI ________________//

  return (
    <>
      <div className="StanCollectionCard" style={{ margin: "0px 10px" }}>
        <div className="collectionCard-header">
          <div className="collectionCard-banner">
            <Image
              alt={"Not found"}
              preview={false}
              onLoad={(e) => setLoadedBanner(true)}
              style={loadedBanner ? { cursor: "pointer" } : { display: "none" }}
              onClick={() =>
                handleViewDetailCollection(
                  collection.id || collection.collectionId
                )
              }
              src={
                _.isNull(collection.imageBanner)
                  ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcs7QeC2_kP0lJEj7Q25mpHyeNkLt_oQ43uP2_jLnhozFShnw-Mba_ataiwQd_W1aByyU&usqp=CAU"
                  : collection.imageBanner
              }
              fallback="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcs7QeC2_kP0lJEj7Q25mpHyeNkLt_oQ43uP2_jLnhozFShnw-Mba_ataiwQd_W1aByyU&usqp=CAU"
            />
            {!loadedBanner ? (
              <Skeleton.Image
                className="StanCollectionCard-bannerSkeleton"
                active={true}
              />
            ) : null}
          </div>
          <div className="collectionCard-avt">
            <Image
              style={loadedAvt ? { cursor: "pointer" } : { display: "none" }}
              onLoad={(e) => setLoadedAvt(true)}
              src={collection.imageLogo}
              preview={false}
              onClick={() =>
                handleViewDetailCollection(
                  collection.id || collection.collectionId
                )
              }
            />
            {!loadedAvt ? (
              <Skeleton.Image
                className="StanCollectionCard-avatarSkeleton"
                active={true}
              />
            ) : null}
          </div>
        </div>
        <div className="collectionCard-body d-flex flex-column">
          <div className="ellipsisText">
            <StanTypo
              fontWeight={500}
              fontSize={20}
              content={collection.name}
            />
          </div>
          {type === "HOT_COLLECTION" ? (
            <>
              <div className="ellipsisText">
                <span style={{ color: "#35A075" }}>{collection.owner}</span>
              </div>
              <div className="ellipsisText">
                <StanTypo
                  fontSize={14}
                  fontWeight={400}
                  color="#8D8D8D"
                  content={
                    <>
                      <MaticIcon />{" "}
                      {formatStanCrypto(
                        collection.totalVolumeDetail.totalVolumeCrypto
                      )}
                    </>
                  }
                />
              </div>
              <div className="ellipsisText">
                <StanTypo
                  fontSize={12}
                  fontWeight={400}
                  color="#8D8D8D"
                  content={
                    <>
                      <StanPointIcon />{" "}
                      {formatStanPoint(
                        collection.totalVolumeDetail.totalVolumePoint
                      )}
                    </>
                  }
                />
              </div>
            </>
          ) : (
            <>
              <div className="ellipsisText">
                <span>
                  {t("TEXT.BY", { ns: "components" })}{" "}
                  <span style={{ color: "#35A075" }}>
                    {collection.ownerWalletAddress ===
                    Cookies.get("UserAddress")
                      ? "You"
                      : collection.ownerName}
                  </span>
                </span>
              </div>
              <div className="m-3 ellipsisText">
                <StanTypo
                  fontSize={14}
                  fontWeight={400}
                  color="#272727"
                  content={collection.description}
                />
              </div>
              <div>
                <StanTypo
                  fontSize={14}
                  fontWeight={400}
                  color="#8D8D8D"
                  content={
                    collection.currentNumberNft +
                    " " +
                    t("STATS.ITEMS", { ns: "common" })
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
