import { Spin } from "antd";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MaticIcon, WETHIcon } from "../../../common/commonSvg";
import ModalInfoNFT from "../../../component/ModalInfoNFT/ModalInfoNFT";
import StanButton from "../../../component/StanButton/StanButton";
import StanTypo from "../../../component/StanTypo/StanTypo";
import { buyNFT, getBalanceOfFan } from "../../../redux/action/AutionAction";
import { getStanToken } from "../../../redux/action/StanTokenAction";
import { OPEN_DEPOSIT } from "../../../redux/types/ModalType";
import {
  COMPLETE_REQUEST,
  FAILED_REQUEST,
  INSUFFICENT_FUND,
  PROCESSING,
  REQUESTING,
} from "../../../redux/types/UserType";
import {
  formatedCash,
  formatStanCryptoDetail,
  formatStanUsd,
  fromEtherToValue,
  subStringText,
  usdOnCrypto,
} from "../../../util/settings/utils";
import "./BuyNFT.css";
import { openNotificationWithIcon } from "../../../App";
import { MsgInsufficientBalance } from "../../../common/Constant";

export default function BuyNFT(props) {
  const { t } = useTranslation(["common", "nft"]);
  const { transactionList } = useSelector((state) => state.NFTReducer);
  const { balanceOfFan, nftStatus, txtHash, stanFixed } = useSelector(
    (state) => state.AutionReducer
  );

  const { payload, onClose } = useSelector((state) => state.ModalReducer);
  const { nft } = payload;

  const { rateCrypto } = useSelector((state) => state.UserReducer);
  const isInsufficientStanFund =
    Number(balanceOfFan) <
    Number(fromEtherToValue(stanFixed?.feeListingNFTAuction));
  const isNotEnoughToken =
    Number(balanceOfFan) < Number(nft.priceWithCrypto) && !nft.price; //home page nft.price is undefined - different btw homepage and nft detail

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBalanceOfFan());
    dispatch(getStanToken());
  }, []);

  function handleBuyNFT() {
    if (isInsufficientStanFund || isNotEnoughToken) {
      dispatch({ type: FAILED_REQUEST });
      openNotificationWithIcon("error", "Error", MsgInsufficientBalance);
      return;
    }
    const transaction = transactionList.find(
      (transaction) =>
        transaction.type === "LIST" &&
        transaction.saleMethod === "FIXED_PRICE" &&
        transaction.status === "ACTIVE"
    );
    dispatch(
      buyNFT(
        { transactionId: transaction.id },
        {
          ownerAddress: nft.ownerAddress,
          tokenId: nft.tokenId,
          price: Number(nft.priceWithCrypto),
          collectionId: nft.collectionId,
          nftName: nft.name,
        },
        !!nft.price
      )
    );
  }

  function renderContent() {
    switch (nftStatus) {
      case COMPLETE_REQUEST:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <img alt="" src="/img/Confetti.png" width={150} height={150} /> */}
            <ModalInfoNFT nft={nft} />
            <span style={{ margin: "20px 0px" }}>
              {t("TEXT.SUCCESSFULLY_BOUGHT", { ns: "nft" })}
            </span>
            <StanButton
              title={t("BUTTON.VIEW_TRANSACTION")}
              handleOnClick={() => {
                window.open("https://mumbai.polygonscan.com/tx/" + txtHash);
              }}
              type="primary"
            />
          </div>
        );
      case PROCESSING:
        return (
          <Fragment>
            <div className="loadingTransferNFT">
              <Spin style={{ margin: "0px 10px" }} />
              <p>{t("TEXT.PROCESSING", { ns: "nft" })}</p>
            </div>
            {txtHash ? (
              <StanButton
                title={t("BUTTON.VIEW_TRANSACTION")}
                handleOnClick={() => {
                  window.open("https://mumbai.polygonscan.com/tx/" + txtHash);
                }}
                type="primary"
              />
            ) : null}
          </Fragment>
        );
      case INSUFFICENT_FUND:
        return (
          <div className="completeTransferNFT d-flex flex-column justify-content-center align-items-center">
            <img
              alt=""
              src="/img/insufficent-fund.png"
              width={150}
              height={150}
            />
            <p>{t("TEXT.INSUFFICIENT_FUND", { ns: "nft" })}</p>
            <StanButton
              title={t("BUTTON.RECHARGE")}
              handleOnClick={() => {
                dispatch({ type: OPEN_DEPOSIT });
              }}
              type="primary"
            />
            <StanButton
              title={t("BUTTON.CANCEL")}
              handleOnClick={() => {
                onClose();
              }}
              type="secondary"
            />
          </div>
        );
      default:
        return (
          <div>
            <div>
              <ModalInfoNFT nft={nft} />
              <div className="BuyNftBody">
                <div className="BuyNftTotalAmount">
                  <h4 style={{ fontWeight: 700 }}>
                    {t("TEXT.TOTAL_AMOUNT", { ns: "nft" })}
                  </h4>
                  <div className="BuyNftTotalAmountPrice">
                    <div>
                      <MaticIcon />
                      <StanTypo
                        fontSize={20}
                        fontWeight={400}
                        color="#0D0D0D"
                        content={formatStanCryptoDetail(nft.priceCrypto)}
                      />
                    </div>
                    <div>
                      <StanTypo
                        fontSize={16}
                        fontWeight={400}
                        color="#4D4D4D"
                        content={
                          "$ " +
                          formatStanUsd(
                            usdOnCrypto(nft.priceCrypto, rateCrypto)
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="BuyNftBalance">
                  <p>
                    {t("TEXT.BALANCE", { ns: "nft" })} <MaticIcon />{" "}
                    {balanceOfFan}{" "}
                  </p>
                </div>
                <StanButton
                  handleOnClick={() => handleBuyNFT()}
                  disabled={nftStatus === REQUESTING}
                  title={t("BUTTON.BUY_NOW")}
                  type="primary"
                />
              </div>
            </div>
          </div>
        );
    }
  }

  return renderContent();
}
