import _ from "lodash";
import web3 from "web3";
import { ethers } from "ethers";
import Compress from "browser-image-compression";
import {
  facebookSvg,
  instaSvg,
  tiktokSvg,
  twitterSvg,
  youtubeSvg,
} from "../../common/commonSvg";
import moment from "moment";
import { message } from "antd";
import { REGEX_DECIMAL } from "../../common/Constant";

const formatNumber = (num) => {
  if (_.isNumber(num)) {
    let value = num.toString();
    let parts = value?.split(".");
    let n =
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      (parts[1] ? "." + parts[1] : "");
    return n;
  }
  if (_.isString(num)) {
    let parts = num?.split(".");
    let n =
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      (parts[1] ? "." + parts[1] : "");
    return n;
  }
};

export function formatedCoin(value) {
  let fixedValue = Number.parseFloat(value).toFixed(2);
  return formatNumber(fixedValue);
}

export function formatedCash(value) {
  return formatNumber(value);
}

export function preventDecimal(event) {
  var regex = new RegExp("^[0-9]+$");
  var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
  if (!regex.test(key)) {
    event.preventDefault();
    return false;
  }
}

export function subStringText(text, limit) {
  if (text && text.length > limit) {
    text = text.substring(0, limit) + "...";
  }
  return text;
}

export function subStringAddress(text) {
  let formatedAddress = "";
  if (text) {
    formatedAddress =
      text.substring(0, 6) + "..." + text.substring(text.length - 4);
  }
  return formatedAddress;
}

export function checkWalletAddress(text) {
  return web3.utils.isAddress(text);
}

export function checkInputNumber(text) {
  var reg = /^\d+$/;
  return reg.test(text);
}

export function generateMediaSvg(type, url) {
  let svg;
  if (type === "TWITTER" && url !== "") {
    svg = twitterSvg(url);
  }
  if (type === "INSTAGRAM" && url !== "") {
    svg = instaSvg(url);
  }
  if (type === "FACEBOOK" && url !== "") {
    svg = facebookSvg(url);
  }
  if (type === "YOUTUBE" && url !== "") {
    svg = youtubeSvg(url);
  }
  if (type === "TIKTOK" && url !== "") {
    svg = tiktokSvg(url);
  }
  return svg;
}

export function isValidType(type) {
  const fileType =
    type === "image/jpeg" || type === "image/png" || type === "image/gif";
  return fileType;
}

export function isValidTypeNFT(type) {
  const fileType =
    type === "image/jpeg" ||
    type === "image/png" ||
    type === "image/gif" ||
    type === "video/mp4";
  return fileType;
}

export async function compressImage(file) {
  if (file.type === "image/gif") {
    return file;
  }
  const options = {
    maxSizeMB: 1,
    useWebWorker: true,
  };
  let convertedBlobFile = {};
  await Compress(file, options).then((compressedBlob) => {
    convertedBlobFile = new File([compressedBlob], file.name, {
      type: file.type,
      lastModified: Date.now(),
    });
  });
  return convertedBlobFile;
}

export function dateDiff(date1, date2) {
  // calculate date from current date
  var DateDiff = {
    inDays: function (d1, d2) {
      var t2 = d2.getTime();
      var t1 = d1.getTime();
      return parseInt((t2 - t1) / (24 * 3600 * 1000));
    },
  };
  var d1 = new Date(date1);
  var d2 = new Date(date2);
  if (date2 == null) {
    d2 = new Date();
  }
  return DateDiff.inDays(d1, d2);
}

export function ethToUsdt(nftPrice, ethPrice) {
  let Usd = nftPrice * ethPrice;
  return formatedCash(Math.round(Usd * 100) / 100);
}

export function convertCoinToUsd(priceNFT, coinPrice) {
  let Usd = priceNFT * coinPrice;
  return Math.round(Usd * 100) / 100;
}

export function convertPriceToWei(price) {
  return web3.utils.toWei(price, "ether");
}

// CONVERT UNIX (DAYS)
export function convertTimeStampToUnix(expirationDay) {
  let currentTime = new Date();
  let newTime = new Date();
  newTime.setDate(currentTime.getDate() + parseInt(expirationDay));
  return Math.floor(newTime.getTime() / 1000);
}

export function convertStringToBytes(text) {
  let bytes = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(text));
  return bytes;
}

export function getMobileOPSystyem() {
  var userAgent =
    navigator.userAgent || navigator.vendor || window.DOMPointReadOnly;
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "PC";
}

export function copyToClipboard(value) {
  navigator.clipboard.writeText(value);
  message.success("Copied to clipboard!");
}

export function formatStanPoint(point) {
  let fixedValue = Number.parseFloat(point).toFixed(0);
  if (fixedValue < 10000)
    return formatedCash(Number.parseFloat(point).toFixed(0));
  if (fixedValue >= 10000 && fixedValue < 1000000) {
    return fixedValue.slice(0, -3) + "K";
  } else if (fixedValue >= 1000000) {
    return fixedValue.slice(0, -6) + "M";
  }
}

export function formatStanPointDetail(point) {
  let fixedValue = Number.parseFloat(point)?.toFixed(0);
  if (fixedValue > 0 && fixedValue < 1000000000) {
    return formatedCash(fixedValue);
  } else if (fixedValue >= 1000000000) {
    return fixedValue.slice(0, -9) + "B";
  }
}

export function formatStanCrypto(value) {
  let checkedValue;
  if (_.isString(value)) {
    checkedValue = Number.parseFloat(value);
  } else {
    checkedValue = value;
  }
  if (value && value < 10) return Number.parseFloat(checkedValue?.toFixed(4));
  if (value && value >= 10 && value < 100)
    return Number.parseFloat(checkedValue.toFixed(3));
  if (value && value >= 100 && value < 1000)
    return Number.parseFloat(checkedValue.toFixed(2));
  if (value && value >= 1000 && value < 10000)
    return formatedCash(Number.parseFloat(checkedValue.toFixed(1)));
  if (value && value >= 10000 && value < 100000)
    return formatedCash(Number.parseFloat(checkedValue.toFixed(0)));

  if (value && value >= 100000 && value < 1000000)
    return Number.parseFloat(value).toFixed(0).slice(0, -3) + "K";
  if (value && value >= 1000000 && value < 10000000)
    return (
      Number.parseFloat(value)
        .toFixed(0)
        .slice(0, -4)
        .replace(/\B(?=(\d{2})+(?!\d))/g, ".") + "M"
    );
  if (value && value >= 10000000 && value < 100000000)
    return (
      Number.parseFloat(value)
        .toFixed(0)
        .slice(0, -4)
        .replace(/\B(?=(\d{2})+(?!\d))/g, ".") + "M"
    );
}

export function formatStanCryptoDetail(value) {
  let checkedValue;
  if (_.isString(value)) {
    checkedValue = Number.parseFloat(value);
  } else {
    checkedValue = value;
  }
  if (value && value < 0.00001) return 0;
  if (value && value < 10) return Number.parseFloat(checkedValue.toFixed(9));
  if (value && value < 100) return Number.parseFloat(checkedValue.toFixed(8));
  if (value && value < 1000) return Number.parseFloat(checkedValue.toFixed(7));
  if (value && value < 10000)
    return formatedCash(Number.parseFloat(checkedValue.toFixed(6)));
  if (value && value < 100000)
    return formatedCash(Number.parseFloat(checkedValue.toFixed(5)));
  if (value && value < 1000000)
    return formatedCash(Number.parseFloat(checkedValue.toFixed(4)));
  if (value && value < 10000000)
    return formatedCash(Number.parseFloat(checkedValue.toFixed(3)));
  if (value && value < 100000000)
    return formatedCash(Number.parseFloat(checkedValue.toFixed(2)));
  if (value && value < 1000000000)
    return formatedCash(Number.parseFloat(checkedValue.toFixed(1)));
  if (value && value >= 1000000000)
    return Number.parseFloat(value).toFixed(0).slice(0, -9) + "B";
}

export function formatStanUsd(value) {
  if (!!value && value < 100000000)
    return formatedCash(Number.parseFloat(value.toFixed(2)));
  if (!!value && value < 1000000000)
    return formatedCash(Number.parseFloat(value.toFixed(1)));
  return Number.parseFloat(value).toFixed(0).slice(0, -9) + "B";
}

export function formatViewLikeCount(value) {
  if (value < 10000) return formatedCash(Number.parseFloat(value));
  if (value < 1000000)
    return (
      Number.parseFloat(value)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        .slice(0, -2) + "K"
    );
  if (value < 1000000000)
    return (
      Number.parseFloat(value)
        .toString()
        .replace(/\B(?=(\d{6})+(?!\d))/g, ".")
        .slice(0, -4) + "M"
    );
  return Number.parseFloat(value).toString().slice(0, -9) + "B";
}

export function cryptoOnPoint(point, ratePoint, rateCrypto) {
  // return Crypto value based on value of Point
  if (point && ratePoint && rateCrypto) {
    return ((ratePoint / rateCrypto) * point).toFixed(10);
  }
}

export function usdOnCrypto(crypto, rateCrypto) {
  // return USD value based on value of Crypto
  if (!_.isUndefined(crypto)) return +(crypto * rateCrypto).toFixed(2);
  else return "0";
}

export function fromEtherToValue(value) {
  if (value) {
    return ethers.utils.formatUnits(value, "ether");
  }
  return 0;
}

export function preventSpecialCharacters(event) {
  const regex = new RegExp("^[a-zA-Z0-9]+$");
  const key = String.fromCharCode(
    !event.charCode ? event.which : event.charCode
  );
  if (event.which === 32) {
    event.preventDefault();
    return false;
  }
  if (!regex.test(key)) {
    event.preventDefault();
    return false;
  }
}

export function decimalCharacters(event) {
  const regex = new RegExp("^(0|[1-9]d*)(.d{0,5})?$");
  const key = String.fromCharCode(
    !event.charCode ? event.which : event.charCode
  );
  if (!regex.test(key) && event.charCode !== 46) {
    event.preventDefault();
    return false;
  }
}
export const checkIfNumber = (variable) => {
  if (typeof variable === "number" && !isNaN(variable)) {
    return true;
  } else {
    return false;
  }
};
