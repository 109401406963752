export const GET_QR_CODE = "GET_QR_CODE";

export const GET_LOGO_MEDIA_PATH = "GET_LOGO_MEDIA_PATH";
export const REMOVE_LOGO = "REMOVE_LOGO";

export const GET_BANNER_MEDIA_PATH = "GET_BANNER_MEDIA_PATH";
export const REMOVE_BANNER = "REMOVE_BANNER";

export const GET_THUMBNAIL_PATH = "GET_THUMNAIL_PATH";
export const REMOVE_THUMBNAIL = "REMOVE_THUMBNAIL";

export const GET_LIST_MEDIA = "GET_LIST_MEDIA";
export const SELECT_EXIST_MEDIA = "SELECT_EXIST_MEDIA";
export const REMOVE_EXIST_MEDIA = "REMOVE_EXIST_MEDIA";

export const GET_LIST_ARTIST = "GET_LIST_ARTIST";
export const GET_ALL_ARTISTS = "GET_ALL_ARTISTS";
export const GET_LIST_ARTIST_EXPLORE = "GET_LIST_ARTIST_EXPLORE";
export const GET_LIST_OF_OWNERS = "GET_LIST_OF_OWNERS";
export const GET_LIST_OF_OWNERS_IN_COLLECTION =
  "GET_LIST_OF_OWNERS_IN_COLLECTION";
// _________________________________________________________________
export const GET_HOT_OFFER = "GET_HOT_OFFER";
export const GET_LIVE_AUCTION = "GET_LIVE_AUCTION";
export const GET_HOT_COLLECTION = "GET_HOT_COLLECTION";
export const GET_PROFILE_ACTIVITIES = "GET_PROFILE_ACTIVITIES";
export const GET_OWNED_NFT = "GET_OWNED_NFT";
export const GET_PROFILE_LISTINGS = "GET_PROFILE_LISTINGS";
export const GET_MADE_OFFERS = "GET_MADE_OFFERS";
export const GET_RECEIVED_OFFERS = "GET_RECEIVED_OFFERS";
export const GET_PROFILE_COLLECTIONS = "GET_PROFILE_COLLECTIONS";
export const GET_NFT_PROFILE = "GET_NFT_PROFILE";
export const GET_USD_RATE = "GET_USD_RATE";
export const GET_RPC_URL = "GET_RPC_URL";
export const GET_STAN_FEE = "GET_STAN_FEE";
// _________________________________________________________________
export const PENDING_REQUEST = "PENDING_REQUEST";
export const REQUESTING = "REQUESTING";
export const PROCESSING = "PROCESSING";
export const COMPLETE_REQUEST = "COMPLETE_REQUEST";
export const REJECT_REQUEST = "REJECT_REQUEST";
export const FAILED_REQUEST = "FAILED_REQUEST";
export const INSUFFICENT_FUND = "INSUFFICENT_FUND";
export const CLEAR_STATE = "CLEAR_STATE";

export const SET_ACTIVE_TAB_EXPLORE = "SET_ACTIVE_TAB_EXPLORE";
export const SET_ACTIVE_TAB_PROFILE = "SET_ACTIVE_TAB_PROFILE";
// _________________________________________________________________
export const TRANSLATE_DESCRIPTION = "TRANSLATE_DESCRIPTION";

export const SOCKET_STATUS = "SOCKET_STATUS";

// AUTION //
export const CLEAR_STATE_OFFER = "CLEAR_STATE_OFFER";

// INPUT SEARCH
export const SEARCHING_FOR_ARTIST = "SEARCHING_FOR_ARTIST";
export const CHECK_APPROVE = "CHECK_APPROVE";
