import { Empty, Pagination, Select, Spin } from "antd";
import {
  MaticIcon,
  StanPointIcon,
  WETHIcon,
  cancelSvg,
  listSvg,
  mintSvg,
  offerSvg,
  saleSvg,
  transferSvg2,
  visitSvg,
} from "../../../common/commonSvg";
import {
  formatStanCrypto,
  formatStanPoint,
} from "../../../util/settings/utils";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { getActivities } from "../../../redux/action/NftAction";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  NFT_ACTIVITY_TYPE,
  NFT_DETAIL_TABPANE,
} from "../../../common/Constant";
import { REQUESTING } from "../../../redux/types/UserType";
const { Option } = Select;

export default function HistoryTab(props) {
  const { id } = useParams();
  const { activeTabPane } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "nftDetail", "explore"]);
  const [activities, setActivities] = useState("ALL");
  const [selectedPage, setSelectedPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { activitiesList, totalActivities } = useSelector(
    (state) => state.NFTReducer
  );
  const { nftStatus } = useSelector((state) => state.AutionReducer);

  useEffect(() => {
    dispatch(
      getActivities(
        id,
        { status: "", type: activities === "ALL" ? "" : activities, by: "" },
        pageSize,
        selectedPage
      )
    );
  }, [activities, selectedPage, pageSize]);

  useEffect(() => {
    if (activeTabPane === NFT_DETAIL_TABPANE.HISTORY) {
      setActivities("ALL");
      setSelectedPage(1);
      setPageSize(10);
    }
  }, [activeTabPane]);

  useEffect(() => {
    setPageSize(10);
    setSelectedPage(1);
  }, [activities]);

  const getIcon = (type) => {
    switch (type) {
      case NFT_ACTIVITY_TYPE.TRANSFER:
        return transferSvg2();
      case NFT_ACTIVITY_TYPE.TRANSFER_NFT_PVP:
        return transferSvg2();
      case NFT_ACTIVITY_TYPE.SALE:
        return saleSvg();
      case NFT_ACTIVITY_TYPE.OFFER:
        return offerSvg();
      case NFT_ACTIVITY_TYPE.BUY:
        return mintSvg();
      case NFT_ACTIVITY_TYPE.MINT:
        return mintSvg();
      case NFT_ACTIVITY_TYPE.CREATE_NFT_BY_STAN:
        return mintSvg();
      case NFT_ACTIVITY_TYPE.LIST:
        return listSvg();
      case NFT_ACTIVITY_TYPE.CANCEL_LISTING:
        return cancelSvg();
      case NFT_ACTIVITY_TYPE.CANCEL_OFFER:
        return cancelSvg();
      case NFT_ACTIVITY_TYPE.AUCTION:
        return listSvg();
      default:
        break;
    }
  };

  return (
    <>
      <div className="historyTabContainer">
        <div>
          <Select
            onChange={(e) => setActivities(e)}
            className="filterHistory"
            value={activities}
            defaultValue="ALL"
            size="large"
          >
            <Option value="ALL">{t("PLACEHOLDER.ALL_ACTIVITIES")}</Option>
            <Option value={NFT_ACTIVITY_TYPE.CREATE_NFT_BY_STAN}>
              {mintSvg()}
              {t("MENU.NFT_TYPE.MINT")}
            </Option>
            <Option value={NFT_ACTIVITY_TYPE.LIST}>
              {listSvg()}
              {t("MENU.NFT_TYPE.LIST")}
            </Option>
            <Option value={NFT_ACTIVITY_TYPE.SALE}>
              {saleSvg()}
              {t("MENU.NFT_TYPE.SALE")}
            </Option>
            <Option value={NFT_ACTIVITY_TYPE.TRANSFER_NFT_PVP}>
              {transferSvg2()}
              {t("MENU.NFT_TYPE.TRANSFER_NFT_PVP")}
            </Option>
            <Option value={NFT_ACTIVITY_TYPE.OFFER}>
              {offerSvg()}
              {t("MENU.NFT_TYPE.OFFER")}
            </Option>
            <Option value={NFT_ACTIVITY_TYPE.CANCEL_LISTING}>
              {cancelSvg()}
              {t("MENU.NFT_TYPE.CANCEL_LISTING")}
            </Option>
            <Option value={NFT_ACTIVITY_TYPE.CANCEL_OFFER}>
              {cancelSvg()}
              {t("MENU.NFT_TYPE.CANCEL_OFFER")}
            </Option>
          </Select>
        </div>

        {activitiesList.length > 0 ? (
          <Spin spinning={nftStatus === REQUESTING}>
            <div className="activitiesListContainer">
              {activitiesList.map((activity) => {
                return (
                  <div className="historyTab">
                    <div className="historySide">
                      <div className="historySideIcon" style={{ width: "35%" }}>
                        <div style={{ margin: "10px 0px" }}>
                          <span style={{ fontWeight: "bold" }}>
                            {getIcon(activity.activityType)}{" "}
                            {t("MENU.NFT_TYPE." + activity.activityType)}
                          </span>
                        </div>
                        {activity.type === "TRANSFER" ||
                        activity.type === "MINT" ? null : (
                          <div
                            className="d-flex flex-column"
                            style={{ margin: "10px 0px" }}
                          >
                            <span style={{ fontWeight: "bold" }}>
                              <MaticIcon />
                              {formatStanCrypto(activity.priceCrypto)}{" "}
                            </span>
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                color: "gray",
                              }}
                            >
                              <StanPointIcon />{" "}
                              {formatStanPoint(activity.pricePoint)}
                            </span>
                          </div>
                        )}
                      </div>
                      <div
                        className="historySideAddress"
                        style={{ width: "45%" }}
                      >
                        <div className="d-flex flex-column justify-content-center mr-3">
                          <div>
                            <span>{t("FROM", { ns: "common" })}</span>
                          </div>
                          {activity.type === NFT_ACTIVITY_TYPE.LIST ||
                          activity.type === NFT_ACTIVITY_TYPE.AUCTION ? null : (
                            <div>
                              <span>{t("TO", { ns: "common" })}</span>
                            </div>
                          )}
                        </div>
                        <div
                          className="d-flex flex-column justify-content-center align-items-start"
                          style={{ width: "100%" }}
                        >
                          <div
                            className="ellipsisText"
                            style={{ width: "80%" }}
                          >
                            <span style={{ color: "#35A075" }}>
                              {activity.type === NFT_ACTIVITY_TYPE.TRANSFER ||
                              activity.type === NFT_ACTIVITY_TYPE.LIST
                                ? activity.sellerName
                                : activity.buyerName}
                            </span>
                          </div>
                          <div
                            className="ellipsisText"
                            style={{ width: "80%" }}
                          >
                            <span style={{ color: "#35A075" }}>
                              {activity.type === NFT_ACTIVITY_TYPE.TRANSFER
                                ? activity.buyerName
                                : activity.type === NFT_ACTIVITY_TYPE.LIST
                                ? null
                                : activity.sellerName}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="historySideTime" style={{ width: "20%" }}>
                        <span style={{ fontSize: "14px", color: "#8D8D8D" }}>
                          {moment(activity.createdAt)
                            .startOf("minutes")
                            .fromNow()}
                        </span>
                        {activity.transactionTX &&
                        activity.transactionTX !== "transactionTX"
                          ? visitSvg(activity.transactionTxUrl)
                          : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="activitiesListContainerResponsive">
              {activitiesList.map((activity) => {
                return (
                  <div
                    className="d-flex flex-column justify-content-between activityItemResponsive"
                    key={activity.id}
                  >
                    <div className="d-flex justify-content-between">
                      <div style={{ width: "50%" }}>
                        <span style={{ fontWeight: "bold" }}>
                          {getIcon(activity.type)} {activity.type}
                        </span>
                      </div>
                      <div
                        className="d-flex justify-content-end"
                        style={{ width: "50%" }}
                      >
                        <span style={{ color: "#8D8D8D" }} className="mr-1">
                          {moment(activity.createdAt)
                            .startOf("minutes")
                            .fromNow()}
                        </span>
                        {activity.transactionTX &&
                        activity.transactionTX !== "transactionTX"
                          ? visitSvg(activity.transactionTxUrl)
                          : null}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div
                        className="d-flex flex-column"
                        style={{ width: "50%" }}
                      >
                        <div className="ellipsisText">
                          <span style={{ fontWeight: "bold" }}>
                            {activity.type === NFT_ACTIVITY_TYPE.MINT ||
                            activity.type ===
                              NFT_ACTIVITY_TYPE.TRANSFER ? null : (
                              <>
                                <MaticIcon />
                                {formatStanCrypto(activity.priceCrypto)}
                              </>
                            )}
                          </span>
                        </div>
                        {activity.type === NFT_ACTIVITY_TYPE.MINT ||
                        activity.type === NFT_ACTIVITY_TYPE.TRANSFER ? null : (
                          <div className="ellipsisText">
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                color: "gray",
                              }}
                            >
                              <StanPointIcon />
                              {formatStanPoint(activity.pricePoint)}
                            </span>
                          </div>
                        )}
                      </div>
                      <div
                        className="d-flex justify-content-start"
                        style={{ width: "50%" }}
                      >
                        <div className="d-flex flex-column mr-2">
                          <div>
                            <span>{t("MENU.FROM", { ns: "explore" })} </span>
                          </div>
                          {activity.type === NFT_ACTIVITY_TYPE.LIST ||
                          activity.type === NFT_ACTIVITY_TYPE.AUCTION ? null : (
                            <div>
                              <span>{t("MENU.TO", { ns: "explore" })}</span>
                            </div>
                          )}
                        </div>
                        <div
                          className="d-flex flex-column"
                          style={{ width: "80%" }}
                        >
                          <div className="ellipsisText">
                            <span style={{ color: "#35A075" }}>
                              {activity.type === NFT_ACTIVITY_TYPE.TRANSFER ||
                              activity.type === NFT_ACTIVITY_TYPE.LIST
                                ? activity.sellerName
                                : activity.buyerName}
                            </span>
                          </div>
                          <div className="ellipsisText">
                            <span style={{ color: "#35A075" }}>
                              {activity.type === NFT_ACTIVITY_TYPE.TRANSFER
                                ? activity.buyerName
                                : activity.type === NFT_ACTIVITY_TYPE.LIST
                                ? null
                                : activity.sellerName}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <Pagination
              responsive={true}
              showSizeChanger={true}
              current={selectedPage}
              pageSize={pageSize}
              onChange={(page, pageSize) => {
                setSelectedPage(page);
                setPageSize(pageSize);
              }}
              total={totalActivities}
            />
          </Spin>
        ) : (
          <Empty description={t("MSG.NO_RESULTS_FOUND", { ns: "common" })} />
        )}
      </div>
    </>
  );
}
