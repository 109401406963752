import Axios from "axios";
import { DOMAIN, DOMAIN_MEDIA, TOKEN_COOKIE } from "../util/settings/config";
import { baseService } from "./baseService";

export class UserService extends baseService {
  constructor() {
    super();
  }

  getMetamaskMessage = (walletAddress) => {
    return this.get(`auth/metamask/message/${walletAddress}`);
  };

  verifyMessage = (data) => {
    return this.post(`auth/metamask/verify-message`, data);
  };

  getUserProfile = (userId, accessToken) => {
    return Axios({
      url: `${DOMAIN_MEDIA}/api/v1/users/get-profile?id=${userId}`,
      method: "GET",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    });
  };

  getTalentByAddress = (walletAddress, accessToken) => {
    return Axios({
      url: `${DOMAIN_MEDIA}/api/v1/talents/get-profile?walletAddress=${walletAddress}`,
      method: "GET",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    });
  };

  getUserProfileByAddress = (walletAddress) => {
    let url = `profile/web/user-info?walletAddress=${walletAddress}`;
    return this.get(url);
  };

  getPresignMedia = (type, file) => {
    let url = `${DOMAIN_MEDIA}/api/v1/medias/presigned?type=${type}&`;
    if (file.type === "image/gif") {
      url += `extension=gif`;
    }
    return Axios({
      url: url,
      method: "GET",
      headers: {
        Authorization: "Bearer " + TOKEN_COOKIE,
      },
    });
  };

  getPresignJson = () => {
    return Axios({
      url: `${DOMAIN_MEDIA}/api/v1/medias/presigned-upload-file-json`,
      method: "GET",
      headers: {
        Authorization: "Bearer " + TOKEN_COOKIE,
      },
    });
  };

  getVimeoUpload = (fileSize) => {
    return Axios({
      url: `${DOMAIN_MEDIA}/api/v1/users/vimeo-upload-link?fileSize=${fileSize}`,
      method: "GET",
      headers: {
        Authorization: "Bearer " + TOKEN_COOKIE,
      },
    });
  };

  uploadJson = (uploadURL, json) => {
    return Axios({
      url: `${uploadURL}`,
      method: "PUT",
      data: json,
    }).catch(function (error) {
      console.log("Errror =>", error);
    });
  };

  getListMedia = (limit, page, sort) => {
    return Axios({
      url: `${DOMAIN_MEDIA}/api/v1/posts/get-list-media?limit=${limit}&offset=${
        (page - 1) * limit
      }&sort=${sort}`,
      method: "GET",
      headers: {
        Authorization: "Bearer " + TOKEN_COOKIE,
      },
    });
  };

  getListArtist = (limit, page, name, sortBy, connectedWallet) => {
    let url = `${DOMAIN_MEDIA}/api/v1/talents/get?limit=${limit}&offset=${
      (page - 1) * limit
    }&`;
    if (name && name !== "") {
      url += `search=${name}&`;
    }
    if (sortBy && sortBy !== "") {
      url += `sortBy=${sortBy}&`;
    }
    if (connectedWallet) {
      url += `connectedWallet=${connectedWallet}`;
    }
    return Axios({
      url: url,
      method: "GET",
      headers: {
        Authorization: "Bearer " + TOKEN_COOKIE,
      },
    });
  };

  getListArtistExplore = (pageSize, page, keyword, property, sort) => {
    let url = `web/nft/artist-explore?limit=${pageSize}&offset=${
      (page - 1) * pageSize
    }`;
    if (keyword && keyword !== "") {
      url += `&keyword=${keyword}`;
    }
    if (property && property !== "") {
      url += `&property=${property}`;
    }
    if (sort && sort !== "") {
      url += `&sort=${sort}`;
    }
    return this.get(url);
  };

  getListOfOwners = (
    pageSize,
    page,
    keyword,
    walletAddress,
    property,
    sort
  ) => {
    let url = `web/nft/list-of-owners/${walletAddress}?limit=${pageSize}&offset=${
      (page - 1) * pageSize
    }`;
    if (keyword && keyword !== "") {
      url += `&keyword=${keyword}`;
    }
    if (property && property !== "") {
      url += `&property=${property}`;
    }
    if (sort && sort !== "") {
      url += `&sort=${sort}`;
    }
    return this.get(url);
  };

  getListOfOwnersInCollection = (
    pageSize,
    page,
    keyword,
    collectionId,
    property,
    sort
  ) => {
    let url = `web/collection/${collectionId}/list-of-owners?limit=${pageSize}&offset=${
      (page - 1) * pageSize
    }`;
    if (keyword && keyword !== "") {
      url += `&keyword=${keyword}`;
    }
    if (property && property !== "") {
      url += `&property=${property}`;
    }
    if (sort && sort !== "") {
      url += `&sort=${sort}`;
    }
    return this.get(url);
  };

  uploadMedia = (uploadURL, file) => {
    return Axios({
      url: `${uploadURL}`,
      method: "PUT",
      data: file,
    }).catch(function (error) {
      console.log("Errror =>", error);
    });
  };

  uploadVimeo = (uploadURL, file) => {
    return Axios({
      url: `${uploadURL}`,
      method: "PATCH",
      data: file,
      headers: {
        "Tus-Resumable": "1.0.0 ",
        "Upload-Offset": 0,
        "Content-Type": "application/offset+octet-stream",
        Accept: "application/vnd.vimeo.*+json;version=3.4",
      },
    }).catch(function (error) {
      console.log("Errror =>", error);
    });
  };

  getUserByName = (data) => {
    return Axios({
      url: `${DOMAIN_MEDIA}/api/v1/users?keyword=${data}`,
      method: "GET",
      headers: {
        Authorization: "Bearer " + TOKEN_COOKIE,
      },
    });
  };

  getWalletAddress = (data) => {
    return Axios({
      url: `${DOMAIN_MEDIA}/api/v1/users/get-wallet-address?id=${data.id}`,
      method: "GET",
      headers: {
        Authorization: "Bearer " + TOKEN_COOKIE,
      },
    });
  };

  // PROFILE

  getHotOffers = (filterData, pageSize, page) => {
    let url = `web/nft/hot-offers?`;
    if (filterData.keyword && filterData.keyword !== "") {
      url += `keyword=${filterData.keyword}&`;
    }
    if (filterData.property && filterData.property !== "") {
      url += `property=${filterData.property}&`;
    }
    if (filterData.sortDir && filterData.sortDir !== "") {
      url += `sort=${filterData.sortDir}&`;
    }
    url += `limit=${pageSize}&offset=${(page - 1) * pageSize}`;
    return this.get(url);
  };

  getLiveAuction = (filterData, pageSize, page) => {
    let url = `web/nft/live-auction?`;
    if (filterData.keyword && filterData.keyword !== "") {
      url += `keyword=${filterData.keyword}&`;
    }
    if (filterData.property && filterData.property !== "") {
      url += `property=${filterData.property}&`;
    }
    if (filterData.sortDir && filterData.sortDir !== "") {
      url += `sort=${filterData.sortDir}&`;
    }
    url += `limit=${pageSize}&offset=${(page - 1) * pageSize}`;
    return this.get(url);
  };

  getHotCollections = (filterData, pageSize, page) => {
    let url = `web/collection/hot-collections?`;
    if (filterData.keyword && filterData.keyword !== "") {
      url += `keyword=${filterData.keyword}&`;
    }
    if (filterData.time && filterData.time !== "") {
      url += `time=${filterData.time}&`;
    }
    url += `limit=${pageSize}&offset=${(page - 1) * pageSize}`;
    return this.get(url);
  };

  getProfileActivities = (filterData, pageSize, page) => {
    let url = `profile/activities?`;
    if (filterData.status && filterData.status !== "") {
      url += `status=${filterData.status}&`;
    }
    if (filterData.type && filterData.type !== "") {
      url += `type=${filterData.type}&`;
    }
    if (filterData.by && filterData.by !== "") {
      url += `by=${filterData.by}&`;
    }
    if (filterData.walletAddress && filterData.walletAddress !== "") {
      url += `walletAddress=${filterData.walletAddress}&`;
    }
    url += `limit=${pageSize}&offset=${(page - 1) * pageSize}`;
    return this.get(url);
  };

  getOwnedNFT = (filterData, pageSize, page) => {
    let url = `web/nft/owned-nfts?`;
    if (filterData.collectionIds && filterData.collectionIds.length > 0) {
      url += `collectionIds=${filterData.collectionIds}&`;
    }
    if (filterData.name && filterData.name !== "") {
      url += `name=${filterData.name}&`;
    }
    if (filterData.status && filterData.status !== "") {
      url += `status=${filterData.status}&`;
    }
    if (filterData.currency && filterData.currency !== "") {
      url += `currency=${filterData.currency}&`;
    }
    if (filterData.fromPrice && filterData.fromPrice !== "") {
      url += `fromPrice=${filterData.fromPrice}&`;
    }
    if (filterData.toPrice && filterData.toPrice !== "") {
      url += `toPrice=${filterData.toPrice}&`;
    }
    if (filterData.orderBy && filterData.orderBy !== "") {
      url += `orderBy=${filterData.orderBy}&`;
    }
    if (filterData.walletAddress && filterData.walletAddress !== "") {
      url += `walletAddress=${filterData.walletAddress}&`;
    }
    if (filterData.categoryIds && filterData.categoryIds !== "") {
      url += `categoryIds=${filterData.categoryIds}&`;
    }
    url += `limit=${pageSize}&offset=${(page - 1) * pageSize}`;
    return this.get(url);
  };

  getProfileListing = (collectionId, collectionName) => {
    let url = `profile/listing-list`;
    if (collectionId && collectionId !== "") {
      url += `?collectionId=${collectionId}&`;
    }
    if (collectionName && collectionName !== "") {
      url += `collectionName=${collectionName}`;
    }
    return this.get(url);
  };

  getProfileCollections = (pageSize, page, walletAddress, keyword) => {
    let url = `profile/web/collections?limit=${pageSize}&offset=${
      (page - 1) * pageSize
    }&`;
    if (walletAddress && walletAddress !== "") {
      url += `walletAddress=${walletAddress}&`;
    }
    if (keyword && keyword !== "") {
      url += `keyword=${keyword}`;
    }
    return this.get(url);
  };

  getMadeOffers = (walletAddress) => {
    let url = `profile/offered-nfts?option=MADE&walletAddress=${walletAddress}`;
    return this.get(url);
  };

  getReceivedOffers = (walletAddress) => {
    let url = `profile/offered-nfts?option=RECEIVED&walletAddress=${walletAddress}`;
    return this.get(url);
  };

  translateDescription = (data) => {
    return Axios({
      url: `${DOMAIN_MEDIA}/api/v1/master-data/translate`,
      method: "POST",
      data: data,
      headers: {
        Authorization: "Bearer " + TOKEN_COOKIE,
      },
    });
  };

  getUSDRate = () => {
    let url = `profile/usd-rate`;
    return this.get(url);
  };

  getRpcUrl = () => {
    let url = `profile/rpc`;
    return this.get(url);
  };

  getStanFee = () => {
    let url = `profile/stan-fee`;
    return this.get(url);
  };
}
export const userService = new UserService();
