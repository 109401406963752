import { Empty, Image, Pagination, Select, Spin } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useParams } from "react-router-dom";
import { history } from "../../../../App";
import {
  cancelSvg,
  listSvg,
  MaticIcon,
  mintSvg,
  offerSvg,
  saleSvg,
  StanPointIcon,
  transferSvg2,
  visitSvg,
  WETHIcon,
} from "../../../../common/commonSvg";
import StanTypo from "../../../../component/StanTypo/StanTypo";
import { getProfileActivities } from "../../../../redux/action/UserAction";
import { CLEAR_STATE, REQUESTING } from "../../../../redux/types/UserType";
import {
  formatStanCrypto,
  formatStanPoint,
  subStringText,
} from "../../../../util/settings/utils";
import "./ActivityTab.css";
import { NFT_ACTIVITY_TYPE } from "../../../../common/Constant";

const { Option } = Select;
export default function ActivityTab(props) {
  const { t } = useTranslation(["common", "explore"]);
  const dispatch = useDispatch();
  const { walletAddress } = useParams();

  const [selectedPageSize, setSelectedPageSize] = useState(10);
  const [selectedPage, setSelectedPage] = useState(1);
  const [filterData, setFilterData] = useState({
    walletAddress: walletAddress.toLowerCase(),
  });
  const [activityType, setActivityType] = useState([]);
  const [loadedImage, setLoadedImage] = useState(false);
  const [activities, setActivities] = useState([]);
  const { listProfileActivities, totalProfileActivities } = useSelector(
    (state) => state.UserReducer
  );
  const { nftStatus } = useSelector((state) => state.AutionReducer);

  const getIcon = (type) => {
    switch (type) {
      case NFT_ACTIVITY_TYPE.TRANSFER:
      case NFT_ACTIVITY_TYPE.TRANSFER_NFT_PVP:
        return transferSvg2();
      case NFT_ACTIVITY_TYPE.SALE:
        return saleSvg();
      case NFT_ACTIVITY_TYPE.OFFER:
        return offerSvg();
      case NFT_ACTIVITY_TYPE.BUY:
        return mintSvg();
      case NFT_ACTIVITY_TYPE.MINT:
      case NFT_ACTIVITY_TYPE.CREATE_NFT_BY_STAN:
        return mintSvg();
      case NFT_ACTIVITY_TYPE.LIST:
        return listSvg();
      case NFT_ACTIVITY_TYPE.CANCEL_LISTING:
        return cancelSvg();
      case NFT_ACTIVITY_TYPE.CANCEL_OFFER:
        return cancelSvg();
      case NFT_ACTIVITY_TYPE.AUCTION:
        return listSvg();
      default:
        break;
    }
  };

  const handleChange = (value) => {
    setActivityType(value);
  };

  useEffect(() => {
    setActivities(listProfileActivities);
  }, [listProfileActivities]);

  useEffect(() => {
    setFilterData({ ...filterData, type: activityType.toString() });
  }, [activityType]);

  useEffect(() => {
    dispatch(getProfileActivities(filterData, selectedPageSize, selectedPage));
  }, [filterData, selectedPageSize, selectedPage]);

  console.log("listProfileActivities", activities);

  const ActivityItem = (props) => {
    const { activity } = props;
    return (
      <>
        <div className="activityItem">
          <div className="activityType">
            <span style={{ fontWeight: 400 }}>
              {getIcon(activity.activityType)}
              {t("MENU.NFT_TYPE." + activity.activityType)}
            </span>
          </div>

          <div className="activityOfNFT">
            <div className="activityNFTImg">
              <Image
                onLoad={(e) => setLoadedImage(true)}
                width={56}
                height={56}
                preview={false}
                onClick={() => {
                  dispatch({ type: CLEAR_STATE });
                  history.push(
                    generatePath("/nft/:nftId", {
                      nftId: activity.nftId,
                    })
                  );
                }}
                fallback="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcs7QeC2_kP0lJEj7Q25mpHyeNkLt_oQ43uP2_jLnhozFShnw-Mba_ataiwQd_W1aByyU&usqp=CAU"
                style={
                  loadedImage
                    ? {
                        cursor: "pointer",
                        objectFit: "cover",
                      }
                    : { display: "none" }
                }
                src={activity.nftMediaPreviewUrl || activity.nftMediaUrl}
              />
              {!loadedImage && (
                <div className="loadingOverlayNFT">
                  <Spin
                    size="large"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                </div>
              )}
            </div>
            <div className="activityNFTInfo">
              <span style={{ fontSize: "14px", color: "#8D8D8D" }}>
                {activity.ownerName}
              </span>
              <span
                style={{ fontSize: "16px", fontWeight: 700, color: "#0D0D0D" }}
              >
                {activity.nftName}
              </span>
            </div>
          </div>

          <div className="activityPrice">
            {activity.type === "TRANSFER" ||
            activity.type === "LIST" ||
            activity.type === "MINT" ? null : (
              <div className="d-flex flex-column align-items-center justify-content-center">
                <StanTypo
                  fontSize={14}
                  fontWeight={500}
                  color="#0D0D0D"
                  content={
                    <>
                      {/* <WETHIcon /> */}
                      <MaticIcon />
                      {formatStanCrypto(activity.priceCrypto)}
                    </>
                  }
                />
                <StanTypo
                  fontSize={10}
                  fontWeight={400}
                  color="#939393"
                  content={
                    <>
                      <StanPointIcon />
                      {formatStanPoint(activity.pricePoint)}
                    </>
                  }
                />
              </div>
            )}
          </div>

          <div className="activityFromTo">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{ fontWeight: 500, fontSize: "14px", color: "#8D8D8D" }}
              >
                {t("MENU.FROM", { ns: "explore" })}
              </span>
              {activity.type === "LIST" ||
              activity.type === "AUCTION" ? null : (
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "#8D8D8D",
                  }}
                >
                  {t("MENU.TO", { ns: "explore" })}
                </span>
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{ fontWeight: 500, fontSize: "14px", color: "#35A075" }}
              >
                {activity.type === "TRANSFER" || activity.type === "LIST"
                  ? activity.sellerName
                  : activity.buyerName}
              </span>
              <span
                style={{ fontWeight: 500, fontSize: "14px", color: "#35A075" }}
              >
                {activity.type === "TRANSFER"
                  ? activity.buyerName
                  : activity.type === "LIST"
                  ? null
                  : activity.sellerName}
              </span>
            </div>
          </div>

          <div className="activityTime">
            <span
              style={{ fontWeight: 500, fontSize: "14px", color: "#8D8D8D" }}
            >
              {moment(activity.createdAt).startOf("minute").fromNow()}{" "}
              {activity.transactionTX &&
              activity.transactionTX !== "transactionTX"
                ? visitSvg(activity.transactionTxUrl)
                : null}
            </span>
          </div>
        </div>

        {/* RESPONSIVE */}
        <div className="activityItemResponsive">
          <div className="d-flex">
            <span style={{ fontWeight: 400 }}>
              {getIcon(activity.type)} {activity.type}
            </span>
          </div>

          <div className="activityOfNFTResponsive d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center" style={{ width: "70%" }}>
              <div className="activityNFTImg">
                <Image
                  onLoad={(e) => setLoadedImage(true)}
                  width={56}
                  height={56}
                  preview={false}
                  onClick={() => {
                    dispatch({ type: CLEAR_STATE });
                    history.push(
                      generatePath("/nft/:nftId", {
                        nftId: activity.nftId,
                      })
                    );
                  }}
                  fallback="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcs7QeC2_kP0lJEj7Q25mpHyeNkLt_oQ43uP2_jLnhozFShnw-Mba_ataiwQd_W1aByyU&usqp=CAU"
                  style={
                    loadedImage
                      ? {
                          cursor: "pointer",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }
                      : { display: "none" }
                  }
                  src={activity.nftMediaPreviewUrl || activity.nftMediaUrl}
                />
                {!loadedImage && (
                  <div className="loadingOverlayNFT">
                    <Spin
                      size="large"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="activityNFTInfo">
                <span style={{ fontSize: "14px", color: "#8D8D8D" }}>
                  {subStringText(activity.colName, 15)}
                </span>
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: 700,
                    color: "#0D0D0D",
                  }}
                >
                  {subStringText(activity.nftName, 15)}
                </span>
              </div>
            </div>
            <div className="activityPriceResponsive">
              {activity.type === "TRANSFER" ||
              activity.type === "LIST" ||
              activity.type === "MINT" ? null : (
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <StanTypo
                    fontSize={14}
                    fontWeight={500}
                    color="#0D0D0D"
                    content={
                      <>
                        {/* <WETHIcon /> */}
                        <MaticIcon />
                        {formatStanCrypto(activity.priceCrypto)}
                      </>
                    }
                  />
                  <StanTypo
                    fontSize={10}
                    fontWeight={400}
                    color="#939393"
                    content={
                      <>
                        <StanPointIcon />
                        {formatStanPoint(activity.pricePoint)}
                      </>
                    }
                  />
                </div>
              )}
            </div>
          </div>

          <div className="activityFromToResponsive d-flex justify-content-between">
            <div style={{ width: "60%" }}>
              <div style={{ display: "flex" }}>
                <div>
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "#8D8D8D",
                      marginRight: "5px",
                    }}
                  >
                    {t("FROM")}
                  </span>
                </div>
                <div className="ellipsisText">
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "#35A075",
                    }}
                  >
                    {activity.type === "TRANSFER" || activity.type === "LIST"
                      ? activity.sellerName
                      : activity.buyerName}
                  </span>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                {activity.type === "LIST" ||
                activity.type === "AUCTION" ? null : (
                  <div>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "#8D8D8D",
                        marginRight: "5px",
                      }}
                    >
                      {t("TO")}
                    </span>
                  </div>
                )}
                <div className="ellipsisText">
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "#35A075",
                    }}
                  >
                    {activity.type === "TRANSFER"
                      ? activity.buyerName
                      : activity.type === "LIST"
                      ? null
                      : activity.sellerName}
                  </span>
                </div>
              </div>
            </div>
            <div className="activityTimeResponsive">
              <span
                style={{ fontWeight: 500, fontSize: "14px", color: "#8D8D8D" }}
              >
                {moment(activity.createdAt).startOf("minute").fromNow()}{" "}
                {activity.transactionTX &&
                activity.transactionTX !== "transactionTX"
                  ? visitSvg(activity.transactionTxUrl)
                  : null}
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Spin spinning={nftStatus === REQUESTING}>
        <div className="activityTabContainer" style={{ minHeight: "100vh" }}>
          <div className="circleBackground">
            <img alt="" src="/img/Circle.png" />
          </div>
          <div className="triangleImg">
            <img alt="" src="/img/triangle.png" />
          </div>

          <div className="searchSection">
            <div style={{ width: "100%" }}>
              <Select
                mode="multiple"
                allowClear
                placeholder={t("PLACEHOLDER.SELECT_ACTIVITY_TYPES")}
                onChange={handleChange}
                style={{ width: "100%" }}
              >
                <Option value={NFT_ACTIVITY_TYPE.CREATE_NFT_BY_STAN}>
                  {mintSvg()}
                  {t("MENU.NFT_TYPE.MINT")}
                </Option>
                <Option value={NFT_ACTIVITY_TYPE.LIST}>
                  {listSvg()}
                  {t("MENU.NFT_TYPE.LIST")}
                </Option>
                <Option value={NFT_ACTIVITY_TYPE.SALE}>
                  {saleSvg()}
                  {t("MENU.NFT_TYPE.SALE")}
                </Option>
                <Option value={NFT_ACTIVITY_TYPE.TRANSFER_NFT_PVP}>
                  {transferSvg2()}
                  {t("MENU.NFT_TYPE.TRANSFER")}
                </Option>
                <Option value={NFT_ACTIVITY_TYPE.OFFER}>
                  {offerSvg()}
                  {t("MENU.NFT_TYPE.OFFER")}
                </Option>
                <Option value={NFT_ACTIVITY_TYPE.CANCEL_LISTING}>
                  {cancelSvg()}
                  {t("MENU.NFT_TYPE.CANCEL_LISTING")}
                </Option>
                <Option value={NFT_ACTIVITY_TYPE.CANCEL_OFFER}>
                  {cancelSvg()}
                  {t("MENU.NFT_TYPE.CANCEL_OFFER")}
                </Option>
              </Select>
            </div>
          </div>

          {activities.length > 0 ? (
            <div className="activityTable">
              {activities.map((item) => {
                return (
                  <ActivityItem activity={item} key={item.transactionTX} />
                );
              })}
            </div>
          ) : (
            <Empty />
          )}

          <div>
            <Pagination
              responsive={true}
              showSizeChanger={true}
              current={selectedPage}
              pageSize={selectedPageSize}
              onChange={(page, pageSize) => {
                setSelectedPage(page);
                setSelectedPageSize(pageSize);
              }}
              total={totalProfileActivities}
            />
          </div>
        </div>
      </Spin>
    </>
  );
}
