// import _ from "lodash";
import { ethers } from "ethers";
import Cookies from "js-cookie";
import Web3 from "web3";
import { history, openNotificationWithIcon } from "../../App";
import {
  ABI_AUCTION_MAINNET,
  ABI_AUCTION_MAINNET_STAGING,
  ABI_AUCTION_TESTNET,
  ABI_NFT_MAINNET,
  ABI_NFT_MAINNET_STAGING,
  ABI_NFT_TESTNET,
} from "../../common/ABIs";
import { COMMON_PATH } from "../../common/Constant";
import { GAS_LIMIT, PRIORITY_FEE } from "../../common/commonParams";
import { nftService } from "../../services/NftService";
import { userService } from "../../services/UserService";
import {
  CHAIN_ID,
  CONTRACT_ADDRESS_AUTION,
  CONTRACT_ADDRESS_NFT,
  stagingEnvironment,
} from "../../util/settings/config";
import { convertStringToBytes } from "../../util/settings/utils";
import { CLOSE_LOADING, OPEN_LOADING } from "../types/LoadingType";
import {
  CLAIM_NFT,
  DEPOSIT_NFT,
  GET_ACTIVITIES,
  GET_ARTIST_LATEST_NFT,
  GET_DETAIL_NFT,
  GET_LATEST_SALE,
  GET_LIST_CATEGORIES,
  GET_LIST_FIXED_PRICE_NFTS,
  GET_POSITION_SECTION,
  SEARCH_NFT,
  SEARCH_NFT_HEADER,
  SHUFFLE_NFT,
} from "../types/NFTType";
import {
  CLEAR_STATE,
  COMPLETE_REQUEST,
  FAILED_REQUEST,
  PENDING_REQUEST,
  PROCESSING,
  REJECT_REQUEST,
  REQUESTING,
} from "../types/UserType";

const ethereum = window?.ethereum;

const Web3Client = new Web3(ethereum);

const contract_nft = new Web3Client.eth.Contract(
  CHAIN_ID === "137"
    ? stagingEnvironment
      ? ABI_NFT_MAINNET_STAGING
      : ABI_NFT_MAINNET
    : ABI_NFT_TESTNET,
  CONTRACT_ADDRESS_NFT
);

const contract_auction = new Web3Client.eth.Contract(
  CHAIN_ID === "137"
    ? stagingEnvironment
      ? ABI_AUCTION_MAINNET_STAGING
      : ABI_AUCTION_MAINNET
    : ABI_AUCTION_TESTNET,
  CONTRACT_ADDRESS_AUTION
);

export const mintNFT = (data, json, nftPreviewInfo) => {
  return async (dispatch) => {
    try {
      // CALL API UPLOAD JSON, receive URL to call SmartContract
      const resultUrl = await userService.getPresignJson();
      if (resultUrl && resultUrl.data.status === "success") {
        const url = resultUrl.data.data.url;
        await userService.uploadJson(url, json);
        const result = await nftService.createNft({
          ...data,
          price: data.price,
          contractAddress: CONTRACT_ADDRESS_NFT,
          ownerAddress: Cookies.get("UserAddress"),
        });
        if (result && result.data.status === "success") {
          let nftIds = result.data.data.nftIds.join("|");
          await contract_nft.methods
            .createNFT(
              convertStringToBytes(result.data.data.requestId), // _requestId (bytes)
              convertStringToBytes(data.collectionId), // _collectionId (bytes)
              // resultUrl.data.data.public_url, // publicURL
              data.quantity, // _quantity (uint256)
              convertStringToBytes(nftIds), // _nftIds (bytes)
              true // _isWeb (bool)
            )
            .send(
              CHAIN_ID === "137"
                ? {
                    from: Cookies.get("UserAddress"),
                    maxPriorityFeePerGas: ethers.BigNumber.from(PRIORITY_FEE),
                  }
                : { from: Cookies.get("UserAddress") }
            )
            .on("transactionHash", function (hash) {
              if (hash) {
                dispatch({ type: PROCESSING });
              }
            })
            .on("receipt", async function (receipt) {
              console.log("RECEIPT?", receipt);
              dispatch({
                type: OPEN_LOADING,
                receipt: receipt,
                txtHash: receipt.transactionHash,
                urlNavigate: "/collectionDetail/" + data.collectionId,
                createType: "NFT",
                nftPreviewInfo: nftPreviewInfo,
              });
            });
        }
      }
    } catch (error) {
      console.log(error);
      if (error.code === 4001) {
        openNotificationWithIcon(
          "error",
          "Error",
          "You declined the action in your wallet"
        );
        dispatch({ type: COMPLETE_REQUEST });
        dispatch({ type: CLOSE_LOADING });
      } else {
        openNotificationWithIcon("error", "Error", "Error. Please try again!");
        history.push("/collections");
        dispatch({ type: CLEAR_STATE });
      }
    } finally {
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

export const detailNFT = (nftId) => {
  return async (dispatch) => {
    try {
      const result = await nftService.nftDetail(nftId);
      if (result.data.data && result.data.status === "success") {
        dispatch({
          type: GET_DETAIL_NFT,
          nft: result.data.data.nft,
          transactionList: result.data.data.transactionList,
          user: result.data.data.user,
        });
      } else if (result.data.status === "error") {
        history.push(COMMON_PATH.NOT_FOUND_404);
        openNotificationWithIcon("error", "Error", result.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const searchNFT = (data, pageSize, page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: REQUESTING });
      const result = await nftService.nftSearch(data, pageSize, page);
      if (result.data.data && result.data.status === "success") {
        dispatch({
          type: SEARCH_NFT,
          listNFT: result.data.data.nftLIst,
          totalNFT: result.data.data.total,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

export const searchNFTHeader = (data, pageSize, page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: REQUESTING });
      const result = await nftService.nftSearch(data, pageSize, page);
      if (result.data.data && result.data.status === "success") {
        dispatch({
          type: SEARCH_NFT_HEADER,
          listNFT: result.data.data.nftLIst,
          totalNFT: result.data.data.total,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

export const getActivities = (nftId, filterData, pageSize, page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: REQUESTING });
      const result = await nftService.getActivities(
        nftId,
        filterData,
        pageSize,
        page
      );
      if (result.data.data && result.data.status === "success") {
        dispatch({
          type: GET_ACTIVITIES,
          activities: result.data.data.items,
          totalActivities: result.data.data.total,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

export const shuffleNFT = () => {
  return async (dispatch) => {
    try {
      const result = await nftService.shuffleNFT();
      if (result && result.data.status === "success") {
        dispatch({ type: SHUFFLE_NFT, nftShuffle: result.data.data.nfts });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const depositNFT = (nftId, tokenId) => {
  let txtHash = "";
  return async (dispatch) => {
    try {
      dispatch({ type: REQUESTING });
      const resultSC = await contract_auction.methods
        .depositNFT(
          convertStringToBytes("depositNFT"),
          tokenId,
          convertStringToBytes(nftId),
          true //isWeb
        )
        .send({ from: Cookies.get("UserAddress"), gasLimit: GAS_LIMIT })
        .on("transactionHash", function (hash) {
          console.log("TRANSACTION HASH => ", hash);
          txtHash = hash;
          dispatch({ type: PROCESSING, txtHash: hash });
        })
        .on("receipt", async function (receipt) {
          console.log("RECEIPT => ", receipt);
          dispatch({
            type: DEPOSIT_NFT,
            txtHash: receipt.transactionHash,
          });
          dispatch({
            type: COMPLETE_REQUEST,
            txtHash: receipt.transactionHash,
          });
          const result = await nftService.depositNFT(nftId);
          if (result.data.data && result.data.status === "success") {
            dispatch({ type: COMPLETE_REQUEST });
            openNotificationWithIcon(
              "success",
              "Success",
              "Deposit NFT successfully!"
            );
          }
        });
    } catch (error) {
      if (error.code === 4001) {
        openNotificationWithIcon(
          "error",
          "Error",
          "You declined the action in your wallet"
        );
        dispatch({ type: REQUESTING });
      } else {
        dispatch({
          type: FAILED_REQUEST,
          txtHash: txtHash,
        });
        openNotificationWithIcon(
          "error",
          "Error",
          "Something went wrong. Please check the transaction for more details"
        );
      }
      console.log(error);
    }
  };
};

export const claimNFT = (nftId) => {
  let txtHash = "";
  return async (dispatch) => {
    try {
      dispatch({ type: REQUESTING });
      const resultSC = await contract_auction.methods
        .claimNFT(
          convertStringToBytes("depositNFT"),
          convertStringToBytes(nftId),
          true //isWeb
        )
        .send({ from: Cookies.get("UserAddress"), gasLimit: GAS_LIMIT })
        .on("transactionHash", function (hash) {
          txtHash = hash;
          console.log("TRANSACTION HASH => ", hash);
          dispatch({ type: PROCESSING, txtHash: hash });
        })
        .on("receipt", async function (receipt) {
          console.log("RECEIPT => ", receipt);
          dispatch({
            type: CLAIM_NFT,
            txtHash: receipt.transactionHash,
          });
          dispatch({
            type: COMPLETE_REQUEST,
            txtHash: receipt.transactionHash,
          });
          const result = await nftService.claimNFT(nftId);
          if (result.data.data && result.data.status === "success") {
            dispatch({ type: COMPLETE_REQUEST });
            openNotificationWithIcon(
              "success",
              "Success",
              "Claim NFT successfully!"
            );
          }
        });
    } catch (error) {
      if (error.code === 4001) {
        openNotificationWithIcon(
          "error",
          "Error",
          "You declined the action in your wallet"
        );
        dispatch({ type: REJECT_REQUEST });
      } else {
        dispatch({
          type: FAILED_REQUEST,
          txtHash: txtHash,
        });
        openNotificationWithIcon(
          "error",
          "Error",
          "Something went wrong. Please check the transaction for more details"
        );
      }
      console.log(error);
    }
  };
};

export const trackingNFT = (data) => {
  return async (dispatch) => {
    try {
      const result = await nftService.trackingViewNFT(data);
    } catch (error) {
      console.log(error);
    }
  };
};

export const likeNFT = (nftId) => {
  return async (dispatch) => {
    try {
      const result = await nftService.likeNFT(nftId);
      if (result && result.data.status === "success") {
        dispatch(detailNFT(nftId));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const unlikeNFT = (nftId) => {
  return async (dispatch) => {
    try {
      const result = await nftService.unlikeNFT(nftId);
      if (result && result.data.status === "success") {
        dispatch(detailNFT(nftId));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getArtistLatestNFTs = (filterData, pageSize, page) => {
  return async (dispatch) => {
    try {
      const result = await nftService.getArtistLatestNFTs(
        filterData,
        pageSize,
        page
      );
      if (result.data.data && result.data.status === "success") {
        dispatch({
          type: GET_ARTIST_LATEST_NFT,
          artistLatestNFTs: result.data.data.nfts,
          totalArtistLatestNFTs: result.data.data.total,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getLatestSale = (filterData, pageSize, page) => {
  return async (dispatch) => {
    try {
      const result = await nftService.getLatestSale(filterData, pageSize, page);
      if (result.data.data && result.data.status === "success") {
        dispatch({
          type: GET_LATEST_SALE,
          latestSale: result.data.data.nfts,
          totalLatestSale: result.data.data.total,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getPositionSection = () => {
  return async (dispatch) => {
    try {
      const result = await nftService.getPositionSection();
      if (result && result.data.status === "success") {
        dispatch({
          type: GET_POSITION_SECTION,
          positionSection: result.data.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getListCategories = () => {
  return async (dispatch) => {
    try {
      const result = await nftService.getListCategories();
      if (result && result.data.status === "success") {
        dispatch({
          type: GET_LIST_CATEGORIES,
          listCategories: result.data.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getFixedPriceNFTs = (filterData, pageSize, page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: REQUESTING });
      const result = await nftService.getFixedPriceNFTs(
        filterData,
        pageSize,
        page
      );
      if (result.data.data && result.data.status === "success") {
        dispatch({
          type: GET_LIST_FIXED_PRICE_NFTS,
          listFixedPriceNFT: result.data.data.nfts,
          totalFixedPriceNFT: result.data.data.total,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: PENDING_REQUEST });
    }
  };
};
