// export const GET_LIST_COLLECTION = "GET_LIST_COLLECTION";
export const SEARCH_NFT = "SEARCH_NFT";
export const GET_DETAIL_NFT = "GET_DETAIL_NFT";
export const SAVE_PROPERTIES = "SAVE_PROPERTIES";
export const TRANSFERED_NFT = "TRANSFERED_NFT";
export const MAKE_OFFER = "MAKE_OFFER";
export const GET_COIN_PRICE = "GET_COIN_PRICE";
export const GET_ACTIVITIES = "GET_ACTIVITIES";
export const GET_CREATED_NFT = "GET_CREATED_NFT";
export const SHUFFLE_NFT = "SHUFFLE_NFT";
export const CLAIM_NFT = "CLAIM_NFT";
export const DEPOSIT_NFT = "DEPOSIT_NFT";
export const TRACKING_VIEW_NFT = "TRACKING_VIEW_NFT";
export const LIKE_NFT = "LIKE_NFT";
export const UNLIKE_NFT = "UNLIKE_NFT";
export const GET_ARTIST_LATEST_NFT = "GET_ARTIST_LATEST_NFT";
export const GET_LATEST_SALE = "GET_LATEST_SALE";
export const GET_POSITION_SECTION = "GET_POSITION_SECTION";
export const GET_LIST_CATEGORIES = "GET_LIST_CATEGORIES";
export const GET_LIST_FIXED_PRICE_NFTS = "GET_LIST_FIXED_PRICE_NFTS";

export const SEARCH_NFT_HEADER = "SEARCH_NFT_HEADER";

// ______________ NFT STATUS ______________________
export const NFT_STATUS = {
  SELLABLE: "SELLABLE",
  BUY: "BUY",
  ON_BID: "ON_BID",
};
