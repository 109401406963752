import { Empty, Input, Pagination } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import StanArtistNFT from "../../component/StanArtistNFT/StanArtistNFT";
import { getArtistLatestNFTs } from "../../redux/action/NftAction";
import "./ArtistLatestNFT.css";
import StanNFTFilter from "../../component/StanNFTFilter/StanNFTFilter";

export default function ArtistLatestNFT(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["common", "homePage", "explore"]);
  const [dataFilter, setDataFilter] = useState({});
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedPageSize, setSelectedPageSize] = useState(10);

  const { artistLatestNFTs, totalArtistLatestNFTs } = useSelector(
    (state) => state.NFTReducer
  );

  function resetOffset() {
    setSelectedPage(1);
    setSelectedPageSize(10);
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can change this to 'auto' for instant scrolling
    });
  }, []);

  useEffect(() => {
    resetOffset();
  }, [dataFilter]);

  useEffect(() => {
    dispatch(getArtistLatestNFTs(dataFilter, selectedPageSize, selectedPage));
  }, [dataFilter, selectedPage, selectedPageSize]);

  return (
    <div style={{ minHeight: "100vh" }}>
      <div className="Explore-ArtistLatestNFT">
        <div className="d-flex justify-content-center mt-5 mb-3">
          <h2>{t("ARTIST_LATEST_NFT", { ns: "homePage" })}</h2>
        </div>
        <StanNFTFilter
          showSearch
          showSort
          priceName="Listing"
          dataFilter={dataFilter}
          setDataFilter={setDataFilter}
        />
        {totalArtistLatestNFTs > 0 ? (
          <>
            <div className="Explore-AritstLatestNFT-list">
              {artistLatestNFTs.map((nft) => {
                return (
                  <StanArtistNFT
                    nft={nft}
                    key={nft.id}
                    searchNFT={() => dispatch(getArtistLatestNFTs(10, 1))}
                  />
                );
              })}
            </div>
            <Pagination
              responsive={true}
              showSizeChanger={true}
              current={selectedPage}
              pageSize={selectedPageSize}
              onChange={(selectedPage, pageSize) => {
                setSelectedPage(selectedPage);
                setSelectedPageSize(pageSize);
              }}
              total={totalArtistLatestNFTs}
            />
          </>
        ) : (
          <Empty
            className="mt-5"
            description={t("MSG.NO_RESULTS_FOUND", { ns: "common" })}
          />
        )}
      </div>
    </div>
  );
}
