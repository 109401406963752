export const GET_LIST_COLLECTION = "GET_LIST_COLLECTION";
export const GET_DETAIL_COLLECTION = "GET_DETAIL_COLLECTION";
export const GET_COLLECTION_ACTIVITIES = "GET_COLLECTION_ACTIVITIES";
export const GET_COLLECTION_COLLECTED = "GET_COLLECTION_COLLECTED";
export const CREATE_COLLECTION = "CREATE_COLLECTION";
export const EDIT_COLLECTION = "EDIT_COLLECTION";
export const CLEAR_STATE_COLLECTION = "CLEAR_STATE_COLLECTION";

// INPUT SEARCH
export const SEARCHING_FOR_COLLECTION = "SEARCHING_FOR_COLLECTION";
export const SET_HOT_COLLECTION_SORT = "SET_HOT_COLLECTION_SORT";