// ___________________________________________________________________________
export const OPEN_MODAL_SELECT_IMAGES = "OPEN_MODAL_SELECT_IMAGES";
export const CLOSE_MODAL_SELECT_IMAGES = "CLOSE_MODAL_SELECT_IMAGES";
export const OPEN_MODAL_ADD_PROPERTY = "OPEN_MODAL_ADD_PROPERTY";
export const CLOSE_MODAL_ADD_PROPERTY = "CLOSE_MODAL_ADD_PROPERTY";
// ___________________________________________________________________________

// _________________________ DRAWER __________________________________________
export const OPEN_QR_DRAWER = "OPEN_QR_DRAWER";
export const CLOSE_QR_DRAWER = "CLOSE_QR_DRAWER";
// _________________________ DRAWER __________________________________________

// _________________________ CONFIRM MODAL __________________________________________
export const OPEN_CONFIRM_MODAL = "OPEN_CONFIRM_MODAL";
export const CLOSE_CONFIRM_MODAL = "CLOSE_CONFIRM_MODAL";
// _________________________ CONFIRM MODAL __________________________________________

// _________________________ DEPOSIT/ WITHDRAW _________________________________________
export const OPEN_DEPOSIT = "OPEN_DEPOSIT";
export const CLOSE_DEPOSIT = "CLOSE_DEPOSIT";
export const OPEN_WITHDRAW = "OPEN_WITHDRAW";
export const CLOSE_WITHDRAW = "CLOSE_WITHDRAW";
// _________________________ DEPOSIT/ WITHDRAW __________________________________________

// _________________________ CONNECT_WALLET _____________________________________________
export const OPEN_CONNECT_WALLET = "OPEN_CONNECT_WALLET";
export const CLOSE_CONNECT_WALLET = "CLOSE_CONNECT_WALLET";
// _________________________ CONNECT_WALLET _____________________________________________

// _________________________ DEPOSIT_NFT/ CLAIM_NFT _____________________________________
export const OPEN_DEPOSIT_NFT = "OPEN_DEPOSIT_NFT";
export const CLOSE_DEPOSIT_NFT = "CLOSE_DEPOSIT_NFT";
export const OPEN_CLAIM_NFT = "OPEN_CLAIM_NFT";
export const CLOSE_CLAIM_NFT = "CLOSE_CLAIM_NFT";
// _________________________ DEPOSIT_NFT/ CLAIM_NFT _____________________________________

// _________________________ LISTING_NFT/ CANCEL_LISTING_NFT ________________________________________________
export const OPEN_LISTING_NFT = "OPEN_LISTING_NFT";
export const CLOSE_LISTING_NFT = "CLOSE_LISTING_NFT";
export const OPEN_CANCEL_LISTING_NFT = "OPEN_CANCEL_LISTING_NFT";
export const CLOSE_CANCEL_LISTING_NFT = "CLOSE_CANCEL_LISTING_NFT";
// _________________________ LISTING_NFT ________________________________________________

// _________________________ MAKE_OFFER _________________________________________________
export const OPEN_MAKE_OFFER = "OPEN_MAKE_OFFER";
export const CLOSE_MAKE_OFFER = "CLOSE_MAKE_OFFER";
// _________________________ PLACE_BID __________________________________________________
export const OPEN_PLACE_BID = "OPEN_PLACE_BID";
export const CLOSE_PLACE_BID = "CLOSE_PLACE_BID";
// _________________________ BUY_NOW ____________________________________________________
export const OPEN_BUY_NOW = "OPEN_BUY_NOW";
export const CLOSE_BUY_NOW = "CLOSE_BUY_NOW";

// _________________________ ACCEPT/ CANCEL_OFFER _______________________________________
export const OPEN_ACCEPT_OFFER = "OPEN_ACCEPT_OFFER";
export const CLOSE_ACCEPT_OFFER = "CLOSE_ACCEPT_OFFER";
export const OPEN_CANCEL_OFFER = "OPEN_CANCEL_OFFER";
export const CLOSE_CANCEL_OFFER = "CLOSE_CANCEL_OFFER";

// _________________________ TRANSFER_NFT _______________________________________________
export const OPEN_TRANSFER_NFT = "OPEN_TRANSFER_NFT";
export const CLOSE_TRANSFER_NFT = "CLOSE_TRANSFER_NFT";
