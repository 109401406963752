import { useDispatch, useSelector } from "react-redux";
import { openNotificationWithIcon } from "../../../App";
import ModalInfoNFT from "../../../component/ModalInfoNFT/ModalInfoNFT";
import StanButton from "../../../component/StanButton/StanButton";
import StanTypo from "../../../component/StanTypo/StanTypo";
import { claimNFT } from "../../../redux/action/NftAction";
import {
  COMPLETE_REQUEST,
  FAILED_REQUEST,
  PROCESSING,
  REQUESTING,
} from "../../../redux/types/UserType";
import { autionService } from "../../../services/AutionService";

export default function ClaimNFT(props) {
  const { nftStatus, txtHash } = useSelector((state) => state.AutionReducer);
  const { payload } = useSelector((state) => state.ModalReducer);
  const { nft } = payload;
  const dispatch = useDispatch();

  const CheckNFT = async () => {
    const result = await autionService.getOffer(nft.id, 10, 1);
    if (result && result.data.data.total === 0) {
      dispatch(claimNFT(nft.id));
    } else {
      openNotificationWithIcon(
        "error",
        "Error",
        "Having active offers in this NFT!"
      );
      dispatch({ type: FAILED_REQUEST });
    }
  };

  const RenderContent = () => {
    switch (nftStatus) {
      case COMPLETE_REQUEST:
        return (
          <>
            <div className="d-flex flex-column text-center">
              <div>
                <img alt="" src="/img/Frame.png" width={150} height={150} />
              </div>
              <div>
                <StanTypo
                  fontSize={16}
                  fontWeight={400}
                  content="Claim NFT request submited"
                />
              </div>
              <div
                className="mt-4 pt-3"
                style={{ borderTop: "1px solid #EEEEEE" }}
              >
                <StanButton
                  title="View Transaction"
                  handleOnClick={() => {
                    window.open("https://mumbai.polygonscan.com/tx/" + txtHash);
                  }}
                  type="primary"
                />
              </div>
            </div>
          </>
        );
      case FAILED_REQUEST:
        return (
          <>
            <div className="d-flex flex-column claimNFT-modal">
              <div className="d-flex justify-content-center">
                <img alt="" src="/svg/failedMark.svg" />
              </div>
              <div className="d-flex justify-content-center">
                <h3>Transaction failed</h3>
              </div>
              <div className="d-flex justify-content-center">
                <StanTypo
                  fontSize={20}
                  fontWeight={400}
                  content="Something went wrong. Please check the transaction for more details"
                />
              </div>
              <div
                className="mt-4 pt-3"
                style={{ borderTop: "1px solid #EEEEEE" }}
              >
                <StanButton
                  title="View Transaction"
                  handleOnClick={() => {
                    window.open("https://mumbai.polygonscan.com/tx/" + txtHash);
                  }}
                  type="primary"
                />
              </div>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className="d-flex flex-column claimNFT-modal">
              <div className="mt-3 mb-3">
                <ModalInfoNFT nft={nft} disableRight={true} />
              </div>
              <div>
                <StanTypo
                  fontSize={16}
                  fontWeight={400}
                  content="This is the process of claiming ownership of the NFT. After succesfully claiming NFT, you can sell NFT on Auction Web, but not on Stan Mobile App."
                />
              </div>
              <div
                className="mt-4 pt-3"
                style={{ borderTop: "1px solid #EEEEEE" }}
              >
                <StanButton
                  type="primary"
                  title="Claim NFT"
                  disabled={
                    nftStatus === REQUESTING || nftStatus === PROCESSING
                  }
                  handleOnClick={() => CheckNFT()}
                />
              </div>
            </div>
          </>
        );
    }
  };
  return <RenderContent />;
}
