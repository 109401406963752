import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { EXPLORE_TABPANE } from "../../common/Constant";
import {
  exploreArtistSvg,
  exploreCollectionsSvg,
  exploreItemsSvg,
} from "../../common/commonSvg";
import { getListCategories } from "../../redux/action/NftAction";
import { getUSDRate } from "../../redux/action/UserAction";
import { SET_ACTIVE_TAB_EXPLORE } from "../../redux/types/UserType";
import "./Explore.css";
import ExploreArtist from "./ExploreArtist/ExploreArtist";
import ExploreCollection from "./ExploreCollection/ExploreCollection";
import ExploreItem from "./ExploreItem/ExploreItem";

const { TabPane } = Tabs;
export default function Explore(props) {
  const { t } = useTranslation(["common", "explore"]);
  const dispatch = useDispatch();
  const { exploreCurrentActiveTab } = useSelector(
    (state) => state.ModalReducer
  );
  const [activeTabPane, setActiveTabPane] = useState(
    EXPLORE_TABPANE.EXPLORE_ITEMS
  );
  const userInfo = JSON.parse(localStorage.getItem("UserProfile"));

  useEffect(() => {
    dispatch(getUSDRate());
    dispatch(getListCategories());
  }, []);

  useEffect(() => {
    if (exploreCurrentActiveTab !== "") {
      setActiveTabPane(exploreCurrentActiveTab);
    }
  }, [exploreCurrentActiveTab]);

  function renderTabPaneTitle(title, key) {
    let icon;
    if (key === EXPLORE_TABPANE.EXPLORE_COLLECTIONS) {
      icon = exploreCollectionsSvg(activeTabPane === key ? true : false);
    } else if (key === EXPLORE_TABPANE.EXPLORE_ITEMS) {
      icon = exploreItemsSvg(activeTabPane === key ? true : false);
    } else if (key === EXPLORE_TABPANE.EXPLORE_ARTISTS) {
      icon = exploreArtistSvg(activeTabPane === key ? true : false);
    }
    return (
      <span style={{ color: activeTabPane === key ? "#49D19A" : "#CBCBCB" }}>
        {icon} {title}
      </span>
    );
  }

  return (
    <>
      <div className="explore" style={{ minHeight: "100vh" }}>
        <div className="d-flex justify-content-center m-4">
          <h1>{t("TITLE.EXPLORE", { ns: "explore" })} 🎊</h1>
        </div>
        <Tabs
          activeKey={activeTabPane}
          onChange={(e) => {
            setActiveTabPane(e);
            dispatch({
              type: SET_ACTIVE_TAB_EXPLORE,
              exploreCurrentActiveTab: e,
            });
          }}
          size="large"
          className="exploreTabPane"
        >
          <TabPane
            tab={renderTabPaneTitle(
              t("TABPANE.ITEMS"),
              EXPLORE_TABPANE.EXPLORE_ITEMS
            )}
            key={EXPLORE_TABPANE.EXPLORE_ITEMS}
          >
            <ExploreItem />
          </TabPane>

          <TabPane
            tab={renderTabPaneTitle(
              t("TABPANE.ARTIST"),
              EXPLORE_TABPANE.EXPLORE_ARTISTS
            )}
            key={EXPLORE_TABPANE.EXPLORE_ARTISTS}
          >
            <ExploreArtist />
          </TabPane>
          
          <TabPane
            tab={renderTabPaneTitle(
              t("TABPANE.COLLECTIONS"),
              EXPLORE_TABPANE.EXPLORE_COLLECTIONS
            )}
            key={EXPLORE_TABPANE.EXPLORE_COLLECTIONS}
          >
            <ExploreCollection />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}
