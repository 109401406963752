import moment from "moment";
import { useEffect, useState } from "react";
import "./CountDown.css";

const DEFAULT_COUNT_DOWN = ["--", "--", "--", "--"];
export default function CountDown(props) {
  const [countDown, setCountDown] = useState(DEFAULT_COUNT_DOWN);
  const [days, hours, minutes, seconds] = countDown;
  const [isExpired, setIsExpired] = useState(false);
  const [countdownEndTime, setCountdownEndTime] = useState(
    moment().add(props.countdownEndTime, "seconds")
  );

  useEffect(() => {
    let interval = setInterval(() => {
      const now = moment();
      const duration = moment.duration(countdownEndTime.diff(now));
      let days = duration.days();
      let hours = duration.hours();
      let minutes = duration.minutes();
      let seconds = duration.seconds();
      setCountDown([days, hours, minutes, seconds]);
      if (days === 0 && hours === 0 && minutes === 0 && seconds <= 0) {
        setIsExpired(true);
        clearInterval(interval);
      }
    }, 1000);
  });

  return (
    <>
      {!isExpired ? (
        <div className="countDownContainer" style={{ display: "flex" }}>
          <div className="countDownItem">
            <h1>{days}</h1>
            <span>DAYS</span>
          </div>
          <span className="countDownSplitter">:</span>
          <div className="countDownItem">
            <h1>{hours}</h1>
            <span>HOURS</span>
          </div>
          <span className="countDownSplitter">:</span>
          <div className="countDownItem">
            <h1>{minutes}</h1>
            <span>MINUTES</span>
          </div>
          <span className="countDownSplitter">:</span>
          <div className="countDownItem">
            <h1>{seconds}</h1>
            <span>SECONDS</span>
          </div>
        </div>
      ) : null}
    </>
  );
}
