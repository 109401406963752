import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { MaticIcon, WETHIcon } from "../../common/commonSvg";
import {
  formatedCash,
  formatStanCryptoDetail,
  formatStanUsd,
  subStringText,
  usdOnCrypto,
} from "../../util/settings/utils";
import StanTypo from "../StanTypo/StanTypo";
import "./ModalInfoNFT.css";
import { MEDIA_TYPE } from "../../common/Constant";

export default function ModalInfoNFT(props) {
  const { nft, InputPrice, currency, disableRight } = props;
  const { rateCrypto } = useSelector((state) => state.UserReducer);

  let price = 0;
  if (!_.isUndefined(InputPrice)) {
    price = InputPrice;
  } else {
    price = _.isUndefined(nft) ? 0 : nft?.priceCrypto || nft?.price;
  }

  function mediaUrl() {
    if (
      nft?.mediaType === MEDIA_TYPE.VIDEO ||
      nft?.mediaType === MEDIA_TYPE.LIVESTREAM ||
      nft?.mediaType === MEDIA_TYPE.AUDIO
    ) {
      return nft?.mediaPreviewUrl;
    } else {
      return nft?.mediaUrl || nft?.nftMediaUrl;
    }
  }

  return (
    <div className="ModalInfo">
      <div className="ModalInfoDetail">
        <img alt="" src={mediaUrl()} width={56} height={56} />
        <div className="ModalHeadDetail">
          <h5>{subStringText(nft?.collectionName, 20)}</h5>
          <h4>{subStringText(nft?.name || nft?.nftName, 20)}</h4>
        </div>
      </div>
      {disableRight ? null : (
        <div className="ModalPrice">
          <div className="d-flex flex-column align-items-end justify-content-center">
            <div className="d-flex">
              {/* <WETHIcon /> */}
              <MaticIcon />
              <StanTypo
                fontSize={20}
                fontWeight={500}
                content={formatStanCryptoDetail(price)}
                color="#0D0D0D"
              />
            </div>
            {/* <div>
              <StanPointIcon />
              <StanTypo
                fontSize={12}
                fontWeight={400}
                content={formatStanPoint(nft?.pricePoint)}
              />
            </div> */}
            <div className="d-flex">
              <StanTypo
                fontSize={12}
                fontWeight={400}
                content={
                  nft?.priceUsd
                    ? "$" + formatStanUsd(nft.priceUsd)
                    : "$" + formatedCash(usdOnCrypto(price, rateCrypto))
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
