import {
  CLOSE_ACCEPT_OFFER,
  CLOSE_BUY_NOW,
  CLOSE_CANCEL_LISTING_NFT,
  CLOSE_CANCEL_OFFER,
  CLOSE_CLAIM_NFT,
  CLOSE_CONFIRM_MODAL,
  CLOSE_CONNECT_WALLET,
  CLOSE_DEPOSIT,
  CLOSE_DEPOSIT_NFT,
  CLOSE_LISTING_NFT,
  CLOSE_MAKE_OFFER,
  CLOSE_MODAL_ADD_PROPERTY,
  CLOSE_MODAL_SELECT_IMAGES,
  CLOSE_PLACE_BID,
  CLOSE_QR_DRAWER,
  CLOSE_TRANSFER_NFT,
  CLOSE_WITHDRAW,
  OPEN_ACCEPT_OFFER,
  OPEN_BUY_NOW,
  OPEN_CANCEL_LISTING_NFT,
  OPEN_CANCEL_OFFER,
  OPEN_CLAIM_NFT,
  OPEN_CONFIRM_MODAL,
  OPEN_CONNECT_WALLET,
  OPEN_DEPOSIT,
  OPEN_DEPOSIT_NFT,
  OPEN_LISTING_NFT,
  OPEN_MAKE_OFFER,
  OPEN_MODAL_ADD_PROPERTY,
  OPEN_MODAL_SELECT_IMAGES,
  OPEN_PLACE_BID,
  OPEN_QR_DRAWER,
  OPEN_TRANSFER_NFT,
  OPEN_WITHDRAW,
} from "../types/ModalType";
import {
  SET_ACTIVE_TAB_EXPLORE,
  SET_ACTIVE_TAB_PROFILE,
} from "../types/UserType";

const initialState = {
  openModalSelectImages: false,
  openModalAddProperty: false,
  openQRDrawer: false,
  openDeposit: false,
  openWithdraw: false,
  openConnectWallet: false,
  openConfirm: false,
  // AUCTION
  openDepositNFT: false,
  openClaimNFT: false,
  openListingNFT: false,
  openCancelListingNFT: false,
  openMakeOffer: false,
  openPlaceBid: false,
  openBuyNow: false,
  openAcceptOffer: false,
  openCancelOffer: false,
  openTransferNFT: false,
  transactionHash: "",
  payload: {},
  onClose: {},
  exploreCurrentActiveTab: "",
  profileCurrentActiveTab: "",
};

export const ModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CONFIRM_MODAL:
      return { ...state, openConfirm: true };
    case CLOSE_CONFIRM_MODAL:
      return { ...state, openConfirm: false };
    case OPEN_MODAL_SELECT_IMAGES:
      state.openModalSelectImages = true;
      return { ...state };
    case CLOSE_MODAL_SELECT_IMAGES:
      state.openModalSelectImages = false;
      return { ...state };
    case OPEN_MODAL_ADD_PROPERTY:
      state.openModalAddProperty = true;
      return { ...state };
    case CLOSE_MODAL_ADD_PROPERTY:
      state.openModalAddProperty = false;
      return { ...state };
    case OPEN_QR_DRAWER:
      state.openQRDrawer = true;
      return { ...state };
    case CLOSE_QR_DRAWER:
      state.openQRDrawer = false;
      return { ...state };
    case OPEN_DEPOSIT:
      state.openDeposit = true;
      return { ...state };
    case CLOSE_DEPOSIT:
      state.openDeposit = false;
      return { ...state };
    case OPEN_WITHDRAW:
      state.openDeposit = true;
      return { ...state };
    case CLOSE_WITHDRAW:
      state.openDeposit = false;
      return { ...state };
    case OPEN_CONNECT_WALLET:
      state.openConnectWallet = true;
      state.onClose = action.onClose;
      return { ...state };
    case CLOSE_CONNECT_WALLET:
      state.openConnectWallet = false;
      return { ...state };
    case OPEN_DEPOSIT_NFT:
      state.openDepositNFT = true;
      state.onClose = action.onClose;
      state.payload = action.payload;
      return { ...state };
    case CLOSE_DEPOSIT_NFT:
      state.openDepositNFT = false;
      return { ...state };
    case OPEN_CLAIM_NFT:
      state.openClaimNFT = true;
      state.onClose = action.onClose;
      state.payload = action.payload;
      return { ...state };
    case CLOSE_CLAIM_NFT:
      state.openClaimNFT = false;
      return { ...state };
    case OPEN_LISTING_NFT:
      return {
        ...state,
        openListingNFT: true,
        payload: action.payload,
        onClose: action.onClose,
      };
    case CLOSE_LISTING_NFT:
      return {
        ...state,
        openListingNFT: false,
      };
    case OPEN_CANCEL_LISTING_NFT:
      return {
        ...state,
        openCancelListingNFT: true,
        payload: action.payload,
        onClose: action.onClose,
      };
    case CLOSE_CANCEL_LISTING_NFT:
      return {
        ...state,
        openCancelListingNFT: false,
      };
    case OPEN_MAKE_OFFER:
      return {
        ...state,
        openMakeOffer: true,
        payload: action.payload,
        onClose: action.onClose,
      };
    case CLOSE_MAKE_OFFER:
      return {
        ...state,
        openMakeOffer: false,
      };
    case OPEN_PLACE_BID:
      return {
        ...state,
        openPlaceBid: true,
        payload: action.payload,
        onClose: action.onClose,
      };
    case CLOSE_PLACE_BID:
      return {
        ...state,
        openPlaceBid: false,
      };
    case OPEN_BUY_NOW:
      return {
        ...state,
        openBuyNow: true,
        payload: action.payload,
        onClose: action.onClose,
      };
    case CLOSE_BUY_NOW:
      return {
        ...state,
        openBuyNow: false,
      };
    case OPEN_ACCEPT_OFFER:
      return {
        ...state,
        openAcceptOffer: true,
        payload: action.payload,
        onClose: action.onClose,
      };
    case CLOSE_ACCEPT_OFFER:
      return {
        ...state,
        openAcceptOffer: false,
      };
    case OPEN_CANCEL_OFFER:
      return {
        ...state,
        openCancelOffer: true,
        payload: action.payload,
        onClose: action.onClose,
      };
    case CLOSE_CANCEL_OFFER:
      return {
        ...state,
        openCancelOffer: false,
      };
    case OPEN_TRANSFER_NFT:
      return {
        ...state,
        openTransferNFT: true,
        payload: action.payload,
        onClose: action.onClose,
      };
    case CLOSE_TRANSFER_NFT:
      return {
        ...state,
        openTransferNFT: false,
      };
    // SET ACTIVE TAB (ALIVE)
    case SET_ACTIVE_TAB_EXPLORE: {
      return {
        ...state,
        exploreCurrentActiveTab: action.exploreCurrentActiveTab,
      };
    }
    case SET_ACTIVE_TAB_PROFILE: {
      return {
        ...state,
        profileCurrentActiveTab: action.profileCurrentActiveTab,
      };
    }
    default:
      return { ...state };
  }
};
