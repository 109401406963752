import { Empty } from "antd";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { history } from "../../../App";
import { EXPLORE_PATH } from "../../../common/Constant";
import {
  FixedPriceNFTIcon,
  SampleNextArrow,
  SamplePrevArrow,
} from "../../../common/commonSvg";
import StanNFTCard from "../../../component/StanNFTCard/StanNFTCard";
import StanTypo from "../../../component/StanTypo/StanTypo";
import { getFixedPriceNFTs } from "../../../redux/action/NftAction";
import "./FixedPriceNFTSection.css";

export default function FixedPriceNFTSection() {
  const { t, i18n } = useTranslation(["common", "homePage", "nft"]);
  const dispatch = useDispatch();
  const { listFixedPriceNFT, totalFixedPriceNFT } = useSelector(
    (state) => state.NFTReducer
  );

  const settingsFixedPrice = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow:
      listFixedPriceNFT.length === 3 || listFixedPriceNFT.length === 4
        ? listFixedPriceNFT.length
        : 5,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow:
            listFixedPriceNFT.length < 4 ? listFixedPriceNFT.length : 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  useEffect(() => {
    dispatch(getFixedPriceNFTs({}, 10, 1));
  }, []);

  const FixedPriceNFTs = useMemo(() => {
    if (listFixedPriceNFT.length === 0) {
      return (
        <div>
          <Empty description={t("NONE_NFT", { ns: "homePage" })} />
        </div>
      );
    } else if (listFixedPriceNFT.length <= 2) {
      return (
        <>
          <div className="LiveAuction-list">
            {listFixedPriceNFT.map((nft) => {
              return <StanNFTCard nft={nft} key={nft.id} />;
            })}
          </div>
        </>
      );
    } else {
      return (
        <Slider {...settingsFixedPrice}>
          {listFixedPriceNFT.map((nft) => {
            return <StanNFTCard nft={nft} key={nft.id} />;
          })}
        </Slider>
      );
    }
  }, [listFixedPriceNFT]);

  return (
    <>
      <div className="FixedPrice">
        <div className="FixedPrice-header">
          <div className="d-flex align-items-center">
            <div className="FixedPrice-icon mr-2">
              <FixedPriceNFTIcon />
            </div>
            <h2>{t("TITLE.FIXED_PRICE_NFT", { ns: "nft" })}</h2>
          </div>
          <div
            className="d-flex"
            onClick={() => history.push(EXPLORE_PATH.FIXED_PRICE)}
          >
            <StanTypo
              fontSize={16}
              fontWeight={400}
              color="#49D19A"
              customStyle={{ cursor: "pointer" }}
              content={t("BUTTON.SEE_ALL", { ns: "common" }).toUpperCase()}
            />
          </div>
        </div>
        {FixedPriceNFTs}
      </div>
    </>
  );
}
