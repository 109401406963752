import { Image } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import { history } from "../../../../App";
import {
  MaticIcon,
  StanPointIcon,
  WETHIcon,
  listSvg,
  visitSvg,
} from "../../../../common/commonSvg";
import StanButton from "../../../../component/StanButton/StanButton";
import { getProfileListing } from "../../../../redux/action/UserAction";
import {
  CLOSE_CANCEL_LISTING_NFT,
  OPEN_CANCEL_LISTING_NFT,
} from "../../../../redux/types/ModalType";
import { PROCESSING, REQUESTING } from "../../../../redux/types/UserType";
import {
  dateDiff,
  formatStanCrypto,
  formatStanCryptoDetail,
  formatStanPoint,
  formatStanUsd,
  subStringText,
} from "../../../../util/settings/utils";
import "./ListingTab.css";

export default function ListingTab(props) {
  const { t } = useTranslation(["common", "nftDetail"]);
  const { listProfileListings } = useSelector((state) => state.UserReducer);
  const { nftStatus } = useSelector((state) => state.AutionReducer);
  const dispatch = useDispatch();

  const ListedNftItem = (props) => {
    const { nft } = props;

    function closeModal() {
      dispatch(getProfileListing(null, null));
    }

    return (
      <>
        <div className="listingMadeItem d-flex justify-content-between align-items-center">
          <div
            style={{ width: "30%" }}
            className="d-flex flex-row justify-content-start align-items-center"
          >
            <div className="mr-3">
              <Image
                src={nft.nftMediaUrl}
                preview={false}
                onClick={() => {
                  history.push(generatePath("/nft/:id", { id: nft.nftId }));
                }}
                width={56}
                height={56}
                style={{ objectFit: "cover", cursor: "pointer" }}
              />
            </div>
            <div className="d-flex flex-column justify-content-start align-items-start">
              <span>{nft.collectionName}</span>
              <span>{nft.nftName}</span>
            </div>
          </div>

          <div
            style={{ width: "30%" }}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <div>
              <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                <MaticIcon /> {formatStanCryptoDetail(nft.priceCrypto)}
              </span>
              <span>
                <StanPointIcon /> {formatStanPoint(nft.pricePoint)}
              </span>
            </div>
            <div>
              <span>($ {formatStanUsd(nft.priceUsd)})</span>
            </div>
          </div>

          <div
            style={{ width: "30%" }}
            className="d-flex justify-content-start align-items-center"
          >
            <span>
              {dateDiff(nft.createdAt)} days ago{" "}
              {visitSvg(nft.transactionTxUrl)}
            </span>
          </div>
          <div style={{ width: "10%" }}>
            <StanButton
              type="stanBtnCancel"
              title={t("BUTTON.CANCEL")}
              hidden={nft.isMobile}
              disabled={nftStatus === REQUESTING || nftStatus === PROCESSING}
              handleOnClick={() => {
                dispatch({
                  type: OPEN_CANCEL_LISTING_NFT,
                  payload: { nft: nft },
                  onClose: () => {
                    dispatch({ type: CLOSE_CANCEL_LISTING_NFT });
                    closeModal();
                  },
                });
              }}
            />
          </div>
        </div>
        {/* RESPONSIVE */}
        <div className="listingMadeItemResponsive">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <div className="mr-2">
                <Image
                  src={nft.nftMediaUrl}
                  preview={false}
                  onClick={() => {
                    history.push(generatePath("/nft/:id", { id: nft.nftId }));
                  }}
                  width={56}
                  height={56}
                  style={{
                    objectFit: "cover",
                    cursor: "pointer",
                    borderRadius: "5px",
                  }}
                />
              </div>
              <div className="d-flex flex-column justify-content-center align-items-start">
                <span style={{ fontSize: "14px", color: "#8D8D8D" }}>
                  {subStringText(nft.collectionName, 15)}
                </span>
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {subStringText(nft.nftName, 15)}
                </span>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div>
                <span style={{ fontWeight: "bold", marginRight: "3px" }}>
                  <MaticIcon /> {formatStanCrypto(nft.priceCrypto)}
                </span>
                <span style={{ fontWeight: "bold" }}>
                  <StanPointIcon /> {formatStanPoint(nft.pricePoint)}
                </span>
              </div>
              <div>
                <span style={{ fontSize: "12px", color: "#8D8D8D" }}>
                  ${formatStanUsd(nft.priceUsd)}
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <span style={{ fontSize: "14px", color: "#8D8D8D" }}>
              {dateDiff(nft.createdAt)} day ago {visitSvg(nft.transactionTxUrl)}
            </span>
          </div>
          <div>
            <StanButton
              type="secondary"
              title={t("BUTTON.CANCEL")}
              hidden={nft.isMobile}
              disabled={nftStatus === REQUESTING || nftStatus === PROCESSING}
              handleOnClick={() => {
                dispatch({
                  type: OPEN_CANCEL_LISTING_NFT,
                  payload: { nft: nft },
                  onClose: () => {
                    dispatch({ type: CLOSE_CANCEL_LISTING_NFT });
                    closeModal();
                  },
                });
              }}
            />
          </div>
        </div>
        {/* RESPONSIVE */}
      </>
    );
  };

  return (
    <div className="moreTab">
      <div className="moreTabContent">
        <div
          className="listingMadeTitle"
          style={{
            padding: "15px 30px",
            border: "1px solid #EEEEEE",
            display: "flex",
            alignItems: "center",
          }}
        >
          <h3>
            {listSvg()}
            {t("TABPANE.LISTING")}
          </h3>
        </div>
        {listProfileListings.map((item) => {
          return <ListedNftItem nft={item} key={item.id} />;
        })}
      </div>
    </div>
  );
}
