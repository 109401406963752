import { Image, message, Skeleton, Tabs, Tooltip } from "antd";
import branch from "branch-sdk";
import Cookies from "js-cookie";
import _ from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useParams } from "react-router-dom";
import { history } from "../../App";
import { BUY, ON_BID, SELLABLE } from "../../common/commonStatus";
import {
  aboutTabPaneSvg,
  historyTabPaneSvg,
  makeOfferSvg,
  MaticIcon,
  offerTabPaneSvg,
  sellable,
  StanPointIcon,
  transferSvg,
} from "../../common/commonSvg";
import {
  EXPLORE_PATH,
  MEDIA_TYPE,
  NFT_DETAIL_TABPANE,
  NFT_SORT_BY,
} from "../../common/Constant";
import CountDown from "../../component/CountDown/CountDown";
import InfoCard from "../../component/InfoCard/InfoCard";
import StanButton from "../../component/StanButton/StanButton";
import StanNFTCard from "../../component/StanNFTCard/StanNFTCard";
import StanTypo from "../../component/StanTypo/StanTypo";
import {
  getBalanceOfFan,
  getListOffer,
  getStanFixed,
} from "../../redux/action/AutionAction";
import {
  detailNFT,
  getActivities,
  likeNFT,
  searchNFT,
  trackingNFT,
  unlikeNFT,
} from "../../redux/action/NftAction";
import { getStanFee, getUSDRate } from "../../redux/action/UserAction";
import {
  CLOSE_BUY_NOW,
  CLOSE_CANCEL_LISTING_NFT,
  CLOSE_CONNECT_WALLET,
  CLOSE_LISTING_NFT,
  CLOSE_MAKE_OFFER,
  CLOSE_PLACE_BID,
  CLOSE_TRANSFER_NFT,
  OPEN_BUY_NOW,
  OPEN_CANCEL_LISTING_NFT,
  OPEN_CONNECT_WALLET,
  OPEN_LISTING_NFT,
  OPEN_MAKE_OFFER,
  OPEN_PLACE_BID,
  OPEN_QR_DRAWER,
  OPEN_TRANSFER_NFT,
} from "../../redux/types/ModalType";
import { CLEAR_STATE, PENDING_REQUEST } from "../../redux/types/UserType";
import { SITE_URL, TOKEN_COOKIE } from "../../util/settings/config";
import {
  formatStanCryptoDetail,
  formatStanPointDetail,
  formatViewLikeCount,
  subStringText,
} from "../../util/settings/utils";
import AboutTab from "./AboutTab/AboutTab";
import HistoryTab from "./HistoryTab/HistoryTab";
import "./NftDetail.css";
import OfferTab from "./OfferTab/OfferTab";
import ReactHlsPlayer from "react-hls-player";
// import AudioControl from "../../component/AudioControl/AudioControl";

const { TabPane } = Tabs;
export default function NftDetail() {
  const { t } = useTranslation(["common", "nftDetail", "explore"]);
  //____________________________ INITIAL STATE ____________________________//
  const dispatch = useDispatch();
  const { id } = useParams();
  const [activities, setActivities] = useState("ALL");
  const [activeTabPane, setActiveTabPane] = useState(NFT_DETAIL_TABPANE.ABOUT);
  const userProfileLogged = JSON.parse(localStorage.getItem("UserProfile"));
  const audioRef = useRef();

  // REDUX
  const { listOffer } = useSelector((state) => state.AutionReducer);
  const { nft, user, listNFT, transactionList } = useSelector(
    (state) => state.NFTReducer
  );

  // useEffects //
  useEffect(() => {
    window.scrollTo(0, 0);
    // dispatch(detailNFT(id));
    dispatch(getListOffer(id, 10, 1));
    dispatch(trackingNFT({ nftId: id }));
    dispatch(getUSDRate());
    dispatch(getStanFixed());
    dispatch(getStanFee());
    // branch.init(DEEP_LINK_KEY, function (err, data) {
    //   console.log(err, data);
    // });
  }, [id]);

  useEffect(() => {
    if (activeTabPane === NFT_DETAIL_TABPANE.OFFERS) {
      dispatch(getListOffer(id, 10, 1));
    }
    if (activeTabPane === NFT_DETAIL_TABPANE.HISTORY) {
      dispatch(
        getActivities(
          id,
          { status: "", type: activities === "ALL" ? "" : activities, by: "" },
          10,
          1
        )
      );
    }
    if (activeTabPane === NFT_DETAIL_TABPANE.ABOUT) {
      dispatch(getStanFee());
    }
  }, [activeTabPane]);

  // MORE FROM THIS COLLECTION
  useEffect(() => {
    if (nft.collectionId) {
      dispatch(
        searchNFT(
          {
            collectionId: nft.collectionId,
            orderBy: NFT_SORT_BY.RECENTLY_CREATED,
          },
          6,
          1
        )
      );
    }
  }, [nft]);

  useEffect(() => {
    dispatch(detailNFT(id));
  }, [id]);

  useEffect(() => {
    dispatch(getBalanceOfFan());
  }, []);

  //______________________________________________________________________//

  const handlePlayAudio = () => {
    if (
      audioRef.current.paused &&
      audioRef.current.currentTime > 0 &&
      !audioRef.current.ended
    ) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  };
  //______________________________________________________________________//

  const PriceOfNft = (props) => {
    const { nft } = props;
    return (
      <>
        {nft.status !== SELLABLE ? (
          <div className="d-flex flex-column mt-3 mb-3">
            <div className="d-flex">
              <MaticIcon />
              <StanTypo
                fontSize={32}
                fontWeight={700}
                color="#0D0D0D"
                content={formatStanCryptoDetail(nft.priceCrypto)}
              />
            </div>
            <div className="d-flex">
              <StanPointIcon />
              <StanTypo
                fontSize={20}
                fontWeight={500}
                color="#939393"
                content={formatStanPointDetail(nft.pricePoint)}
              />
            </div>
            {/* <span style={{ color: "#8D8D8D" }}>
              ${formatStanUsd(nft.priceUsd)}
            </span> */}
          </div>
        ) : null}
      </>
    );
  };

  const NftDetailButton = () => {
    const existedOffer = listOffer.find(
      (offer) =>
        offer.type === "OFFER" &&
        offer.status === "ACTIVE" &&
        offer.buyerWalletAddress === Cookies.get("UserAddress")
    );
    // OWNER
    if (nft.ownerAddress === Cookies.get("UserAddress")) {
      switch (nft.status) {
        case SELLABLE:
          return (
            <div className="btnOwnerSellable">
              <div>
                <StanButton
                  title={t("BUTTON.SELL_NOW")}
                  handleOnClick={() => {
                    dispatch({
                      type: OPEN_LISTING_NFT,
                      payload: { nft: nft },
                      onClose: () => {
                        dispatch({ type: CLOSE_LISTING_NFT });
                        dispatch({ type: PENDING_REQUEST });
                        dispatch(detailNFT(id));
                      },
                    });
                  }}
                  type="primary"
                  icon={sellable()}
                  requireApprove
                />
              </div>
              {
                // check nft is from owner address to show button transfer
                nft.status !== ON_BID ? (
                  <div>
                    <StanButton
                      title={t("TITLE.TRANSFER", { ns: "nftDetail" })}
                      handleOnClick={() => {
                        dispatch({
                          type: OPEN_TRANSFER_NFT,
                          payload: { nft: nft },
                          onClose: () => {
                            dispatch({ type: CLOSE_TRANSFER_NFT });
                            closeModal();
                          },
                        });
                      }}
                      type="transfer"
                      icon={transferSvg()}
                      requireApprove
                    />
                  </div>
                ) : null
              }
            </div>
          );
        case ON_BID:
          return (
            <>
              <div className="mt-4">
                <StanButton
                  handleOnClick={() => {
                    dispatch({
                      type: OPEN_CANCEL_LISTING_NFT,
                      payload: { nft: nft },
                      onClose: () => {
                        dispatch({ type: CLOSE_CANCEL_LISTING_NFT });
                        closeModal();
                      },
                    });
                  }}
                  title={t("BUTTON.CANCEL")}
                  type="stanBtnCancel"
                  requireApprove
                />
              </div>
              <div style={{ margin: "10px 0px" }}>{renderCountDown()}</div>
            </>
          );
        case BUY:
          return (
            <>
              <div className="mt-4">
                <StanButton
                  handleOnClick={() => {
                    dispatch({
                      type: OPEN_CANCEL_LISTING_NFT,
                      payload: { nft: nft },
                      onClose: () => {
                        dispatch({ type: CLOSE_CANCEL_LISTING_NFT });
                        closeModal();
                      },
                    });
                  }}
                  title={t("BUTTON.CANCEL")}
                  type="stanBtnCancel"
                  requireApprove
                />
              </div>
              <div style={{ margin: "10px 0px" }}>{renderCountDown()}</div>
            </>
          );
        default:
          return null;
      }
    } else {
      // VIEWER
      switch (nft.status) {
        case "ON_BID":
          return (
            <div className="mt-4">
              <StanButton
                title={
                  existedOffer
                    ? t("TITLE.PLACE_BID", { ns: "nftDetail" })
                    : t("TITLE.PLACE_BID", { ns: "nftDetail" })
                }
                handleOnClick={() => {
                  if (Cookies.get("TokenId")) {
                    dispatch({
                      type: OPEN_PLACE_BID,
                      payload: { nft: nft, transactionList: transactionList },
                      onClose: () => {
                        dispatch({ type: CLOSE_PLACE_BID });
                        closeModal();
                      },
                    });
                  } else if (
                    !Cookies.get("TokenId") ||
                    _.isUndefined(TOKEN_COOKIE)
                  ) {
                    dispatch({ type: OPEN_QR_DRAWER });
                  }
                }}
                type="primary"
                icon={sellable()}
                requireApprove
              />
              <div style={{ margin: "10px 0px" }}>{renderCountDown()}</div>
            </div>
          );
        default:
          return (
            <>
              <div className="btnOwnerSellable">
                {nft.status === "BUY" ? (
                  <div>
                    <StanButton
                      title="BUY NOW"
                      handleOnClick={() => {
                        if (Cookies.get("TokenId")) {
                          dispatch({
                            type: OPEN_BUY_NOW,
                            payload: { nft: nft },
                            onClose: () => {
                              dispatch({ type: CLOSE_BUY_NOW });
                              closeModal();
                            },
                          });
                        } else if (
                          !Cookies.get("TokenId") ||
                          _.isUndefined(TOKEN_COOKIE)
                        ) {
                          dispatch({ type: OPEN_QR_DRAWER });
                        }
                      }}
                      type="primary"
                      icon={sellable()}
                      requireApprove
                    />
                  </div>
                ) : null}
                {/* NOT ALLOW USER MAKE OFFER FOR NFT WHICH LISTED */}
                {nft.status === "BUY" ? null : OfferButton()}

                {/* ALLOW USER MAKE OFFER FOR NFT WHICH LISTED */}
                {/* {nft.status === "BUY" ? OfferButton() : OfferButton()} */}
                {/* {OfferButton()} */}
              </div>
              <div style={{ margin: "10px 0px" }}>{renderCountDown()}</div>
            </>
          );
      }
    }
  };
  // _______________ MODAL CONTROL _______________
  console.log("nft123", nft);

  const onChangeTabPane = (key) => {
    setActiveTabPane(key);
  };

  const handleViewDetailCollection = (data) => {
    dispatch({ type: CLEAR_STATE });
    history.push(
      generatePath("/collectionDetail/:collectionId", {
        collectionId: data,
      })
    );
  };

  function closeModal() {
    dispatch(detailNFT(id));
    dispatch(getListOffer(id, 10, 1));
    dispatch({ type: PENDING_REQUEST });
  }

  function handleShareNFT() {
    var linkData = {
      data: {
        data: JSON.stringify({
          content: nft.id,
          type: "nftDetail",
        }),
        $og_title: "STAN - NFT",
        $og_description: "STAN Marketplace",
        $og_image_url:
          nft.mediaType === MEDIA_TYPE.IMAGE
            ? nft.mediaUrl
            : nft.mediaPreviewUrl,
        $og_image:
          nft.mediaType === MEDIA_TYPE.IMAGE
            ? nft.mediaUrl
            : nft.mediaPreviewUrl,
        $desktop_url: SITE_URL + "nft/" + nft.id,
        $ios_url: SITE_URL + "nft/" + nft.id,
        $android_url: SITE_URL + "nft/" + nft.id,
      },
    };
    branch.link(linkData, function (err, link) {
      console.log(link);
      navigator.clipboard.writeText(link);
      message.success("Copied to clipboard!");
    });
  }

  const isM3u8File = (url) => {
    return url.includes("m3u8");
  };

  function renderImage(nft) {
    let imageSrc = nft.mediaUrl;
    if (_.isNull(imageSrc) || !imageSrc) {
      return <Skeleton.Image width="100%" />;
    }
    if (nft?.mediaType === MEDIA_TYPE.LIVESTREAM && isM3u8File(imageSrc)) {
      return (
        <div className="previewSideImage">
          <ReactHlsPlayer
            src={imageSrc}
            autoPlay
            controls
            width={"100%"}
            height={"100%"}
            muted={true}
            loop
          />
        </div>
      );
    } else if (
      nft?.mediaType === MEDIA_TYPE.VIDEO ||
      nft?.mediaType === MEDIA_TYPE.LIVESTREAM
    ) {
      return (
        <div className="previewSideImage">
          <video
            src={imageSrc}
            width={"100%"}
            height={"100%"}
            controls={true}
            autoPlay={true}
            muted={true}
            loop
          />
        </div>
      );
    } else if (nft?.mediaType === MEDIA_TYPE.AUDIO) {
      return (
        <div className="audio-wrapper" onClick={handlePlayAudio} id="audio-id">
          <img src={nft?.audioThumbnailUrl} className="audio-image" alt="" />
          <div className="clsaudio">
            <audio
              ref={audioRef}
              controls
              autoPlay
              loop
              className="audio-control"
              src={nft?.mediaUrl}
            ></audio>
          </div>
        </div>
      );
    } else {
      return (
        <div className="previewSideImage">
          <Image
            src={nft.mediaUrl}
            width={"100%"}
            height={"100%"}
            style={{ objectFit: "contain" }}
          />
        </div>
      );
    }
  }

  function renderCountDown() {
    let listingTransaction = transactionList.find(
      (transaction) =>
        transaction.status === "ACTIVE" && transaction.type === "AUCTION"
    );
    if (listingTransaction) {
      let endDate = moment(listingTransaction.endDate).format(
        "DD-MM-YYYY HH:mm:ss"
      );
      let endDateFormat = moment(listingTransaction.endDate).format("LL");
      let endTime = moment(listingTransaction.endDate).format("h:mma");

      const then = moment(endDate, "DD-MM-YYYY HH:mm:ss");
      const now = moment();
      const duration = moment.duration(then.diff(now));
      const days = duration.days();
      return (
        <>
          <span>
            {t("ITEM_EXPIRE_ON", { ns: "nftDetail" })} {endDateFormat}{" "}
            {t("AT", { ns: "nftDetail" })} {endTime}
          </span>
          {days < 7 ? (
            <CountDown
              countdownEndTime={listingTransaction.countdownEndTime}
              timeFormat="DD-MM-YYYY HH:mm:ss"
            />
          ) : null}
        </>
      );
    } else {
      return null;
    }
  }

  function OfferButton() {
    if (
      transactionList.find(
        (item) =>
          item.buyerWalletAddress === Cookies.get("UserAddress") &&
          item.status === "ACTIVE" &&
          item.isAuction
      )
    ) {
      return (
        <div>
          <StanButton
            title={t("TITLE.MAKE_OFFER", { ns: "nftDetail" })}
            handleOnClick={() => {
              if (Cookies.get("TokenId")) {
                dispatch(getListOffer(nft.id, 500, 1));
                dispatch({
                  type: OPEN_MAKE_OFFER,
                  payload: { nft: nft },
                  onClose: () => {
                    dispatch({ type: CLOSE_MAKE_OFFER });
                    closeModal();
                  },
                });
              } else if (
                !Cookies.get("TokenId") ||
                _.isUndefined(TOKEN_COOKIE)
              ) {
                dispatch({ type: OPEN_QR_DRAWER });
              }
            }}
            type="primary"
            icon={sellable()}
            requireApprove
          />
        </div>
      );
    } else {
      return (
        <div>
          <StanButton
            title={t("TITLE.MAKE_OFFER", { ns: "nftDetail" })}
            handleOnClick={() => {
              if (Cookies.get("TokenId")) {
                dispatch(getListOffer(nft.id, 500, 1));
                dispatch({
                  type: OPEN_MAKE_OFFER,
                  payload: { nft: nft },
                  onClose: () => {
                    dispatch({ type: CLOSE_MAKE_OFFER });
                    closeModal();
                  },
                });
              } else if (
                !Cookies.get("TokenId") ||
                _.isUndefined(TOKEN_COOKIE)
              ) {
                dispatch({ type: OPEN_QR_DRAWER });
              }
            }}
            type="transfer"
            icon={makeOfferSvg()}
            requireApprove
          />
        </div>
      );
    }
  }

  function renderTabPaneTitle(title) {
    let icon;
    if (title === NFT_DETAIL_TABPANE.ABOUT) {
      icon = aboutTabPaneSvg(activeTabPane === title ? true : false);
    } else if (title === NFT_DETAIL_TABPANE.OFFERS) {
      icon = offerTabPaneSvg(activeTabPane === title ? true : false);
    } else if (title === NFT_DETAIL_TABPANE.HISTORY) {
      icon = historyTabPaneSvg(activeTabPane === title ? true : false);
    }
    return (
      <span style={{ color: activeTabPane === title ? "#49D19A" : "#CBCBCB" }}>
        {icon} {title}
      </span>
    );
  }

  // RENDER FUNCs //

  return (
    <>
      <div>
        <div className="nftDetailBody">
          <div className="circleBackground">
            <img alt="" src="/img/Circle.png" />
          </div>
          <div className="triangleImg">
            <img alt="" src="/img/triangle.png" />
          </div>
          <div className="previewSide">{renderImage(nft)}</div>
          <div className="infoSide">
            <div className="infoSideNameCollection">
              <Tooltip title={nft.name}>
                <h1>{subStringText(nft.name, 30)}</h1>
              </Tooltip>
              <div className="mt-4 mb-4 mintedOn">
                <div className="d-flex align-items-center mr-5">
                  <span
                    style={{ color: "#939393", fontSize: "20px" }}
                    className="mr-2"
                  >
                    {t("TEXT.MINTED_ON", { ns: "nftDetail" })}
                  </span>
                  <img alt="" src="/svg/stan_Logo.svg" />
                </div>
                <div className="d-flex align-items-center">
                  <div className="viewsCount mr-3">
                    <img alt="" src="/svg/viewsCount.svg" />
                    <span style={{ color: "#939393", fontSize: "12px" }}>
                      {" "}
                      {formatViewLikeCount(nft.viewCount)}{" "}
                      {t("TEXT.VIEWS", { ns: "nftDetail" })}
                    </span>
                  </div>
                  <div className="likesCount mr-3">
                    {nft.isLiked ? (
                      <img
                        id="heart"
                        onClick={() => dispatch(unlikeNFT(nft.id))}
                        style={{ cursor: "pointer" }}
                        alt=""
                        src="/svg/likedNft.svg"
                      />
                    ) : (
                      <img
                        onClick={() => dispatch(likeNFT(nft.id))}
                        style={{ cursor: "pointer" }}
                        alt=""
                        src="/svg/likesCount.svg"
                      />
                    )}
                    <span style={{ color: "#939393", fontSize: "12px" }}>
                      {" "}
                      {formatViewLikeCount(nft.likeCount)}{" "}
                      {t("TEXT.LIKES", { ns: "nftDetail" })}
                    </span>
                  </div>
                  <div className="sharesCount">
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => handleShareNFT()}
                      alt=""
                      src="/svg/sharesCount.svg"
                    />
                    <span style={{ color: "#939393", fontSize: "12px" }}>
                      {t("TEXT.SHARES", { ns: "nftDetail" })}
                    </span>
                  </div>
                </div>
              </div>
              <PriceOfNft nft={nft} />
              <div className="d-flex">
                <div style={{ width: "25%" }}>
                  <InfoCard
                    title={t("TITLE.OWNED_BY", { ns: "nftDetail" })}
                    name={
                      Cookies.get("UserAddress") &&
                      userProfileLogged?.id === user.id
                        ? "You"
                        : user.name
                    }
                    isFollow={user.isFollowing}
                    image={user.avatar}
                    nameColor="#49D19A"
                    redirect={() =>
                      history.push(
                        generatePath(EXPLORE_PATH.PROFILE, {
                          walletAddress: nft.ownerAddress,
                        })
                      )
                    }
                  />
                </div>
                <div style={{ width: "40%" }}>
                  <InfoCard
                    title={t("TITLE.COLLECTION", { ns: "nftDetail" })}
                    name={nft.collectionName}
                    image={nft.collectionImageLogo}
                    redirect={() =>
                      handleViewDetailCollection(nft.collectionId)
                    }
                  />
                </div>
              </div>
              {/* BUTTONs RENDER */}
              {Cookies.get("UserAddress") ? (
                <NftDetailButton />
              ) : (
                <div className="mt-5">
                  <StanButton
                    type="primary"
                    title={t("TITLE.CONNECT_WALLET")}
                    handleOnClick={() =>
                      dispatch({
                        type: OPEN_CONNECT_WALLET,
                        onClose: () => dispatch({ type: CLOSE_CONNECT_WALLET }),
                      })
                    }
                  />
                </div>
              )}
              {/* BUTTONs RENDER */}

              {/* TABPANE */}
              <div>
                <Tabs
                  defaultActiveKey={NFT_DETAIL_TABPANE.ABOUT}
                  onChange={onChangeTabPane}
                  size="large"
                  className="tabPane"
                >
                  <TabPane
                    tab={renderTabPaneTitle(
                      t("TITLE.ABOUT", { ns: "nftDetail" })
                    )}
                    key={NFT_DETAIL_TABPANE.ABOUT}
                    className="tabPaneAbout"
                  >
                    <AboutTab />
                  </TabPane>
                  <TabPane
                    tab={renderTabPaneTitle(
                      t("TITLE.OFFERS", { ns: "nftDetail" })
                    )}
                    key={NFT_DETAIL_TABPANE.OFFERS}
                    // disabled={!Cookies.get("TokenId")}
                    className="tabPaneOffer"
                  >
                    <OfferTab activeTabPane={activeTabPane} />
                  </TabPane>
                  <TabPane
                    tab={renderTabPaneTitle(
                      t("TITLE.HISTORY", { ns: "nftDetail" })
                    )}
                    key={NFT_DETAIL_TABPANE.HISTORY}
                    // disabled={!Cookies.get("TokenId")}
                  >
                    <HistoryTab activeTabPane={activeTabPane} />
                  </TabPane>
                </Tabs>
              </div>
              {/* TABPANE */}
            </div>
          </div>
        </div>
        <div
          className="moreFrom"
          style={listNFT < 1 ? { display: "none" } : {}}
        >
          <span style={{ fontWeight: 700, fontSize: 40 }}>
            {t("MORE_FROM_THIS_COLLECTION", { ns: "nftDetail" })}
          </span>
          <div className="moreFromNFT">
            {listNFT.map((item) => {
              if (item.id !== id) {
                // Just display others NFT
                return (
                  <StanNFTCard
                    key={item.id}
                    nft={item}
                    searchNFT={() => console.log("")}
                  />
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
    </>
  );
}
