import { Image, Skeleton, Tooltip } from "antd";
import { useState } from "react";
import { subStringText } from "../../util/settings/utils";
import "./InfoCard.css";

export default function InfoCard(props) {
  const { title, name, image, redirect, nameColor, isFollow } = props;
  const [loadedImage, setLoadedImage] = useState(false);
  return (
    <>
      <Tooltip title={name}>
        <div className="d-flex flex-column InfoCardContainer">
          <div className="mb-2">
            <span style={{ fontWeight: "bold", color: "#4D4D4D" }}>
              {title}
            </span>
          </div>
          <div
            className="InfoCardItem"
            onClick={() => redirect()}
          >
            <div className="InfoCardItem-image">
              <Image
                style={
                  loadedImage
                    ? {
                        borderRadius: "16px",
                        objectFit: "cover",
                      }
                    : { display: "none" }
                }
                placeholder={
                  <Skeleton.Image style={{ width: "50px", height: "50px" }} />
                }
                preview={false}
                onLoad={(e) => setLoadedImage(true)}
                fallback="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcs7QeC2_kP0lJEj7Q25mpHyeNkLt_oQ43uP2_jLnhozFShnw-Mba_ataiwQd_W1aByyU&usqp=CAU"
                src={image}
                width={50}
                height={50}
              />
              {isFollow ? (
                <img
                  className="InfoCardItem-follow"
                  width={24}
                  alt=""
                  src="/svg/followedIcon.svg"
                />
              ) : null}
            </div>
            <div
              style={{ width: "100%" }}
              className="d-flex justify-content-center mt-3"
            >
              <div className="ellipsisText">
                <span
                  style={
                    loadedImage
                      ? { fontWeight: "bold", color: nameColor }
                      : { display: "none" }
                  }
                >
                  {name}
                </span>
              </div>
            </div>
            {!loadedImage ? (
              <>
                <div>
                  <Skeleton.Input />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </Tooltip>
    </>
  );
}
