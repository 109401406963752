import { Col, Pagination, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { NFT_ORDER } from "../../common/commonParams";
import StanNFTCard from "../../component/StanNFTCard/StanNFTCard";
import { searchNFT } from "../../redux/action/NftAction";
import { REQUESTING } from "../../redux/types/UserType";
import "./Hashtag.css";

export default function Hashtag(props) {
  const { t } = useTranslation(["common"]);
  const { hashtag } = useParams();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();

  const { listNFT, totalNFT } = useSelector((state) => state.NFTReducer);
  const { nftStatus } = useSelector((state) => state.AutionReducer);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(
      searchNFT(
        { hashtag: hashtag, orderBy: NFT_ORDER.RECENTLY_CREATED },
        pageSize,
        page
      )
    );
  }, [page, pageSize]);

  return (
    <div style={{ minHeight: "100vh" }} className="ExploreHashtag">
      <div className="d-flex align-items-center">
        <h4 className="mr-3">#{hashtag}</h4>
        <p>
          {totalNFT} {t("TEXT.RESULTS")}
        </p>
      </div>
      <Spin spinning={nftStatus === REQUESTING}>
        <div className="Hashtag-listNFT">
          <Row>
            {listNFT?.map((nft) => {
              return (
                <Col key={nft.id} xxl={6} xl={6} lg={6} md={8} sm={12} xs={12}>
                  <StanNFTCard nft={nft} />
                </Col>
              );
            })}
          </Row>
        </div>
      </Spin>
      <div>
        <Pagination
          responsive={true}
          showSizeChanger={true}
          current={page}
          pageSize={pageSize}
          onChange={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }}
          total={totalNFT}
        />
      </div>
    </div>
  );
}
