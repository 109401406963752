import { Empty, Image, Modal, Pagination } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getListMedia } from "../../redux/action/UserAction";
import { CLOSE_MODAL_SELECT_IMAGES } from "../../redux/types/ModalType";
import { SELECT_EXIST_MEDIA } from "../../redux/types/UserType";
import StanButton from "../StanButton/StanButton";
import "./SelectImagesModal.css";

export default function SelectImagesModal() {
  //___________ INITIAL STATE ___________//
  const { t } = useTranslation(["nft"]);
  const { openModalSelectImages } = useSelector((state) => state.ModalReducer);
  const { listMedia, totalMedia } = useSelector((state) => state.UserReducer);
  const [selectedImage, setSelectedImage] = useState({});
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedPageSize, setSelectedPageSize] = useState(20);
  const dispatch = useDispatch();
  //_____________________________________//

  //___________ HANDLE FUNCs ___________//
  const handleOk = () => {
    dispatch({
      type: CLOSE_MODAL_SELECT_IMAGES,
    });
  };

  const handleCancel = () => {
    dispatch({
      type: CLOSE_MODAL_SELECT_IMAGES,
    });
    dispatch({
      type: SELECT_EXIST_MEDIA,
      existMedia: {},
    });
    setSelectedImage({});
  };

  function selectImage() {
    dispatch({
      type: SELECT_EXIST_MEDIA,
      existMedia: selectedImage,
    });
    dispatch({
      type: CLOSE_MODAL_SELECT_IMAGES,
    });
  }
  //_____________________________________//

  useEffect(() => {
    dispatch(getListMedia(selectedPageSize, selectedPage, "DESC"));
  }, [selectedPage, selectedPageSize]);

  return (
    <>
      <div className="selectImagesModal">
        <Modal
          title={
            <h3
              style={{
                fontFamily: "Space Grotesk",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "20px",
                lineHeight: "24px",
                color: "#000000",
              }}
              className="text-center m-0"
            >
              {t("TITLE.SELECT_ITEM")}
            </h3>
          }
          footer={null}
          className="submitted"
          visible={openModalSelectImages}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1000}
        >
          {listMedia?.length > 0 ? (
            <div className="imagesCatologue">
              {listMedia.map((item, index) => {
                return (
                  <>
                    {item.fileType === "video" ? (
                      <div className="itemCatologue" key={index}>
                        <video
                          src={item.originUrl}
                          controls={true}
                          autoPlay={false}
                          muted={true}
                          width={"268px"}
                          height={"208px"}
                          style={
                            _.isEqual(item, selectedImage)
                              ? { border: "2px solid #49D19A" }
                              : { borderRadius: "16px" }
                          }
                          onClick={() => setSelectedImage(item)}
                        />
                        {_.isEqual(item, selectedImage) ? (
                          <div
                            style={{
                              position: "absolute",
                              top: "12px",
                              right: "12px",
                            }}
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17 0H7C3.13401 0 0 3.13401 0 7V17C0 20.866 3.13401 24 7 24H17C20.866 24 24 20.866 24 17V7C24 3.13401 20.866 0 17 0Z"
                                fill="#49D19A"
                              />
                              <path
                                d="M7.70007 11.639L10.714 14.6542L16.3002 9.06677"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        ) : null}
                      </div>
                    ) : item.fileType === "audio" ? (
                      <div className="audioWrapper">
                        <img
                          src={item.thumb}
                          style={
                            _.isEqual(item, selectedImage)
                              ? { border: "2px solid #49D19A" }
                              : { borderRadius: "16px", marginBottom: "16px" }
                          }
                          onClick={() => setSelectedImage(item)}
                          className="audioThumb"
                          alt=""
                        />
                        <audio
                          controls
                          loop
                          className="audioController"
                          src={item?.originUrl}
                        ></audio>
                        {_.isEqual(item, selectedImage) ? (
                          <div
                            style={{
                              position: "absolute",
                              top: "12px",
                              right: "12px",
                            }}
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17 0H7C3.13401 0 0 3.13401 0 7V17C0 20.866 3.13401 24 7 24H17C20.866 24 24 20.866 24 17V7C24 3.13401 20.866 0 17 0Z"
                                fill="#49D19A"
                              />
                              <path
                                d="M7.70007 11.639L10.714 14.6542L16.3002 9.06677"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div
                        className="itemCatologue"
                        key={index}
                        style={{ minWidth: 200 }}
                      >
                        <Image
                          style={
                            _.isEqual(item, selectedImage)
                              ? { border: "2px solid #49D19A" }
                              : { borderRadius: "16px", marginBottom: "16px" }
                          }
                          width={"100%"}
                          height={"100%"}
                          preview={false}
                          src={item.originUrl}
                          onClick={() => setSelectedImage(item)}
                        />
                        {_.isEqual(item, selectedImage) ? (
                          <div
                            style={{
                              position: "absolute",
                              top: "12px",
                              right: "12px",
                            }}
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17 0H7C3.13401 0 0 3.13401 0 7V17C0 20.866 3.13401 24 7 24H17C20.866 24 24 20.866 24 17V7C24 3.13401 20.866 0 17 0Z"
                                fill="#49D19A"
                              />
                              <path
                                d="M7.70007 11.639L10.714 14.6542L16.3002 9.06677"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        ) : null}
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          ) : (
            <Empty />
          )}

          <div style={{ padding: "20px 0px" }}>
            <Pagination
              responsive={true}
              current={selectedPage}
              disabled={listMedia?.length === 0}
              pageSize={selectedPageSize}
              onChange={(page, pageSize) => {
                setSelectedPage(page);
                setSelectedPageSize(pageSize);
              }}
              total={totalMedia}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <StanButton
              disabled={listMedia?.length === 0}
              handleOnClick={() => selectImage()}
              type="primary"
              title={t("TITLE.SELECT_ITEM")}
            />
          </div>
        </Modal>
      </div>
    </>
  );
}
