import { Image, Spin } from "antd";
import { useState } from "react";
import { generatePath } from "react-router-dom";
import { history } from "../../App";
import { subStringText } from "../../util/settings/utils";
import StanTypo from "../StanTypo/StanTypo";
import "./StanUserItem.css";

export const StanUserItem = (props) => {
  const { artist } = props;
  const [loadedImage, setLoadedImage] = useState(false);
  return (
    <>
      <div
        className="artistItem d-flex flex-column justify-content-center align-items-center"
        onClick={() =>
          history.push(
            generatePath("/profile/:walletAddress", {
              walletAddress: artist.walletAddress,
            })
          )
        }
      >
        <div className="artistItem-imageContainer d-flex justify-content-center align-items-center">
          <Image
            className="artistItem-image"
            src={artist.avatar}
            preview={false}
            onLoad={(e) => setLoadedImage(true)}
            fallback="https://www.salonlfc.com/wp-content/uploads/2018/01/image-not-found-scaled-1150x647.png"
          />
          {!loadedImage && (
            <div className="loadingOverlayNFT">
              <Spin
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            </div>
          )}
          {artist.is_following === 1 ? (
            <div className="followingStatus">
              <img alt="" src="/svg/followedIcon.svg" />
            </div>
          ) : null}
        </div>
        <div className="text-center mt-3">
          <StanTypo
            color="#0D0D0D"
            fontSize={16}
            fontWeight={700}
            content={subStringText(artist.name, 15)}
          />
        </div>
      </div>
    </>
  );
};
