import { ethers } from "ethers";
import Cookies from "js-cookie";
import Web3 from "web3";

import { openNotificationWithIcon } from "../../App";
import {
  ABI_AUCTION_MAINNET,
  ABI_AUCTION_MAINNET_STAGING,
  ABI_AUCTION_TESTNET,
  ABI_STAN_CONFIG_MAINNET,
  ABI_STAN_CONFIG_MAINNET_STAGING,
  ABI_STAN_CONFIG_TESTNET,
  ABI_STAN_FUND_MAINNET,
  ABI_STAN_FUND_MAINNET_STAGING,
  ABI_STAN_FUND_TESTNET,
} from "../../common/ABIs";
import { PRIORITY_FEE } from "../../common/commonParams";
import { NFT_ERROR } from "../../common/commonStatus";
import { autionService } from "../../services/AutionService";
import { nftService } from "../../services/NftService";
import {
  CHAIN_ID,
  CONTRACT_ADDRESS_AUTION,
  CONTRACT_ADDRESS_CONFIG,
  CONTRACT_ADDRESS_STAN_FUND,
  stagingEnvironment,
} from "../../util/settings/config";
import {
  convertPriceToWei,
  convertStringToBytes,
  convertTimeStampToUnix,
} from "../../util/settings/utils";
import {
  GET_BALANCE_OF_BUYER,
  GET_BALANCE_OF_FAN,
  GET_LIST_OFFER,
  GET_STAN_FIXED,
  GET_STAN_ROYALTY_FEE,
  GET_STAN_SERVICE_FEE,
} from "../types/AutionType";
import { OPEN_CONFIRM_MODAL } from "../types/ModalType";
import { GET_COIN_PRICE } from "../types/NFTType";
import {
  COMPLETE_REQUEST,
  FAILED_REQUEST,
  INSUFFICENT_FUND,
  PENDING_REQUEST,
  PROCESSING,
  REJECT_REQUEST,
  REQUESTING,
} from "../types/UserType";
import { isApprovedForAll } from "./Web3Action";
import i18next from "i18next";

const ethereum = window?.ethereum;

const Web3Client = new Web3(ethereum);

const contract = new Web3Client.eth.Contract(
  CHAIN_ID === "137"
    ? stagingEnvironment
      ? ABI_AUCTION_MAINNET_STAGING
      : ABI_AUCTION_MAINNET
    : ABI_AUCTION_TESTNET,
  CONTRACT_ADDRESS_AUTION
);

const stanFundContract = new Web3Client.eth.Contract(
  CHAIN_ID === "137"
    ? stagingEnvironment
      ? ABI_STAN_FUND_MAINNET_STAGING
      : ABI_STAN_FUND_MAINNET
    : ABI_STAN_FUND_TESTNET,
  CONTRACT_ADDRESS_STAN_FUND
);

const stanConfig = new Web3Client.eth.Contract(
  CHAIN_ID === "137"
    ? stagingEnvironment
      ? ABI_STAN_CONFIG_MAINNET_STAGING
      : ABI_STAN_CONFIG_MAINNET
    : ABI_STAN_CONFIG_TESTNET,
  CONTRACT_ADDRESS_CONFIG
);

// SUPPORT ACTIONS
export const getUserMatics = (data) => {
  return async (dispatch) => {
    try {
      // const result = await Web3Client.eth.getBalance(
      //   Cookies.get("UserAddress")
      // );
      // console.log("result => ", result / Math.pow(10, 18));
      let balanceMatics;
      await stanFundContract.methods
        .get(Cookies.get("UserAddress"))
        .call({ from: Cookies.get("UserAddress") })
        .then(async (result) => {
          balanceMatics = result / Math.pow(10, 18);
          console.log("balance", result);
        });
    } catch (error) {
      console.log(error);
    }
  };
};

export const checkBalanceOfUser = async () => {
  let balanceFund;
  await stanFundContract.methods
    .stanFund(Cookies.get("UserAddress"))
    .call({ from: Cookies.get("UserAddress") })
    .then(async (result) => {
      balanceFund = result / Math.pow(10, 18);
      console.log("balance", result / Math.pow(10, 18));
    });
  return balanceFund;
};

export const getBalanceOfFan = (data) => {
  return async (dispatch) => {
    try {
      await stanFundContract.methods
        .stanFund(Cookies.get("UserAddress"))
        .call({ from: Cookies.get("UserAddress") })
        .then((result) => {
          // convert wei to ETH
          console.log("result =>", result / Math.pow(10, 18));
          let Eth = result / Math.pow(10, 18);
          dispatch({
            type: GET_BALANCE_OF_FAN,
            data: Eth,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
};

export const getBalanceOfBuyer = (data) => {
  return async (dispatch) => {
    try {
      await stanFundContract.methods
        .stanFund(data)
        .call({ from: data })
        .then((result) => {
          // convert wei to ETH
          console.log("result =>", result / Math.pow(10, 18));
          let Eth = result / Math.pow(10, 18);
          dispatch({
            type: GET_BALANCE_OF_BUYER,
            data: Eth,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
};

export const getStanBalance = (data) => {
  return async (dispatch) => {
    try {
      await stanFundContract.methods
        .getInforStanFund()
        .call()
        .then((result) => {
          console.log("RESULT BALANCE =>", result);
        });
    } catch (error) {
      console.log(error);
    }
  };
};

export const getCoinPrice = (data) => {
  return async (dispatch) => {
    try {
      const result = await nftService.getCoinPrice(data);
      if (result.data.status === "success") {
        dispatch({
          type: GET_COIN_PRICE,
          data: result.data.data.price,
        });
      }
    } catch (error) {
      console.log(error);
      openNotificationWithIcon(
        "error",
        "Error",
        "Something is wrong! Please try again."
      );
    }
  };
};

export const getStanRoyalty = () => {
  return async (dispatch) => {
    try {
      await stanConfig.methods
        .feeStanSystem()
        .call()
        .then((data) => {
          dispatch({ type: GET_STAN_ROYALTY_FEE, stanRoyalty: data });
        });
    } catch (error) {
      console.log(error);
    }
  };
};

export const getStanFixed = () => {
  return async (dispatch) => {
    try {
      await stanConfig.methods
        .feeStanFixed()
        .call()
        .then((data) => {
          dispatch({ type: GET_STAN_FIXED, stanFixed: data });
        });
    } catch (error) {
      console.log(error);
    }
  };
};

export const getStanServiceFee = () => {
  return async (dispatch) => {
    try {
      await stanConfig.methods
        .feeStanService()
        .call()
        .then((data) => {
          dispatch({ type: GET_STAN_SERVICE_FEE, stanServiceFee: data });
        });
    } catch (error) {
      console.log(error);
    }
  };
};
// SUPPORT ACTIONS

// LISTING/ CANCEL LISTING
export const postListingFixPrice = (data, tokenId) => {
  return async (dispatch) => {
    dispatch({
      type: REQUESTING,
    });
    if (await isApprovedForAll()) {
      try {
        dispatch({
          type: "InputPrice",
          data: data.price,
        });
        const result = await nftService.listNFT(data);
        console.log("result listing =>", result);
        if (result.data.status === "success") {
          await contract.methods
            .listingNFTFixedPrice(
              convertStringToBytes(result.data.data.id), // _requestId (bytes)
              convertStringToBytes(result.data.data.id), // _listingId (bytes)
              // convertPriceToWei(data.price.toString()), // _amount
              ethers.utils.parseEther(data.price.toString()), // _amount (uint256)
              tokenId, // tokenId (uint256)
              convertTimeStampToUnix(999999).toString(), // _expirationTime (uint256)
              "0x0000000000000000000000000000000000000000", // _maker (address)
              convertStringToBytes(data.nftId), // nftId (bytes)
              1 // currency (uint8) (1: WEB ; 0: APP)
            )
            .send(
              CHAIN_ID === "137"
                ? {
                    from: Cookies.get("UserAddress"),
                    maxPriorityFeePerGas: ethers.BigNumber.from(PRIORITY_FEE),
                  }
                : { from: Cookies.get("UserAddress") }
            )
            .on("transactionHash", async function (hash) {
              dispatch({
                type: PROCESSING,
              });
              openNotificationWithIcon(
                "info",
                "NFT is pending",
                "Listing NFT is in progress"
              );
            })
            .on("receipt", async function (receipt) {
              dispatch({
                type: COMPLETE_REQUEST,
                txtHash: receipt.transactionHash,
              });
              openNotificationWithIcon(
                "success",
                "Success",
                "Listing NFT successfully!"
              );
            });
        } else {
          if (result.data.errorCode === "NFT_IN_PROCESS") {
            openNotificationWithIcon(
              "error",
              "Error",
              NFT_ERROR.NFT_IN_PROCESS
            );
          } else {
            openNotificationWithIcon("error", "Error", result.data.message);
          }
          dispatch({ type: FAILED_REQUEST });
        }
      } catch (error) {
        console.log(error);
        if (error.code === 4001) {
          openNotificationWithIcon(
            "error",
            "Error",
            "You declined the action in your wallet"
          );
          dispatch({ type: REJECT_REQUEST });
        } else {
          dispatch({ type: FAILED_REQUEST });
          openNotificationWithIcon(
            "error",
            "Error",
            "Something is wrong! Please try again."
          );
        }
      }
    } else {
      dispatch({ type: OPEN_CONFIRM_MODAL });
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

export const postListingHighestBid = (data, tokenId) => {
  return async (dispatch) => {
    dispatch({
      type: REQUESTING,
    });
    if (await isApprovedForAll()) {
      try {
        dispatch({
          type: "InputPrice",
          data: data.price,
        });
        // if ((await checkBalanceOfUser()) < STAN_FEE) {
        //   openNotificationWithIcon("error", "Error", "Insufficent Fund");
        //   dispatch({ type: INSUFFICENT_FUND });
        //   return;
        // }
        const result = await nftService.listNFT(data);
        if (result.data.status === "success") {
          let transactionId = result.data.data.requestId;
          await contract.methods
            .listingNFTAuction(
              convertStringToBytes(result.data.data.id), // _requestId (bytes)
              convertStringToBytes(result.data.data.id), // _auctionId (bytes)
              convertPriceToWei(data.price.toString()), // _amount (uint256)
              tokenId, // _tokenId (uint256)
              convertTimeStampToUnix(data.expirationPeriod).toString(), // _expirationTime (uint256)
              "0x0000000000000000000000000000000000000000", // _maker (address)
              convertStringToBytes(data.nftId), // _nftId (bytes)
              1 // currency (uint8) (1: WEB ; 0: APP)
            )
            .send(
              CHAIN_ID === "137"
                ? {
                    from: Cookies.get("UserAddress"),
                    maxPriorityFeePerGas: ethers.BigNumber.from(PRIORITY_FEE),
                  }
                : { from: Cookies.get("UserAddress") }
            )
            .on("transactionHash", function (hash) {
              console.log("TRANSACTION HASH =>", hash);
              openNotificationWithIcon(
                "info",
                "NFT is pending ",
                "Listing NFT is in progress!"
              );
              dispatch({
                type: PROCESSING,
              });
            })
            .on("receipt", async function (receipt) {
              console.log("receipt => ", receipt);
              dispatch({
                type: COMPLETE_REQUEST,
                txtHash: receipt.transactionHash,
              });
              openNotificationWithIcon(
                "success",
                "Success",
                "Listing NFT successfully!"
              );
            });
        } else {
          dispatch({ type: FAILED_REQUEST });
          openNotificationWithIcon("error", "Error", result.data.message);
        }
      } catch (error) {
        console.log(error);
        if (error.code === 4001) {
          openNotificationWithIcon(
            "error",
            "Error",
            "You declined the action in your wallet"
          );
          dispatch({ type: REJECT_REQUEST });
        } else {
          dispatch({ type: FAILED_REQUEST });
          openNotificationWithIcon(
            "error",
            "Error",
            "Something is wrong! Please try again."
          );
        }
      }
    } else {
      dispatch({ type: OPEN_CONFIRM_MODAL });
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

export const cancelListingFixedPrice = (transactionId) => {
  return async (dispatch) => {
    dispatch({
      type: REQUESTING,
    });
    if (await isApprovedForAll()) {
      try {
        const result = await nftService.cancelListing(transactionId);
        if (result.data.status === "success") {
          await contract.methods
            .cancelListingFixedPrice(
              convertStringToBytes(result.data.data.requestId), // _requestId (bytes)
              convertStringToBytes(result.data.data.requestId), // _listingId (bytes)
              1 // _currency (uint8) (0: APP ; 1: WEB)
            )
            .send(
              CHAIN_ID === "137"
                ? {
                    from: Cookies.get("UserAddress"),
                    maxPriorityFeePerGas: ethers.BigNumber.from(PRIORITY_FEE),
                  }
                : { from: Cookies.get("UserAddress") }
            )
            .on("transactionHash", function (hash) {
              console.log("Hash TXT => ", hash);
              dispatch({
                type: PROCESSING,
              });
              openNotificationWithIcon(
                "info",
                "NFT is pending ",
                "Cancel Listing NFT is in process "
              );
            })
            .on("receipt", function (receipt) {
              console.log(receipt, " receipt");
              openNotificationWithIcon(
                "success",
                "Success",
                "Your listings have been cancelled successfully!"
              );
              dispatch({
                type: COMPLETE_REQUEST,
                txtHash: receipt.transactionHash,
              });
            });
        } else {
          openNotificationWithIcon("error", "Error", result.data.message);
          dispatch({ type: FAILED_REQUEST });
        }
      } catch (error) {
        console.log("error => ", error);
        if (error.code === 4001) {
          openNotificationWithIcon(
            "error",
            "Error",
            "You declined the action in your wallet"
          );
          dispatch({ type: REJECT_REQUEST });
        } else {
          openNotificationWithIcon(
            "error",
            "Error",
            "Error. Please try again!"
          );
          dispatch({
            type: FAILED_REQUEST,
          });
        }
      }
    } else {
      dispatch({ type: OPEN_CONFIRM_MODAL });
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

export const cancelListingAuction = (transactionId) => {
  return async (dispatch) => {
    dispatch({
      type: REQUESTING,
    });
    if (await isApprovedForAll()) {
      try {
        // if ((await checkBalanceOfUser()) < STAN_FEE) {
        //   openNotificationWithIcon("error", "Error", "Insufficent Fund");
        //   dispatch({ type: INSUFFICENT_FUND });
        //   return;
        // }
        const result = await nftService.cancelListing(transactionId);
        if (result.data.status === "success") {
          await contract.methods
            .cancelListingAuction(
              convertStringToBytes(result.data.data.requestId), // _requestId (bytes)
              convertStringToBytes(result.data.data.requestId), // _listingId (bytes)
              1 // _currency (uint8) (0: APP ; 1: WEB)
            )
            .send(
              CHAIN_ID === "137"
                ? {
                    from: Cookies.get("UserAddress"),
                    maxPriorityFeePerGas: ethers.BigNumber.from(PRIORITY_FEE),
                  }
                : { from: Cookies.get("UserAddress") }
            )
            .on("transactionHash", function (hash) {
              console.log("Hash TXT => ", hash);
              dispatch({
                type: PROCESSING,
              });
              openNotificationWithIcon(
                "info",
                "NFT is pending ",
                "Cancel Listing NFT is in progress!"
              );
            })
            .on("receipt", function (receipt) {
              console.log(receipt, " receipt");
              dispatch({
                type: COMPLETE_REQUEST,
                txtHash: receipt.transactionHash,
              });
              openNotificationWithIcon(
                "success",
                "Success",
                "Your listings have been cancelled successfully!"
              );
            });
        } else {
          openNotificationWithIcon("error", "Error", result.data.message);
          dispatch({
            type: FAILED_REQUEST,
          });
        }
      } catch (error) {
        console.log("error => ", error);
        if (error.code === 4001) {
          openNotificationWithIcon(
            "error",
            "Error",
            "You declined the action in your wallet"
          );
          dispatch({ type: REJECT_REQUEST });
        } else {
          dispatch({ type: FAILED_REQUEST });
          openNotificationWithIcon(
            "error",
            "Error",
            "Error. Please try again!"
          );
        }
      }
    } else {
      dispatch({ type: OPEN_CONFIRM_MODAL });
      dispatch({ type: PENDING_REQUEST });
    }
  };
};
// LISTING/ CANCEL LISTING

// MAKE OFFER - PLACE BID
export const makeOffer = (data, tokenId) => {
  return async (dispatch) => {
    dispatch({
      type: REQUESTING,
    });
    if (await isApprovedForAll()) {
      try {
        dispatch({
          type: "InputPrice",
          data: data.price,
        });
        const result = await autionService.makeOffer(data);
        if (result.data.status === "success") {
          await contract.methods
            .makeOfferFixedPrice(
              convertStringToBytes(result.data.data.requestId), // _requestId (bytes)
              convertStringToBytes(result.data.data.offer.id), // _subOfferId (bytes)
              convertStringToBytes(data.id), // _nftId (bytes)
              tokenId, // _tokenId (uint256)
              convertTimeStampToUnix(data.expirationPeriod).toString(), // _expirationTime (uint256)
              convertPriceToWei(data.price.toString()), // _amount (uint256)
              "0x0000000000000000000000000000000000000000", // _maker (address)
              1 // _currency (uint8) (0: APP ; 1: WEB)
            )
            .send(
              CHAIN_ID === "137"
                ? {
                    from: Cookies.get("UserAddress"),
                    maxPriorityFeePerGas: ethers.BigNumber.from(PRIORITY_FEE),
                  }
                : { from: Cookies.get("UserAddress") }
            )
            .on("transactionHash", function (hash) {
              dispatch({
                type: PROCESSING,
              });
              console.log("TRANSACTION HASH =>", hash);
              openNotificationWithIcon(
                "info",
                "NFT is pending ",
                "Make offer NFT is in progress. "
              );
            })
            .on("receipt", async function (receipt) {
              openNotificationWithIcon(
                "success",
                "Success",
                "Make offer successfully! "
              );
              dispatch({
                type: COMPLETE_REQUEST,
                txtHash: receipt.transactionHash,
              });
            });
        } else {
          dispatch({
            type: FAILED_REQUEST,
          });
          if (result.data.errorCode === "NFT_IN_PROCESS") {
            openNotificationWithIcon(
              "error",
              "Error",
              NFT_ERROR.NFT_IN_PROCESS
            );
          } else {
            openNotificationWithIcon("error", "Error", result.data.message);
          }
        }
      } catch (error) {
        console.log(error);
        if (error.code === 4001) {
          openNotificationWithIcon(
            "error",
            "Error",
            "You declined the action in your wallet"
          );
          dispatch({ type: REJECT_REQUEST });
        } else {
          dispatch({ type: FAILED_REQUEST });
          openNotificationWithIcon(
            "error",
            "Error",
            "Something is wrong! Please try again."
          );
        }
      }
    } else {
      dispatch({ type: OPEN_CONFIRM_MODAL });
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

export const placeBid = (data, tokenId, expirationTimeStamp) => {
  return async (dispatch) => {
    dispatch({
      type: REQUESTING,
    });
    if (await isApprovedForAll()) {
      try {
        dispatch({
          type: "InputPrice",
          data: data.price,
        });
        // if ((await checkBalanceOfUser()) < STAN_FEE) {
        //   openNotificationWithIcon("error", "Error", "Insufficent Fund");
        //   dispatch({ type: INSUFFICENT_FUND });
        //   return;
        // }
        const result = await autionService.makeOffer(data);
        if (result.data.status === "success") {
          await contract.methods
            .placeBidAuction(
              convertStringToBytes(result.data.data.requestId), // _requestId (bytes)
              convertStringToBytes(result.data.data.offer.id), // _subOfferId (bytes)
              convertStringToBytes(data.id), // _auctionId (bytes)
              // moment(expirationTimeStamp).unix(), // _expirationTime (uint256)
              convertPriceToWei(data.price.toString()), // _amount (uint256)
              "0x0000000000000000000000000000000000000000", // _maker (address)
              1 // _currency (uint8) (0: APP ; 1: WEB)
            )
            .send(
              CHAIN_ID === "137"
                ? {
                    from: Cookies.get("UserAddress"),
                    maxPriorityFeePerGas: ethers.BigNumber.from(PRIORITY_FEE),
                  }
                : { from: Cookies.get("UserAddress") }
            )
            .on("transactionHash", function (hash) {
              dispatch({
                type: PROCESSING,
              });
              console.log("TRANSACTION HASH =>", hash);
              openNotificationWithIcon(
                "info",
                "NFT is pending ",
                "Place bid NFT is in progress"
              );
            })
            .on("receipt", async function (receipt) {
              console.log("receipt => ", receipt);
              dispatch({
                type: COMPLETE_REQUEST,
                txtHash: receipt.transactionHash,
              });
              openNotificationWithIcon(
                "success",
                "Success",
                "Place bid successfully! "
              );
            });
        } else {
          dispatch({
            type: FAILED_REQUEST,
          });
          if (result.data.errorCode === "NFT_IN_PROCESS") {
            openNotificationWithIcon(
              "error",
              "Error",
              NFT_ERROR.NFT_IN_PROCESS
            );
          } else {
            openNotificationWithIcon("error", "Error", result.data.message);
          }
        }
      } catch (error) {
        console.log(error);
        if (error.code === 4001) {
          dispatch({
            type: REJECT_REQUEST,
          });
          openNotificationWithIcon(
            "error",
            "Error",
            "You declined the action in your wallet"
          );
        } else {
          dispatch({
            type: FAILED_REQUEST,
          });
          openNotificationWithIcon(
            "error",
            "Error",
            "Something is wrong! Please try again."
          );
        }
      }
    } else {
      dispatch({ type: OPEN_CONFIRM_MODAL });
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

export const reOffer = (data) => {
  return async (dispatch) => {
    dispatch({
      type: REQUESTING,
    });
    if (await isApprovedForAll()) {
      try {
        const result = await autionService.reOffer({
          transactionId: data.offerId,
          price: data.price,
        });
        if (result.data.status === "success") {
          await contract.methods
            .placeBidAuction(
              convertStringToBytes(result.data.data.requestId), // _requestId (bytes)
              convertStringToBytes(data.offerId), // _subOfferId (bytes)
              convertStringToBytes(data.auctionId), // _auctionId (bytes)
              convertPriceToWei(data.price.toString()), // _amount (uint256)
              "0x0000000000000000000000000000000000000000", // _maker (address)
              1 // _currency (uint8) (0: APP ; 1: WEB)
            )
            .send(
              CHAIN_ID === "137"
                ? {
                    from: Cookies.get("UserAddress"),
                    maxPriorityFeePerGas: ethers.BigNumber.from(PRIORITY_FEE),
                  }
                : { from: Cookies.get("UserAddress") }
            )
            .on("transactionHash", function (hash) {
              console.log("TRANSACTION HASH =>", hash);
              dispatch({
                type: PROCESSING,
              });
            })
            .on("receipt", async function (receipt) {
              console.log("receipt => ", receipt);
              dispatch({
                type: COMPLETE_REQUEST,
                txtHash: receipt.transactionHash,
              });
              openNotificationWithIcon(
                "success",
                "Success",
                "Re-offer successfully! "
              );
            });
        } else {
          dispatch({
            type: FAILED_REQUEST,
          });
          if (result.data.errorCode === "NFT_IN_PROCESS") {
            openNotificationWithIcon(
              "error",
              "Error",
              NFT_ERROR.NFT_IN_PROCESS
            );
          } else {
            openNotificationWithIcon("error", "Error", result.data.message);
          }
        }
      } catch (error) {
        console.log(error);
        if (error.code === 4001) {
          dispatch({
            type: REJECT_REQUEST,
          });
          openNotificationWithIcon(
            "error",
            "Error",
            "You declined the action in your wallet"
          );
        } else {
          dispatch({
            type: FAILED_REQUEST,
          });
          openNotificationWithIcon(
            "error",
            "Error",
            "Something is wrong! Please try again."
          );
        }
      }
    } else {
      dispatch({ type: OPEN_CONFIRM_MODAL });
      dispatch({ type: PENDING_REQUEST });
    }
  };
};
// MAKE OFFER - PLACE BID

// ACCEPT/ CANCEL OFFER
export const acceptOffer = (nft, offer) => {
  return async (dispatch) => {
    dispatch({ type: REQUESTING });
    dispatch(checkTotalSupply());
    if (await isApprovedForAll()) {
      try {
        const result = await autionService.acceptOffer(offer.id);
        console.log("RESULT =>", result);
        if (result.data.errorCode === "BUYER_NOT_ENOUGH_POINT") {
          openNotificationWithIcon(
            "error",
            "Error",
            i18next.t("MSG.MAKE_OFFER_FAILD", { ns: "nft" })
          );
          dispatch({ type: REJECT_REQUEST });
        }
        if (result && result.data.status === "success") {
          await contract.methods
            .acceptOfferPvP(
              convertStringToBytes(result.data.data.requestId), // _requestId (bytes)
              convertStringToBytes(nft.id), // _nftId (bytes)
              convertStringToBytes(offer.id), // subOfferId (bytes)
              1 // _currency (uint8) (0: APP ; 1: WEB)
            )
            .send(
              CHAIN_ID === "137"
                ? {
                    from: Cookies.get("UserAddress"),
                    maxPriorityFeePerGas: ethers.BigNumber.from(PRIORITY_FEE),
                  }
                : { from: Cookies.get("UserAddress") }
            )
            .on("transactionHash", function (hash) {
              console.log("TRANSACTION HASH =>", hash);
              dispatch({
                type: PROCESSING,
                txtHash: hash,
              });
            })
            .on("receipt", async function (receipt) {
              console.log("RECEIPT => ", receipt);
              dispatch({
                type: COMPLETE_REQUEST,
                txtHash: receipt.transactionHash,
              });
            });
        } else if (result && result.data.code === 400) {
          if (result.data.errorCode === "NFT_IN_PROCESS") {
            openNotificationWithIcon(
              "error",
              "Error",
              NFT_ERROR.NFT_IN_PROCESS
            );
          } else {
            openNotificationWithIcon("error", "Error", result.data.message);
          }
          dispatch({ type: FAILED_REQUEST });
        }
      } catch (error) {
        console.log("ERRROR =>", error);
        if (error.code === 4001) {
          openNotificationWithIcon(
            "error",
            "Error",
            "You declined the action in your wallet"
          );
          dispatch({ type: REJECT_REQUEST });
        } else {
          console.log(error);
          dispatch({ type: FAILED_REQUEST });
          openNotificationWithIcon(
            "error",
            "Error",
            "Something is wrong! Please try again."
          );
        }
      }
    } else {
      dispatch({ type: OPEN_CONFIRM_MODAL });
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

export const acceptOfferAuction = (nft, offer) => {
  return async (dispatch) => {
    dispatch({ type: REQUESTING });
    if (await isApprovedForAll()) {
      try {
        const result = await autionService.acceptOffer(offer.id);
        if (result && result.data.status === "success") {
          await contract.methods
            .acceptOfferAuction(
              convertStringToBytes(result.data.data.requestId), // _requestId (bytes)
              convertStringToBytes(offer.auctionId), // _auctionId (bytes)
              convertStringToBytes(offer.id), // _subOfferId (bytes)
              convertStringToBytes(nft.id), // _nftId (bytes)
              1 // _currency (uint8) (0: APP ; 1: WEB)
            )
            .send(
              CHAIN_ID === "137"
                ? {
                    from: Cookies.get("UserAddress"),
                    maxPriorityFeePerGas: ethers.BigNumber.from(PRIORITY_FEE),
                  }
                : { from: Cookies.get("UserAddress") }
            )
            .on("transactionHash", function (hash) {
              console.log("TRANSACTION HASH =>", hash);
              dispatch({
                type: PROCESSING,
                txtHash: hash,
              });
            })
            .on("receipt", async function (receipt) {
              console.log("RECEIPT => ", receipt);
              dispatch({
                type: COMPLETE_REQUEST,
                txtHash: receipt.transactionHash,
              });
            });
        } else if (result && result.data.code === 400) {
          if (result.data.errorCode === "NFT_IN_PROCESS") {
            openNotificationWithIcon(
              "error",
              "Error",
              NFT_ERROR.NFT_IN_PROCESS
            );
          } else {
            openNotificationWithIcon("error", "Error", result.data.message);
          }
          dispatch({ type: FAILED_REQUEST });
        }
      } catch (error) {
        console.log("ERRROR =>", error);
        if (error.code === 4001) {
          openNotificationWithIcon(
            "error",
            "Error",
            "You declined the action in your wallet"
          );
          dispatch({ type: REJECT_REQUEST });
        } else {
          console.log(error);
          dispatch({ type: FAILED_REQUEST });
          openNotificationWithIcon(
            "error",
            "Error",
            "Something is wrong! Please try again."
          );
        }
      }
    } else {
      dispatch({ type: OPEN_CONFIRM_MODAL });
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

export const cancelOffer = (nft, offer) => {
  return async (dispatch) => {
    dispatch({ type: REQUESTING });
    if (await isApprovedForAll()) {
      try {
        const result = await autionService.cancelOffer(offer.id);
        if (result && result.data.status === "success") {
          await contract.methods
            .cancelOfferPvP(
              convertStringToBytes(result.data.data.requestId), // _requestId (bytes)
              convertStringToBytes(nft.id), // _nftId (bytes)
              convertStringToBytes(offer.id), // _subOfferId (bytes)
              1 // _currency (uint8) (0: APP ; 1: WEB)
            )
            .send(
              CHAIN_ID === "137"
                ? {
                    from: Cookies.get("UserAddress"),
                    maxPriorityFeePerGas: ethers.BigNumber.from(PRIORITY_FEE),
                  }
                : { from: Cookies.get("UserAddress") }
            )
            .on("transactionHash", function (hash) {
              console.log("TRANSACTION HASH =>", hash);
              dispatch({
                type: PROCESSING,
                txtHash: hash,
              });
            })
            .on("receipt", async function (receipt) {
              console.log("RECEIPT => ", receipt);
              dispatch({
                type: COMPLETE_REQUEST,
                txtHash: receipt.transactionHash,
              });
              openNotificationWithIcon(
                "success",
                "Success",
                "Your offer canceled successfully"
              );
            });
        } else if (result && result.data.code === 400) {
          openNotificationWithIcon(
            "error",
            result.data.errorCode,
            result.data.message
          );
          dispatch({ type: FAILED_REQUEST });
        }
      } catch (error) {
        console.log("ERRROR =>", error);
        if (error.code === 4001) {
          openNotificationWithIcon(
            "error",
            "Error",
            "You declined the action in your wallet"
          );
        } else {
          console.log(error);
          dispatch({ type: FAILED_REQUEST });
          openNotificationWithIcon(
            "error",
            "Error",
            "Something is wrong! Please try again."
          );
        }
      }
    } else {
      dispatch({ type: OPEN_CONFIRM_MODAL });
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

export const cancelOfferAuction = (nft, offer) => {
  return async (dispatch) => {
    dispatch({ type: REQUESTING });
    if (await isApprovedForAll()) {
      try {
        const result = await autionService.cancelOffer(offer.id);
        console.log("RESULT => ", result);
        if (result && result.data.status === "success") {
          await contract.methods
            .cancelOfferAuction(
              convertStringToBytes(result.data.data.requestId), // _requestId (bytes)
              convertStringToBytes(offer.auctionId), // _nftId (bytes)
              convertStringToBytes(offer.id), // _subOfferId (bytes)
              1 // _currency (uint8) (0: APP ; 1: WEB)
            )
            .send(
              CHAIN_ID === "137"
                ? {
                    from: Cookies.get("UserAddress"),
                    maxPriorityFeePerGas: ethers.BigNumber.from(PRIORITY_FEE),
                  }
                : { from: Cookies.get("UserAddress") }
            )
            .on("transactionHash", function (hash) {
              console.log("TRANSACTION HASH =>", hash);
              dispatch({
                type: PROCESSING,
                txtHash: hash,
              });
            })
            .on("receipt", async function (receipt) {
              console.log("RECEIPT => ", receipt);
              dispatch({
                type: COMPLETE_REQUEST,
                txtHash: receipt.transactionHash,
              });
              openNotificationWithIcon(
                "success",
                "Success",
                "Your offer canceled successfully"
              );
            });
        } else if (result && result.data.code === 400) {
          openNotificationWithIcon(
            "error",
            result.data.errorCode,
            result.data.message
          );
          dispatch({ type: FAILED_REQUEST });
        }
      } catch (error) {
        console.log("ERRROR =>", error);
        if (error.code === 4001) {
          openNotificationWithIcon(
            "error",
            "Error",
            "You declined the action in your wallet"
          );
        } else {
          console.log(error);
          dispatch({ type: FAILED_REQUEST });
          openNotificationWithIcon(
            "error",
            "Error",
            "Something is wrong! Please try again."
          );
        }
      }
    } else {
      dispatch({ type: OPEN_CONFIRM_MODAL });
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

// ACCEPT/ CANCEL OFFER

export const getListOffer = (data, pageSize, page) => {
  return async (dispatch) => {
    dispatch({
      type: REQUESTING,
    });
    try {
      const result = await autionService.getOffer(data, pageSize, page);
      if (result.data.status === "success") {
        dispatch({
          type: GET_LIST_OFFER,
          data: result.data.data.offers,
          totalOffers: result.data.data.total,
        });
        dispatch({
          type: COMPLETE_REQUEST,
        });
      }
    } catch (error) {
      console.log(error);
      openNotificationWithIcon(
        "error",
        "Error",
        "Something is wrong! Please try again."
      );
    } finally {
      dispatch({
        type: PENDING_REQUEST,
      });
    }
  };
};

export const getListProfileOffer = (data, pageSize, page) => {
  return async (dispatch) => {
    dispatch({
      type: REQUESTING,
    });
    try {
      const result = await autionService.getProfileOffer(data, pageSize, page);
      if (result.data.status === "success") {
        dispatch({
          type: GET_LIST_OFFER,
          data: result.data.data.offers,
          totalOffers: result.data.data.total,
        });
        dispatch({
          type: COMPLETE_REQUEST,
        });
      }
    } catch (error) {
      console.log(error);
      openNotificationWithIcon(
        "error",
        "Error",
        "Something is wrong! Please try again."
      );
    } finally {
      dispatch({
        type: PENDING_REQUEST,
      });
    }
  };
};

export const transferNft = (data, nftId) => {
  return async (dispatch) => {
    dispatch({ type: REQUESTING });
    if (await isApprovedForAll()) {
      try {
        // if ((await checkBalanceOfUser()) < STAN_FEE) {
        //   openNotificationWithIcon("error", "Error", "Insufficent Fund");
        //   dispatch({ type: INSUFFICENT_FUND });
        //   return;
        // }
        const resultSuccess = await nftService.transferNft(data);
        if (resultSuccess.data.status === "success") {
          console.log("resultSuccess ", resultSuccess.data);
          await contract.methods
            .transferNFTPvP(
              convertStringToBytes(resultSuccess.data.data.requestId), // _requestId (bytes)
              data.receiverAddress, // _receiver (address)
              data.tokenId, // _tokenId (uint256)
              // convertStringToBytes(data.nftId), // _nftId (address)
              "0x0000000000000000000000000000000000000000", // _maker (address)
              1 // _currency (uint8) (0: APP ; 1: WEB)
            )
            .send(
              CHAIN_ID === "137"
                ? {
                    from: Cookies.get("UserAddress"),
                    maxPriorityFeePerGas: ethers.BigNumber.from(PRIORITY_FEE),
                  }
                : { from: Cookies.get("UserAddress") }
            )
            .on("transactionHash", async function (hash) {
              console.log("Hash TXT => ", hash);
              dispatch({
                type: PROCESSING,
                txtHash: hash,
              });
              openNotificationWithIcon(
                "info",
                "NFT is pending ",
                "Transfer NFT is in progress "
              );
            })
            .on("receipt", async function (receipt) {
              console.log(receipt, " receipt");
              dispatch({
                type: COMPLETE_REQUEST,
                txtHash: receipt.transactionHash,
              });
              openNotificationWithIcon(
                "success",
                "Success",
                "Transfer NFT success"
              );
            });
        } else {
          dispatch({
            type: FAILED_REQUEST,
          });
          if (resultSuccess.data.errorCode === "NFT_IN_PROCESS") {
            openNotificationWithIcon(
              "error",
              "Error",
              NFT_ERROR.NFT_IN_PROCESS
            );
          } else {
            openNotificationWithIcon(
              "error",
              "Error",
              resultSuccess.data.message
            );
          }
        }
      } catch (error) {
        console.log("error => ", error);
        if (error.code === 4001) {
          openNotificationWithIcon(
            "error",
            "Error",
            "You declined the action in your wallet"
          );
          dispatch({ type: REJECT_REQUEST });
        } else {
          dispatch({
            type: FAILED_REQUEST,
          });
          openNotificationWithIcon(
            "error",
            "Error",
            "Error. Please try again!"
          );
        }
      }
    } else {
      dispatch({ type: OPEN_CONFIRM_MODAL });
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

export const buyNFT = (transactionId, data, isDetailPage) => {
  return async (dispatch) => {
    dispatch({ type: REQUESTING });
    if (await isApprovedForAll()) {
      if ((await checkBalanceOfUser()) < data.price && isDetailPage) {
        openNotificationWithIcon("error", "Error", "Insufficent Fund");
        dispatch({ type: INSUFFICENT_FUND });
        return;
      }
      try {
        const result = await nftService.buyNft(transactionId);
        if (result.data.status === "success") {
          await contract.methods
            .buyFixPrice(
              convertStringToBytes(result.data.data.requestId), // _requestId (bytes)
              data.ownerAddress, // _seller (address)
              "0x0000000000000000000000000000000000000000", // _maker (address)
              convertStringToBytes(result.data.data.requestId), // _listingId (bytes)
              // data.tokenId,
              1 // _currency (uint8) (0: APP ; 1: WEB)
            )
            .send(
              CHAIN_ID === "137"
                ? {
                    from: Cookies.get("UserAddress"),
                    maxPriorityFeePerGas: ethers.BigNumber.from(PRIORITY_FEE),
                  }
                : { from: Cookies.get("UserAddress") }
            )
            .on("transactionHash", async function (hash) {
              console.log("Hash TXT => ", hash);
              openNotificationWithIcon(
                "info",
                "NFT is pending ",
                "Buy NFT is in process "
              );
              dispatch({
                type: PROCESSING,
                txtHash: hash,
              });
            })
            .on("receipt", async function (receipt) {
              console.log(receipt, " receipt");
              openNotificationWithIcon(
                "success",
                "Success",
                data.nftName + " was successfully purchased!"
              );
              dispatch({
                type: COMPLETE_REQUEST,
                txtHash: receipt.transactionHash,
              });
            });
        } else {
          dispatch({ type: FAILED_REQUEST });
          if (result.data.errorCode === "NFT_IN_PROCESS") {
            openNotificationWithIcon(
              "error",
              "Error",
              NFT_ERROR.NFT_IN_PROCESS
            );
          } else {
            openNotificationWithIcon("error", "Error", result.data.message);
          }
        }
      } catch (error) {
        console.log("error => ", error);
        if (error.code === 4001) {
          openNotificationWithIcon(
            "error",
            "Error",
            "You declined the action in your wallet"
          );
          dispatch({ type: REJECT_REQUEST });
        } else {
          dispatch({ type: FAILED_REQUEST });
          openNotificationWithIcon(
            "error",
            "Error",
            "Error. Please try again!"
          );
        }
      }
    } else {
      dispatch({ type: OPEN_CONFIRM_MODAL });
      dispatch({ type: PENDING_REQUEST });
    }
  };
};

// WITHDRAW/ DEPOSIT
export const addFund = (amount) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: REQUESTING,
      });
      //
      stanFundContract.methods
        .deposit(
          convertStringToBytes("string"),
          1 // 0: POINT ; 1: CRYPTO
        )
        .send(
          CHAIN_ID === "137"
            ? {
                from: Cookies.get("UserAddress"),
                maxPriorityFeePerGas: ethers.BigNumber.from(PRIORITY_FEE),
                value: convertPriceToWei(amount.toString()),
              }
            : {
                from: Cookies.get("UserAddress"),
                value: convertPriceToWei(amount.toString()),
              }
        )
        .on("transactionHash", function (hash) {
          console.log("Hash TXT => ", hash);
          dispatch({
            type: PROCESSING,
          });
          openNotificationWithIcon(
            "info",
            "Transaction is in progress ",
            "Depositing. Please wait. "
          );
        })
        .on("receipt", function (receipt) {
          dispatch({
            type: COMPLETE_REQUEST,
            txtHash: receipt.transactionHash,
          });
          openNotificationWithIcon(
            "success",
            "Success",
            "You deposited crypto successfully"
          );
        });
    } catch (error) {
      console.log("error => ", error);
      if (error.code === 4001) {
        openNotificationWithIcon(
          "error",
          "Error",
          "You declined the action in your wallet"
        );
        dispatch({ type: REJECT_REQUEST });
      } else {
        dispatch({ type: FAILED_REQUEST });
        openNotificationWithIcon("error", "Error", "Error. Please try again!");
      }
    }
  };
};

export const withdraw = (amount) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: REQUESTING,
      });
      await stanFundContract.methods
        .withdraw(
          convertStringToBytes("string"),
          convertPriceToWei(amount.toString()),
          1 // 0: POINT ; 1: CRYPTO
        )
        .send(
          CHAIN_ID === "137"
            ? {
                from: Cookies.get("UserAddress"),
                maxPriorityFeePerGas: ethers.BigNumber.from(PRIORITY_FEE),
              }
            : {
                from: Cookies.get("UserAddress"),
              }
        )
        .on("transactionHash", function (hash) {
          console.log("Hash TXT => ", hash);
          dispatch({
            type: PROCESSING,
          });
          openNotificationWithIcon(
            "info",
            "Transaction is in progress ",
            "Withdrawing. Please wait. "
          );
        })
        .on("receipt", function (receipt) {
          console.log(receipt, " receipt");
          dispatch({
            type: COMPLETE_REQUEST,
            txtHash: receipt.transactionHash,
          });
          openNotificationWithIcon(
            "success",
            "Success",
            "Withdraw successfully!"
          );
        });
    } catch (error) {
      console.log("error => ", error);
      if (error.code === 4001) {
        openNotificationWithIcon(
          "error",
          "Error",
          "You declined the action in your wallet"
        );
        dispatch({ type: REJECT_REQUEST });
      } else {
        dispatch({ type: FAILED_REQUEST });
        openNotificationWithIcon("error", "Error", "Error. Please try again!");
      }
    }
  };
};

export const checkTotalSupply = () => {
  return async (dispatch) => {
    try {
      const result = await autionService.checkTotalSupply();
    } catch (error) {
      console.log(error);
    }
  };
};
