// TESTNET ABIs

export const ABI_COLLECTION_TESTNET = [
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint8", name: "version", type: "uint8" },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "requestId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
      { indexed: false, internalType: "bool", name: "platForm", type: "bool" },
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "STAN_EVENT",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "requestId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "royalty",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "stanFee",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "exchangeWeb",
        type: "uint256",
      },
    ],
    name: "STAN_TX",
    type: "event",
  },
  {
    inputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    name: "_collectionNFT",
    outputs: [
      { internalType: "bytes", name: "id", type: "bytes" },
      { internalType: "uint128", name: "currentNumber", type: "uint128" },
      { internalType: "uint128", name: "maxNumber", type: "uint128" },
      { internalType: "uint128", name: "ratioCreatorVal", type: "uint128" },
      {
        internalType: "uint128",
        name: "ratioCreatorDenomination",
        type: "uint128",
      },
      { internalType: "uint128", name: "ratioStanVal", type: "uint128" },
      {
        internalType: "uint128",
        name: "ratioStanDenomination",
        type: "uint128",
      },
      { internalType: "address", name: "owner", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_idCollection", type: "bytes" },
      { internalType: "address", name: "_creator", type: "address" },
      { internalType: "uint256", name: "_toId", type: "uint256" },
      { internalType: "uint256", name: "_quantity", type: "uint256" },
    ],
    name: "addBatchNFTToCollection",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_idCollection", type: "bytes" },
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
      { internalType: "address", name: "_creator", type: "address" },
      { internalType: "bool", name: "_isWeb", type: "bool" },
    ],
    name: "addNFTtoCollection",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    name: "collectionId",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "config",
    outputs: [{ internalType: "contract IConfig", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_collectionId", type: "bytes" },
      { internalType: "uint128", name: "_ratioCreatorVal", type: "uint128" },
      {
        internalType: "uint128",
        name: "_ratioCreatorDenomination",
        type: "uint128",
      },
      { internalType: "bool", name: "_isWeb", type: "bool" },
    ],
    name: "createCollection",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "address", name: "_to", type: "address" },
      { internalType: "bytes", name: "_collectionId", type: "bytes" },
      { internalType: "bool", name: "_isWeb", type: "bool" },
      {
        internalType: "uint128",
        name: "_ratioCreatorRoyaltyFee",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "_ratioCreatorDenomination",
        type: "uint128",
      },
    ],
    name: "createCollectionByStan",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_idCollection", type: "bytes" },
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
      { internalType: "address", name: "_currentOwnerNFT", type: "address" },
    ],
    name: "getInfoCollection",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "ratioCreatorVal", type: "uint256" },
          {
            internalType: "uint256",
            name: "ratioCreatorDenomination",
            type: "uint256",
          },
          { internalType: "uint256", name: "ratioStanVal", type: "uint256" },
          {
            internalType: "uint256",
            name: "ratioStanDenomination",
            type: "uint256",
          },
          { internalType: "address", name: "creator", type: "address" },
          { internalType: "address", name: "_owner", type: "address" },
          { internalType: "uint256", name: "nft", type: "uint256" },
          { internalType: "address", name: "currentOwnerNFT", type: "address" },
        ],
        internalType: "struct AuctionStructure.infoCollection",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_tokenId", type: "uint256" }],
    name: "getTokenIdToCollectionId",
    outputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_stanNFT", type: "address" },
      { internalType: "address", name: "_config", type: "address" },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_oldIdCollection", type: "bytes" },
      { internalType: "bytes", name: "_newIdCollection", type: "bytes" },
      { internalType: "address", name: "_creator", type: "address" },
      { internalType: "uint256", name: "_quantity", type: "uint256" },
      { internalType: "uint256[]", name: "_tokenIds", type: "uint256[]" },
    ],
    name: "processAddingBatchtoCollection",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_config", type: "address" }],
    name: "setConfig",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_stanFundAddress", type: "address" },
    ],
    name: "setStanFund",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_stanNFT", type: "address" }],
    name: "setStanNFT",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "stanFund",
    outputs: [
      { internalType: "contract IStanFund", name: "", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "stanNFT",
    outputs: [{ internalType: "contract IStanNFT", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "tokenIdToCollectionId",
    outputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_idCollection", type: "bytes" },
      { internalType: "address", name: "_from", type: "address" },
      { internalType: "address", name: "_to", type: "address" },
    ],
    name: "updateOwnerNFT",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const ABI_NFT_TESTNET = [
  { inputs: [], name: "ApprovalCallerNotOwnerNorApproved", type: "error" },
  { inputs: [], name: "ApprovalQueryForNonexistentToken", type: "error" },
  { inputs: [], name: "BalanceQueryForZeroAddress", type: "error" },
  { inputs: [], name: "MintERC2309QuantityExceedsLimit", type: "error" },
  { inputs: [], name: "MintToZeroAddress", type: "error" },
  { inputs: [], name: "MintZeroQuantity", type: "error" },
  { inputs: [], name: "OwnerQueryForNonexistentToken", type: "error" },
  { inputs: [], name: "OwnershipNotInitializedForExtraData", type: "error" },
  { inputs: [], name: "TransferCallerNotOwnerNorApproved", type: "error" },
  { inputs: [], name: "TransferFromIncorrectOwner", type: "error" },
  { inputs: [], name: "TransferToNonERC721ReceiverImplementer", type: "error" },
  { inputs: [], name: "TransferToZeroAddress", type: "error" },
  { inputs: [], name: "URIQueryForNonexistentToken", type: "error" },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "approved",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
      { indexed: false, internalType: "bool", name: "approved", type: "bool" },
    ],
    name: "ApprovalForAll",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "fromTokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "toTokenId",
        type: "uint256",
      },
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
    ],
    name: "ConsecutiveTransfer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint8", name: "version", type: "uint8" },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "requestId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
      { indexed: false, internalType: "bool", name: "platform", type: "bool" },
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "MOVE_BATCH_NFT_STAN_EVENT",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "requestId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
      { indexed: false, internalType: "bool", name: "platform", type: "bool" },
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      { indexed: false, internalType: "bytes", name: "nftIds", type: "bytes" },
    ],
    name: "STAN_EVENT",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "requestId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "royalty",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "stanFee",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "exchangeWeb",
        type: "uint256",
      },
    ],
    name: "STAN_TX",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "approve",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_auctionFixedPrice", type: "address" },
      { internalType: "address", name: "_auctionPure", type: "address" },
      { internalType: "bool", name: "_approved", type: "bool" },
    ],
    name: "approveForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_owner", type: "address" },
      { internalType: "address[]", name: "_operators", type: "address[]" },
      { internalType: "bool", name: "_approved", type: "bool" },
    ],
    name: "approveForAuction",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "auctionAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "baseURI",
    outputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "config",
    outputs: [{ internalType: "contract IConfig", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_collectionId", type: "bytes" },
      { internalType: "uint256", name: "_quantity", type: "uint256" },
      { internalType: "bytes", name: "_nftIds", type: "bytes" },
      { internalType: "bool", name: "_isWeb", type: "bool" },
    ],
    name: "createNFT",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_collectionId", type: "bytes" },
      { internalType: "uint256", name: "_quantity", type: "uint256" },
      { internalType: "address", name: "_to", type: "address" },
      { internalType: "bool", name: "_isWeb", type: "bool" },
    ],
    name: "createNFTByStan",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "getApproved",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_owner", type: "address" },
      { internalType: "address", name: "_operator", type: "address" },
    ],
    name: "getIsApprovedForAll",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_tokenId", type: "uint256" }],
    name: "getPriceNFT",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_tokenId", type: "uint256" }],
    name: "getTokenToListing",
    outputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "string", name: "name_", type: "string" },
      { internalType: "string", name: "symbol_", type: "string" },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_config", type: "address" }],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "operator", type: "address" },
    ],
    name: "isApprovedForAll",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_oldIdCollection", type: "bytes" },
      { internalType: "bytes", name: "_newIdCollection", type: "bytes" },
      { internalType: "address", name: "_creator", type: "address" },
      { internalType: "uint256", name: "_quantity", type: "uint256" },
      { internalType: "uint256[]", name: "_tokenIds", type: "uint256[]" },
      { internalType: "bool", name: "_isWeb", type: "bool" },
    ],
    name: "moveBatchNFTToCollection",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "operator", type: "address" },
      { internalType: "address", name: "from", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
      { internalType: "bytes", name: "data", type: "bytes" },
    ],
    name: "onERC721Received",
    outputs: [{ internalType: "bytes4", name: "", type: "bytes4" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "ownerOf",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
      { internalType: "bytes", name: "_data", type: "bytes" },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "operator", type: "address" },
      { internalType: "bool", name: "approved", type: "bool" },
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address[]", name: "_caller", type: "address[]" },
      { internalType: "bool", name: "_allowed", type: "bool" },
    ],
    name: "setAuthorizers",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_collectionNFTAdrress",
        type: "address",
      },
    ],
    name: "setCollectionNFT",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_config", type: "address" }],
    name: "setConfig",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
    ],
    name: "setPriceNFT",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_stanFundAddress", type: "address" },
    ],
    name: "setStanFund",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "stanFund",
    outputs: [
      { internalType: "contract IStanFund", name: "", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
    name: "supportsInterface",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "tokenURI",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_from", type: "address" },
      { internalType: "address", name: "_to", type: "address" },
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
    ],
    name: "updateOwnerNFTAndTransferNFT",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_listing", type: "bytes" },
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
    ],
    name: "updateTokenToListing",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const ABI_AUCTION_TESTNET = [
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint8", name: "version", type: "uint8" },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "requestId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "enum AuctionStructure.Currency",
        name: "currency",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "STAN_EVENT",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "requestId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "royalty",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "stanFee",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "exchangeWeb",
        type: "uint256",
      },
    ],
    name: "STAN_TX",
    type: "event",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_auctionId", type: "bytes" },
      { internalType: "bytes", name: "_subOfferId", type: "bytes" },
      { internalType: "bytes", name: "_nftId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "acceptOfferAuction",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_nftId", type: "bytes" },
      { internalType: "bytes", name: "_subOfferId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "acceptOfferPvP",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "auctionFixedPrice",
    outputs: [
      {
        internalType: "contract IAuctionFixedPrice",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "auctionFixedPriceAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "auctionPure",
    outputs: [
      { internalType: "contract IAuctionPure", name: "", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "auctionPureAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "address", name: "_seller", type: "address" },
      { internalType: "address", name: "_maker", type: "address" },
      { internalType: "bytes", name: "_listingId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "buyFixPrice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_listingId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "cancelListingAuction",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_listingId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "cancelListingFixedPrice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_auctionId", type: "bytes" },
      { internalType: "bytes", name: "_subOfferId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "cancelOfferAuction",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_nftId", type: "bytes" },
      { internalType: "bytes", name: "_subOfferId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "cancelOfferPvP",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "config",
    outputs: [{ internalType: "contract IConfig", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes[]", name: "_listingIds", type: "bytes[]" },
      { internalType: "bool", name: "_isAuction", type: "bool" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "expiredListing",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_indexId", type: "bytes" },
      { internalType: "bytes[]", name: "_subOffersIdParam", type: "bytes[]" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "expiredOffer",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_auctionId", type: "bytes" },
      { internalType: "bytes", name: "_nftId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "finishAuction",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_auctionFixedPrice", type: "address" },
      { internalType: "address", name: "_auctionPure", type: "address" },
      { internalType: "address", name: "_config", type: "address" },
      { internalType: "address", name: "_stanWalletAddress", type: "address" },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_auctionId", type: "bytes" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
      { internalType: "uint256", name: "_expirationTime", type: "uint256" },
      { internalType: "address", name: "_maker", type: "address" },
      { internalType: "bytes", name: "_nftId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "listingNFTAuction",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_listingId", type: "bytes" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
      { internalType: "uint256", name: "_expirationTime", type: "uint256" },
      { internalType: "address", name: "_maker", type: "address" },
      { internalType: "bytes", name: "_nftId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "listingNFTFixedPrice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_subOfferId", type: "bytes" },
      { internalType: "bytes", name: "_nftID", type: "bytes" },
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
      { internalType: "uint256", name: "_expirationTime", type: "uint256" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "address", name: "_maker", type: "address" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "makeOfferFixedPrice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_subOfferId", type: "bytes" },
      { internalType: "bytes", name: "_auctionId", type: "bytes" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "address", name: "_maker", type: "address" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "placeBidAuction",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_auctionFixedPrice", type: "address" },
    ],
    name: "setAuctionFixedPrice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_auctionPure", type: "address" },
    ],
    name: "setAuctionPure",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_config", type: "address" }],
    name: "setConfig",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_stanAddress", type: "address" },
    ],
    name: "setStanAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_user", type: "address" }],
    name: "setWhileList",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "stanFund",
    outputs: [
      { internalType: "contract IStanFund", name: "", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "stanFundAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "stanWalletAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenStanAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "address", name: "_receiver", type: "address" },
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
      { internalType: "address", name: "_maker", type: "address" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "transferNFTPvP",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const ABI_STAN_TOKEN_TESTNET = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint8", name: "version", type: "uint8" },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [],
    name: "Decimals",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "spender", type: "address" },
    ],
    name: "allowance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "subtractedValue", type: "uint256" },
    ],
    name: "decreaseAllowance",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "addedValue", type: "uint256" },
    ],
    name: "increaseAllowance",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "test",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_testNumber", type: "uint256" }],
    name: "testing",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const ABI_STAN_FUND_TESTNET = [
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint8", name: "version", type: "uint8" },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "requestId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "STAN_EVENT",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "_totalSupply",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_userSupply",
        type: "uint256",
      },
    ],
    name: "STAN_WARNING",
    type: "event",
  },
  {
    inputs: [],
    name: "ability",
    outputs: [
      { internalType: "uint256", name: "val", type: "uint256" },
      { internalType: "uint256", name: "valDenomination", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "auctionAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "deposit",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "depositByStan",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_user", type: "address" }],
    name: "get",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "userStanFund", type: "uint256" },
          { internalType: "bool", name: "result", type: "bool" },
        ],
        internalType: "struct AuctionStructure.stanFundParams",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getInforStanFund",
    outputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          { internalType: "address", name: "maker", type: "address" },
          { internalType: "uint256", name: "bidnumber", type: "uint256" },
        ],
        internalType: "struct AuctionStructure.userFund[]",
        name: "_users",
        type: "tuple[]",
      },
    ],
    name: "handleBackFeeToUser",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_collectionNFT", type: "address" },
      { internalType: "address", name: "_config", type: "address" },
      { internalType: "address", name: "_admin", type: "address" },
      { internalType: "uint256", name: "_val", type: "uint256" },
      { internalType: "uint256", name: "_valDenomination", type: "uint256" },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          { internalType: "address", name: "seller", type: "address" },
          { internalType: "address", name: "buyer", type: "address" },
          { internalType: "uint256", name: "amount", type: "uint256" },
          { internalType: "uint256", name: "feeStanVal", type: "uint256" },
          {
            internalType: "uint256",
            name: "feeStanValDenomination",
            type: "uint256",
          },
          { internalType: "uint256", name: "tokenId", type: "uint256" },
          {
            internalType: "enum AuctionStructure.Method",
            name: "method",
            type: "uint8",
          },
          {
            internalType: "enum AuctionStructure.MethodToPayment",
            name: "methodToPayment",
            type: "uint8",
          },
        ],
        internalType: "struct AuctionStructure.puchasing",
        name: "params",
        type: "tuple",
      },
    ],
    name: "purchaseProcessing",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "royalty", type: "uint256" },
          { internalType: "uint256", name: "stanFee", type: "uint256" },
          { internalType: "uint256", name: "exchangeWeb", type: "uint256" },
        ],
        internalType: "struct AuctionStructure.totalFee",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_amount", type: "uint256" },
      {
        internalType: "enum AuctionStructure.Operator",
        name: "_operator",
        type: "uint8",
      },
      { internalType: "address", name: "_user", type: "address" },
    ],
    name: "set",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_val", type: "uint256" },
      { internalType: "uint256", name: "_valDenomination", type: "uint256" },
    ],
    name: "setAbilityToWithdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_stanAdmin", type: "address" }],
    name: "setAdmin",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address[]", name: "_authorizers", type: "address[]" },
      { internalType: "bool", name: "_allowed", type: "bool" },
    ],
    name: "setAuthorizers",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_config", type: "address" }],
    name: "setConfig",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_amount", type: "uint256" },
      {
        internalType: "enum AuctionStructure.Operator",
        name: "_operator",
        type: "uint8",
      },
    ],
    name: "setSupply",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "stanAdmin",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "stanFund",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "userSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "withdrawByStan",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  { stateMutability: "payable", type: "receive" },
];

export const ABI_STAN_CONFIG_TESTNET = [
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint8", name: "version", type: "uint8" },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
    ],
    name: "STAN_EVENT",
    type: "event",
  },
  {
    inputs: [],
    name: "feeStanFixed",
    outputs: [
      { internalType: "uint256", name: "feeTransferNFTPvP", type: "uint256" },
      {
        internalType: "uint256",
        name: "feeListingNFTAuction",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "feeCancelListingAuction",
        type: "uint256",
      },
      { internalType: "uint256", name: "feePlaceBidAuction", type: "uint256" },
      { internalType: "uint256", name: "feeMintingNFT", type: "uint256" },
      { internalType: "uint256", name: "feeCreateCollection", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "feeStanService",
    outputs: [
      {
        internalType: "uint128",
        name: "ratioBuyFixedPriceVal",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioBuyFixedPriceDenomination",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioAcceptOfferPvPVal",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioAcceptOfferPvPDenomination",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioAcceptOfferAuctionVal",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioAcceptOfferAuctionDenomination",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioFinishAuctionVal",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioFinishAuctionDenomination",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioCancelOfferPvPVal",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioCancelOfferPvPDenomination",
        type: "uint128",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "feeStanSystem",
    outputs: [
      { internalType: "uint128", name: "ratioCreatorVal", type: "uint128" },
      {
        internalType: "uint128",
        name: "ratioCreatorDenomination",
        type: "uint128",
      },
      { internalType: "uint128", name: "ratioStanVal", type: "uint128" },
      {
        internalType: "uint128",
        name: "ratioStanDenomination",
        type: "uint128",
      },
      { internalType: "uint128", name: "maxCollectionNumber", type: "uint128" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getFeeStanFixed",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "feeTransferNFTPvP",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feeListingNFTAuction",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feeCancelListingAuction",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feePlaceBidAuction",
            type: "uint256",
          },
          { internalType: "uint256", name: "feeMintingNFT", type: "uint256" },
          {
            internalType: "uint256",
            name: "feeCreateCollection",
            type: "uint256",
          },
        ],
        internalType: "struct AuctionStructure.feeStanFixed",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getFeeStanService",
    outputs: [
      {
        components: [
          {
            internalType: "uint128",
            name: "ratioBuyFixedPriceVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioBuyFixedPriceDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferPvPVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferPvPDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferAuctionVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferAuctionDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioFinishAuctionVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioFinishAuctionDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioCancelOfferPvPVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioCancelOfferPvPDenomination",
            type: "uint128",
          },
        ],
        internalType: "struct AuctionStructure.feeStanService",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getFeeStanSystem",
    outputs: [
      {
        components: [
          { internalType: "uint128", name: "ratioCreatorVal", type: "uint128" },
          {
            internalType: "uint128",
            name: "ratioCreatorDenomination",
            type: "uint128",
          },
          { internalType: "uint128", name: "ratioStanVal", type: "uint128" },
          {
            internalType: "uint128",
            name: "ratioStanDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "maxCollectionNumber",
            type: "uint128",
          },
        ],
        internalType: "struct AuctionStructure.feeStanSystem",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          { internalType: "uint128", name: "ratioCreatorVal", type: "uint128" },
          {
            internalType: "uint128",
            name: "ratioCreatorDenomination",
            type: "uint128",
          },
          { internalType: "uint128", name: "ratioStanVal", type: "uint128" },
          {
            internalType: "uint128",
            name: "ratioStanDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "maxCollectionNumber",
            type: "uint128",
          },
        ],
        internalType: "struct AuctionStructure.feeStanSystem",
        name: "_feeStanSystem",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "uint256",
            name: "feeTransferNFTPvP",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feeListingNFTAuction",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feeCancelListingAuction",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feePlaceBidAuction",
            type: "uint256",
          },
          { internalType: "uint256", name: "feeMintingNFT", type: "uint256" },
          {
            internalType: "uint256",
            name: "feeCreateCollection",
            type: "uint256",
          },
        ],
        internalType: "struct AuctionStructure.feeStanFixed",
        name: "_feeStanFixed",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "uint128",
            name: "ratioBuyFixedPriceVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioBuyFixedPriceDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferPvPVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferPvPDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferAuctionVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferAuctionDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioFinishAuctionVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioFinishAuctionDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioCancelOfferPvPVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioCancelOfferPvPDenomination",
            type: "uint128",
          },
        ],
        internalType: "struct AuctionStructure.feeStanService",
        name: "_feeStanService",
        type: "tuple",
      },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint128",
        name: "_maxCollectionNumber",
        type: "uint128",
      },
    ],
    name: "setMaxCollectionNumber",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint128", name: "_ratioCreatorVal", type: "uint128" },
      {
        internalType: "uint128",
        name: "_ratioCreatorDenomination",
        type: "uint128",
      },
      { internalType: "uint128", name: "_ratioStanVal", type: "uint128" },
      {
        internalType: "uint128",
        name: "_ratioStanDenomination",
        type: "uint128",
      },
    ],
    name: "setRoyaltyFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint128",
            name: "ratioBuyFixedPriceVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioBuyFixedPriceDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferPvPVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferPvPDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferAuctionVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferAuctionDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioFinishAuctionVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioFinishAuctionDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioCancelOfferPvPVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioCancelOfferPvPDenomination",
            type: "uint128",
          },
        ],
        internalType: "struct AuctionStructure.feeStanService",
        name: "_feeStanService",
        type: "tuple",
      },
    ],
    name: "setServiceFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "feeTransferNFTPvP",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feeListingNFTAuction",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feeCancelListingAuction",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feePlaceBidAuction",
            type: "uint256",
          },
          { internalType: "uint256", name: "feeMintingNFT", type: "uint256" },
          {
            internalType: "uint256",
            name: "feeCreateCollection",
            type: "uint256",
          },
        ],
        internalType: "struct AuctionStructure.feeStanFixed",
        name: "_feeStanFixed",
        type: "tuple",
      },
    ],
    name: "setStanFixed",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

// MAINNET ABIs

export const ABI_COLLECTION_MAINNET = [
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint8", name: "version", type: "uint8" },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "requestId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
      { indexed: false, internalType: "bool", name: "platForm", type: "bool" },
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "STAN_EVENT",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "requestId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "royalty",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "stanFee",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "exchangeWeb",
        type: "uint256",
      },
    ],
    name: "STAN_TX",
    type: "event",
  },
  {
    inputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    name: "_collectionNFT",
    outputs: [
      { internalType: "bytes", name: "id", type: "bytes" },
      { internalType: "uint128", name: "currentNumber", type: "uint128" },
      { internalType: "uint128", name: "maxNumber", type: "uint128" },
      { internalType: "uint128", name: "ratioCreatorVal", type: "uint128" },
      {
        internalType: "uint128",
        name: "ratioCreatorDenomination",
        type: "uint128",
      },
      { internalType: "uint128", name: "ratioStanVal", type: "uint128" },
      {
        internalType: "uint128",
        name: "ratioStanDenomination",
        type: "uint128",
      },
      { internalType: "address", name: "owner", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_idCollection", type: "bytes" },
      { internalType: "address", name: "_creator", type: "address" },
      { internalType: "uint256", name: "_toId", type: "uint256" },
      { internalType: "uint256", name: "_quantity", type: "uint256" },
    ],
    name: "addBatchNFTToCollection",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_idCollection", type: "bytes" },
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
      { internalType: "address", name: "_creator", type: "address" },
      { internalType: "bool", name: "_isWeb", type: "bool" },
    ],
    name: "addNFTtoCollection",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    name: "collectionId",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "config",
    outputs: [{ internalType: "contract IConfig", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_collectionId", type: "bytes" },
      { internalType: "uint128", name: "_ratioCreatorVal", type: "uint128" },
      {
        internalType: "uint128",
        name: "_ratioCreatorDenomination",
        type: "uint128",
      },
      { internalType: "bool", name: "_isWeb", type: "bool" },
    ],
    name: "createCollection",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "address", name: "_to", type: "address" },
      { internalType: "bytes", name: "_collectionId", type: "bytes" },
      { internalType: "bool", name: "_isWeb", type: "bool" },
      {
        internalType: "uint128",
        name: "_ratioCreatorRoyaltyFee",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "_ratioCreatorDenomination",
        type: "uint128",
      },
    ],
    name: "createCollectionByStan",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_idCollection", type: "bytes" },
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
      { internalType: "address", name: "_currentOwnerNFT", type: "address" },
    ],
    name: "getInfoCollection",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "ratioCreatorVal", type: "uint256" },
          {
            internalType: "uint256",
            name: "ratioCreatorDenomination",
            type: "uint256",
          },
          { internalType: "uint256", name: "ratioStanVal", type: "uint256" },
          {
            internalType: "uint256",
            name: "ratioStanDenomination",
            type: "uint256",
          },
          { internalType: "address", name: "creator", type: "address" },
          { internalType: "address", name: "_owner", type: "address" },
          { internalType: "uint256", name: "nft", type: "uint256" },
          { internalType: "address", name: "currentOwnerNFT", type: "address" },
        ],
        internalType: "struct AuctionStructure.infoCollection",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_tokenId", type: "uint256" }],
    name: "getTokenIdToCollectionId",
    outputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_stanNFT", type: "address" },
      { internalType: "address", name: "_config", type: "address" },
      { internalType: "address", name: "_beWallet", type: "address" },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_oldIdCollection", type: "bytes" },
      { internalType: "bytes", name: "_newIdCollection", type: "bytes" },
      { internalType: "address", name: "_creator", type: "address" },
      { internalType: "uint256", name: "_quantity", type: "uint256" },
      { internalType: "uint256[]", name: "_tokenIds", type: "uint256[]" },
    ],
    name: "processAddingBatchtoCollection",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_wallet", type: "address" }],
    name: "setBEwallet",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_config", type: "address" }],
    name: "setConfig",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes", name: "_idCollection", type: "bytes" }],
    name: "setNumberNftInCollection",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_stanFundAddress", type: "address" },
    ],
    name: "setStanFund",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_stanNFT", type: "address" }],
    name: "setStanNFT",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "stanFund",
    outputs: [
      { internalType: "contract IStanFund", name: "", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "stanNFT",
    outputs: [{ internalType: "contract IStanNFT", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "tokenIdToCollectionId",
    outputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_idCollection", type: "bytes" },
      { internalType: "address", name: "_from", type: "address" },
      { internalType: "address", name: "_to", type: "address" },
    ],
    name: "updateOwnerNFT",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const ABI_NFT_MAINNET = [
  { inputs: [], name: "ApprovalCallerNotOwnerNorApproved", type: "error" },
  { inputs: [], name: "ApprovalQueryForNonexistentToken", type: "error" },
  { inputs: [], name: "BalanceQueryForZeroAddress", type: "error" },
  { inputs: [], name: "MintERC2309QuantityExceedsLimit", type: "error" },
  { inputs: [], name: "MintToZeroAddress", type: "error" },
  { inputs: [], name: "MintZeroQuantity", type: "error" },
  { inputs: [], name: "OwnerQueryForNonexistentToken", type: "error" },
  { inputs: [], name: "OwnershipNotInitializedForExtraData", type: "error" },
  { inputs: [], name: "TransferCallerNotOwnerNorApproved", type: "error" },
  { inputs: [], name: "TransferFromIncorrectOwner", type: "error" },
  { inputs: [], name: "TransferToNonERC721ReceiverImplementer", type: "error" },
  { inputs: [], name: "TransferToZeroAddress", type: "error" },
  { inputs: [], name: "URIQueryForNonexistentToken", type: "error" },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "approved",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
      { indexed: false, internalType: "bool", name: "approved", type: "bool" },
    ],
    name: "ApprovalForAll",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "fromTokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "toTokenId",
        type: "uint256",
      },
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
    ],
    name: "ConsecutiveTransfer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint8", name: "version", type: "uint8" },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "requestId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
      { indexed: false, internalType: "bool", name: "platform", type: "bool" },
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "MOVE_BATCH_NFT_STAN_EVENT",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "requestId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
      { indexed: false, internalType: "bool", name: "platform", type: "bool" },
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      { indexed: false, internalType: "bytes", name: "nftIds", type: "bytes" },
    ],
    name: "STAN_EVENT",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "requestId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "royalty",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "stanFee",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "exchangeWeb",
        type: "uint256",
      },
    ],
    name: "STAN_TX",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "approve",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_auctionFixedPrice", type: "address" },
      { internalType: "address", name: "_auctionPure", type: "address" },
      { internalType: "bool", name: "_approved", type: "bool" },
    ],
    name: "approveForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_owner", type: "address" },
      { internalType: "address[]", name: "_operators", type: "address[]" },
      { internalType: "bool", name: "_approved", type: "bool" },
    ],
    name: "approveForAuction",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "auctionAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "baseURI",
    outputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "config",
    outputs: [{ internalType: "contract IConfig", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_collectionId", type: "bytes" },
      { internalType: "uint256", name: "_quantity", type: "uint256" },
      { internalType: "bytes", name: "_nftIds", type: "bytes" },
      { internalType: "bool", name: "_isWeb", type: "bool" },
    ],
    name: "createNFT",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_collectionId", type: "bytes" },
      { internalType: "uint256", name: "_quantity", type: "uint256" },
      { internalType: "address", name: "_to", type: "address" },
      { internalType: "bool", name: "_isWeb", type: "bool" },
    ],
    name: "createNFTByStan",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "getApproved",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_owner", type: "address" },
      { internalType: "address", name: "_operator", type: "address" },
    ],
    name: "getIsApprovedForAll",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_tokenId", type: "uint256" }],
    name: "getPriceNFT",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_tokenId", type: "uint256" }],
    name: "getTokenToListing",
    outputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_config", type: "address" },
      { internalType: "address", name: "_beWallet", type: "address" },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "string", name: "name_", type: "string" },
      { internalType: "string", name: "symbol_", type: "string" },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "operator", type: "address" },
    ],
    name: "isApprovedForAll",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_oldIdCollection", type: "bytes" },
      { internalType: "bytes", name: "_newIdCollection", type: "bytes" },
      { internalType: "address", name: "_creator", type: "address" },
      { internalType: "uint256", name: "_quantity", type: "uint256" },
      { internalType: "uint256[]", name: "_tokenIds", type: "uint256[]" },
      { internalType: "bool", name: "_isWeb", type: "bool" },
    ],
    name: "moveBatchNFTToCollection",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "operator", type: "address" },
      { internalType: "address", name: "from", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
      { internalType: "bytes", name: "data", type: "bytes" },
    ],
    name: "onERC721Received",
    outputs: [{ internalType: "bytes4", name: "", type: "bytes4" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "ownerOf",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
      { internalType: "bytes", name: "_data", type: "bytes" },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "operator", type: "address" },
      { internalType: "bool", name: "approved", type: "bool" },
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address[]", name: "_caller", type: "address[]" },
      { internalType: "bool", name: "_allowed", type: "bool" },
    ],
    name: "setAuthorizers",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_collectionNFTAdrress",
        type: "address",
      },
    ],
    name: "setCollectionNFT",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_config", type: "address" }],
    name: "setConfig",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
    ],
    name: "setPriceNFT",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_stanFundAddress", type: "address" },
    ],
    name: "setStanFund",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "stanFund",
    outputs: [
      { internalType: "contract IStanFund", name: "", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
    name: "supportsInterface",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "tokenURI",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_from", type: "address" },
      { internalType: "address", name: "_to", type: "address" },
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
    ],
    name: "updateOwnerNFTAndTransferNFT",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_listing", type: "bytes" },
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
    ],
    name: "updateTokenToListing",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const ABI_AUCTION_MAINNET = [
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint8", name: "version", type: "uint8" },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "requestId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "enum AuctionStructure.Currency",
        name: "currency",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "STAN_EVENT",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "requestId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "royalty",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "stanFee",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "exchangeWeb",
        type: "uint256",
      },
    ],
    name: "STAN_TX",
    type: "event",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_auctionId", type: "bytes" },
      { internalType: "bytes", name: "_subOfferId", type: "bytes" },
      { internalType: "bytes", name: "_nftId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "acceptOfferAuction",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_nftId", type: "bytes" },
      { internalType: "bytes", name: "_subOfferId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "acceptOfferPvP",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "auctionFixedPrice",
    outputs: [
      {
        internalType: "contract IAuctionFixedPrice",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "auctionFixedPriceAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "auctionPure",
    outputs: [
      { internalType: "contract IAuctionPure", name: "", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "auctionPureAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "address", name: "_seller", type: "address" },
      { internalType: "address", name: "_maker", type: "address" },
      { internalType: "bytes", name: "_listingId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "buyFixPrice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_listingId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "cancelListingAuction",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_listingId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "cancelListingFixedPrice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_auctionId", type: "bytes" },
      { internalType: "bytes", name: "_subOfferId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "cancelOfferAuction",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_nftId", type: "bytes" },
      { internalType: "bytes", name: "_subOfferId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "cancelOfferPvP",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "config",
    outputs: [{ internalType: "contract IConfig", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes[]", name: "_listingIds", type: "bytes[]" },
      { internalType: "bool", name: "_isAuction", type: "bool" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "expiredListing",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_indexId", type: "bytes" },
      { internalType: "bytes[]", name: "_subOffersIdParam", type: "bytes[]" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "expiredOffer",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_auctionId", type: "bytes" },
      { internalType: "bytes", name: "_nftId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "finishAuction",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_auctionFixedPrice", type: "address" },
      { internalType: "address", name: "_auctionPure", type: "address" },
      { internalType: "address", name: "_config", type: "address" },
      { internalType: "address", name: "_beWallet", type: "address" },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_auctionId", type: "bytes" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
      { internalType: "uint256", name: "_expirationTime", type: "uint256" },
      { internalType: "address", name: "_maker", type: "address" },
      { internalType: "bytes", name: "_nftId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "listingNFTAuction",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_listingId", type: "bytes" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
      { internalType: "uint256", name: "_expirationTime", type: "uint256" },
      { internalType: "address", name: "_maker", type: "address" },
      { internalType: "bytes", name: "_nftId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "listingNFTFixedPrice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_subOfferId", type: "bytes" },
      { internalType: "bytes", name: "_nftID", type: "bytes" },
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
      { internalType: "uint256", name: "_expirationTime", type: "uint256" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "address", name: "_maker", type: "address" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "makeOfferFixedPrice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_subOfferId", type: "bytes" },
      { internalType: "bytes", name: "_auctionId", type: "bytes" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "address", name: "_maker", type: "address" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "placeBidAuction",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_auctionFixedPrice", type: "address" },
    ],
    name: "setAuctionFixedPrice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_auctionPure", type: "address" },
    ],
    name: "setAuctionPure",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_wallet", type: "address" }],
    name: "setBEwallet",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_config", type: "address" }],
    name: "setConfig",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_user", type: "address" }],
    name: "setWhileList",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "stanFund",
    outputs: [
      { internalType: "contract IStanFund", name: "", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "stanFundAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenStanAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "address", name: "_receiver", type: "address" },
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
      { internalType: "address", name: "_maker", type: "address" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "transferNFTPvP",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const ABI_STAN_TOKEN_MAINNET = [
  {
    inputs: [
      { internalType: "address", name: "childChainManager", type: "address" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "userAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address payable",
        name: "relayerAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "functionSignature",
        type: "bytes",
      },
    ],
    name: "MetaTransactionExecuted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
      {
        indexed: true,
        internalType: "bytes32",
        name: "previousAdminRole",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "newAdminRole",
        type: "bytes32",
      },
    ],
    name: "RoleAdminChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "RoleGranted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "RoleRevoked",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [],
    name: "CHILD_CHAIN_ID",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "CHILD_CHAIN_ID_BYTES",
    outputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "DEFAULT_ADMIN_ROLE",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "DEPOSITOR_ROLE",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ERC712_VERSION",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ROOT_CHAIN_ID",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ROOT_CHAIN_ID_BYTES",
    outputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "spender", type: "address" },
    ],
    name: "allowance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "subtractedValue", type: "uint256" },
    ],
    name: "decreaseAllowance",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "user", type: "address" },
      { internalType: "bytes", name: "depositData", type: "bytes" },
    ],
    name: "deposit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "userAddress", type: "address" },
      { internalType: "bytes", name: "functionSignature", type: "bytes" },
      { internalType: "bytes32", name: "sigR", type: "bytes32" },
      { internalType: "bytes32", name: "sigS", type: "bytes32" },
      { internalType: "uint8", name: "sigV", type: "uint8" },
    ],
    name: "executeMetaTransaction",
    outputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "getChainId",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [],
    name: "getDomainSeperator",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "user", type: "address" }],
    name: "getNonce",
    outputs: [{ internalType: "uint256", name: "nonce", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "role", type: "bytes32" }],
    name: "getRoleAdmin",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "uint256", name: "index", type: "uint256" },
    ],
    name: "getRoleMember",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "role", type: "bytes32" }],
    name: "getRoleMemberCount",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "grantRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "hasRole",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "addedValue", type: "uint256" },
    ],
    name: "increaseAllowance",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "renounceRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "revokeRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "sender", type: "address" },
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const ABI_STAN_FUND_MAINNET = [
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint8", name: "version", type: "uint8" },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "requestId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "STAN_EVENT",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "_totalSupply",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_userSupply",
        type: "uint256",
      },
    ],
    name: "STAN_WARNING",
    type: "event",
  },
  {
    inputs: [],
    name: "ability",
    outputs: [
      { internalType: "uint256", name: "val", type: "uint256" },
      { internalType: "uint256", name: "valDenomination", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "auctionAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "deposit",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "depositByStan",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_user", type: "address" }],
    name: "get",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "userStanFund", type: "uint256" },
          { internalType: "bool", name: "result", type: "bool" },
        ],
        internalType: "struct AuctionStructure.stanFundParams",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getInforStanFund",
    outputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          { internalType: "address", name: "maker", type: "address" },
          { internalType: "uint256", name: "bidnumber", type: "uint256" },
        ],
        internalType: "struct AuctionStructure.userFund[]",
        name: "_users",
        type: "tuple[]",
      },
    ],
    name: "handleBackFeeToUser",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_collectionNFT", type: "address" },
      { internalType: "address", name: "_config", type: "address" },
      { internalType: "address", name: "_admin", type: "address" },
      { internalType: "uint256", name: "_val", type: "uint256" },
      { internalType: "uint256", name: "_valDenomination", type: "uint256" },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          { internalType: "address", name: "seller", type: "address" },
          { internalType: "address", name: "buyer", type: "address" },
          { internalType: "uint256", name: "amount", type: "uint256" },
          { internalType: "uint256", name: "feeStanVal", type: "uint256" },
          {
            internalType: "uint256",
            name: "feeStanValDenomination",
            type: "uint256",
          },
          { internalType: "uint256", name: "tokenId", type: "uint256" },
          {
            internalType: "enum AuctionStructure.Method",
            name: "method",
            type: "uint8",
          },
          {
            internalType: "enum AuctionStructure.MethodToPayment",
            name: "methodToPayment",
            type: "uint8",
          },
        ],
        internalType: "struct AuctionStructure.puchasing",
        name: "params",
        type: "tuple",
      },
    ],
    name: "purchaseProcessing",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "royalty", type: "uint256" },
          { internalType: "uint256", name: "stanFee", type: "uint256" },
          { internalType: "uint256", name: "exchangeWeb", type: "uint256" },
        ],
        internalType: "struct AuctionStructure.totalFee",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_amount", type: "uint256" },
      {
        internalType: "enum AuctionStructure.Operator",
        name: "_operator",
        type: "uint8",
      },
      { internalType: "address", name: "_user", type: "address" },
    ],
    name: "set",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_val", type: "uint256" },
      { internalType: "uint256", name: "_valDenomination", type: "uint256" },
    ],
    name: "setAbilityToWithdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_stanAdmin", type: "address" }],
    name: "setAdmin",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address[]", name: "_authorizers", type: "address[]" },
      { internalType: "bool", name: "_allowed", type: "bool" },
    ],
    name: "setAuthorizers",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_collectionNFT", type: "address" },
    ],
    name: "setCollection",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_config", type: "address" }],
    name: "setConfig",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_amount", type: "uint256" },
      {
        internalType: "enum AuctionStructure.Operator",
        name: "_operator",
        type: "uint8",
      },
    ],
    name: "setSupply",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "stanAdmin",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "stanFund",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "test",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "userSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "withdrawByStan",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  { stateMutability: "payable", type: "receive" },
];

export const ABI_STAN_CONFIG_MAINNET = [
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint8", name: "version", type: "uint8" },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
    ],
    name: "STAN_EVENT",
    type: "event",
  },
  {
    inputs: [],
    name: "feeStanFixed",
    outputs: [
      { internalType: "uint256", name: "feeTransferNFTPvP", type: "uint256" },
      {
        internalType: "uint256",
        name: "feeListingNFTAuction",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "feeCancelListingAuction",
        type: "uint256",
      },
      { internalType: "uint256", name: "feePlaceBidAuction", type: "uint256" },
      { internalType: "uint256", name: "feeMintingNFT", type: "uint256" },
      { internalType: "uint256", name: "feeCreateCollection", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "feeStanService",
    outputs: [
      {
        internalType: "uint128",
        name: "ratioBuyFixedPriceVal",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioBuyFixedPriceDenomination",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioAcceptOfferPvPVal",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioAcceptOfferPvPDenomination",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioAcceptOfferAuctionVal",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioAcceptOfferAuctionDenomination",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioFinishAuctionVal",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioFinishAuctionDenomination",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioCancelOfferPvPVal",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioCancelOfferPvPDenomination",
        type: "uint128",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "feeStanSystem",
    outputs: [
      { internalType: "uint128", name: "ratioCreatorVal", type: "uint128" },
      {
        internalType: "uint128",
        name: "ratioCreatorDenomination",
        type: "uint128",
      },
      { internalType: "uint128", name: "ratioStanVal", type: "uint128" },
      {
        internalType: "uint128",
        name: "ratioStanDenomination",
        type: "uint128",
      },
      { internalType: "uint128", name: "maxCollectionNumber", type: "uint128" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getFeeStanFixed",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "feeTransferNFTPvP",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feeListingNFTAuction",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feeCancelListingAuction",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feePlaceBidAuction",
            type: "uint256",
          },
          { internalType: "uint256", name: "feeMintingNFT", type: "uint256" },
          {
            internalType: "uint256",
            name: "feeCreateCollection",
            type: "uint256",
          },
        ],
        internalType: "struct AuctionStructure.feeStanFixed",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getFeeStanService",
    outputs: [
      {
        components: [
          {
            internalType: "uint128",
            name: "ratioBuyFixedPriceVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioBuyFixedPriceDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferPvPVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferPvPDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferAuctionVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferAuctionDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioFinishAuctionVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioFinishAuctionDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioCancelOfferPvPVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioCancelOfferPvPDenomination",
            type: "uint128",
          },
        ],
        internalType: "struct AuctionStructure.feeStanService",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getFeeStanSystem",
    outputs: [
      {
        components: [
          { internalType: "uint128", name: "ratioCreatorVal", type: "uint128" },
          {
            internalType: "uint128",
            name: "ratioCreatorDenomination",
            type: "uint128",
          },
          { internalType: "uint128", name: "ratioStanVal", type: "uint128" },
          {
            internalType: "uint128",
            name: "ratioStanDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "maxCollectionNumber",
            type: "uint128",
          },
        ],
        internalType: "struct AuctionStructure.feeStanSystem",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          { internalType: "uint128", name: "ratioCreatorVal", type: "uint128" },
          {
            internalType: "uint128",
            name: "ratioCreatorDenomination",
            type: "uint128",
          },
          { internalType: "uint128", name: "ratioStanVal", type: "uint128" },
          {
            internalType: "uint128",
            name: "ratioStanDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "maxCollectionNumber",
            type: "uint128",
          },
        ],
        internalType: "struct AuctionStructure.feeStanSystem",
        name: "_feeStanSystem",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "uint256",
            name: "feeTransferNFTPvP",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feeListingNFTAuction",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feeCancelListingAuction",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feePlaceBidAuction",
            type: "uint256",
          },
          { internalType: "uint256", name: "feeMintingNFT", type: "uint256" },
          {
            internalType: "uint256",
            name: "feeCreateCollection",
            type: "uint256",
          },
        ],
        internalType: "struct AuctionStructure.feeStanFixed",
        name: "_feeStanFixed",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "uint128",
            name: "ratioBuyFixedPriceVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioBuyFixedPriceDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferPvPVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferPvPDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferAuctionVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferAuctionDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioFinishAuctionVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioFinishAuctionDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioCancelOfferPvPVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioCancelOfferPvPDenomination",
            type: "uint128",
          },
        ],
        internalType: "struct AuctionStructure.feeStanService",
        name: "_feeStanService",
        type: "tuple",
      },
      { internalType: "address", name: "_beWallet", type: "address" },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_wallet", type: "address" }],
    name: "setBEwallet",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint128",
        name: "_maxCollectionNumber",
        type: "uint128",
      },
    ],
    name: "setMaxCollectionNumber",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint128", name: "_ratioCreatorVal", type: "uint128" },
      {
        internalType: "uint128",
        name: "_ratioCreatorDenomination",
        type: "uint128",
      },
      { internalType: "uint128", name: "_ratioStanVal", type: "uint128" },
      {
        internalType: "uint128",
        name: "_ratioStanDenomination",
        type: "uint128",
      },
    ],
    name: "setRoyaltyFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint128",
            name: "ratioBuyFixedPriceVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioBuyFixedPriceDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferPvPVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferPvPDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferAuctionVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferAuctionDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioFinishAuctionVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioFinishAuctionDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioCancelOfferPvPVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioCancelOfferPvPDenomination",
            type: "uint128",
          },
        ],
        internalType: "struct AuctionStructure.feeStanService",
        name: "_feeStanService",
        type: "tuple",
      },
    ],
    name: "setServiceFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "feeTransferNFTPvP",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feeListingNFTAuction",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feeCancelListingAuction",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feePlaceBidAuction",
            type: "uint256",
          },
          { internalType: "uint256", name: "feeMintingNFT", type: "uint256" },
          {
            internalType: "uint256",
            name: "feeCreateCollection",
            type: "uint256",
          },
        ],
        internalType: "struct AuctionStructure.feeStanFixed",
        name: "_feeStanFixed",
        type: "tuple",
      },
    ],
    name: "setStanFixed",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

// MAINNET ABIs staging

export const ABI_COLLECTION_MAINNET_STAGING = [
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint8", name: "version", type: "uint8" },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "requestId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
      { indexed: false, internalType: "bool", name: "platForm", type: "bool" },
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "STAN_EVENT",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "requestId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "royalty",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "stanFee",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "exchangeWeb",
        type: "uint256",
      },
    ],
    name: "STAN_TX",
    type: "event",
  },
  {
    inputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    name: "_collectionNFT",
    outputs: [
      { internalType: "bytes", name: "id", type: "bytes" },
      { internalType: "uint128", name: "currentNumber", type: "uint128" },
      { internalType: "uint128", name: "maxNumber", type: "uint128" },
      { internalType: "uint128", name: "ratioCreatorVal", type: "uint128" },
      {
        internalType: "uint128",
        name: "ratioCreatorDenomination",
        type: "uint128",
      },
      { internalType: "uint128", name: "ratioStanVal", type: "uint128" },
      {
        internalType: "uint128",
        name: "ratioStanDenomination",
        type: "uint128",
      },
      { internalType: "address", name: "owner", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_idCollection", type: "bytes" },
      { internalType: "address", name: "_creator", type: "address" },
      { internalType: "uint256", name: "_toId", type: "uint256" },
      { internalType: "uint256", name: "_quantity", type: "uint256" },
    ],
    name: "addBatchNFTToCollection",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_idCollection", type: "bytes" },
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
      { internalType: "address", name: "_creator", type: "address" },
      { internalType: "bool", name: "_isWeb", type: "bool" },
    ],
    name: "addNFTtoCollection",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    name: "collectionId",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "config",
    outputs: [{ internalType: "contract IConfig", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_collectionId", type: "bytes" },
      { internalType: "uint128", name: "_ratioCreatorVal", type: "uint128" },
      {
        internalType: "uint128",
        name: "_ratioCreatorDenomination",
        type: "uint128",
      },
      { internalType: "bool", name: "_isWeb", type: "bool" },
    ],
    name: "createCollection",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "address", name: "_to", type: "address" },
      { internalType: "bytes", name: "_collectionId", type: "bytes" },
      { internalType: "bool", name: "_isWeb", type: "bool" },
      {
        internalType: "uint128",
        name: "_ratioCreatorRoyaltyFee",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "_ratioCreatorDenomination",
        type: "uint128",
      },
    ],
    name: "createCollectionByStan",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_idCollection", type: "bytes" },
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
      { internalType: "address", name: "_currentOwnerNFT", type: "address" },
    ],
    name: "getInfoCollection",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "ratioCreatorVal", type: "uint256" },
          {
            internalType: "uint256",
            name: "ratioCreatorDenomination",
            type: "uint256",
          },
          { internalType: "uint256", name: "ratioStanVal", type: "uint256" },
          {
            internalType: "uint256",
            name: "ratioStanDenomination",
            type: "uint256",
          },
          { internalType: "address", name: "creator", type: "address" },
          { internalType: "address", name: "_owner", type: "address" },
          { internalType: "uint256", name: "nft", type: "uint256" },
          { internalType: "address", name: "currentOwnerNFT", type: "address" },
        ],
        internalType: "struct AuctionStructure.infoCollection",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_tokenId", type: "uint256" }],
    name: "getTokenIdToCollectionId",
    outputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_stanNFT", type: "address" },
      { internalType: "address", name: "_config", type: "address" },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_oldIdCollection", type: "bytes" },
      { internalType: "bytes", name: "_newIdCollection", type: "bytes" },
      { internalType: "address", name: "_creator", type: "address" },
      { internalType: "uint256", name: "_quantity", type: "uint256" },
      { internalType: "uint256[]", name: "_tokenIds", type: "uint256[]" },
    ],
    name: "processAddingBatchtoCollection",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_config", type: "address" }],
    name: "setConfig",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes", name: "_idCollection", type: "bytes" }],
    name: "setNumberNftInCollection",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_stanFundAddress", type: "address" },
    ],
    name: "setStanFund",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_stanNFT", type: "address" }],
    name: "setStanNFT",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "stanFund",
    outputs: [
      { internalType: "contract IStanFund", name: "", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "stanNFT",
    outputs: [{ internalType: "contract IStanNFT", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "tokenIdToCollectionId",
    outputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_idCollection", type: "bytes" },
      { internalType: "address", name: "_from", type: "address" },
      { internalType: "address", name: "_to", type: "address" },
    ],
    name: "updateOwnerNFT",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const ABI_NFT_MAINNET_STAGING = [
  { inputs: [], name: "ApprovalCallerNotOwnerNorApproved", type: "error" },
  { inputs: [], name: "ApprovalQueryForNonexistentToken", type: "error" },
  { inputs: [], name: "BalanceQueryForZeroAddress", type: "error" },
  { inputs: [], name: "MintERC2309QuantityExceedsLimit", type: "error" },
  { inputs: [], name: "MintToZeroAddress", type: "error" },
  { inputs: [], name: "MintZeroQuantity", type: "error" },
  { inputs: [], name: "OwnerQueryForNonexistentToken", type: "error" },
  { inputs: [], name: "OwnershipNotInitializedForExtraData", type: "error" },
  { inputs: [], name: "TransferCallerNotOwnerNorApproved", type: "error" },
  { inputs: [], name: "TransferFromIncorrectOwner", type: "error" },
  { inputs: [], name: "TransferToNonERC721ReceiverImplementer", type: "error" },
  { inputs: [], name: "TransferToZeroAddress", type: "error" },
  { inputs: [], name: "URIQueryForNonexistentToken", type: "error" },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "approved",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
      { indexed: false, internalType: "bool", name: "approved", type: "bool" },
    ],
    name: "ApprovalForAll",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "fromTokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "toTokenId",
        type: "uint256",
      },
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
    ],
    name: "ConsecutiveTransfer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint8", name: "version", type: "uint8" },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "requestId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
      { indexed: false, internalType: "bool", name: "platform", type: "bool" },
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "MOVE_BATCH_NFT_STAN_EVENT",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "requestId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
      { indexed: false, internalType: "bool", name: "platform", type: "bool" },
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      { indexed: false, internalType: "bytes", name: "nftIds", type: "bytes" },
    ],
    name: "STAN_EVENT",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "requestId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "royalty",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "stanFee",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "exchangeWeb",
        type: "uint256",
      },
    ],
    name: "STAN_TX",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "approve",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_auctionFixedPrice", type: "address" },
      { internalType: "address", name: "_auctionPure", type: "address" },
      { internalType: "bool", name: "_approved", type: "bool" },
    ],
    name: "approveForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_owner", type: "address" },
      { internalType: "address[]", name: "_operators", type: "address[]" },
      { internalType: "bool", name: "_approved", type: "bool" },
    ],
    name: "approveForAuction",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "auctionAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "baseURI",
    outputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "config",
    outputs: [{ internalType: "contract IConfig", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_collectionId", type: "bytes" },
      { internalType: "uint256", name: "_quantity", type: "uint256" },
      { internalType: "bytes", name: "_nftIds", type: "bytes" },
      { internalType: "bool", name: "_isWeb", type: "bool" },
    ],
    name: "createNFT",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_collectionId", type: "bytes" },
      { internalType: "uint256", name: "_quantity", type: "uint256" },
      { internalType: "address", name: "_to", type: "address" },
      { internalType: "bool", name: "_isWeb", type: "bool" },
    ],
    name: "createNFTByStan",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "getApproved",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_owner", type: "address" },
      { internalType: "address", name: "_operator", type: "address" },
    ],
    name: "getIsApprovedForAll",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_tokenId", type: "uint256" }],
    name: "getPriceNFT",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_tokenId", type: "uint256" }],
    name: "getTokenToListing",
    outputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "string", name: "name_", type: "string" },
      { internalType: "string", name: "symbol_", type: "string" },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_config", type: "address" }],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "operator", type: "address" },
    ],
    name: "isApprovedForAll",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_oldIdCollection", type: "bytes" },
      { internalType: "bytes", name: "_newIdCollection", type: "bytes" },
      { internalType: "address", name: "_creator", type: "address" },
      { internalType: "uint256", name: "_quantity", type: "uint256" },
      { internalType: "uint256[]", name: "_tokenIds", type: "uint256[]" },
      { internalType: "bool", name: "_isWeb", type: "bool" },
    ],
    name: "moveBatchNFTToCollection",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "operator", type: "address" },
      { internalType: "address", name: "from", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
      { internalType: "bytes", name: "data", type: "bytes" },
    ],
    name: "onERC721Received",
    outputs: [{ internalType: "bytes4", name: "", type: "bytes4" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "ownerOf",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
      { internalType: "bytes", name: "_data", type: "bytes" },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "operator", type: "address" },
      { internalType: "bool", name: "approved", type: "bool" },
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address[]", name: "_caller", type: "address[]" },
      { internalType: "bool", name: "_allowed", type: "bool" },
    ],
    name: "setAuthorizers",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_collectionNFTAdrress",
        type: "address",
      },
    ],
    name: "setCollectionNFT",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_config", type: "address" }],
    name: "setConfig",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
    ],
    name: "setPriceNFT",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_stanFundAddress", type: "address" },
    ],
    name: "setStanFund",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "stanFund",
    outputs: [
      { internalType: "contract IStanFund", name: "", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
    name: "supportsInterface",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "tokenURI",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_from", type: "address" },
      { internalType: "address", name: "_to", type: "address" },
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
    ],
    name: "updateOwnerNFTAndTransferNFT",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_listing", type: "bytes" },
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
    ],
    name: "updateTokenToListing",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const ABI_AUCTION_MAINNET_STAGING = [
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint8", name: "version", type: "uint8" },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "requestId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "enum AuctionStructure.Currency",
        name: "currency",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "STAN_EVENT",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "requestId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "royalty",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "stanFee",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "exchangeWeb",
        type: "uint256",
      },
    ],
    name: "STAN_TX",
    type: "event",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_auctionId", type: "bytes" },
      { internalType: "bytes", name: "_subOfferId", type: "bytes" },
      { internalType: "bytes", name: "_nftId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "acceptOfferAuction",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_nftId", type: "bytes" },
      { internalType: "bytes", name: "_subOfferId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "acceptOfferPvP",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "auctionFixedPrice",
    outputs: [
      {
        internalType: "contract IAuctionFixedPrice",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "auctionFixedPriceAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "auctionPure",
    outputs: [
      { internalType: "contract IAuctionPure", name: "", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "auctionPureAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "address", name: "_seller", type: "address" },
      { internalType: "address", name: "_maker", type: "address" },
      { internalType: "bytes", name: "_listingId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "buyFixPrice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_listingId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "cancelListingAuction",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_listingId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "cancelListingFixedPrice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_auctionId", type: "bytes" },
      { internalType: "bytes", name: "_subOfferId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "cancelOfferAuction",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_nftId", type: "bytes" },
      { internalType: "bytes", name: "_subOfferId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "cancelOfferPvP",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "config",
    outputs: [{ internalType: "contract IConfig", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes[]", name: "_listingIds", type: "bytes[]" },
      { internalType: "bool", name: "_isAuction", type: "bool" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "expiredListing",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_indexId", type: "bytes" },
      { internalType: "bytes[]", name: "_subOffersIdParam", type: "bytes[]" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "expiredOffer",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_auctionId", type: "bytes" },
      { internalType: "bytes", name: "_nftId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "finishAuction",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_auctionFixedPrice", type: "address" },
      { internalType: "address", name: "_auctionPure", type: "address" },
      { internalType: "address", name: "_config", type: "address" },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_auctionId", type: "bytes" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
      { internalType: "uint256", name: "_expirationTime", type: "uint256" },
      { internalType: "address", name: "_maker", type: "address" },
      { internalType: "bytes", name: "_nftId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "listingNFTAuction",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_listingId", type: "bytes" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
      { internalType: "uint256", name: "_expirationTime", type: "uint256" },
      { internalType: "address", name: "_maker", type: "address" },
      { internalType: "bytes", name: "_nftId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "listingNFTFixedPrice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_subOfferId", type: "bytes" },
      { internalType: "bytes", name: "_nftID", type: "bytes" },
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
      { internalType: "uint256", name: "_expirationTime", type: "uint256" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "address", name: "_maker", type: "address" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "makeOfferFixedPrice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "bytes", name: "_subOfferId", type: "bytes" },
      { internalType: "bytes", name: "_auctionId", type: "bytes" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "address", name: "_maker", type: "address" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "placeBidAuction",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_auctionFixedPrice", type: "address" },
    ],
    name: "setAuctionFixedPrice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_auctionPure", type: "address" },
    ],
    name: "setAuctionPure",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_config", type: "address" }],
    name: "setConfig",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_user", type: "address" }],
    name: "setWhileList",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "stanFund",
    outputs: [
      { internalType: "contract IStanFund", name: "", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "stanFundAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenStanAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "address", name: "_receiver", type: "address" },
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
      { internalType: "address", name: "_maker", type: "address" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "transferNFTPvP",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const ABI_STAN_TOKEN_MAINNET_STAGING = [
  {
    inputs: [
      { internalType: "address", name: "childChainManager", type: "address" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "userAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address payable",
        name: "relayerAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "functionSignature",
        type: "bytes",
      },
    ],
    name: "MetaTransactionExecuted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
      {
        indexed: true,
        internalType: "bytes32",
        name: "previousAdminRole",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "newAdminRole",
        type: "bytes32",
      },
    ],
    name: "RoleAdminChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "RoleGranted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "RoleRevoked",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [],
    name: "CHILD_CHAIN_ID",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "CHILD_CHAIN_ID_BYTES",
    outputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "DEFAULT_ADMIN_ROLE",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "DEPOSITOR_ROLE",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ERC712_VERSION",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ROOT_CHAIN_ID",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ROOT_CHAIN_ID_BYTES",
    outputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "spender", type: "address" },
    ],
    name: "allowance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "subtractedValue", type: "uint256" },
    ],
    name: "decreaseAllowance",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "user", type: "address" },
      { internalType: "bytes", name: "depositData", type: "bytes" },
    ],
    name: "deposit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "userAddress", type: "address" },
      { internalType: "bytes", name: "functionSignature", type: "bytes" },
      { internalType: "bytes32", name: "sigR", type: "bytes32" },
      { internalType: "bytes32", name: "sigS", type: "bytes32" },
      { internalType: "uint8", name: "sigV", type: "uint8" },
    ],
    name: "executeMetaTransaction",
    outputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "getChainId",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [],
    name: "getDomainSeperator",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "user", type: "address" }],
    name: "getNonce",
    outputs: [{ internalType: "uint256", name: "nonce", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "role", type: "bytes32" }],
    name: "getRoleAdmin",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "uint256", name: "index", type: "uint256" },
    ],
    name: "getRoleMember",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "role", type: "bytes32" }],
    name: "getRoleMemberCount",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "grantRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "hasRole",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "addedValue", type: "uint256" },
    ],
    name: "increaseAllowance",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "renounceRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "revokeRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "sender", type: "address" },
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const ABI_STAN_FUND_MAINNET_STAGING = [
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint8", name: "version", type: "uint8" },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "requestId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "STAN_EVENT",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "_totalSupply",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_userSupply",
        type: "uint256",
      },
    ],
    name: "STAN_WARNING",
    type: "event",
  },
  {
    inputs: [],
    name: "ability",
    outputs: [
      { internalType: "uint256", name: "val", type: "uint256" },
      { internalType: "uint256", name: "valDenomination", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "auctionAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "deposit",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "depositByStan",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_user", type: "address" }],
    name: "get",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "userStanFund", type: "uint256" },
          { internalType: "bool", name: "result", type: "bool" },
        ],
        internalType: "struct AuctionStructure.stanFundParams",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getInforStanFund",
    outputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          { internalType: "address", name: "maker", type: "address" },
          { internalType: "uint256", name: "bidnumber", type: "uint256" },
        ],
        internalType: "struct AuctionStructure.userFund[]",
        name: "_users",
        type: "tuple[]",
      },
    ],
    name: "handleBackFeeToUser",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_collectionNFT", type: "address" },
      { internalType: "address", name: "_config", type: "address" },
      { internalType: "address", name: "_admin", type: "address" },
      { internalType: "uint256", name: "_val", type: "uint256" },
      { internalType: "uint256", name: "_valDenomination", type: "uint256" },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          { internalType: "address", name: "seller", type: "address" },
          { internalType: "address", name: "buyer", type: "address" },
          { internalType: "uint256", name: "amount", type: "uint256" },
          { internalType: "uint256", name: "feeStanVal", type: "uint256" },
          {
            internalType: "uint256",
            name: "feeStanValDenomination",
            type: "uint256",
          },
          { internalType: "uint256", name: "tokenId", type: "uint256" },
          {
            internalType: "enum AuctionStructure.Method",
            name: "method",
            type: "uint8",
          },
          {
            internalType: "enum AuctionStructure.MethodToPayment",
            name: "methodToPayment",
            type: "uint8",
          },
        ],
        internalType: "struct AuctionStructure.puchasing",
        name: "params",
        type: "tuple",
      },
    ],
    name: "purchaseProcessing",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "royalty", type: "uint256" },
          { internalType: "uint256", name: "stanFee", type: "uint256" },
          { internalType: "uint256", name: "exchangeWeb", type: "uint256" },
        ],
        internalType: "struct AuctionStructure.totalFee",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_amount", type: "uint256" },
      {
        internalType: "enum AuctionStructure.Operator",
        name: "_operator",
        type: "uint8",
      },
      { internalType: "address", name: "_user", type: "address" },
    ],
    name: "set",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_val", type: "uint256" },
      { internalType: "uint256", name: "_valDenomination", type: "uint256" },
    ],
    name: "setAbilityToWithdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_stanAdmin", type: "address" }],
    name: "setAdmin",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address[]", name: "_authorizers", type: "address[]" },
      { internalType: "bool", name: "_allowed", type: "bool" },
    ],
    name: "setAuthorizers",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_collectionNFT", type: "address" },
    ],
    name: "setCollection",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_config", type: "address" }],
    name: "setConfig",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_amount", type: "uint256" },
      {
        internalType: "enum AuctionStructure.Operator",
        name: "_operator",
        type: "uint8",
      },
    ],
    name: "setSupply",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "stanAdmin",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "stanFund",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "test",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "userSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes", name: "_requestId", type: "bytes" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      {
        internalType: "enum AuctionStructure.Currency",
        name: "_currency",
        type: "uint8",
      },
    ],
    name: "withdrawByStan",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  { stateMutability: "payable", type: "receive" },
];

export const ABI_STAN_CONFIG_MAINNET_STAGING = [
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint8", name: "version", type: "uint8" },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "enum AuctionLibrary.FunctionName",
        name: "nameFunction",
        type: "uint8",
      },
    ],
    name: "STAN_EVENT",
    type: "event",
  },
  {
    inputs: [],
    name: "feeStanFixed",
    outputs: [
      { internalType: "uint256", name: "feeTransferNFTPvP", type: "uint256" },
      {
        internalType: "uint256",
        name: "feeListingNFTAuction",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "feeCancelListingAuction",
        type: "uint256",
      },
      { internalType: "uint256", name: "feePlaceBidAuction", type: "uint256" },
      { internalType: "uint256", name: "feeMintingNFT", type: "uint256" },
      { internalType: "uint256", name: "feeCreateCollection", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "feeStanService",
    outputs: [
      {
        internalType: "uint128",
        name: "ratioBuyFixedPriceVal",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioBuyFixedPriceDenomination",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioAcceptOfferPvPVal",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioAcceptOfferPvPDenomination",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioAcceptOfferAuctionVal",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioAcceptOfferAuctionDenomination",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioFinishAuctionVal",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioFinishAuctionDenomination",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioCancelOfferPvPVal",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "ratioCancelOfferPvPDenomination",
        type: "uint128",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "feeStanSystem",
    outputs: [
      { internalType: "uint128", name: "ratioCreatorVal", type: "uint128" },
      {
        internalType: "uint128",
        name: "ratioCreatorDenomination",
        type: "uint128",
      },
      { internalType: "uint128", name: "ratioStanVal", type: "uint128" },
      {
        internalType: "uint128",
        name: "ratioStanDenomination",
        type: "uint128",
      },
      { internalType: "uint128", name: "maxCollectionNumber", type: "uint128" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getFeeStanFixed",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "feeTransferNFTPvP",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feeListingNFTAuction",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feeCancelListingAuction",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feePlaceBidAuction",
            type: "uint256",
          },
          { internalType: "uint256", name: "feeMintingNFT", type: "uint256" },
          {
            internalType: "uint256",
            name: "feeCreateCollection",
            type: "uint256",
          },
        ],
        internalType: "struct AuctionStructure.feeStanFixed",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getFeeStanService",
    outputs: [
      {
        components: [
          {
            internalType: "uint128",
            name: "ratioBuyFixedPriceVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioBuyFixedPriceDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferPvPVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferPvPDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferAuctionVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferAuctionDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioFinishAuctionVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioFinishAuctionDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioCancelOfferPvPVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioCancelOfferPvPDenomination",
            type: "uint128",
          },
        ],
        internalType: "struct AuctionStructure.feeStanService",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getFeeStanSystem",
    outputs: [
      {
        components: [
          { internalType: "uint128", name: "ratioCreatorVal", type: "uint128" },
          {
            internalType: "uint128",
            name: "ratioCreatorDenomination",
            type: "uint128",
          },
          { internalType: "uint128", name: "ratioStanVal", type: "uint128" },
          {
            internalType: "uint128",
            name: "ratioStanDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "maxCollectionNumber",
            type: "uint128",
          },
        ],
        internalType: "struct AuctionStructure.feeStanSystem",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          { internalType: "uint128", name: "ratioCreatorVal", type: "uint128" },
          {
            internalType: "uint128",
            name: "ratioCreatorDenomination",
            type: "uint128",
          },
          { internalType: "uint128", name: "ratioStanVal", type: "uint128" },
          {
            internalType: "uint128",
            name: "ratioStanDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "maxCollectionNumber",
            type: "uint128",
          },
        ],
        internalType: "struct AuctionStructure.feeStanSystem",
        name: "_feeStanSystem",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "uint256",
            name: "feeTransferNFTPvP",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feeListingNFTAuction",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feeCancelListingAuction",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feePlaceBidAuction",
            type: "uint256",
          },
          { internalType: "uint256", name: "feeMintingNFT", type: "uint256" },
          {
            internalType: "uint256",
            name: "feeCreateCollection",
            type: "uint256",
          },
        ],
        internalType: "struct AuctionStructure.feeStanFixed",
        name: "_feeStanFixed",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "uint128",
            name: "ratioBuyFixedPriceVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioBuyFixedPriceDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferPvPVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferPvPDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferAuctionVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferAuctionDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioFinishAuctionVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioFinishAuctionDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioCancelOfferPvPVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioCancelOfferPvPDenomination",
            type: "uint128",
          },
        ],
        internalType: "struct AuctionStructure.feeStanService",
        name: "_feeStanService",
        type: "tuple",
      },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint128",
        name: "_maxCollectionNumber",
        type: "uint128",
      },
    ],
    name: "setMaxCollectionNumber",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint128", name: "_ratioCreatorVal", type: "uint128" },
      {
        internalType: "uint128",
        name: "_ratioCreatorDenomination",
        type: "uint128",
      },
      { internalType: "uint128", name: "_ratioStanVal", type: "uint128" },
      {
        internalType: "uint128",
        name: "_ratioStanDenomination",
        type: "uint128",
      },
    ],
    name: "setRoyaltyFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint128",
            name: "ratioBuyFixedPriceVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioBuyFixedPriceDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferPvPVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferPvPDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferAuctionVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioAcceptOfferAuctionDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioFinishAuctionVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioFinishAuctionDenomination",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioCancelOfferPvPVal",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "ratioCancelOfferPvPDenomination",
            type: "uint128",
          },
        ],
        internalType: "struct AuctionStructure.feeStanService",
        name: "_feeStanService",
        type: "tuple",
      },
    ],
    name: "setServiceFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "feeTransferNFTPvP",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feeListingNFTAuction",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feeCancelListingAuction",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "feePlaceBidAuction",
            type: "uint256",
          },
          { internalType: "uint256", name: "feeMintingNFT", type: "uint256" },
          {
            internalType: "uint256",
            name: "feeCreateCollection",
            type: "uint256",
          },
        ],
        internalType: "struct AuctionStructure.feeStanFixed",
        name: "_feeStanFixed",
        type: "tuple",
      },
    ],
    name: "setStanFixed",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];
