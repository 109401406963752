import { ArrowDownOutlined } from "@ant-design/icons";
import { BackTop, Drawer } from "antd";
import branch from "branch-sdk";
import Cookies from "js-cookie";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { smallBannerStanWhite } from "../../common/commonSvg";
import StanButton from "../../component/StanButton/StanButton";
import StanTypo from "../../component/StanTypo/StanTypo";
import { CLOSE_QR_DRAWER } from "../../redux/types/ModalType";
import { userService } from "../../services/UserService";
import { DEEP_LINK_KEY } from "../../util/settings/config";
import Footer from "./Layout/Footer/Footer";
import Header from "./Layout/Header/Header";

export const HomeTemplate = (props) => {
  const { t } = useTranslation(["common", "qr"]);
  const dispatch = useDispatch();
  const { Component, ...restProps } = props;
  const { openQRDrawer } = useSelector((state) => state.ModalReducer);
  const [qrCode, setQrCode] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    branch.init(DEEP_LINK_KEY, function (err, data) {
      // console.log(err, data);
    });
  }, []);

  async function getQrCode() {
    const result = await userService.verifyMessage({
      walletAddress: Cookies.get("UserAddress"),
      signedMessage: Cookies.get("SignedMessage"),
    });
    setQrCode(result.data.data.qrToken);
  }

  useEffect(() => {
    if (openQRDrawer) {
      getQrCode();
    }
  }, [openQRDrawer]);

  function createDeepLink() {
    var linkData = {
      data: {
        data: JSON.stringify({
          content: qrCode,
          type: "nft_qr_token",
        }),
        $og_title: "STAN",
        $og_description: "STAN Marketplace",
        $og_image_url:
          "https://stan.win/wp-content/uploads/2021/08/stan-App-Icon500px-150x150.jpg",
      },
    };
    branch.link(linkData, function (err, link) {
      console.log(link);
      window.location.href = link;
    });
  }

  const onClose = () => {
    dispatch({ type: CLOSE_QR_DRAWER });
  };

  const style = {
    height: 40,
    width: 100,
    zIndex: -11,
    lineHeight: "40px",
    borderRadius: 4,
    backgroundColor: "#49D19A",
    color: "#fff",
    textAlign: "center",
    fontSize: 14,
    position: "fixed",
    right: "1px",
  };

  function renderDrawer() {
    return (
      <Drawer
        title={t("QR_AUTHENTICATION", { ns: "qr" })}
        placement="right"
        onClose={onClose}
        visible={openQRDrawer}
      >
        {Cookies.get("DEVICE") !== "MOBILE" ? (
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center mb-2">
              <div
                style={{
                  padding: "5px 10px",
                  border: "1px solid #A4E8CC",
                  borderRadius: "5px",
                  marginRight: "12px",
                }}
              >
                <span>1</span>
              </div>
              <StanTypo
                color="#000000"
                fontWeight={400}
                fontSize={16}
                content={t("LOGIN_STEPS.STEP_1", { ns: "qr" })}
              />
            </div>
            <div className="d-flex align-items-center mb-2">
              <div
                style={{
                  padding: "5px 10px",
                  border: "1px solid #A4E8CC",
                  borderRadius: "5px",
                  marginRight: "12px",
                }}
              >
                <span>2</span>
              </div>
              <StanTypo
                color="#000000"
                fontWeight={400}
                fontSize={16}
                content={t("LOGIN_STEPS.STEP_2", { ns: "qr" })}
              />
            </div>
            <div className="d-flex align-items-center">
              <div
                style={{
                  padding: "5px 10px",
                  border: "1px solid #A4E8CC",
                  borderRadius: "5px",
                  marginRight: "12px",
                }}
              >
                <span>3</span>
              </div>
              <StanTypo
                color="#000000"
                fontWeight={400}
                fontSize={16}
                content={t("LOGIN_STEPS.STEP_3", { ns: "qr" })}
              />
            </div>
            <div className="text-center mt-4">
              <QRCode value={qrCode} />
              <div className="mt-4">
                <StanButton
                  type="primary"
                  title={t("TITLE.RELOAD", { ns: "qr" })}
                  handleOnClick={() => getQrCode()}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center">
              <div
                style={{
                  padding: "5px 10px",
                  border: "1px solid #A4E8CC",
                  borderRadius: "5px",
                  marginRight: "12px",
                }}
              >
                <span>1</span>
              </div>
              <StanTypo
                color="#000000"
                fontWeight={400}
                fontSize={16}
                content={t("REDIRECT_STEPS.STEP_1", { ns: "qr" })}
              />
              <ArrowDownOutlined />
            </div>
            <div className="d-flex align-items-center mt-2">
              <div
                style={{
                  padding: "5px 10px",
                  border: "1px solid #A4E8CC",
                  borderRadius: "5px",
                  marginRight: "12px",
                }}
              >
                <span>2</span>
              </div>
              <StanTypo
                color="#000000"
                fontWeight={400}
                fontSize={16}
                content={t("REDIRECT_STEPS.STEP_2", { ns: "qr" })}
              />
            </div>
            <div className="text-center mt-4">
              <QRCode value={qrCode} onClick={() => createDeepLink()} />
              <div className="mt-4">
                <StanButton
                  type="primary"
                  title={t("TITLE.RELOAD", { ns: "qr" })}
                  handleOnClick={() => getQrCode()}
                />
              </div>
            </div>
          </div>
        )}
      </Drawer>
    );
  }

  return (
    <Route
      {...restProps}
      render={(propsRoute) => {
        return (
          <Fragment>
            <Header {...propsRoute}></Header>

            <Component {...propsRoute} />

            <Footer></Footer>
            {renderDrawer()}
            <BackTop visibilityHeight={800}>
              <div style={style}>{smallBannerStanWhite()}</div>
            </BackTop>
          </Fragment>
        );
      }}
    />
  );
};
