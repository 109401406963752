import Axios from "axios";
import { openNotificationWithIcon } from "../App";
const {DOMAIN, TOKEN_COOKIE} = require('../util/settings/config')
export class baseService {

  put = async (url, model) => {
    return Axios({
      url: `${DOMAIN}/${url}`,
      method: "PUT",
      data: model,
      headers: {
        Authorization: "Bearer " + TOKEN_COOKIE,
      },
    }).catch(function(error) {
      console.log("Errror =>", error)
    });
  };

  postWithoutToken = (url, model) => {
    return Axios({
      url: `${DOMAIN}/${url}`,
      method: "POST",
      data: model,
    });
  };

  post = (url, model) => {
    return Axios({
      url: `${DOMAIN}/${url}`,
      method: "POST",
      data: model,
      headers: {
        Authorization: "Bearer " + TOKEN_COOKIE,
      },
    });
  };

  get = (url) => {
    return Axios({
      url: `${DOMAIN}/${url}`,
      method: "GET",
      headers: {
        Authorization: "Bearer " + TOKEN_COOKIE,
      },
    }).catch(function(error) {
      console.log("Error =>", error)
      openNotificationWithIcon(
        "error",
        "Warning",
        "Something is wrong! Please try again!"
      );
    });
  };

  delete = (url) => {
    return Axios({
      url: `${DOMAIN}/${url}`,
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + TOKEN_COOKIE,
      },
    });
  };

  patch = async (url) => {
    return Axios({
      url: `${DOMAIN}/${url}`,
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + TOKEN_COOKIE,
      },
    }).catch(function(error) {
      console.log("Errror =>", error)
    });
  };
}
