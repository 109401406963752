import { Button, Input, message, Modal, Upload, Image, Row, Col } from "antd";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { history, openNotificationWithIcon } from "../../App";
import {
  facebookSvg,
  instaSvg,
  stanPointSvg,
  tiktokSvg,
  twitterSvg,
  uploadSvg,
  youtubeSvg,
} from "../../common/commonSvg";
import StanButton from "../../component/StanButton/StanButton";
import { updateCollection } from "../../redux/action/CollectionAction";
import { getPresignMedia } from "../../redux/action/UserAction";
import {
  CLEAR_STATE,
  REMOVE_BANNER,
  REMOVE_LOGO,
} from "../../redux/types/UserType";
import { isValidType } from "../../util/settings/utils";
import { getStanRoyalty } from "../../redux/action/AutionAction";
import { collectionService } from "../../services/collectionService";
import { useParams } from "react-router-dom";
const { TextArea } = Input;

export default function EditCollection() {
  // ______________STATE INITIAL______________ //
  const { t } = useTranslation(["common", "collection", "components", "nft"]);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const { collection } = useSelector((state) => state.CollectionReducer);
  const { logoPath, bannerPath } = useSelector((state) => state.UserReducer);
  const { stanRoyalty, nftStatus } = useSelector(
    (state) => state.AutionReducer
  );
  const [creatorRoyalty, setCreatorRoyalty] = useState(
    +stanRoyalty.ratioCreatorVal
  );
  const params = useParams();

  const [fileListAvt, setFileListAvt] = useState([
    {
      uid: "1",
      name: collection.name + "'s Logo",
      status: "done",
      url: collection.imageLogo,
    },
  ]);
  const [fileListBanner, setFileListBanner] = useState([
    {
      uid: "2",
      name: collection.name + "'s Banner",
      status: "done",
      url: collection.imageBanner !== "" ? collection.imageBanner : "",
    },
  ]);

  const [dataSend, setDataSend] = useState(collection);

  // ______________STATE INITIAL______________ //

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getStanRoyalty());
    if (!Cookies.get("UserAddress")) {
      openNotificationWithIcon("error", "Error", t("MSG.NOT_CONNECT_WALLET"));
      history.push("/home");
      window.location.reload();
    }
    if (collection.ownerWalletAddress !== Cookies.get("UserAddress")) {
      openNotificationWithIcon("error", "Error", t("MSG.NOT_OWNER"));
      history.push("/home");
    }
  }, []);

  useEffect(() => {
    if (logoPath !== "") {
      setDataSend({
        ...dataSend,
        imageLogo: logoPath === "" ? collection.imageLogo : logoPath,
      });
    }
    if (bannerPath !== "") {
      setDataSend({
        ...dataSend,
        imageBanner: bannerPath === "" ? collection.imageBanner : bannerPath,
      });
    }
  }, [logoPath, bannerPath]);

  useEffect(() => {
    if (fileListAvt.length === 0) {
      setDataSend({ ...dataSend, imageLogo: "" });
    }
    if (fileListBanner.length === 0) {
      setDataSend({ ...dataSend, imageBanner: "" });
    }
  }, [fileListAvt, fileListBanner]);

  useEffect(async () => {
    if (params?.collectionId) {
      const detail = await collectionService.getDetailCollection(
        params?.collectionId
      );
      if (detail?.data?.data?.creatorRoyalty) {
        setCreatorRoyalty(detail?.data?.data?.creatorRoyalty);
      }
    }
  }, [params?.collectionId]);

  // _________________________HANDLE UPLOAD_________________________ //

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);

      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const onChangeAvt = async ({ fileList: newFileList }) => {
    const isLt2M = newFileList[0].size / 1024 / 1024 < 10;
    if (isValidType(newFileList[0].type) && isLt2M) {
      if (!newFileList[0].url && !newFileList[0].preview) {
        newFileList[0].preview = await getBase64(newFileList[0].originFileObj);
      }
      setFileListAvt([
        {
          uid: "1",
          name: newFileList[0].name,
          status: "done",
          url: newFileList[0].preview,
        },
      ]);
    }
  };

  const onChangeBanner = async ({ fileList: newFileList }) => {
    if (isValidType(newFileList[0].type)) {
      if (!newFileList[0].url && !newFileList[0].preview) {
        newFileList[0].preview = await getBase64(newFileList[0].originFileObj);
      }
      setFileListBanner([
        {
          uid: "1",
          name: newFileList[0].name,
          status: "done",
          url: newFileList[0].preview,
        },
      ]);
    }
  };

  const beforeUploadAvt = (file) => {
    if (!isValidType(file.type)) {
      message.error(t("MSG.UNSUPPORTED_FILE"), 5);
    }

    const isLt2M = file.size / 1024 / 1024 < 10;

    if (!isLt2M) {
      message.error(t("MSG.TOO_LARGE_IMAGE"));
    }

    if (isValidType(file.type) && isLt2M) {
      dispatch(getPresignMedia("collections-nft", "logo", file));
    }

    return isValidType(file.type) && isLt2M;
  };

  const beforeUploadBanner = (file) => {
    if (!isValidType(file.type)) {
      message.error(t("MSG.UNSUPPORTED_FILE"), 5);
    }

    const isLt2M = file.size / 1024 / 1024 < 20;

    if (!isLt2M) {
      message.error(t("MSG.TOO_LARGE_IMAGE"));
    }

    if (isValidType(file.type) && isLt2M) {
      dispatch(getPresignMedia("collections-nft", "banner", file));
    }

    return isValidType(file.type) && isLt2M;
  };

  const handleCancel = () => setPreviewVisible(false);

  // _________________________HANDLE UPLOAD_________________________ //

  function handleChangeInput(e) {
    const value = e.target.value;
    if (
      e.target.name === "TWITTER" ||
      e.target.name === "INSTAGRAM" ||
      e.target.name === "FACEBOOK" ||
      e.target.name === "YOUTUBE" ||
      e.target.name === "TIKTOK"
    ) {
      let socialMedia = dataSend.socialLink;
      const myRowIndex = socialMedia.findIndex(
        (row) => row.type === e.target.name
      );
      if (myRowIndex < 0) {
        socialMedia = [...socialMedia, { type: e.target.name, url: value }];
        setDataSend({ ...dataSend, socialLink: socialMedia });
      } else {
        socialMedia[myRowIndex].url = value;
        setDataSend({ ...dataSend, socialLink: socialMedia });
      }
    } else {
      setDataSend({ ...dataSend, [e.target.name]: value });
    }
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  function validate() {
    let isValid = true;
    let error = {};
    var regexURL = /^(https?):\/\/[^\s$.?#].[^\s]*$/;
    if (dataSend.name === "" || dataSend.name.trim() === "") {
      error["name"] = t("MSG.NOT_NULL", { name: "The Name" });
      isValid = false;
    }
    if (dataSend.imageLogo === "") {
      error["imageLogo"] = t("MSG.REQUIRED", { name: "Logo image" });
      isValid = false;
    }
    if (
      dataSend.socialLink[0].url &&
      !dataSend.socialLink[0].url.match(regexURL)
    ) {
      error["twitter"] = t("MSG.INVALID_URL");
      isValid = false;
    }
    if (
      dataSend.socialLink[1].url &&
      !dataSend.socialLink[1].url.match(regexURL)
    ) {
      error["instagram"] = t("MSG.INVALID_URL");
      isValid = false;
    }
    if (
      dataSend.socialLink[2].url &&
      !dataSend.socialLink[2].url.match(regexURL)
    ) {
      error["facebook"] = t("MSG.INVALID_URL");
      isValid = false;
    }
    if (
      dataSend.socialLink[3].url &&
      !dataSend.socialLink[3].url.match(regexURL)
    ) {
      error["youtube"] = t("MSG.INVALID_URL");
      isValid = false;
    }
    if (
      dataSend.socialLink[4].url &&
      !dataSend.socialLink[4].url.match(regexURL)
    ) {
      error["tiktok"] = t("MSG.INVALID_URL");
      isValid = false;
    }
    setErrors(error);
    return isValid;
  }

  function editCollection() {
    if (validate()) {
      dispatch(updateCollection(dataSend));
    }
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#FFFFFF",
          minHeight: "100vh",
          padding: "64px 360px",
        }}
        className="updateCollection"
      >
        <div>
          <h1>{t("TITLE_EDIT", { ns: "collection" })}</h1>
        </div>
        <div style={{ display: "inline-grid" }}>
          <span>{t("LOGO_IMAGE", { ns: "collection" })}</span>
          <span>{t("IMAGE_DESCRIPTION", { ns: "collection" })}</span>
          <span style={{ color: "red" }}>{errors.imageLogo}</span>
        </div>
        <div className="logoImage">
          <Upload
            listType="picture-card"
            fileList={fileListAvt}
            onChange={onChangeAvt}
            onPreview={handlePreview}
            onRemove={() => {
              setFileListAvt([]);
              dispatch({ type: REMOVE_LOGO });
            }}
            customRequest={dummyRequest}
            beforeUpload={beforeUploadAvt}
            style={{ width: "500px" }}
          >
            {fileListAvt.length === 0 ? uploadSvg() : null}
          </Upload>
        </div>
        <div style={{ display: "inline-grid" }}>
          <span>{t("BANNER_IMAGE", { ns: "collection" })}</span>
          <span>{t("BANNER_DESCRIPTION", { ns: "collection" })}</span>
        </div>
        <div className="bannerImage">
          <Upload
            listType="picture-card"
            fileList={fileListBanner}
            onChange={onChangeBanner}
            onPreview={handlePreview}
            onRemove={() => {
              setFileListBanner([]);
              dispatch({ type: REMOVE_BANNER });
            }}
            customRequest={dummyRequest}
            beforeUpload={beforeUploadBanner}
            style={{ width: "500px" }}
          >
            {fileListBanner.length === 0 ? uploadSvg() : null}
          </Upload>
        </div>
        <div className="collectionInput">
          <span>{t("NAME", { ns: "collection" })}</span>
          <Input
            placeholder={t("PLACEHOLDER.NAME", { ns: "collection" })}
            name="name"
            size="large"
            value={dataSend.name}
            maxLength={50}
            onChange={(e) => handleChangeInput(e)}
          />
          <span style={{ color: "red" }}>{errors.name}</span>
        </div>
        <div className="collectionInput">
          <span>{t("DESCRIPTION", { ns: "collection" })}</span>
          <TextArea
            rows={4}
            maxLength={2000}
            name="description"
            value={dataSend.description}
            onChange={(e) => handleChangeInput(e)}
          />
        </div>
        <div className="collectionInput">
          <span>{t("SOCIAL_LINK", { ns: "collection" })}</span>
          <Input
            size="large"
            name="TWITTER"
            value={dataSend.socialLink[0].url}
            onChange={(e) => handleChangeInput(e)}
            placeholder="https://www.abcdfer.com/abcdefghjk"
            style={{ color: "#ffffff", margin: "8px 0px" }}
            prefix={
              <div style={{ padding: "0 12px", borderRight: "1px solid grey" }}>
                {twitterSvg(null, true)}
              </div>
            }
          />
          <span style={{ color: "red" }}>{errors.twitter}</span>
          <Input
            size="large"
            name="INSTAGRAM"
            value={dataSend.socialLink[1].url}
            onChange={(e) => handleChangeInput(e)}
            placeholder="https://www.abcdfer.com/abcdefghjk"
            style={{ color: "#ffffff", margin: "8px 0px" }}
            prefix={
              <div style={{ padding: "0 10px", borderRight: "1px solid grey" }}>
                {instaSvg(null, true)}
              </div>
            }
          />
          <span style={{ color: "red" }}>{errors.instagram}</span>

          <Input
            size="large"
            name="FACEBOOK"
            value={dataSend.socialLink[2].url}
            onChange={(e) => handleChangeInput(e)}
            placeholder="https://www.abcdfer.com/abcdefghjk"
            style={{ color: "#ffffff", margin: "8px 0px" }}
            prefix={
              <div style={{ padding: "0 10px", borderRight: "1px solid grey" }}>
                {facebookSvg(null, true)}
              </div>
            }
          />
          <span style={{ color: "red" }}>{errors.facebook}</span>

          <Input
            size="large"
            name="YOUTUBE"
            value={dataSend.socialLink[3].url}
            onChange={(e) => handleChangeInput(e)}
            placeholder="https://www.abcdfer.com/abcdefghjk"
            style={{ color: "#ffffff", margin: "8px 0px" }}
            prefix={
              <div style={{ padding: "0 10px", borderRight: "1px solid grey" }}>
                {youtubeSvg(null, true)}
              </div>
            }
          />
          <span style={{ color: "red" }}>{errors.youtube}</span>

          <Input
            size="large"
            name="TIKTOK"
            value={dataSend.socialLink[4].url}
            onChange={(e) => handleChangeInput(e)}
            placeholder="https://www.abcdfer.com/abcdefghjk"
            style={{ color: "#ffffff", margin: "8px 0px" }}
            prefix={
              <div style={{ padding: "0 10px", borderRight: "1px solid grey" }}>
                {tiktokSvg(null, true)}
              </div>
            }
          />
          <span style={{ color: "red" }}>{errors.tiktok}</span>
        </div>

        {stanRoyalty?.ratioCreatorVal > 0 ? (
          <div className="mb-3">
            <div className="mb-3">
              <span style={{ fontWeight: "bold" }}>
                {t("TITLE.FEE_SUMMARY", { ns: "nft" })}
              </span>
            </div>
            <Row gutter={16}>
              <Col lg={8} md={8} xs={24}>
                <div className="d-flex justify-content-between">
                  <span style={{ fontWeight: "bold" }}>
                    {t("TITLE.ROYALTY_FEE", { ns: "nft" })}
                  </span>
                  <span>{+creatorRoyalty + +stanRoyalty.ratioStanVal}%</span>
                </div>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col lg={8} md={8} xs={24}>
                <div className="d-flex justify-content-between">
                  <span>{t("TEXT.STAN_ROYALTY", { ns: "nft" })}</span>
                  <span>{+stanRoyalty.ratioStanVal}%</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span>{t("TEXT.CREATOR_ROYALTY", { ns: "nft" })}</span>
                  <span>{creatorRoyalty}%</span>
                </div>
              </Col>
            </Row>
          </div>
        ) : null}

        <div
          className="row"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div style={{ width: "25%", margin: "0px 5px" }}>
            <StanButton
              title={t("CANCEL", { ns: "collection" })}
              handleOnClick={() => {
                dispatch({ type: CLEAR_STATE });
                history.push("/collections");
              }}
              type="secondary"
            />
          </div>
          <div style={{ width: "25%", margin: "0px 5px" }}>
            <StanButton
              title={t("SAVE", { ns: "collection" })}
              handleOnClick={() => editCollection()}
              type="primary"
            />
          </div>
        </div>
      </div>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt={t("ALT_IMAGE_EXAMPLE", { ns: "components" })}
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
}
