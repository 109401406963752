import { baseService } from "./baseService";

export class AutionService extends baseService {
  constructor() {
    super();
  }

  makeOffer = (data) => {
    return this.post(`web/offer`, data);
  };

  getOffer = (id, pageSize, page) => {
    return this.get(
      `web/offer/list?nftId=${id}&limit=${pageSize}&offset=${
        (page - 1) * pageSize
      }`
    );
  };

  getProfileOffer = (data, pageSize, page) => {
    return this.get(
      `web/offer/list?nftId=${data.nftId}&isProfile=${
        data.isProfile
      }&walletAddress=${data.walletAddress}&limit=${pageSize}&offset=${
        (page - 1) * pageSize
      }`
    );
  };

  reOffer = (data) => {
    return this.put(`web/offer/reoffer`, data);
  };

  acceptOffer = (id) => {
    return this.patch(`web/offer/accept?id=${id}`);
  };

  cancelOffer = (id) => {
    return this.patch(`web/offer/cancel?id=${id}`);
  };

  saveTransactionTx = (data) => {
    return this.put(`web/offer/transactiontx`, data);
  };

  checkTotalSupply = () => {
    return this.post(`web/nft/check-total-supply`);
  };
}
export const autionService = new AutionService();
