import { useTranslation } from "react-i18next";
import { Page404Img } from "./commonSvg";
import StanButton from "../component/StanButton/StanButton";
import { history } from "../App";
import { EXPLORE_PATH } from "./Constant";

export default function Page404(props) {
  const { t } = useTranslation(["common"]);

  return (
    <div
      style={{
        overflow: "hidden",
        minHeight: "100vh",
      }}
    >
      <div className="d-flex flex-column justify-content-center align-items-center">
        <Page404Img />
        <h2>{t("TITLE.PAGE_NOT_FOUND")}</h2>
        <span>{t("TEXT.PLEASE_TRY_OTHER_SEARCH")}</span>
        <div className="mt-3">
          <StanButton
            title={t("BUTTON.GO_TO_EXPLORE")}
            type="primary"
            handleOnClick={() => history.push(EXPLORE_PATH.HOME)}
          />
        </div>
      </div>
    </div>
  );
}
