import React from "react";
import { useTranslation } from "react-i18next";
import { bannerStan } from "../../../../common/commonSvg";
import "./Footer.css";
export default function Footer() {
  const { t } = useTranslation(["common"]);
  return (
    <div
      style={{ minHeight: "190px", backgroundImage: `url("/img/Footer2.png")` }}
    >
      <div
      // style={{
      //   borderBottom: "0.5px solid rgba(255, 255, 255, 0.65)",
      // }}
      >
        <div className="container">
          <div
            style={{ padding: "50px 0 20px" }}
            className="d-flex justify-content-center "
          >
            {" "}
            {bannerStan()}
          </div>
          <div
            style={{ padding: "0px 0 20px 0" }}
            className="d-flex justify-content-center social_list"
          >
            <img
              alt=""
              src="/svg/footer-facebook.svg"
              onClick={() =>
                window.open("https://www.facebook.com/stan.global.official")
              }
            />
            <img
              alt=""
              src="/svg/footer-instagram.svg"
              onClick={() =>
                window.open("https://www.instagram.com/stan_global_official/")
              }
            />
            <img
              alt=""
              src="/svg/footer-youtube.svg"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/channel/UCL-Si66w6VysZWJ2ig2HXmg/featured"
                )
              }
            />
            {/* <img alt="" src="/svg/footer-linkedin.svg" /> */}
            <img
              alt=""
              src="/svg/footer-twitter.svg"
              width={24}
              height={24}
              onClick={() => window.open("https://twitter.com/stan_co_ltd")}
            />
            <img
              alt=""
              src="/svg/discord.svg"
              onClick={() => window.open("https://discord.gg/sa5pVTu3WJ")}
            />
          </div>
          <p className="p_login text-center ">
            {t("TITLE.FOOTER_DESCRIPTION")}
          </p>
        </div>
      </div>
      {/* <div className="container">
        <div className="container pt-5 row d-flex justify-content-center social_list m-0">
          <div className="col-6 col-md d-flex justify-content-center mt-2 mt-md-0">
            <i className="fab fa-linkedin-in" />
            <span className="social">LinkedIn</span>
          </div>
          <div className="col-6 col-md d-flex justify-content-center mt-2 mt-md-0">
            <i className="fab fa-medium-m" />

            <span className="social">Medium</span>
          </div>
          <div className="col-6 col-md d-flex justify-content-center mt-2 mt-md-0">
            <i className="fab fa-facebook-f" />

            <span className="social">Facebook</span>
          </div>
          <div className="col-6 col-md d-flex justify-content-center mt-2 mt-md-0">
            <i className="fab fa-twitter" />

            <span className="social">Twitter</span>
          </div>
          <div className="col-6 col-md d-flex justify-content-center mt-2 mt-md-0">
            <i className="fab fa-instagram" />

            <span className="social">Instagram</span>
          </div>
          <div className="col-6 col-md d-flex justify-content-center mt-2 mt-md-0">
            <i className="fab fa-youtube" />

            <span className="social">Youtube</span>
          </div>
          <div className="col-6 col-md d-flex justify-content-center mt-2 mt-md-0">
            <i className="fab fa-behance" />

            <span className="social">Behance</span>
          </div>
          <div className="col-6 col-md d-flex justify-content-center mt-2 mt-md-0">
            <i className="fab fa-dribbble" />

            <span className="social">Dribble</span>
          </div>
        </div>
      </div> */}
    </div>
  );
}
