import { Image, message, Tabs } from "antd";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { history, openNotificationWithIcon } from "../../App";
import {
  activityTabPaneSvg,
  bannerSquare,
  collectionTabPaneSvg,
  copySvg,
  createdTabPaneSvg,
  isArtistSvg,
  listingTabPaneSvg,
  offersReceivedSvg,
  ownedTabPaneSvg,
} from "../../common/commonSvg";
import {
  getCreatedNFT,
  getMadeOffers,
  getOwnedNFT,
  getProfileActivities,
  getProfileCollections,
  getProfileListing,
  getReceivedOffers,
  getUSDRate,
  getUserProfileByAddress,
} from "../../redux/action/UserAction";
import { OPEN_QR_DRAWER } from "../../redux/types/ModalType";
import ActivityTab from "./ProfileTab/ActivityTab/ActivityTab";
import CreatedTab from "./ProfileTab/CreatedTab/CreatedTab";
import OwnedTab from "./ProfileTab/OwnedTab/OwnedTab";

import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Web3 from "web3";
import { NFT_SORT_BY, PROFILE_TABPANE, STAN_ROLE } from "../../common/Constant";
import {
  CLEAR_STATE,
  SET_ACTIVE_TAB_PROFILE,
} from "../../redux/types/UserType";
import "./Collections.css";
import CollectionsTab from "./ProfileTab/CollectionsTab/CollectionsTab";
import ListingTab from "./ProfileTab/ListingTab/ListingTab";
import OffersReceived from "./ProfileTab/OffersReceived/OffersReceived";
import OfferSent from "./ProfileTab/OffersSent/OffersSent";

const { TabPane } = Tabs;

export default function Profile() {
  //______________ INITIAL STATE ______________//
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const { walletAddress } = useParams();
  const { nftProfileUser } = useSelector((state) => state.UserReducer);
  const [activeTabPane, setActiveTabPane] = useState(
    PROFILE_TABPANE.PROFILE_COLLECTIONS
  );
  const { profileCurrentActiveTab } = useSelector(
    (state) => state.ModalReducer
  );

  //__________________________________________//

  //______________ HANDLE FUNCs ______________//
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getUSDRate());
    if (!Cookies.get("TokenId") && Cookies.get("UserAddress")) {
      dispatch({ type: OPEN_QR_DRAWER });
    }
  }, []);

  useEffect(() => {
    if (walletAddress && Web3.utils.isAddress(walletAddress)) {
      // if (Cookies.get("TokenId")) {
      dispatch({ type: CLEAR_STATE });
      // dispatch(getTalentByAddress(walletAddress, Cookies.get("TokenId")));
      dispatch(getUserProfileByAddress(walletAddress));
      // }
    } else {
      history.push("/home");
      openNotificationWithIcon("error", "Error", "Invalid wallet address");
    }
  }, [walletAddress]);

  // MEMORIZE CURRENT ACTIVE TABPANE / DEFAULT TABPANE FOR ARTIST AND FAN
  useEffect(() => {
    if (profileCurrentActiveTab !== "") {
      setActiveTabPane(profileCurrentActiveTab);
    } else if (profileCurrentActiveTab === "") {
      if (nftProfileUser?.role === STAN_ROLE.TALENT) {
        setActiveTabPane(PROFILE_TABPANE.PROFILE_COLLECTIONS);
      } else {
        setActiveTabPane(PROFILE_TABPANE.PROFILE_OWNED);
      }
    }
  }, [profileCurrentActiveTab, nftProfileUser]);

  useEffect(() => {
    // if (activeTabPane === PROFILE_TABPANE.PROFILE_COLLECTIONS) {
    //   dispatch(getProfileCollections(10, 1, walletAddress.toLowerCase(), ""));
    // }
    // if (activeTabPane === PROFILE_TABPANE.PROFILE_OWNED) {
    //   dispatch(
    //     getOwnedNFT(
    //       {
    //         orderBy: NFT_SORT_BY.RECENTLY_CREATED,
    //         walletAddress: walletAddress.toLowerCase(),
    //       },
    //       10,
    //       1
    //     )
    //   );
    // }
    // if (activeTabPane === PROFILE_TABPANE.PROFILE_CREATED) {
    //   dispatch(
    //     getCreatedNFT(
    //       {
    //         orderBy: NFT_SORT_BY.RECENTLY_CREATED,
    //         walletAddress: walletAddress.toLowerCase(),
    //       },
    //       10,
    //       1
    //     )
    //   );
    // }
    // if (activeTabPane === PROFILE_TABPANE.PROFILE_ACTIVITY) {
    //   dispatch(
    //     getProfileActivities(
    //       { walletAddress: walletAddress.toLowerCase() },
    //       10,
    //       1
    //     )
    //   );
    // }
    if (activeTabPane === PROFILE_TABPANE.PROFILE_LISTING) {
      dispatch(getProfileListing(null, null));
    }
    if (activeTabPane === PROFILE_TABPANE.PROFILE_OFFERS_SENT) {
      dispatch(getMadeOffers(walletAddress));
    }
    if (activeTabPane === PROFILE_TABPANE.PROFILE_OFFERS_RECEIVED) {
      dispatch(getReceivedOffers(walletAddress));
    }
  }, [activeTabPane, walletAddress]);

  useEffect(() => {
    console.log("HERE =>", nftProfileUser);
  }, [nftProfileUser]);

  function renderTabPaneTitle(title, key) {
    let icon;
    if (key === PROFILE_TABPANE.PROFILE_COLLECTIONS) {
      icon = collectionTabPaneSvg(activeTabPane === key);
    } else if (key === PROFILE_TABPANE.PROFILE_OWNED) {
      icon = ownedTabPaneSvg(activeTabPane === key);
    } else if (key === PROFILE_TABPANE.PROFILE_CREATED) {
      icon = createdTabPaneSvg(activeTabPane === key);
    } else if (key === PROFILE_TABPANE.PROFILE_ACTIVITY) {
      icon = activityTabPaneSvg(activeTabPane === key);
    } else if (key === PROFILE_TABPANE.PROFILE_LISTING) {
      icon = listingTabPaneSvg(activeTabPane === key);
    } else if (key === PROFILE_TABPANE.PROFILE_OFFERS_SENT) {
      icon = activityTabPaneSvg(activeTabPane === key);
    } else if (key === PROFILE_TABPANE.PROFILE_OFFERS_RECEIVED) {
      icon = offersReceivedSvg(activeTabPane === key);
    }
    return (
      <span style={{ color: activeTabPane === key ? "#49D19A" : "#CBCBCB" }}>
        {icon} {title}
      </span>
    );
  }
  //__________________________________________//

  //______________ RENDER FUNCs ______________//

  //__________________________________________//
  return (
    <>
      <div
        style={{
          background: "#FFFFFF",
          overflow: "hidden",
          minHeight: "100vh",
        }}
      >
        <div className="bannerStan">
          <Image
            src={
              nftProfileUser.cover
                ? nftProfileUser.cover
                : "/img/DefaultProfileCover.png"
            }
            preview={false}
            fallback="/img/DefaultProfileCover.png"
          />
          <div className="userAvatar">
            <Image
              src={
                nftProfileUser.avatar
                  ? nftProfileUser.avatar
                  : "/img/avatar-default.png"
              }
              preview={false}
              style={{ borderRadius: "32px" }}
              fallback="/img/avatar-default.png"
            />
            {nftProfileUser && nftProfileUser.role === STAN_ROLE.TALENT ? (
              <div className="isArtist">
                <span>{isArtistSvg()}</span>
              </div>
            ) : null}
          </div>
          <div className="userName">
            <h3>{nftProfileUser?.name}</h3>
          </div>
          <div className="walletAddress">
            <span>
              {walletAddress?.substring(0, 6)}...
              {walletAddress?.substring(walletAddress.length - 4)}
            </span>
            {copySvg(function Copy() {
              navigator.clipboard.writeText(walletAddress);
              message.success("Copied to clipboard!");
            })}
          </div>
          <div className="avatarSquare">
            <span>{bannerSquare()}</span>
          </div>
        </div>

        <div className="collectionFilter">
          <Tabs
            defaultActiveKey={activeTabPane}
            activeKey={activeTabPane}
            onChange={(e) => {
              setActiveTabPane(e);
              dispatch({
                type: SET_ACTIVE_TAB_PROFILE,
                profileCurrentActiveTab: e,
              });
            }}
            size="large"
          >
            {nftProfileUser && nftProfileUser.role === STAN_ROLE.USER ? null : (
              <TabPane
                tab={renderTabPaneTitle(
                  t("TABPANE.COLLECTIONS"),
                  PROFILE_TABPANE.PROFILE_COLLECTIONS
                )}
                key={PROFILE_TABPANE.PROFILE_COLLECTIONS}
              >
                <div className="tabContent">
                  <CollectionsTab />
                </div>
              </TabPane>
            )}
            <TabPane
              tab={renderTabPaneTitle(
                t("TABPANE.OWNED"),
                PROFILE_TABPANE.PROFILE_OWNED
              )}
              key={PROFILE_TABPANE.PROFILE_OWNED}
            >
              <OwnedTab />
            </TabPane>
            {nftProfileUser && nftProfileUser.role === STAN_ROLE.USER ? null : (
              <TabPane
                tab={renderTabPaneTitle(
                  t("TABPANE.CREATED"),
                  PROFILE_TABPANE.PROFILE_CREATED
                )}
                key={PROFILE_TABPANE.PROFILE_CREATED}
              >
                <CreatedTab />
              </TabPane>
            )}
            <TabPane
              tab={renderTabPaneTitle(
                t("TABPANE.ACTIVITY"),
                PROFILE_TABPANE.PROFILE_ACTIVITY
              )}
              key={PROFILE_TABPANE.PROFILE_ACTIVITY}
            >
              <ActivityTab />
            </TabPane>
            <TabPane
              tab={renderTabPaneTitle(
                t("TABPANE.LISTING"),
                PROFILE_TABPANE.PROFILE_LISTING
              )}
              key={PROFILE_TABPANE.PROFILE_LISTING}
            >
              <ListingTab />
            </TabPane>
            <TabPane
              tab={renderTabPaneTitle(
                t("TABPANE.ACTIVE_OFFER_SENT").toUpperCase(),
                PROFILE_TABPANE.PROFILE_OFFERS_SENT
              )}
              key={PROFILE_TABPANE.PROFILE_OFFERS_SENT}
            >
              <OfferSent />
            </TabPane>
            <TabPane
              tab={renderTabPaneTitle(
                t("TABPANE.ACTIVE_OFFER_RECEIVED").toUpperCase(),
                PROFILE_TABPANE.PROFILE_OFFERS_RECEIVED
              )}
              key={PROFILE_TABPANE.PROFILE_OFFERS_RECEIVED}
            >
              <OffersReceived />
            </TabPane>
            {/* <TabPane
              tab={renderTabPaneTitle(t("TABPANE.MORE"), "PROFILE_MORE")}
              key="PROFILE_MORE"
            >
              <MoreTab content={moreTabContent} />
            </TabPane> */}
          </Tabs>
        </div>
      </div>
    </>
  );
}
