import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { metamaskSvg } from "../../common/commonSvg";
import { checkWalletIsConnected } from "../../redux/action/Web3Action";

export default function ConnectWallet(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation(["common"]);
  const { payload } = props;

  return (
    <>
      <div
        className="walletPlatform"
        onClick={() => dispatch(checkWalletIsConnected())}
      >
        {metamaskSvg()}
        <span>METAMASK</span>
      </div>
    </>
  );
}
