import { Col, Input, message, Modal, Row, Upload } from "antd";
import Compress from "browser-image-compression";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { history, openNotificationWithIcon } from "../../App";
import {
  facebookSvg,
  instaSvg,
  tiktokSvg,
  twitterSvg,
  uploadSvg,
  youtubeSvg,
} from "../../common/commonSvg";
import StanButton from "../../component/StanButton/StanButton";
import { getStanRoyalty } from "../../redux/action/AutionAction";
import { createCollection } from "../../redux/action/CollectionAction";
import { getPresignMedia } from "../../redux/action/UserAction";
import {
  CLEAR_STATE,
  PROCESSING,
  REMOVE_LOGO,
  REQUESTING,
} from "../../redux/types/UserType";
import {
  checkIfNumber,
  compressImage,
  isValidType,
} from "../../util/settings/utils";
import "./Collections.css";
const { TextArea } = Input;

export default function CreateCollection() {
  // ______________STATE INITIAL______________ //
  const { t } = useTranslation(["common", "collection", "components", "nftDetail"]);
  const dispatch = useDispatch();
  const userProfile = JSON.parse(localStorage.getItem("UserProfile"));
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [errors, setErrors] = useState({});
  const [creatorRoyalty, setCreatorRoyalty] = useState();

  const { logoPath, bannerPath } = useSelector((state) => state.UserReducer);
  const { stanRoyalty, nftStatus } = useSelector(
    (state) => state.AutionReducer
  );
  const [fileListAvt, setFileListAvt] = useState([]);
  const [fileListBanner, setFileListBanner] = useState([]);
  const [dataSend, setDataSend] = useState({
    name: "",
    imageLogo: "",
    imageBanner: "",
    description: "",
    socialLink: [
      {
        type: "TWITTER",
        url: "",
      },
      {
        type: "INSTAGRAM",
        url: "",
      },
      {
        type: "FACEBOOK",
        url: "",
      },
      {
        type: "YOUTUBE",
        url: "",
      },
      {
        type: "TIKTOK",
        url: "",
      },
    ],
    networkBlockchain: "POLYGON",
    type: "CRYPTO",
    ownerWalletAddress: Cookies.get("UserAddress"),
    creatorRoyalty: "",
  });

  // ______________STATE INITIAL______________ //

  useEffect(() => {
    if (+stanRoyalty.ratioCreatorVal) {
      setCreatorRoyalty(+stanRoyalty.ratioCreatorVal);
    }
  }, [stanRoyalty]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getStanRoyalty());
    if (!Cookies.get("UserAddress")) {
      openNotificationWithIcon("error", "Error", t("MSG.NOT_CONNECT_WALLET"));
      history.push("/home");
    } else if (userProfile && userProfile.role === 1) {
      openNotificationWithIcon(
        "error",
        "Error",
        t("MSG.UNAUTHORIZED_CREATE_COLLECTION")
      );
      history.push("/home");
    }
    if (!Cookies.get("TokenId") && Cookies.get("UserAddress")) {
      history.push("/collections");
    }
  }, []);

  useEffect(() => {
    setDataSend({ ...dataSend, imageLogo: logoPath, imageBanner: bannerPath });
  }, [logoPath, bannerPath]);

  useEffect(() => {
    if (nftStatus === PROCESSING) {
      openNotificationWithIcon(
        "info",
        t("LOADING.TRANSACTION_IS_INPROGRESS", {
          ns: "components",
        }),
        t("LOADING.TRANSACTION_IS_INPROGRESS_PLEASE_WAIT", {
          ns: "components",
        })
      );
    }
  }, [nftStatus]);

  // _________________________HANDLE UPLOAD_________________________ //

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);

      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const onChangeAvt = async ({ fileList: newFileList }) => {
    if (validateAvt(newFileList[0])) {
      if (!newFileList[0].url && !newFileList[0].preview) {
        newFileList[0].preview = await getBase64(newFileList[0].originFileObj);
      }
      setFileListAvt([
        {
          uid: "1",
          name: newFileList[0].name,
          status: "done",
          url: newFileList[0].preview,
        },
      ]);
    }
    // }
  };

  const onChangeBanner = async ({ fileList: newFileList }) => {
    if (validateBanner(newFileList[0])) {
      if (isValidType(newFileList[0].type)) {
        if (!newFileList[0].url && !newFileList[0].preview) {
          newFileList[0].preview = await getBase64(
            newFileList[0].originFileObj
          );
        }
        setFileListBanner([
          {
            uid: "1",
            name: newFileList[0].name,
            status: "done",
            url: newFileList[0].preview,
          },
        ]);
      }
    }
  };

  function validateAvt(file) {
    let isValid = true;
    const isLt2M = file.size / 1024 / 1024 < 10;

    if (!isValidType(file.type)) {
      message.error(t("MSG.UNSUPPORTED_FILE"), 5);
      isValid = false;
    }

    if (!isLt2M) {
      message.error(t("MSG.TOO_LARGE_IMAGE"), 5);
      isValid = false;
    }

    return isValid;
  }

  function validateBanner(file) {
    let isValid = true;
    const isLt2M = file.size / 1024 / 1024 < 20;

    if (!isValidType(file.type)) {
      message.error(t("MSG.UNSUPPORTED_FILE"), 5);
      isValid = false;
    }

    if (!isLt2M) {
      message.error(t("MSG.TOO_LARGE_IMAGE"), 5);
      isValid = false;
    }

    return isValid;
  }

  const beforeUploadAvt = async (file) => {
    console.log("BEFORE UP =>", file);

    if (validateAvt(file)) {
      let compressedImage = await compressImage(file);
      dispatch(getPresignMedia("collections-nft", "logo", compressedImage));
    }
    return file;
  };

  const beforeUploadBanner = async (file) => {
    console.log("BEFORE UP =>", file);

    if (validateAvt(file)) {
      let compressedImage = await compressImage(file);
      dispatch(getPresignMedia("collections-nft", "banner", compressedImage));
    }

    return file;
  };

  const handleCancel = () => setPreviewVisible(false);

  // _________________________HANDLE UPLOAD_________________________ //

  function handleChangeInput(e) {
    const value = e.target.value;
    if (
      e.target.name === "TWITTER" ||
      e.target.name === "INSTAGRAM" ||
      e.target.name === "FACEBOOK" ||
      e.target.name === "YOUTUBE" ||
      e.target.name === "TIKTOK"
    ) {
      let socialMedia = dataSend.socialLink;
      const myRowIndex = socialMedia.findIndex(
        (row) => row.type === e.target.name
      );
      socialMedia[myRowIndex].url = e.target.value;
      setDataSend({ ...dataSend, socialLink: socialMedia });
    } else if (e.target.name === "creatorRoyalty") {
      if (checkIfNumber(+value)) {
        setCreatorRoyalty(+value);
        setDataSend({ ...dataSend, [e.target.name]: +value });
      }
    } else {
      setDataSend({ ...dataSend, [e.target.name]: value });
    }
  }
  function validate() {
    let isValid = true;
    let error = {};
    var regexURL = /^(https?):\/\/[^\s$.?#].[^\s]*$/;
    if (dataSend.name === "" || dataSend.name.trim() === "") {
      error["name"] = t("MSG.NOT_NULL", { name: "The Name" });
      isValid = false;
    }
    if (dataSend.imageLogo === "") {
      error["imageLogo"] = t("MSG.REQUIRED", { name: "Logo image" });
      isValid = false;
    }
    if (
      dataSend.socialLink[0].url &&
      !dataSend.socialLink[0].url.match(regexURL)
    ) {
      error["twitter"] = t("MSG.INVALID_URL");
      isValid = false;
    }
    if (
      dataSend.socialLink[1].url &&
      !dataSend.socialLink[1].url.match(regexURL)
    ) {
      error["instagram"] = t("MSG.INVALID_URL");
      isValid = false;
    }
    if (
      dataSend.socialLink[2].url &&
      !dataSend.socialLink[2].url.match(regexURL)
    ) {
      error["facebook"] = t("MSG.INVALID_URL");
      isValid = false;
    }
    if (
      dataSend.socialLink[3].url &&
      !dataSend.socialLink[3].url.match(regexURL)
    ) {
      error["youtube"] = t("MSG.INVALID_URL");
      isValid = false;
    }
    if (
      dataSend.socialLink[4].url &&
      !dataSend.socialLink[4].url.match(regexURL)
    ) {
      error["tiktok"] = t("MSG.INVALID_URL");
      isValid = false;
    }
    if (!creatorRoyalty || creatorRoyalty < 1 || creatorRoyalty > 10) {
      // error["creatorRoyalty"] = t("MSG.INVALID_CREATOR_ROYALTY");
      error["creatorRoyalty"] =
        "Creator Royalty value is available for change manually with adjustment range from 1% to 10%";

      isValid = false;
    }
    setErrors(error);
    return isValid;
  }

  function addCollection() {
    if (validate()) {
      if (!dataSend?.creatorRoyalty) {
        dataSend.creatorRoyalty = creatorRoyalty;
      }
      dispatch({ type: REQUESTING });
      dispatch(createCollection(dataSend));
    }
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#FFFFFF",
          minHeight: "100vh",
          padding: "64px 340px",
        }}
        className="createCollection"
      >
        <div>
          <h1>{t("TITLE", { ns: "collection" })}</h1>
        </div>
        <div style={{ padding: "36px 0px" }}>
          <span>{t("CREATE_DESCRIPTION", { ns: "collection" })}</span>
        </div>
        <div style={{ display: "inline-grid" }}>
          <span style={{ fontWeight: "bold" }}>
            {t("LOGO_IMAGE", { ns: "collection" })}
            <span style={{ color: "red" }}> *</span>
          </span>
          <span>{t("IMAGE_DESCRIPTION", { ns: "collection" })}</span>
          <span style={{ color: "red" }}>{errors.imageLogo}</span>
        </div>
        <div className="logoImage" style={{ height: "100%", width: "100%" }}>
          <Upload
            listType="picture-card"
            fileList={fileListAvt}
            onChange={onChangeAvt}
            onPreview={handlePreview}
            onRemove={() => {
              setFileListAvt([]);
              dispatch({ type: REMOVE_LOGO });
            }}
            customRequest={dummyRequest}
            beforeUpload={beforeUploadAvt}
            style={{ width: "500px" }}
          >
            {fileListAvt.length === 0 ? uploadSvg() : null}
          </Upload>
        </div>
        <div style={{ display: "inline-grid" }}>
          <span style={{ fontWeight: "bold" }}>
            {t("BANNER_IMAGE", { ns: "collection" })}
            <span style={{ color: "#8D8D8D" }}>
              {" "}
              ({t("OPTIONAL", { ns: "common" }).toUpperCase()})
            </span>
          </span>
          <span>{t("BANNER_DESCRIPTION", { ns: "collection" })}</span>
        </div>
        <div className="bannerImage">
          <Upload
            listType="picture-card"
            fileList={fileListBanner}
            onChange={onChangeBanner}
            onPreview={handlePreview}
            onRemove={() => setFileListBanner([])}
            customRequest={dummyRequest}
            beforeUpload={beforeUploadBanner}
            style={{ width: "500px" }}
          >
            {fileListBanner.length === 0 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {uploadSvg()}
                  <span>{t("UPLOAD_IMAGE_HERE", { ns: "collection" })}</span>
                </div>
              </>
            ) : null}
          </Upload>
        </div>
        <div className="collectionInput">
          <span style={{ fontWeight: "bold" }}>
            {t("NAME", { ns: "collection" })}
            <span style={{ color: "red" }}> *</span>
          </span>
          <Input
            placeholder={t("PLACEHOLDER.NAME", { ns: "collection" })}
            name="name"
            size="large"
            maxLength={50}
            onChange={(e) => handleChangeInput(e)}
          />
          <span style={{ color: "red" }}>{errors.name}</span>
        </div>
        <div className="collectionInput">
          <span style={{ fontWeight: "bold" }}>
            {t("DESCRIPTION", { ns: "collection" })}
          </span>
          <TextArea
            rows={4}
            name="description"
            maxLength={2000}
            onChange={(e) => handleChangeInput(e)}
          />
        </div>
        <div className="collectionInput">
          <span style={{ fontWeight: "bold" }}>
            {t("SOCIAL_LINK", { ns: "collection" })}
          </span>
          <Input
            size="large"
            name="TWITTER"
            onChange={(e) => handleChangeInput(e)}
            placeholder="https://www.abcdfer.com/abcdefghjk"
            style={{ color: "#ffffff", margin: "8px 0px" }}
            prefix={
              <div style={{ padding: "0 12px", borderRight: "1px solid grey" }}>
                {twitterSvg(null, true)}
              </div>
            }
          />
          <span style={{ color: "red" }}>{errors.twitter}</span>
          <Input
            size="large"
            name="INSTAGRAM"
            onChange={(e) => handleChangeInput(e)}
            placeholder="https://www.abcdfer.com/abcdefghjk"
            style={{ color: "#ffffff", margin: "8px 0px" }}
            prefix={
              <div style={{ padding: "0 10px", borderRight: "1px solid grey" }}>
                {instaSvg(null, true)}
              </div>
            }
          />
          <span style={{ color: "red" }}>{errors.instagram}</span>

          <Input
            size="large"
            name="FACEBOOK"
            onChange={(e) => handleChangeInput(e)}
            placeholder="https://www.abcdfer.com/abcdefghjk"
            style={{ color: "#ffffff", margin: "8px 0px" }}
            prefix={
              <div style={{ padding: "0 10px", borderRight: "1px solid grey" }}>
                {facebookSvg(null, true)}
              </div>
            }
          />
          <span style={{ color: "red" }}>{errors.facebook}</span>

          <Input
            size="large"
            name="YOUTUBE"
            onChange={(e) => handleChangeInput(e)}
            placeholder="https://www.abcdfer.com/abcdefghjk"
            style={{ color: "#ffffff", margin: "8px 0px" }}
            prefix={
              <div style={{ padding: "0 10px", borderRight: "1px solid grey" }}>
                {youtubeSvg(null, true)}
              </div>
            }
          />
          <span style={{ color: "red" }}>{errors.youtube}</span>
          <Input
            size="large"
            name="TIKTOK"
            onChange={(e) => handleChangeInput(e)}
            placeholder="https://www.abcdfer.com/abcdefghjk"
            style={{ color: "#ffffff", margin: "8px 0px" }}
            prefix={
              <div style={{ padding: "0 10px", borderRight: "1px solid grey" }}>
                {tiktokSvg(null, true)}
              </div>
            }
          />
          <span style={{ color: "red" }}>{errors.tiktok}</span>
        </div>

        {stanRoyalty?.ratioCreatorVal > 0 ? (
          <div className="mb-3">
            <div className="mt-3 feeSumary">
              <span style={{ fontWeight: "bold" }}>
                {t("TITLE.FEE_SUMMARY", { ns: "nft" })}
              </span>
            </div>
            <Row gutter={16}>
              <Col lg={8} md={8} xs={24}>
                <div className="d-flex justify-content-between feeItem">
                  <span style={{ fontWeight: "bold" }}>
                    {t("TITLE.ROYALTY_FEE", { ns: "nft" })}
                  </span>
                  <span className="feeValue">
                    {+creatorRoyalty + +stanRoyalty.ratioStanVal}%
                  </span>
                </div>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col lg={8} md={8} xs={24}>
                <div className="d-flex justify-content-between align-items-center feeItem">
                  <span>
                    {t("TEXT.STAN_ROYALTY", { ns: "nft" })} {` (%)`}
                  </span>
                  <span className="feeValue">{+stanRoyalty.ratioStanVal}</span>
                </div>
                <div className="d-flex justify-content-between align-items-center feeItem">
                  <span>
                    {t("ARTIST_ROYALTY", { ns: "collection" })}
                    {` (%)`}
                  </span>
                  <div>
                    <Input
                      size="large"
                      maxLength={2}
                      name="creatorRoyalty"
                      value={creatorRoyalty}
                      onChange={(e) => handleChangeInput(e)}
                      className="creatorRoyalty"
                    />
                  </div>
                </div>
                <span style={{ color: "red", float: "right" }}>
                  {errors.creatorRoyalty}
                </span>
              </Col>
            </Row>
          </div>
        ) : null}

        <div
          className="row"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div style={{ width: "25%", margin: "0px 5px" }}>
            <StanButton
              title={t("CANCEL", { ns: "collection" })}
              handleOnClick={() => {
                dispatch({ type: CLEAR_STATE });
                history.push("/collections");
              }}
              type="secondary"
            />
          </div>
          <div style={{ width: "25%", margin: "0px 5px" }}>
            <StanButton
              title={t("SAVE", { ns: "collection" })}
              disabled={nftStatus === REQUESTING || nftStatus === PROCESSING}
              handleOnClick={() => addCollection()}
              type="primary"
            />
          </div>
        </div>
      </div>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        width="1000px"
        onCancel={handleCancel}
      >
        <img
          alt={t("ALT_IMAGE_EXAMPLE", { ns: "components" })}
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
}
