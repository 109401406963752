export const BUY = "BUY";
export const OFFER = "OFFER";
export const ON_BID = "ON_BID";
export const SELLABLE = "SELLABLE";
export const SUCCESS = "success";
export const PENDING = "pending";

export const NFT_ERROR = {
  NFT_IN_PROCESS: "Please wait while your transaction is processing.",
};
